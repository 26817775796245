import {
    ColorVariant,
    GameResultData,
    GameStatus,
    IPlayer,
    OnlineStatus,
    UserBalanceType,
    GameModeType,
    ITopPlayers,
} from "../models";
import { GameTime, GameTimeValues } from "../lobbyStore";

export type Dice = [number] | [number, number] | [number, number, number];

export interface IClockPerPlayer {
    color: ColorVariant;
    timeleft: number;
    running: boolean;
}

export interface IConnectivityPerPlayer {
    idx: number;
    status: OnlineStatus;
}

export interface IGameState {
    fen: string;
    legalMoves: string[];
    legalPieces: string[];
    undo: null | boolean;
}

export interface IGameSettings {
    bet: number;
    doubling: boolean;
    fen: string;
    private: boolean;
    privateChat: boolean;
    rake: number;
    randomColor: boolean;
    rounds: number;
    timeControl: { time: number; increment: number };
    increment: number;
    time: number;
    undo: boolean;
}

export interface IDoublingData {
    multiplier: number;
    accepted?: boolean;
    aggressor?: ColorVariant;
}

export interface IRematchInfo {
    [ColorVariant.white]: null | boolean;
    [ColorVariant.black]: null | boolean;
    gameId?: string;
    gameCreatedTime?: string;
}

export interface IGuest {
    id: string;
    nickname: string;
    avatar?: string;
}
export interface IInitGameData {
    clock: [IClockPerPlayer, IClockPerPlayer];
    connectivity: [IConnectivityPerPlayer, IConnectivityPerPlayer];
    doubling: IDoublingData;
    drawingProposer: null | ColorVariant;
    history: string[];
    inactivity: null;
    messages: [];
    players: [IPlayer, IPlayer];
    round: number;
    settings: IGameSettings;
    state: IGameState;
    status: GameStatus;
    over: GameResultData | null;
    rematch: IRematchInfo;
    guests: IGuest[];
}

export interface IPiece {
    color: ColorVariant;
    piece: string;
}

export type IBoard = {};

export interface IGameDialogMessage {
    message;
    color: ColorVariant;
    time: number;
    position: "rtl" | "ltr" | "center";
}

export const isGameDialogMessage = (
    t: IGameMessage
): t is IGameDialogMessage => {
    return (t as IGameDialogMessage).message !== undefined;
};

export interface IGameNotification {
    gameStatus?: GameStatus;
    message: {
        label: string;
        props: any;
        defaultValue: string;
    };
    time: number;
    position: "rtl" | "ltr" | "center";
}

export const isGameNotification = (t: IGameMessage): t is IGameNotification => {
    return (t as IGameMoveMessage).color === undefined;
};

export interface IGameMoveMessage {
    color: ColorVariant;
    pieces: {
        dice: number;
        type: string;
        moved: boolean;
        move: string;
    }[];
    time: number;
    position: "ltr" | "rtl";
    castling?: "long" | "short";
}

export const isGameMoveMessage = (t: IGameMessage): t is IGameMoveMessage => {
    return (t as IGameMoveMessage).pieces !== undefined;
};

export type IGameMessage =
    | IGameDialogMessage
    | IGameNotification
    | IGameMoveMessage;

export interface ILocaleItem {
    defaultValue: string;
    label: string;
    props?: any;
}

export interface INotification {
    gameStatus?: GameStatus;
    message: ILocaleItem;
    position: "center" | "left" | "right";
    time: number;
}

export interface IHistoryGame {
    balanceType: UserBalanceType;
    bet: number;
    created: string;
    doubling: {
        multiplier: number;
        aggressor: ColorVariant;
        accepted: boolean;
    };
    events: { event: string; color: ColorVariant; data: any }[];
    fen: string;
    history: string[];
    id: string;
    players: [IPlayer, IPlayer];
    private: boolean;
    privateChat: boolean;
    rematchInfo: IRematchInfo;
    settings: IGameSettings;
    status: GameStatus;
    time: GameTimeValues;
    timeControl: {
        time: number;
        increment: number;
    };
    timestamp: number;
    tournament: {
        gameId: string;
        id: string;
        stage: number;
        startDate: string;
        title: string;
        type: string;
        gameSeries: number;
    } | null;
    type: GameModeType;
    result: GameResultData | null;
    undo: boolean;
    willBeDeletedIn: number;
    challenged?: {
        id: string;
        nickname: string;
        avatar: string;
        isSystemAvatar: boolean;
    } | null;
    previousGameId?: string;
    participating?: boolean | undefined;
    winner?: ITopPlayers | null | undefined;
}
