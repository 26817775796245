import { makeStyles } from '@mui/styles';
import theme from '../../../../theme';


export const useStyles = makeStyles({


    menu: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
        backgroundColor: 'rgb(21, 23, 25)',
        padding: '0',
    },
    menuLinks: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
    },
    menuLink: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '2vw 0',
        [theme.breakpoints.between('sm', 'md')]: {
            flexDirection: 'column',
            padding: '1vw 0',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            flexDirection: 'column',
            padding: '0 0.5vw',
        },
        // '&:hover $hover': {
        //     display: 'flex',
        //     position: "absolute",
        //     bottom: '6px',
        //     left: '-50%',
        //     width: "200%",
        //     height: '5px',
        //     borderRadius: "50%",
        //     background: 'radial-gradient(circle, rgba(177,204,198,1) 0%, rgba(153,167,164,0.5) 83%)',
        //     boxShadow: '0px 0px 0px 0.5px rgba(177,204,198,1)',
        //     '@media (max-width: 385px)': {
        //         bottom: '-3px',
        //     },
        // },
    },

    hover: {
        display: "none"
    },

    linkIcon: {
        width: '8vw',
        height: '8vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '11vw',
            height: '11vw',
        },
    },
    darkShadow: {
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(16, 18, 17, 0.3)',
        zIndex: 15
    },

    link: {
        color: '#fff',
        fontSize: '0,5vw',
        textAlign: 'center',
        margin: 0,
        bottom: "5%",
        whiteSpace: 'nowrap',
        fontFamily: 'Montserrat',
        fontWeight: 600,
        lineHeight: '24.38px',
        display: 'inline',
        [theme.breakpoints.between('xs', 'sm')]: {
            fontSize: '3vw',
            bottom: "18%",
            fontWeight: 400,
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '14px',
            bottom: "14%",
            fontWeight: 400,
        },
    },

});
