import React, { useEffect } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { useHistory } from "react-router-dom";

import { useLocale } from "src/providers/LocaleProvider";
import paths from "src/consts/paths";
import useInitRematchTimer from "src/hooks/useInitRematchTimer";
import { usePlayer } from "src/sounds/use-player";
import UserAvatar from "src/components/UserAvatar";
import FadeInOutVertical from "src/components/Animated";
import useChangeTheme from "src/hooks/useChangeTheme";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
} from "src/pages/game-new/constants";
import { updateSearchParams } from "src/utils/update-search-params";
import { useChessBoardProps } from "src/pages/game-new/hooks/useChessBoardProps";

import useStores from "../../../../../../hooks/useStores";
import useStyles from "../../../common/rematchGeneralDialog/style";
import { endpointsRoot } from "../../../../../../core/endpoints";
import { CountdownCircular } from "../../../../../../components/countDownCircular";
import {
    ButtonType,
    GreenButton,
} from "../../../../../../components/buttons/greenButton";
import { ColorVariant, UserBalanceType } from "../../../../../../store/models";

const AcceptOrRejectAlert = ({ isXlTablet, isMultiTable = false }) => {
    const { gameStore, gamesStore } = useStores();
    const history = useHistory();
    const styles = useStyles();
    const appearanceTheme = useChangeTheme();
    const soundPlayer = usePlayer();
    const { onLobbyClose } = useChessBoardProps();
    const MAX_XL_TABLET_SIZE =
        window.innerWidth <= MAX_CHANGE_SCREEN_WIDTH &&
        window.innerWidth >= MAX_L_TABLET;

    useEffect(() => {
        const isRematchAccepted = gameStore.gameState.isRematchAccepted();
        if (isRematchAccepted && !isMultiTable) {
            history.push(`${paths.game}/${gameStore.rematch.gameId}`);
        } else if (isRematchAccepted && isMultiTable) {
            updateSearchParams({ id: undefined });
            const newGameTables = gamesStore.gameTables.map((table) => {
                if (table && table?.id === gameStore.currentGame?.id) {
                    return { ...table, id: gameStore.rematch.gameId as string };
                }
                return table;
            });
            gamesStore.setGameTables(newGameTables);
        }
    }, [
        gameStore?.rematch?.gameId,
        gameStore?.rematch[ColorVariant.black],
        gameStore?.rematch[ColorVariant.white],
    ]);

    const {
        game: {
            rematch: {
                acceptOrReject: { title, accept, reject },
            },
        },
    } = useLocale();

    const opponentColor = gameStore.gameState.getOpponentColor();

    const actualTimer = useInitRematchTimer(
        gameStore?.rematch?.gameCreatedTime,
        10000
    );

    if (!opponentColor) return null;

    const player = gameStore.gameState.getPlayerByColor(opponentColor);
    if (!player) return null;

    const onCancel = () => {
        gameStore.dialogs.rejectRematch();
        if (isMultiTable) {
            onLobbyClose?.();
            return;
        }
        gameStore.currentGame?.balanceType === UserBalanceType.play
            ? history.push(paths.diceChessLobby)
            : history.push(paths.lobbyFreePlay);
    };

    return (
        <FadeInOutVertical stylesProp={{ alignItems: "flex-end" }}>
            <div
                className={cn(
                    styles.rematchActionContainer,
                    appearanceTheme,
                    "acceptOrRejectAlert",
                    {
                        isMultiTable,
                    }
                )}
            >
                <div
                    className={cn(styles.flexContainer, {
                        isXlTablet: isXlTablet,
                        isMultiTable,
                    })}
                >
                    <div className={styles.avatarBlockWrapper}>
                        <UserAvatar
                            avatarUrl={
                                (player.avatar &&
                                    `${endpointsRoot}/avatars/${player.avatar}`) ||
                                ""
                            }
                            nickname={""}
                            className={cn(styles.avatarBlock, { isMultiTable })}
                            isSystemAvatar={player.isSystemAvatar}
                        />
                    </div>
                    <div>
                        <p className={cn(styles.title, { isMultiTable })}>
                            <span>{title("Let's play one more time")}</span>
                        </p>
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <div className={cn(styles.buttonBox, styles.rejectButton)}>
                        <GreenButton
                            type={ButtonType.purple}
                            fontSize={isMultiTable ? 13 : 16}
                            text={reject("Lobby")}
                            onClick={onCancel}
                            isWidthFixed={!MAX_XL_TABLET_SIZE}
                            width={MAX_XL_TABLET_SIZE ? "100px" : undefined}
                        />
                    </div>

                    <div className={styles.divider} />
                    <div className={cn(styles.buttonBox, styles.countdown)}>
                        <CountdownCircular
                            seconds={actualTimer}
                            reset={0}
                            size={45}
                            pause={false}
                            onEnd={() => {
                                gameStore.dialogs.rejectRematch();

                                if (isMultiTable) return;

                                gameStore.currentGame?.balanceType ===
                                UserBalanceType.play
                                    ? history.push(paths.diceChessLobby)
                                    : history.push(paths.lobbyFreePlay);
                            }}
                        />
                    </div>
                    <div className={styles.divider} />
                    <div className={cn(styles.buttonBox, styles.playButton)}>
                        <GreenButton
                            type={ButtonType.primary}
                            fontSize={isMultiTable ? 13 : 16}
                            text={accept("Play")}
                            onClick={() => {
                                soundPlayer("bidConfirmSoundPlay");
                                gameStore.dialogs.requestRematch();
                            }}
                            isWidthFixed={!MAX_XL_TABLET_SIZE}
                            width={MAX_XL_TABLET_SIZE ? "100px" : undefined}
                        />
                    </div>
                </div>
            </div>
        </FadeInOutVertical>
    );
};

export default observer(AcceptOrRejectAlert);
