import React from 'react';
import { useLocale } from 'src/providers/LocaleProvider';
import { observer } from 'mobx-react';

import { useWinOrLostStatus } from 'src/pages/game-new/hooks/useWinOrLostStatus';
import ViewModeModal, { textType } from '../ViewModeModal';
import useStores from 'src/hooks/useStores';
import { GameFinishReason } from 'src/store/models';
import getOppositeColor from 'src/utils/oppositeColor';

const WinOrLostModal = () => {
    const { gameStore } = useStores();
    const {
        game: {
            notifications: { playerWin, gameEndedDueToDraw },
            lossCauses: {
                viewMode: {
                    timeout: lossByTimeoutOnViewMode,
                    capture: lossByCaptureOnViewMode,
                    resign: lossByResignOnViewMode,
                    inactivity: lossByInactivityOnViewMode,
                },
            },
        },
    } = useLocale();
    const { resultData } = useWinOrLostStatus();

    const isStandoff = resultData?.cause === GameFinishReason.STANDOFF;

    if (!isStandoff && !resultData?.winner) return null;

    const losingPlayer = gameStore.gameState.getPlayerByColor(
        getOppositeColor(resultData.winner)
    );

    const lossCausesCompileData = { nickname: losingPlayer?.name };

    const lossCauses = {
        timeout: lossByTimeoutOnViewMode.compile(
            lossCausesCompileData,
            'the {{nickname}} lost by timeout'
        ),
        capture: lossByCaptureOnViewMode.compile(
            lossCausesCompileData,
            'the {{nickname}} king was taken'
        ),
        resign: lossByResignOnViewMode.compile(
            lossCausesCompileData,
            'the {{nickname}} gave up'
        ),
        inactivity: lossByInactivityOnViewMode.compile(
            lossCausesCompileData,
            'The {{nickname}} lost by inactivity'
        ),
    };

    const winnerPlayerText = () => {
        const playerByColor = gameStore.gameState.getPlayerByColor(
            resultData?.winner
        );

        return playerWin.compile(
            { playerNick: playerByColor?.name },
            `{{playerNick}} win the game`
        );
    };

    return (
        <ViewModeModal
            title={
                isStandoff
                    ? gameEndedDueToDraw('the game ended due to a draw')
                    : winnerPlayerText()
            }
            message={lossCauses[resultData.cause]}
            titleType={textType.gold}
            messsageType={textType.gray}
            delay={2000}
        />
    );
};

export default observer(WinOrLostModal);
