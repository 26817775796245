import { makeStyles } from "@mui/styles";

import { appColors } from "src/consts/app-config";

export const useStyles = makeStyles({
    wrapper: {
        display: "grid",
        justifyContent: "center",
        gridTemplateColumns: "auto auto",
        gridColumnGap: 25,
    },
    includeRounds: {
        gridTemplateColumns: "auto auto auto",
    },
    item: {
        display: "flex",
        alignItems: "center",
    },
    timeDetails: {
        margin: 0,
        color: "rgba(255, 255, 255, 0.8)",
        fontWeight: 500,
        marginLeft: 6,
        fontSize: "24px",
    },
    icon: {
        display: "flex",
        alignItems: "center",
        width: 20,
        height: 20,
    },
    betText: {
        marginLeft: 6,
        fontWeight: 500,
        fontSize: "24px",
        color: appColors.white,
    },
});
