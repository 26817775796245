import { makeStyles } from '@mui/styles';
import { appColors, backgroundColors } from 'src/consts/app-config';
import theme from 'src/theme';
import pattern from 'src/assets/pattern.png';

export const useStyles = makeStyles({
      dialogJoinGame: {
        padding: '0px 18px',
        '&.fastGame': {
          backdropFilter: 'none',
          position: 'absolute',
          padding: '15px',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          alignItems: 'center'
        }
    },
    dialogPaperJoinAwaiting: {
        maxWidth: 680,
        width: '100%',
        border: '1px solid #0B0C12',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '25px',
        margin: 0,
        overflowY: 'unset',
        background: '#17191F !important',
        borderRadius: '50px',
        gridGap: '20px',
        // '&.darkBlue': {
        //     background:
        //         'radial-gradient(40.69% 165.03% at 51.57% 62.5%, #343A59 0%, rgba(52, 58, 89, 0.437526) 45.01%, rgba(52, 58, 89, 0) 100%), #202233',
        // },
        // '&.darkGreen': {
        //     background: '#374949',
        // },
        [theme.breakpoints.down('md')]: {
            maxWidth: '95%',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '98%',
        },
        [theme.breakpoints.down(350)]: {
            padding: 18,
        },
        '@media screen and (max-height: 500px)': {
            transform: 'scale(0.9)',
            padding: 10,
            paddingTop: 5,
            maxHeight: 'unset',
        },
        '@media screen and (max-height: 400px)': {
            transform: 'scale(0.8)',
        },
        '@media screen and (max-height: 350px)': {
            transform: 'scale(0.7)',
        },
        '@media screen and (max-height: 300px)': {
            transform: 'scale(0.6)',
        },
        '@media screen and (max-height: 250px)': {
            transform: 'scale(0.45)',
        },
    },
    pattern: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.5,
        mixBlendMode: 'soft-light',
        zIndex: 0,
        backgroundImage: `url(${pattern})`,
    },

    title: {
        textShadow: '0px 3.19791px 0px rgba(0, 0, 0, 0.15)',
        fontSize: 24,
        fontWeight: 700,
        color: appColors.white,
        margin: 0,
        textAlign: 'center',
    },
    text: {
        color: appColors.white,
        fontSize: 16,
        fontWeight: 400,
        margin: '12px 0 28px',
        textAlign: 'center',
        '& span': {
            fontWeight: 700,
        },
    },
});
