import { makeStyles } from '@mui/styles';
import theme from 'src/theme';
import { appColors, backgroundColors } from 'src/consts/app-config';
import chroma from 'chroma-js';
const palette = chroma.scale(['e94437', 'bab652', '158F80']);

export const useStyles = makeStyles({
    progressWrapper: {
        display: 'flex',
        padding: 0,
        width: 40,
        height: 40,
        position: 'relative',
    },

    progressCircular: {
        '&.path': {
            strokeLinecap: 'butt',
            transition: 'stroke-dashoffset 0.5s ease 0s',
        },
        '&.trail': {
            stroke: '#41486D',
            strokeLinecap: 'butt',
            transformOrigin: 'center center',
        },
        '&.text': {
            fontSize: '16px',
            color: '#fff0',
            visibility: 'hidden',
        },
    },

    progressText: {
        position: 'absolute',
        width: '20px',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        fontSize: '12px',
        margin: 0,
        textAlign: 'center',
        color: appColors.white,
    },
});
