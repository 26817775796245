import React, { useEffect, useState } from "react";

import LocaleText from "src/components/locale-text/locale.text";
import useStores from "src/hooks/useStores";
import useChangeTheme from "src/hooks/useChangeTheme";
import cn from "classnames";

import { GameChatHistoryMessage } from "../message";
import { miniPiecesColors, miniPiecesStroke } from "src/consts/app-config";
import LoadingDots from "../../loadingDots";
// import miniPieces from "../mini-pieces";
import miniPieces from "src/components/PlayerInfoRedesign/images/mini-pieces";
import { IGameChatHistoryMessage } from "../types/IChat";
import { ColorVariant } from "../../../../../store/models";

import { useStyles } from "./styles";
import { appColors } from "src/consts/app-config";
import { ICommunicationGameHistory } from "src/store/gameStore/types";

type HistoryMessageProps = Pick<
    ICommunicationGameHistory,
    "pieces" | "color" | "position" | "time" | "animated"
>;

export const GameChatHistoryDiceMessage = ({
    pieces,
    color,
    position,
    time,
    animated,
}: HistoryMessageProps) => {
    const shortColor: ColorVariant = color
        .toLowerCase()
        .charAt(0) as ColorVariant;
    const classes = useStyles({ shortColor });
    const { gameStore } = useStores();
    const appearanceTheme = useChangeTheme();
    const [rolled, setRolled] = useState<boolean>(false);

    const piecesSize = {
        width: 18,
        height: 18,
    };

    const pieceIcon = (piece: string) => {
        return miniPieces[`${shortColor}${piece.toUpperCase().charAt(0)}`]([
            miniPiecesColors[appearanceTheme],
            miniPiecesStroke[appearanceTheme],
        ]);
    };

    const isRolled = gameStore.gameState.rolled;
    const isMyTurn = gameStore.gameState.isMyTurn();

    useEffect(() => {
        setRolled(false);
        if (!isMyTurn) {
            return setRolled(true);
        }
        if (!isRolled && isMyTurn) {
            return setRolled(true);
        }
        if (isRolled && isMyTurn) {
            setTimeout(() => setRolled(true), 2000);
        }
    }, [isRolled]);

    return (
        <GameChatHistoryMessage position={position} time={time}>
            {pieces.map(({ type, moved, move }, key) => (
                <div
                    key={`${move}_${key}`}
                    className={
                        rolled
                            ? cn(classes.rowAnimate, appearanceTheme)
                            : cn(classes.row, appearanceTheme)
                    }
                >
                    <span
                        className={cn(
                            classes.iconGameHistoryMessage,
                            appearanceTheme
                        )}
                    >
                        {rolled ? pieceIcon(type) : pieceIcon("k")}
                    </span>
                    {moved ? (
                        move ? (
                            <span
                                className={cn(
                                    classes.moveOnHistoryMessage,
                                    appearanceTheme
                                )}
                            >
                                {move}
                            </span>
                        ) : (
                            <LocaleText
                                defaultValue="Нет хода"
                                label="game.history.noMove"
                                className={cn(
                                    classes.moveOnHistoryMessage,
                                    appearanceTheme
                                )}
                            />
                        )
                    ) : (
                        <LoadingDots
                            size={4}
                            color="white"
                            time={0.2}
                            delay={0.8}
                            className={classes.dots}
                            stopped={!animated}
                        />
                    )}
                </div>
            ))}
        </GameChatHistoryMessage>
    );
};
