import { action, makeAutoObservable } from "mobx";
import {
    acceptFriendRequest,
    addFriend,
    cancelFriendRequest,
    getFriends,
    rejectFriendRequest,
    removeFriend,
    addFriendNew,
    removeFriendNew,
} from "src/service/api/friends";
import {
    IAcceptFriendRequest,
    IFriendActionRequest,
    IFriendDeleteRequest,
    IRejectFriendRequest,
    ICancelFriendRequest,
    IFriendAdd,
    IFriendRemove,
    IFriendActionResponse,
} from "src/service/api/friends/requestResponses";

import { IFriend } from "./models";

interface IFriendUpdateParams {
    online: boolean;
    isPlaying: boolean;
    rematchAwait?: boolean;
    joinAwait?: boolean;
    game: {
        id: string;
    };
}

export class FriendsStore {
    friends: IFriend[] | any = [];
    isLoaded: boolean = false;
    errorOccured: boolean = false;
    selectedFriend: IFriend | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    async getFriends() {
        try {
            this.setLoadedState(false);
            this.setIsErrorOccured(false);
            const { data: friends } = await getFriends();
            this.setFriends(friends);
        } catch (e) {
            this.setIsErrorOccured(true);
        }
    }
    @action async getFreindsNew(friends: any[]) {
        try {
            this.setLoadedState(false);
            this.setIsErrorOccured(false);
            this.setFriends(friends);
        } catch (e) {
            this.setIsErrorOccured(true);
        } finally {
            this.setIsErrorOccured(false);
        }
    }

    async addFriend(addFriendParams: IFriendActionRequest) {
        await addFriend(addFriendParams);
        await this.getFriends();
    }

    async removeFriend(removeFriendParams: IFriendDeleteRequest) {
        await removeFriend(removeFriendParams);
        await this.getFriends();
    }
    //=====
    @action async addFriendNew(addFriendParams: IFriendAdd) {
        this.setLoadedState(false);
        this.setIsErrorOccured(false);

        try {
            const res = await addFriendNew(addFriendParams);
            const data = res.data;
            if ("success" in data) {
                this.setLoadedState(true);
                return data;
            }
        } catch (e) {
            this.setLoadedState(true);
            this.setIsErrorOccured(true);
            return e.response?.data || "Add friend error";
        }
    }
    @action async removeFriendNew(removeFriendParams: IFriendRemove) {
        this.setLoadedState(false);
        this.setIsErrorOccured(false);

        try {
            const res = await removeFriendNew(removeFriendParams);
            const data = res.data;
            if ("success" in data) {
                this.setLoadedState(true);
                return data;
            }
        } catch (e) {
            this.setLoadedState(true);
            this.setIsErrorOccured(true);
            return e.response?.data || "Delete frined error";
        }
    }

    async acceptFriendRequest(params: IAcceptFriendRequest) {
        await acceptFriendRequest(params);
        await this.getFriends();
    }

    async rejectFriendRequest(params: IRejectFriendRequest) {
        await rejectFriendRequest(params);
        await this.getFriends();
    }

    async cancelFriendRequest(params: ICancelFriendRequest) {
        await cancelFriendRequest(params);
        await this.getFriends();
    }

    @action
    updateFriendById(friendId: string, params: Partial<IFriendUpdateParams>) {
        this.friends = this.friends
            .map((friend) => {
                if (friend.id === friendId) {
                    for (const param in params) {
                        friend[param] = params[param];
                    }
                }
                return { ...friend };
            })
            .sort((a, b) => {
                return a.online === b.online ? 0 : a.online ? -1 : 1;
            });
    }

    @action
    updateAfterDisconnect(friendId: string) {
        //@ts-ignore
        this.friends = this.friends
            .map((friend) => {
                if (friend.id === friendId) {
                    return friend.isPlaying
                        ? { ...friend, online: false, isPlaying: true }
                        : { ...friend, online: false, isPlaying: false };
                }
                return { ...friend };
            })
            .sort((a, b) => {
                return a.online === b.online ? 0 : a.online ? -1 : 1;
            });
    }

    @action
    setLoadedState(isLoaded: boolean) {
        this.isLoaded = isLoaded;
    }

    @action
    setIsErrorOccured(isLoaded: boolean) {
        this.errorOccured = isLoaded;
    }

    @action
    setFriends(friends: IFriend[]) {
        this.friends = friends;
        this.isLoaded = true;
    }

    @action
    setSelectedFriend(friend: IFriend) {
        this.selectedFriend = friend;
    }
}

export default FriendsStore;
