import React, { memo } from "react";
import cn from "classnames";

import { NotationSVG } from "../notation-svg";
import { VNotation } from "../NotationVertical";
import { HNotation } from "../NotationHorizontal";

import { useStyles } from "./styles";

export interface INotationProps {
    borderWidth: number;
    orientation: string;
    showNotationInBorder: boolean;
    isMultiTable?: boolean;
    cellSize?: number | undefined;
}

export const Notation = memo(
    ({
        borderWidth,
        orientation,
        showNotationInBorder,
        isMultiTable,
        cellSize,
    }: INotationProps) => {
        const classes = useStyles({ borderWidth });
        if (!showNotationInBorder) {
            return <NotationSVG orientation={orientation} />;
        }

        return (
            <React.Fragment>
                <div
                    className={cn(classes.wrapper, classes.verticalWrapper, {
                        isMultiTable,
                    })}
                >
                    <VNotation
                        orientation={orientation}
                        borderWidth={borderWidth}
                        isMultiTable={isMultiTable}
                        cellSize={cellSize}
                    />
                </div>
                <div
                    className={cn(classes.wrapper, classes.horizontalWrapper, {
                        isMultiTable,
                    })}
                >
                    <HNotation
                        orientation={orientation}
                        borderWidth={borderWidth}
                        isMultiTable={isMultiTable}
                        cellSize={cellSize}
                    />
                </div>
            </React.Fragment>
        );
    }
);
