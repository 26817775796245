import React, { useEffect, useState, memo } from "react";
import { ComponentType } from "react";
import cn from "classnames";

import { useLocale } from "src/providers/LocaleProvider";
import SocialPathsContainer from "src/pages/socialPathContainer/socialPaths";
import SupportPathsContainer from "src/pages/supportPathContainer/supportPaths";
import { appColors, backgroundColors } from "src/consts/app-config";

import { useStyles } from "./styles";
import { ESocial } from "../SideMenu";

export interface ISocial {
    linkTypes: keyof typeof ESocial;
    className?: string;
}

const SocialLinks = ({ linkTypes }: ISocial) => {
    const styles = useStyles();
    const pathObject = {
        social: SocialPathsContainer(25, 25, appColors.semiwhite),
        support: SupportPathsContainer(25, 25, appColors.semiwhite),
    };
    const {
        header: { support },
    } = useLocale();

    return (
        <div className={cn(styles.linkBoxWrapper, "main", {})}>
            {linkTypes === ESocial.support && (
                <p className={cn(styles.supportText, {})}>
                    {support("Support")}:
                </p>
            )}
            <div className={cn(styles.linkBoxWrapper, {})}>
                {pathObject &&
                    pathObject[linkTypes].map((item) => {
                        return (
                            <div
                                className={cn(styles.linkBox, {})}
                                key={`socLink_${item.alt}`}
                            >
                                <a
                                    className={cn(styles.linkItem, {})}
                                    href={
                                        item.soclLink.includes("https")
                                            ? item.soclLink
                                            : `mailto:${item.soclLink}`
                                    }
                                    target="_blank"
                                >
                                    <div className={cn(styles.linkImage, {})}>
                                        {item.socialIcon
                                            ? item.socialIcon
                                            : item.alt}
                                    </div>
                                </a>
                                <span
                                    className={cn(
                                        styles.linkBoxSpan,
                                        "open",
                                        {}
                                    )}
                                >
                                    {/* {item.alt} */}
                                    <a
                                        className={cn(
                                            styles.linkItem,
                                            "span",
                                            {}
                                        )}
                                        href={item.soclLink}
                                        target="_blank"
                                    >
                                        <div
                                            className={cn(
                                                styles.linkImage,
                                                "span",
                                                {}
                                            )}
                                        >
                                            {item.alt}
                                        </div>
                                    </a>
                                </span>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default memo(SocialLinks);
