import React, { FC, useEffect, useState } from 'react';
import chroma from 'chroma-js';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Box } from '@mui/material';

import { GameModeType } from 'src/store/models';

import { CircularProgress } from './components/circularProgress';
import { useStyles } from './styles';

interface Props {
    seconds: number;
    onEnd: () => void;
    pause: boolean;
    reset: number;
    backgroundImage?: string;
    size?: number;
    gameType?: string;
}

export const CountdownCircular: FC<Props> = ({
    seconds,
    onEnd,
    pause,
    reset,
    backgroundImage,
    size,
    gameType,
}) => {
    const [progress, setProgress] = useState<number>(seconds * 10);
    const [intervalId, setIntervalId] = useState<any>();
    const palette = chroma.scale(['e94437', 'bab652', '158F80']);
    const styles = useStyles();

    useEffect(() => {
        if (pause) {
            clearInterval(intervalId);
        } else {
            setIntervalId(
                setInterval(() => {
                    setProgress((progress) => progress - 1);
                }, 100)
            );
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [pause]);

    useEffect(() => {
        setProgress(seconds * 10);
    }, [seconds, reset]);

    useEffect(() => {
        if (progress <= 0) {
            if (intervalId) clearInterval(intervalId);
            onEnd();
        }
    }, [progress]);

    const dataBar = ((seconds * 10 - progress) / (seconds * 10)) * 100;

    return (
        <Box component='div'  className={styles.progressWrapper}>
            {gameType === GameModeType.TOURNAMENT ? (
                <>
                    <CircularProgressbar
                        className={styles.progressCircular}
                        value={dataBar}
                        text={`${Math.floor(progress / 10)}`}
                        styles={{
                            // Customize the root svg element
                            path: {
                                stroke: palette(
                                    progress / 100
                                ) as unknown as string,
                            },
                        }}
                    />
                    <p className={styles.progressText}>
                        {Math.floor(progress / 10)}
                    </p>
                </>
            ) : (
                <>
                    <CircularProgress
                        backgroundImage={backgroundImage}
                        invert
                        progress={Math.floor(progress)}
                        value={Math.floor(progress / 10)}
                        size={size}
                    />
                </>
            )}
        </Box>
    );
};
