import React, { useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { Box } from '@mui/material';

import { useLocale } from 'src/providers/LocaleProvider';
import { SteelButton, ButtonType } from 'src/components/buttons/SteelButton';
import { getButtonColor } from 'src/helpers';
import useStores from 'src/hooks/useStores';
import { errorService } from 'src/service/services';
import useChangeTheme from 'src/hooks/useChangeTheme';
import { EModalsLabels } from 'src/store/models';

import { useStyles } from './styles';

type IModalButtonsBox = {
    acceptText?: string | undefined;
    rejectText?: string | undefined;
    label?: string | undefined;
    acceptActions?: () => Promise<undefined | null> | undefined | any;
    rejectActions?: () => void | undefined;
    isDisabled?: boolean | undefined;
    isMaxNumberOfGames?: boolean | undefined;
};

const ModalButtonsBox = ({
    acceptText,
    rejectText,
    label,
    acceptActions,
    rejectActions,
    isDisabled,
    isMaxNumberOfGames,
}: IModalButtonsBox) => {
    const styles = useStyles();
    const appearanceTheme = useChangeTheme();

    return (
        <>
            {(label === EModalsLabels.friendFightReceive ||
                label === EModalsLabels.lobbyConfirmGame) && (
                <Box className={cn(styles.buttonModalContainer, label)}>
                    <SteelButton
                        bgColor={ButtonType.negative}
                        className={styles.button}
                        fontSize={18}
                        text={rejectText ? rejectText : ''}
                        onClick={rejectActions && rejectActions}
                        disabled={isDisabled}
                        height={45}
                        padding={5}
                        width={"100%"}
                    />
                    <SteelButton
                        bgColor={getButtonColor(appearanceTheme, ButtonType)}
                        className={styles.button}
                        fontSize={18}
                        text={acceptText ? acceptText : ''}
                        onClick={acceptActions && acceptActions}
                        disabled={isDisabled}
                        height={45}
                        padding={5}
                        width={"100%"}
                    />
                </Box>
            )}
            {label === EModalsLabels.friendFightInit && (
                <Box className={cn(styles.buttonModalContainer, label)}>
                    <SteelButton
                        bgColor={ButtonType.negative}
                        className={styles.button}
                        fontSize={18}
                        text={rejectText ? rejectText : ''}
                        onClick={rejectActions && rejectActions}
                        disabled={isDisabled}
                        height={45}
                        padding={5}
                        width={"100%"}
                    />
                    <SteelButton
                        bgColor={getButtonColor(appearanceTheme, ButtonType)}
                        className={styles.button}
                        fontSize={18}
                        text={acceptText ? acceptText : ''}
                        onClick={acceptActions && acceptActions}
                        disabled={isDisabled || isMaxNumberOfGames}
                        height={45}
                        padding={5}
                        width={"100%"}
                    />
                </Box>
            )}
            {label === EModalsLabels.lobbyInitiatorAwaiting && (
                <Box className={cn(styles.buttonModalContainer, label)}>
                    <SteelButton
                        bgColor={ButtonType.negative}
                        className={styles.button}
                        fontSize={18}
                        text={rejectText ? rejectText : ''}
                        onClick={rejectActions && rejectActions}
                        disabled={isDisabled}
                        height={45}
                        padding={5}
                    />
                </Box>
            )}
        </>
    );
};

export default observer(ModalButtonsBox);
