import { makeStyles } from "@mui/styles";
import { appColors, backgroundColors } from "src/consts/app-config";
import theme from "src/theme";
import { DARKGREEN_ALERTS } from "src/pages/game-new/constants";

const ALERT_BORDER_COLOR = "#DCE5F4";
const ALERT_COLOR = "#252B4F";

export const useStyles = makeStyles({
    container: {
        padding: "5px 20px",
        display: "grid",
        placeItems: "center",
        // position: 'absolute',
        // left: '50%',
        // top: 0,
        background: ALERT_COLOR,
        border: `1px solid ${ALERT_BORDER_COLOR}`,
        borderRadius: 10,
        // zIndex: 3,
        textAlign: "center",
        color: appColors.white,
        fontWeight: 500,
        maxWidth: "80%",
        minWidth: "40%",
        margin: "0 auto",
        marginBottom: 15,
        [theme.breakpoints.between("xs", 369)]: {
            // top: '8%',
            padding: "10px 10px",
            fontSize: "22px",
            minWidth: "60%",
        },
        [theme.breakpoints.between(370, "sm")]: {
            // top: '11%',
            padding: "10px 10px",
            fontSize: "24px",
        },
        [theme.breakpoints.between("sm", "md")]: {
            // top: '15px',
            padding: "15px 10px",
            fontSize: "24px",
        },
        "@media screen and (max-width: 360px)": {
            padding: "5px 12px",
        },
        "@media screen and (max-width: 450px) and (max-height: 680px)": {
            fontSize: "17px",
            padding: "5px 12px",
        },
        "@media screen and (max-width: 450px) and (max-height: 730px)": {
            fontSize: "16px",
            padding: "6px 10px",
        },
        "@media screen and (max-width: 450px) and (max-height: 760px)": {
            fontSize: "16px",
            padding: "5px 12px",
        },
        "&.darkBlue": {
            background: ALERT_COLOR,
            border: `1px solid ${ALERT_BORDER_COLOR}`,
        },
        "&.darkGreen": {
            background: backgroundColors.darkGreen,
            border: "1px solid #004348",
        },
        "&.deepBlack": {
            background: ALERT_COLOR,
            border: `1px solid ${ALERT_BORDER_COLOR}`,
        },
    },
    arrowHolder: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
    },
    arrow: {
        background: ALERT_COLOR,
        transform: "rotate(45deg) translate(-50%, -30%)",
        width: 12,
        height: 12,
        borderLeft: `1px solid ${ALERT_BORDER_COLOR}`,
        borderTop: `1px solid ${ALERT_BORDER_COLOR}`,
        margin: "auto",
        "&.darkBlue": {
            background: ALERT_COLOR,
        },
        "&.darkGreen": {
            background: backgroundColors.darkGreen,
            border: "1px solid #004348",
        },
        "&.deepBlack": {
            background: ALERT_COLOR,
        },
    },
});
