import { makeStyles } from "@mui/styles";

import freeCoin from "src/assets/icons/freeCoin.png";
import coin from "src/assets/icons/coin.svg";
import bankBgDarkGreen from "src/components/controls/bank/images/bankBgGreenDrakGreen.svg";
import bankBgDarkBlue from "src/components/controls/bank/images/bankBgGreenDrakBlue.svg";
import theme from "src/theme";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
} from "src/pages/game-new/constants";


const useStyles = makeStyles({
    "@global": {
        "@keyframes isWinnerAndHideBank": {
            "0%": {
                opacity: 1,
            },
            "100%": {
                opacity: 0,
            },
        },
    },

    area: () => ({
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        "&.isMultiTable": {
            maxWidth: "100%",
            width: "100%",
            transform: "scale(0.7)",
        },
        "&.isWinnerAndHideBank": {
            animationName: "isWinnerAndHideBank",
            animationDelay: "2.1s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "300ms",
        },
    }),
    backgroundImg: {
        position: "absolute",
        marginTop: 45,
        [theme.breakpoints.between(1360, 1370)]: {
            marginTop: 40,
        },
    },

    contentWrapperNew: {
        position: "relative",
        padding: 0,
        width: "80%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "50% 0",
        // backgroundImage: `url(${coin})`,
        aspectRatio: "6",
        // '&.darkBlue': {
        //   backgroundImage: `url(${bankBgDarkBlue})`,
        // },
        // '&.darkGreen': {
        //   backgroundImage: `url(${bankBgDarkGreen})`,
        // },
        [theme.breakpoints.up("lg")]: {
            width: "100%",
        },
        [theme.breakpoints.up("xl")]: {
            width: "80%",
        },
        [theme.breakpoints.up(1800)]: {
            width: "85%",
        },
        // '&::before, &::after': {
        //     content: "''",
        //     position: 'absolute',
        //     zIndex: -1,
        //     borderRadius: '8px',
        //     width: '15px',
        //     height: '100%',
        //     background: 'red',
        // },
        // '&::before': {
        //     left: '-10px',
        //     transform: 'skew(-17deg)',
        // },
        // '&::after': {
        //     right: '-10px',
        //     transform: 'skew(17deg)',
        // },
        "&.isMultiTable": {
            [theme.breakpoints.up("xl")]: {
                width: "100%",
                margin: 0,
            },
        },
    },

    coin: {
        height: 42,
        width: 42,
        [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            height: 38,
            width: 38,
        },
        marginRight: 10,
        // marginTop: 5,
        backgroundImage: `url(${coin})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        zIndex: 5,
        opacity: 1,
        // animationName: '$getPotText',
        animationDelay: "0.3s",
        animationIterationCount: 1,
        animationFillMode: "forwards",
        animationDuration: "1000ms",
    },
    freeCoin: {
        height: 42,
        width: 42,
        [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            height: 38,
            width: 38,
        },
        marginRight: 10,
        // marginTop: 5,
        backgroundImage: `url(${freeCoin})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        zIndex: 5,
        opacity: 1,
        // animationName: '$getPotText',
        animationDelay: "0.3s",
        animationIterationCount: 1,
        animationFillMode: "forwards",
        animationDuration: "1000ms",
    },

    coinHide: {
        height: 42,
        width: 42,
        [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            height: 38,
            width: 38,
        },
        marginRight: 10,
        // marginTop: 5,
        backgroundImage: `url(${coin})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        zIndex: 5,
        opacity: 1,
        animationName: "$coinHide",
        animationDelay: "0.8s",
        animationIterationCount: 1,
        animationFillMode: "forwards",
        animationDuration: "300ms",
    },
    freeCoinHide: {
        height: 42,
        width: 42,
        [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            height: 38,
            width: 38,
        },
        marginRight: 10,
        // marginTop: 5,
        backgroundImage: `url(${freeCoin})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        zIndex: 5,
        opacity: 1,
        animationName: "$coinHide",
        animationDelay: "0.8s",
        animationIterationCount: 1,
        animationFillMode: "forwards",
        animationDuration: "300ms",
    },

    "@keyframes coinHide": {
        "0%": {
            opacity: 1,
        },
        "75%": {
            opacity: 1,
        },
        "100%": {
            opacity: 0,
        },
    },

    potText: () => ({
        fontSize: 32,
        textTransform: "uppercase",
        fontWeight: 900,
        background:
            "linear-gradient(178.82deg, #A2A2A2 30.6%, #FFFFFF 50.51%, #A9A9A9 67.41%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        textShadow: "0px 3px 20px rgba(0, 0, 0, 0.15)",
        wordBreak: "initial",
        zIndex: 5,
        [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            fontSize: 26,
        },
        [theme.breakpoints.up(1800)]: {
            fontSize: 40,
        },
    }),
    amount: () => ({
        color: "#4D4D4D",
        textShadow: "0px 2.76486px 8.29459px rgba(0, 0, 0, 0.25)",
        fontSize: 44,
        lineheight: 44,
        fontWeight: 900,
        marginRight: 10,
        zIndex: 5,
        [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            fontSize: 40,
        },
        "&.gold": {
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            // background:
            //     "linear-gradient(180.73deg, #F8BF44 9.68%, #E08632 104.5%)",
            background: "rgba(245, 245, 245, 1)",
        },
        ".silver": {
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            background:
                "linear-gradient(180.73deg, #e9eefc 9.68%, #c2c2c2 104.5%)",
        },
        "&.isMultitable": {
            fontSize: 40,
            lineHeight: 40,
        },
    }),
});

export default useStyles;
