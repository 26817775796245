import React, { useState, useMemo } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { Box } from "@mui/material";

import { useLocale } from "src/providers/LocaleProvider";
import { usePlayerColor } from "src/hooks/usePlayerColorMarker";
import endpoints from "src/core/endpoints";
import useStores from "src/hooks/useStores";
import defaultPlaceholder from "src/assets/images/defaultPlaceholder.png";
import LazyImages from "src/components/lazyImages";
import { ColorVariant } from "src/store/models";

import { useStyles } from "./styles";
import { IPlayersRowProps } from "./types";

const TopPlayerItem = ({
    topPlayer,
    index,
    itemType,
    ratingBorder,
    me,
}: IPlayersRowProps) => {
    const {
        tournaments: {
            titles: {
                watingPlayers,
                prizesTab: { rankPoint },
            },
        },
    } = useLocale();
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const { authStore, lobbyStore, achievementsStatStore } = useStores();
    const classes = useStyles();
    const boxShadowColor = usePlayerColor(topPlayer.id);

    const calcTopPlayersStyle = (
        index: number | undefined,
        itemType: string | undefined
    ) => {
        if (itemType === "modalWindow" || !index) return;
        if (index === 1) return "top1";
        if (index === 2) return "top2";
        if (index === 3) return "top3";
        if (index > 3 && index < 11) return "top10";
    };

    const playerStyle = calcTopPlayersStyle(index, itemType);

    const LazyImagesMemo = useMemo(
        () => (
            <LazyImages
                className={cn(classes.topPlayerAvatar, {
                    isSystemAvatar: topPlayer.isSystemAvatar,
                    isLoaded: !isLoaded,
                    skeleton: !isLoaded,
                })}
                placeholderImg={defaultPlaceholder}
                src={`${endpoints.avatarPath}${topPlayer.avatar}`}
                alt="avatar"
                errorImg={defaultPlaceholder}
                setLoaded={(status: boolean) => setLoaded(status)}
            />
        ),
        [topPlayer.avatar, isLoaded, boxShadowColor]
    );

    return (
        <>
            {!ratingBorder ||
            topPlayer.rating > ratingBorder ||
            itemType === "modalWindow" ? (
                <Box
                    component="div"
                    className={cn(classes.topPlayer, playerStyle, {
                        me: me === topPlayer.id && itemType !== "modalWindow",
                    })}
                    onClick={() =>
                        achievementsStatStore.getPlayerSettingsModal({
                            ...topPlayer,
                            name: topPlayer.nickname,
                            winInGames: topPlayer.stats.wonGames,
                            color: ColorVariant.none,
                        })
                    }
                >
                    <p className={classes.topPlayerPosition}>
                        {index || topPlayer.user}
                    </p>
                    <span
                        style={{
                            boxShadow: `0px 0px 8px 6px ${boxShadowColor}`,
                        }}
                        className={classes.topPlayerAvatarSpan}
                    >
                        {LazyImagesMemo}
                    </span>
                    <div className={classes.topPlayerNick}>
                        <p
                            className={cn(
                                classes.topPlayerNickText,
                                playerStyle,
                                {
                                    me:
                                        me === topPlayer.id &&
                                        itemType !== "modalWindow",
                                }
                            )}
                        >
                            {topPlayer.nickname}
                        </p>
                        <p
                            className={cn(
                                classes.topPlayerNickScore,
                                playerStyle
                            )}
                        >
                            {topPlayer.rating.toFixed(0)}{" "}
                            {rankPoint("Rank Point")}
                        </p>
                    </div>
                </Box>
            ) : (
                <Box component="div" className={cn(classes.topPlayer)}>
                    <p className={classes.topPlayerPosition}>
                        {index || topPlayer.user}
                    </p>
                    <div
                        className={cn(classes.topPlayerAvatar, "skeleton")}
                    ></div>
                    <div className={cn(classes.topPlayerNick, "skeleton")}>
                        <p
                            className={cn(
                                classes.topPlayerNickText,
                                "skeleton"
                            )}
                        ></p>
                        <p
                            className={cn(
                                classes.topPlayerNickScore,
                                "skeleton"
                            )}
                        >
                            {watingPlayers("Wating Player")}
                        </p>
                    </div>
                </Box>
            )}
        </>
    );
};

export default observer(TopPlayerItem);
