import cn from "classnames";
import { CSSProperties } from "react";
import beturangaLogo from "src/components/controls/app-bar/images/logo.png";
import halykLogo from "src/assets/Halyk/logo_h.svg";
import diceChessLogo from "src/components/controls/app-bar/images/logo_classic.png";
import useStores from "src/hooks/useStores";
import { observer } from "mobx-react";

import { useStyles } from "./styles";

interface ISpinner {
    size?: number;
    isFullscreen?: boolean;
    isAbsolute?: boolean;
    background?: string;
    isStart?: boolean;
    isFinish?: boolean;
    style?: CSSProperties;
}

export const LobbySpinner = observer(
    ({
        size,
        isFullscreen,
        isAbsolute,
        isStart,
        isFinish,
        background,
        style,
    }: ISpinner): JSX.Element => {
        const styles = useStyles();
        const { generalStore } = useStores();

        const logoClassName = cn(styles.centerLogo, {
            isStart: isStart,
            [styles.halykLogo]: generalStore.isHalyk
        });

        return (
            <div
                className={cn({
                    [styles.fullscreenSpinner]: isFullscreen,
                    [styles.fullscreenStatic]: !isAbsolute
                        ? !isFullscreen
                        : false,
                    [styles.absoluteWrapper]: isAbsolute,
                    isStart: isStart,
                    isFinish: isFinish,
                })}
                style={{ ...style, background }}
            >
                <div className={cn(styles.center, { isStart: isStart })}>
                    <img
                        className={logoClassName}
                        src={
                            generalStore.isHalyk
                                ? halykLogo
                                : generalStore.isDicechessMode
                                    ? beturangaLogo
                                    : diceChessLogo
                        }
                        alt="logo"
                    />
                </div>
            </div>
        );
    }
);
