import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import cn from "classnames";

import useStores from "src/hooks/useStores";
import paths from "src/consts/paths";

import { useStyles } from "./styles";
import SpeedWarningComponent from "./speedWarningComponent";
import { ISpeed, InfoMessage } from "./types";

// Maybe we should move that component into src/components folder?
function InternetSpeedMeter({ minSpeed }: ISpeed) {
    const { lobbyStore, authStore, gameStore, generalStore } = useStores();
    const location = useLocation();
    const lobbyGameJoin = lobbyStore.joinedGame;
    const lobbyGameAccept = lobbyStore.acceptedGame;
    const internetStatus = generalStore.internetStatus;
    console.log("internetStatus", internetStatus);
    const styles = useStyles({ internetStatus });
    const [delayLost, setDelay] = useState<boolean | string>(false);
    const [lostFinnaly, setLostFinally] = useState<boolean | string>(false);

    const getOnline = () => {
        generalStore.setInternetStatus(InfoMessage.connected);
    };
    // const getOffline = () => {
    //     generalStore.setInternetStatus(InfoMessage.lost);
    // };

    useEffect(() => {
        window.addEventListener("online", getOnline);
        return () => {
            window.removeEventListener("online", getOnline);
        };
    }, []);

    function downloadMsg(status) {
        let i = 0;
        let id;
        id = setInterval(function () {
            i++;
            if (i === 20) {
                setDelay(status);
                clearInterval(id);
            } else {
                console.log(i);
            }
        }, 1000);
    }

    useEffect(() => {
        if (
            internetStatus === InfoMessage.connected &&
            !location.pathname.includes(paths.game) &&
            !lobbyGameJoin &&
            !lobbyGameAccept
        ) {
            lobbyStore.refreshLobby();
            authStore.getMe();
        }
        if (
            internetStatus === InfoMessage.connected &&
            location.pathname.includes(paths.game) &&
            !gameStore?.rematch.gameId
        ) {
            setTimeout(() => {
                gameStore?.setGameId(location.pathname.slice(6));
            }, 300);
        }
    }, [internetStatus]);

    return (
        <>
            <div
                className={cn(styles.speedWarningWrapped, {
                    connected: internetStatus === InfoMessage.connected,

                    //Turnned of before implemented sockets
                    // lost: internetStatus === InfoMessage.lost,
                    // slow: internetStatus === InfoMessage.slow && lostFinnaly,
                })}
            >
                <SpeedWarningComponent wifiSpeedText={internetStatus} />
            </div>
        </>
    );
}

export default observer(InternetSpeedMeter);
