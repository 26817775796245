import { socialPaths } from "src/consts/paths";

import TelegramIconGenerator from "src/assets/icons/social/telegramIcon";
import InstagramIconGenerator from "src/assets/icons/social/instagramIcon";
import YoutubeIconGenerator from "src/assets/icons/social/youtubeIcon";
import TiktokIconGenerator from "src/assets/icons/social/tiktokIcon";
import DiscordIconGenerator from "src/assets/icons/social/discordIcon";
import SupportEmailIconGenerator from "src/assets/icons/social/supportEmail";

function SocialPathsContainer(
    width: number,
    height: number,
    fillColor: string
) {
    return [
        {
            soclLink: socialPaths.telegram,
            socialIcon: (
                <TelegramIconGenerator
                    width={width}
                    height={height}
                    fillColor={fillColor}
                />
            ),
            alt: "dicechess_games",
        },
        {
            soclLink: socialPaths.youtube,
            socialIcon: (
                <YoutubeIconGenerator
                    width={width}
                    height={height}
                    fillColor={fillColor}
                />
            ),
            alt: "@DicechessTV",
        },
        {
            soclLink: socialPaths.instagram,
            socialIcon: (
                <InstagramIconGenerator
                    width={width}
                    height={height}
                    fillColor={fillColor}
                />
            ),
            alt: "dicechess.app",
        },
        {
            soclLink: socialPaths.tiktok,
            socialIcon: (
                <TiktokIconGenerator
                    width={width}
                    height={height}
                    fillColor={fillColor}
                />
            ),
            alt: "dicechess.games",
        },
        {
            soclLink: socialPaths.discord,
            socialIcon: (
                <DiscordIconGenerator
                    width={width}
                    height={height}
                    fillColor={fillColor}
                />
            ),
            alt: "dicechess.discord",
        },
    ];
}

export default SocialPathsContainer;
