import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import cn from "classnames";

import { useLocale } from "src/providers/LocaleProvider";
import diceChessIcon from "src/assets/icons/diceChessIcon.svg";
import sportIcon from "src/assets/icons/sportIcon.svg";
import slotsIcon from "src/assets/icons/slotsIcon.svg";
import rouletteIcon from "src/assets/icons/rouletteIcon.svg";
import cardIcon from "src/assets/icons/cardsIcon.svg";
import virtualSport from "src/assets/icons/virtualSports.png";
import { GameTypes } from "src/store/models";

import paths from "../../../../consts/paths";
import useStores from "../../../../hooks/useStores";
import { ModalProvider, ModalTypes } from "../../../landing/utils/context";
import { RegisterForm } from "../../../register/RegisterForm";
import LoginForm from "../../../../dialogs/login/LoginForm";

import { useStyles } from "./styles";

const MobileNavMenu = () => {
    const history = useHistory();
    const { authStore, generalStore } = useStores();

    const {
        mainLanding: { poker, sport, slots, liveDealer },
    } = useLocale();

    const [modalState, setModalState] = useState({
        register: false,
        login: false,
    });

    const openModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: true }));
    };

    const closeModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: false }));
    };

    const styles = useStyles();

    return (
        <ModalProvider.Provider value={{ openModal, closeModal }}>
            {modalState.register && <RegisterForm />}
            {modalState.login && <LoginForm />}

            <div className={styles.menu}>
                <nav className={styles.menuLinks}>
                    {!generalStore.isHalyk && (
                        <div
                            className={styles.menuLink}
                            onClick={() => {
                                history.push(paths.diceChessLobby);
                            }}
                        >
                            <div>
                                <img
                                    className={styles.linkIcon}
                                    src={diceChessIcon}
                                />
                            </div>

                            <p className={styles.link}>Dicechess</p>

                            <div className={styles.hover}></div>
                        </div>
                    )}
                    <div
                        className={styles.menuLink}
                        onClick={() => {
                            if (
                                !authStore.checkIfUserHasAccessToSection(
                                    GameTypes.jackpoker
                                )
                            )
                                return;
                            history.push(paths.jackpoker);
                        }}
                    >
                        <div>
                            <img
                                className={cn(styles.linkIcon)}
                                src={cardIcon}
                            />
                            <div className={styles.darkShadow}></div>
                        </div>
                        <p className={styles.link}>{poker("Poker")}</p>
                        <div className={styles.hover}></div>
                    </div>
                    <div
                        className={styles.menuLink}
                        onClick={() => {
                            if (
                                !authStore.checkIfUserHasAccessToSection(
                                    GameTypes.sports
                                )
                            )
                                return;
                            history.push(paths.sports);
                        }}
                    >
                        <div>
                            <img className={styles.linkIcon} src={sportIcon} />
                        </div>
                        <p className={styles.link}>{sport("Sport")}</p>
                        <div className={styles.hover}></div>
                    </div>
                    <div
                        className={cn(styles.menuLink)}
                        onClick={() => {
                            if (
                                !authStore.checkIfUserHasAccessToSection(
                                    GameTypes.liveDealer
                                )
                            )
                                return;
                            history.push(paths.liveDealer);
                        }}
                    >
                        <div>
                            <img
                                className={styles.linkIcon}
                                src={rouletteIcon}
                            />
                            <div className={styles.darkShadow}></div>
                        </div>
                        <p className={cn(styles.link)}>
                            {liveDealer("Casino")}
                        </p>
                        <div className={styles.hover}></div>
                    </div>
                    <div
                        className={styles.menuLink}
                        onClick={() => {
                            if (
                                !authStore.checkIfUserHasAccessToSection(
                                    GameTypes.slots
                                )
                            )
                                return;
                            history.push(paths.algaltente);
                        }}
                    >
                        <div>
                            <img
                                className={cn(styles.linkIcon)}
                                src={slotsIcon}
                            />
                            <div className={styles.darkShadow}></div>
                        </div>
                        <p className={cn(styles.link)}>{slots("Slots")}</p>
                        <div className={styles.hover}></div>
                    </div>
                    <div
                        className={styles.menuLink}
                        onClick={() => {
                            if (
                                !authStore.checkIfUserHasAccessToSection(
                                    GameTypes.slots
                                )
                            )
                                return;
                            history.push(paths.algaltente);
                        }}
                    >
                        <div>
                            <img
                                className={cn(styles.linkIcon)}
                                src={virtualSport}
                            />
                            <div className={styles.darkShadow}></div>
                        </div>
                        <p className={cn(styles.link)}>{slots("V-sport")}</p>
                        <div className={styles.hover}></div>
                    </div>
                </nav>
            </div>
        </ModalProvider.Provider>
    );
};

export default MobileNavMenu;
