import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import cn from 'classnames';

import { useLocale } from 'src/providers/LocaleProvider';
import { GreenButton, ButtonType } from 'src/components/buttons/greenButton';

import { useModalStyles } from '../styles';

import ItemAvatar from './ItemAvatar';
import { useStyles } from './styles';
import { ShowHideUpload, IItemAvatarContainer } from './avatarTypes';

function ItemAvatarContainer({
    nonGrid,
    isCustom,
    getAvatarId,
    choosenAvatarId,
    handleUpdateAvatar,
    items,
    showUpload,
}: IItemAvatarContainer) {
    const localStyles = useStyles();
    const modalStyles = useModalStyles();
    const {
        profile: {
            changeAvatar: { selectFileText, hereTitle, chooseTitle },
        },
    } = useLocale();
    return (
        <Box
            className={cn(localStyles.gridBlockWrapper, {
                nonGrid: nonGrid,
            })}
        >
            <Grid
                justifyContent='center'
                alignItems='center'
                container
                spacing={2}
                className={cn(localStyles.gridAvatarContainer, {
                    isCustom: isCustom,
                })}
            >
                {items &&
                    items?.length > 2 &&
                    items.map((item) => (
                        <Grid
                            item
                            key={item.id}
                            xs={4}
                            sm={4}
                            md={3}
                            lg={3}
                            xl={2}
                        >
                            <ItemAvatar
                                item={item}
                                getAvatarId={getAvatarId}
                                choosenAvatarId={choosenAvatarId}
                            />
                        </Grid>
                    ))}
            </Grid>
            <Box className={localStyles.buttonBoxLoaded}>
                <GreenButton
                    text={chooseTitle('Choose')}
                    type={ButtonType.purple}
                    fontSize={20}
                    isWidthFixed
                    width='fit-content'
                    onClick={() => {
                        handleUpdateAvatar(choosenAvatarId);
                    }}
                />
                <Box className={localStyles.buttonBoxInfoGetOwn}>
                    <Typography className={cn(modalStyles.title, 'getOwn')}>
                        {selectFileText('Select a file from your gallery')}
                    </Typography>
                    <GreenButton
                        text={hereTitle('Here')}
                        type={ButtonType.yellow}
                        fontSize={20}
                        isWidthFixed
                        width='fit-content'
                        // ratio={0.8}
                        onClick={() => {
                            showUpload(ShowHideUpload.show);
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default ItemAvatarContainer;
