import { GameTime, GameTimeValues } from "src/store/lobbyStore";
import { PaymentType } from "src/service/api/cashbox/requestResponses";
import { ETournamentType } from "src/entities/tournament";

import { IRematchInfo } from "./gameStore/types";

export enum PopupFilterType {
    time = "time",
    match = "match",
    bet = "bet",
    gameMode = "gameMode",
}

// export enum GameStatus {
//     AWAITING = "awaiting",
//     NOT_READY = "not_ready",
//     IN_PROGRESS = "in_progress",
//     FINISHED = "finished",
//     ABOUT_TO_START = "about_to_start",
// }
//====
export enum GameStatus {
    IN_PROGRESS = 1,
    AWAITING = 2,
    FINISHED = 4,
    NOT_READY = "not_ready",
    ABOUT_TO_START = "about_to_stasrt",
}

export enum TournamentsModal {
    PUSH_TO_GAME = "pushToGame",
    PUSH_TO_MATCH_GAME = "pushToMatchGame",
    CLOSE_OR_PLAY = "closeOrPlay",
    THIRD_GAME = "prepareThirdOrFourth",
}

export enum GameChatType {
    DESKTOP = "desktopType",
    MOBILE = "mobileType",
}

export enum ScreenType {
    mobile = "mobile",
    tablet = "tablet",
    desktop = "desktop",
    multitable = "multitable",
}

export enum GameChatToggleStatus {
    open = "open",
    hide = "hide",
}

export enum ColorVariant {
    white = "w",
    black = "b",
    none = "",
}

export interface IGameSettingsGame {
    bet: number;
    doubling: boolean;
    fen: string;
    private: boolean;
    privateChat: boolean;
    rake: number;
    randomColor: boolean;
    rounds: number;
    timeControl: { time: number; increment: number };
    increment: number;
    time: number;
    undo: boolean;
}

export interface IPlayerBase {
    avatar: string;
    color: ColorVariant;
    id: string;
    name: string;
    rating: number;
    score: number;
    winInGames: number;
    isSystemAvatar?: boolean | undefined;
}
export interface IPlayerOpponent extends IPlayerBase {
    rank?: string | RankType;
}

export interface IPlayer extends IPlayerBase {
    rank?: { title: RankType };
    status?: string;
    pathCompressed?: string;
}

export interface GameResultData {
    winner: ColorVariant;
    cause: GameFinishReason;
}
// TODO: fix typing, game does not has "jackpot" type
export enum GameModeType {
    REGULAR = "regular",
    TOURNAMENT = "tournament",
    JACKPOT = "jackpot",
}
//====

export enum GameModeTypeNew {
    REGULAR = 1,
    TOURNAMENT = 2,
}

export interface ITimeControl {
    time: number;
    increment: number;
}
export interface IGame {
    balanceType: UserBalanceType;
    bet: number;
    created: string;
    doubling: {
        multiplier: number;
        aggressor: ColorVariant;
        accepted: boolean;
    };
    events: { event: string; color: ColorVariant; data: any }[];
    fen: string;
    history: Array<any>;
    id: string;
    players: [IPlayer] | [IPlayer, IPlayer];
    private: boolean;
    privateChat: boolean;
    rematchInfo: IRematchInfo;
    settings: IGameSettingsGame;
    status: GameStatus;
    // time: GameTime;
    time: GameTimeValues;
    timeControl: ITimeControl;
    timestamp: number;
    tournament: {
        gameId: string;
        id: string;
        stage: number;
        startDate: string;
        title: string;
        type: string;
        gameSeries: number;
    } | null;
    type: GameModeType;
    result: GameResultData | null;
    undo: boolean;
    willBeDeletedIn: number;
    challenged?: {
        id: string;
        nickname: string;
        avatar: string;
        isSystemAvatar: boolean;
    } | null;
    previousGameId?: string;
    participating?: boolean | undefined;
    rounds: number;
    hideInFilter?: any;
}
//==========
export interface IGameNew {
    // availableMoves: any[];
    // extra: object;
    // fen: string;
    // fenType: number;
    // players: string[];
    // relativeId: null | string;
    // startedAt: string;
    // state: number;
    // timeLeft: number[];
    // type: number;
    // _id: string;

    _id: string;
    availableMoves: any[];
    curMove: string;
    bank: number;
    coinStatus: number;
    coinValues: any[];
    extra: { [key: string]: any };
    fen: string;
    fenType: number;
    free: boolean;
    gameDieTime: string;
    gameType: number;
    gamesLeft: number;
    history: any[];
    matchHistory: any[];
    messages: any[];
    move: number;
    moves: any[];
    players: {
        _id: string;
        agreement: boolean;
        blockReason: string | null;
        email: string;
        isAdmin: boolean;
        isBot: boolean;
        isSupport: boolean;
        isSystemAvatar: boolean;
        nickname: string;
        online: boolean;
        password: string;
        referralProgramId: string | null;
        userType: string;
    }[];
    relativeId: string | null;
    rolling: number;
    startedAt: string;
    state: number;
    timeLeft: number[];
    timers: number[];
    tournament: any | null;
    type: number;
    viewers: any[];
    winner: string | null;
}

export interface IGameUpdate {
    id: string;
    players: [IPlayer] | [IPlayer, IPlayer];
    status: GameStatus;
    created: string;
    willBeDeletedIn: number;
    timestamp: number;
    participating: boolean;
    previousGameId: string;
}
export interface IPlayerWinner {
    _id: string;
    avatar: string;
    isSystemAvatar: boolean;
    nickname: string;
    rating: number;
    stats: { wonGames: number; _id: string };
    user: string;
}

export interface IFinishedTournament {
    tournament: {
        balanceType: UserBalanceType;
        buyIn: number;
        buyInWithRake: number;
        color: string;
        created: string;
        currentStage: number;
        description: string;
        gameSeries: number;
        id: string;
        maxPlayers: number;
        playersInfo: [IPlayerWinner] | [IPlayerWinner, IPlayerWinner];
        prize: number;
        prizePlace: null | number;
        prizes: {
            amount: number;
            place: number;
            rankPoint: number;
            range?: number[] | undefined;
        }[];
        prizeWinners: {
            place: number;
            user: string;
            range?: number[] | undefined;
        }[];
        rake: number;
        stages: {
            gameSeries: number;
            games: [IStageGame];
            stage: number;
            startDate: string;
            status: string;
        };
        startDate: string;
        status: string;
        timeControl: {
            time: number;
            increment: number;
        };
        title: string;
        winner: IPlayerWinner;
    };
}

interface IGameSettings {
    confirmDoubling: boolean;
    invertCapturedFigures: boolean;
    pieceSound: boolean;
    diceSound: boolean;
    sound: boolean;
    hightlightMoves: boolean;
    autoDice: boolean;
    digitDice: boolean;
    animation: boolean;
    language: ELanguages;
    background: string;
    diceColor: string;
    sideBarIsOpen: boolean;
    dicechessBoardRightBlockMode: keyof typeof EGameChatTab;
}

interface IGameSettingsNew {
    animation: boolean;
    autoDice: boolean;
    background: string;
    classicDice: boolean;
    confirmDoubling: boolean;
    diceColor: string;
    diceSound: boolean;
    dicechessBoardRightBlockMode: string;
    digitDice: boolean;
    hightlightMoves: boolean;
    invertCapturedFigures: boolean;
    isChatBlocked: boolean;
    isSideBarOpen: boolean;
    language: ELanguages;
    pieceSound: boolean;
    sound: boolean;
    sideBarIsOpen: boolean;
}

export interface IPrizeTournamentData {
    id: string;
    title: string;
    position: any;
    prize: {
        coin: {
            amount: number;
            place: number | null;
            range: any[] | any;
        };
        rankPoint: number;
    };
}

interface IGameStats {
    profit: number;
    games: {
        ranked: number;
        standoff: number;
        lost: number;
        won: number;
        played: number;
    };
}

interface IGameStatsNew {
    games: {
        lost: number;
        played: number;
        ranked: number;
        standoff: number;
        won: number;
    };
    rate: {
        elo: number;
        nextRank: any;
        rank: any;
        winStreak: number;
    };
    tournaments: {
        lostGames: number;
        participated: number;
        playedGames: number;
        won: number;
        wonGames: number;
    };
}

export enum RankType {
    pawn1 = "Pawn1",
    pawn2 = "Pawn2",
    pawn3 = "Pawn3",

    knight1 = "Knight1",
    knight2 = "Knight2",
    knight3 = "Knight3",

    bishop1 = "Bishop1",
    bishop2 = "Bishop2",
    bishop3 = "Bishop3",

    rook1 = "Castle1",
    rook2 = "Castle2",
    rook3 = "Castle3",

    queen1 = "Queen1",
    queen2 = "Queen2",
    queen3 = "Queen3",

    king1 = "King1",
    king2 = "King2",
    king3 = "King3",

    gm3 = "gm3",
    gm2 = "gm2",
    gm1 = "gm1",

    elite3 = "elit3",
    elite2 = "elit2",
    elite1 = "elit1",

    pro3 = "pro3",
    pro2 = "pro2",
    pro1 = "pro1",

    none = "none",
}

export interface IRank {
    _id: string;
    title: RankType;
    threshold: number;
    rakeBack: number;
    bonus: number;
    deleted: boolean;
}

export enum UserBalanceType {
    coins = "coins",
    play = "play",
    referralBonus = "referralBonus",
}

export enum UserBalanceTypeNew {
    play = 1,
    coins = 2,
    referralBonus = 3,
}

export enum ChessboardType {
    desktopGame = "desktopGame",
    desktopView = "desktopView",
    mobileGame = "mobileGame",
    mobileView = "mobileView",
}

export interface IUserBalance {
    rakeBack: number;
    play: {
        reserved: number;
        available: number;
    };
    coins: {
        reserved: number;
        available: number;
    };
    referralBonus: {
        reserved: number;
        available: number;
    };
    hidden: boolean;
}

export interface IUserBalanceNew {
    bonus: number;
    coins: number;
    hidden: boolean;
    play: number;
    rakeBack: number;
    referralRevenueShare: number;
}

export interface IBlockedSettings {
    isChatBlocked: boolean;
    isGamePlayBlocked: boolean;
    user: string;
}

export enum GameTypes {
    dicechess = "dicechess",
    jackpoker = "jackpoker",
    sports = "sports",
    slots = "slots",
    liveDealer = "liveDealer",
}

export interface IUser {
    _id: string;
    email: string;
    nickname: string;
    hasRefProgram: boolean;
    gameSettings: IGameSettings;
    stats: IGameStats;
    rating: {
        elo: number;
        cashBattle: number;
    };
    balance: IUserBalance;
    nicknameChangedAt?: string | undefined;
    registered: string;
    receiveEmails: boolean;
    rank: IRank;
    nextRank: IRank;
    rankIndex: number;
    isSystemAvatar: boolean;
    ranks: IRank[];
    avatar?: string;
    status?: string;
    pathCompressed?: string;
    verificationInfo: {
        verified: boolean;
        hasPhoto: boolean;
        hasDocuments: boolean;
    };
    notification: {
        lastViewedDate: string;
        numberOfUnseenNotifications: number;
    };
    isAggregator: boolean;
    token?: string;
    blockedSections: GameTypes[];
}

export interface IUserNew {
    _id: string;
    agreement: boolean;
    email: string;
    externalSettings: {
        favoriteExternalGames: [];
    };
    nickname: string;
    hasRefProgram: boolean;
    settings: IGameSettingsNew;
    stats: IGameStatsNew;
    rating: {
        elo: number;
        cashBattle: number;
    };
    balance: IUserBalanceNew;
    nicknameChangedAt?: string | undefined;
    registered: string;
    receiveEmails: boolean;
    rank: IRank;
    nextRank: IRank;
    rankIndex: number;
    isSystemAvatar: boolean;
    ranks: IRank[];
    avatar?: string;
    status?: string;
    pathCompressed?: string;
    verificationInfo: {
        verified: boolean;
        hasPhoto: boolean;
        hasDocuments: boolean;
    };
    notification: {
        lastViewedDate: string;
        numberOfUnseenNotifications: number;
    };
    isAggregator: boolean;
    token?: string;
    blockedSections: GameTypes[];
    password: any;
    deleted: any;
    blocked: any;
    blockReason: any;
    isAdmin: any;
    isSupport: any;
    isBot: any;
    referralProgramId: {};
    online: any;
    userType: any;
    lastViewedDate: any;
    securityStatus: any;
    comments: any[];
    extendedLogging: any;
    verification: {
        hasDocuments: boolean;
        hasPhoto: boolean;
        verified: boolean;
    };
}

export enum OnlineStatus {
    online = "online",
    offline = "offline",
}

export interface ILocaleItem {
    label: string;
    defaultValue: string;
}

export enum GameFinishReason {
    TIMEOUT = "timeout",
    CAPTURE = "capture",
    RESIGN = "resign",
    INACTIVITY = "inactivity",
    STANDOFF = "standoff",
}

export enum FriendTypeEnum {
    friend = "friend",
    requested = "requested",
    incoming = "incoming",
}

export interface IFriend {
    type: FriendTypeEnum;
    avatar: string;
    name: string;
    id: string;
    score: {
        wins: number;
        losses: number;
    };
    game: {
        id: string;
        status: GameStatus;
    };
    rating: number;
    deleted: boolean;
    online: boolean;
    isPlaying: boolean;
    isSystemAvatar?: boolean;
    rematchAwait?: boolean;
    joinAwait?: boolean;
    pathCompressed?: boolean;
    rank?: { title: RankType };
}

export interface ISearchedUser {
    avatar: string;
    name: string;
    id: string;
    online: boolean;
    rank?: { title: RankType };
    rating?: number;
    isSystemAvatar?: boolean;
    pathCompressed?: string;
}

export enum GameMode {
    AGGREGATOR = "aggregator",
    DICECHESS = "dicechess",
}

export enum GameType {
    jackpoker = "jackpoker",
    dicechess = "dicechess",
    slots = "slots",
    sports = "sports",
    liveDealer = "liveDealer",
}

export interface SoundFilesType {
    diceSoundPlay: string;
    tripleDice: string;
    pieceSoundPlay: string;
    joinSoundPlay: string;
    timeoutSoundPlay: string;
    wonSoundPlay: string;
    lostSoundPlay: string;
    drawOfferSoundPlay: string;
    rematchOfferSoundPlay: string;
    newGameOfferSoundPlay: string;
    // NEW SOUNDS
    bidConfirmSoundPlay: string;
    bidIncreaseSoundPlay: string;
    bidRefuseSoundPlay: string;
    buttonSoundPlay: string;
    cashboxSoundPlay: string;
    diceButtonV2SoundPlay?: string;
    diceConfirmSoundPlay: string;
    figureChooseSoundPlay: string;
    figureEatSoundPlay: string;
    forfeitSoundPlay: string;
    gameCreateSoundPlay?: string;
    musicChessOSTDemoSoundPlay?: string;
    rematchSoundPlay: string;
    roundDefeatSoundPlay: string;
    roundStartSoundPlay: string;
    roundTimeDefeatSoundPlay: string;
    settingsSoundPlay: string;
    diceSpinningSoundPlay: string;
    gameCreate: string;
    modalSound: string;
    victorySound: string;
}

export interface IMyGame {
    id: string;
    bet: number;
    timeControl: {
        time: number;
        increment: number;
    };
    user: {
        id: string;
    };
    opponent: {
        id: string;
        nickname: string;
        avatar: string;
        rank: {
            title: RankType;
        };
        isSystemAvatar: boolean;
        pathCompressed?: string;
    };
    created: string;
    winner: { id: string } | null;
    balanceType: UserBalanceType;
    rounds: number;
}

export enum TournamentStatus {
    REGISTERING = "registering",
    FINISHED = "finished",
    COMPLETED = "completed",
    RUNNING = "running",
    CANCELLED = "cancelled",
}

export interface IPrizes {
    amount: number;
    place: number;
}

// TODO: fix this type, IGame and ITournament got mixed
export interface ITournaments {
    balanceType: string;
    bet: number;
    buyIn: number;
    buyInWithRake: number;
    color: string;
    created: string;
    currentStage: number;
    doubling: boolean;
    fen: string;
    jackpot: {
        fromRank: string;
    } | null;
    history: Array<any>;
    id: string;
    description: string;
    maxPlayers: number;
    participating: boolean;
    playersInfo: ITopPlayers[] | [];
    players: number;
    prize: number;
    private: boolean;
    privateChat: boolean;
    rematchInfo: { b: any; w: any };
    rounds: number;
    time: string;
    prizes: IPrizes[];
    rake: number;
    status: TournamentStatus | string;
    startDate: string;
    settings: {
        isPrivate: boolean;
    };
    timeControl: {
        increment: number;
        time: number;
    };
    timestamp: number;
    title: string;
    tournament: {
        gameId: string;
        id: string;
        stage: number;
        startDate: string;
        title: string;
        type: string;
        gameSeries: number;
        startAt?: any;
    };
    type: ETournamentType;
    undo: boolean;
    willBeDeletedIn: number;
    challenged?: {
        id: string;
        nickname: string;
        avatar: string;
        isSystemAvatar: boolean;
    } | null;
    winner?: ITopPlayers | null;
}

export interface ITournamentModalInfo {
    balanceType: string;
    buyIn: number;
    buyInWithRake: number;
    color: string;
    created: string;
    currentStage: number;
    description: string;
    jackpot: {
        fromRank: string;
    } | null;
    gameSeries: number;
    id: string;
    maxPlayers: number;
    participating: boolean;
    playersInfo: ITopPlayers[] | [];
    prize: number;
    prizePlace: null;
    prizes: {
        amount: number;
        place: number;
        range?: any;
        rankPoint: number;
    }[];
    rake: number;
    settings: {
        isPrivate: boolean;
        registrationStartDate: string;
    };
    stages: any;
    startDate: string;
    status: TournamentStatus | string;
    stopRegistration: boolean;
    timeControl: {
        increment: number;
        time: number;
    };
    title: string;
    type: GameModeType;
    winner?: ITopPlayers | null;
}

export enum TimePreviewExistPosition {
    lobby = "lobby",
    tournamentModal = "tournamentModal",
}

export enum PlayerInfoExistPosition {
    inGame = "inGame",
    inProgressPreview = "inProgressPreview",
    inLobbyPreview = "inLobbyPreview",
    inProgressTournamentView = "inProgressTournamentView",
    inHistoryView = "inHistoryView",
}

export enum ChatExistPosition {
    inGame = "inGame",
    inProgressPreview = "inProgressPreview",
    inProgressTournamentView = "inProgressTournamentView",
    inHistoryView = "inHistoryView",
}

export interface ITopPlayers {
    avatar: string;
    isSystemAvatar: boolean;
    nickname: string;
    rating: number;
    id: string;
    stats: {
        _id: string;
        wonGames: number;
    };
    user: string;
    rank: { title: RankType };
    _id?: string | undefined;
    pathCompressed?: string | undefined;
    score: number;
}

export interface IRequestTopPlayers extends ITopPlayers {
    users: ITopPlayers[];
    place: number;
}

export interface ITournamentBracketPlayer {
    id: string;
    avatar: string;
    isSystemAvatar: boolean;
    nickname: string;
    rating: number;
    stats: {
        _id: string;
        wonGames?: string | undefined;
    };
    user: string;
    _id?: string | undefined;
    pathCompressed?: string | undefined;
}

export interface IStageGame {
    gameId: string | null;
    id: string;
    matches?: {
        gameId: string;
        winner: string;
    }[];
    player1: string | null;
    player2: string | null;
    referTo: string | null;
    referPlayer?: number | null;
    score?: {
        player1: number;
        player2: number;
    };
    status: TournamentStatus | string | null;
    winner: ITopPlayers | string | null;
    startDate?: string | null;
}
export interface ITournamentBracketStages {
    gameSeries: number;
    games: Array<IStageGame>;
    stage: number;
    startDate: string;
    status: TournamentStatus | string | null;
}

export interface ITournamentBracket {
    balanceType: string;
    buyIn: number;
    buyInWithRake: number;
    color: string;
    created: string;
    currentStage: string;
    description: string;
    gameSeries: number;
    id: string;
    maxPlayers: number;
    participating: boolean;
    playersInfo: ITournamentBracketPlayer[];
    playersMap?: Record<string, ITournamentBracketPlayer>;
    prize: number;
    prizePlace: any | null;
    prizes: {
        amount: number;
        place: number;
        rankPoint: number;
        range: any | null;
    }[];
    score: {
        player1: number;
        plaeyer2: number;
    };
    rake: number;
    stages: ITournamentBracketStages;
    startDate: string;
    status: TournamentStatus | string | null;
    timeControl: {
        increment: number;
        time: number;
    };
    title: string;
    winner: ITopPlayers | null;
}

export interface StageClass {
    gameId: string;
    gameUid: string;
    score: {
        player1: number;
        player2: number;
    };
    stage: number;
    type: string;
}

export interface IParticipantsMobile extends ITopPlayers {}

export interface ISeedBracketMobile {
    date: string;
    gameId: string | null;
    id: string;
    player1: string;
    player2: string;
    referPlayer: number | null;
    referTo: string | null;
    score: {
        player1: number;
        player2: number;
    };
    status: string | null;
    teams: ITopPlayers[];
    winner: string | null;
    coupleCoords?: string | null;
    matches: any[];
}

export interface ISeedBracketDesktop {
    date: string;
    gameId: string | null;
    id: string;
    player1: string | null;
    player2: string | null;
    referPlayer: number | null;
    referTo: string | null;
    status: string | null;
    teams: {
        avatar: string | undefined;
        isSystemAvatar: boolean | undefined;
        nickname: string | undefined;
        rating: number | undefined;
        score: number;
        stats: {
            _id: string | undefined;
            wonGames?: string | undefined;
        };
        user: string | undefined;
        _id?: string | undefined;
        pathCompressed?: string | undefined;
    }[];
    winner: string | null;
    coupleCoords?: string | null;
}

export interface ISeedBracket {
    seeds: ISeedBracketDesktop[];
    title: string;
    coupleCoords?: string | null;
    isThirdPlaceGame?: number | null;
}

export enum ELanguages {
    EN = "en",
    FR = "fr",
    DE = "de",
    RU = "ru",
}

export interface IPaymentMethods {
    payment: IPaymentMethod[];
    payout: IPaymentMethod[];
}

export enum PaymentProviderType {
    interkassa = "interkassa",
    cypix = "cypix",

    // TODO: use provider name(0xprocessing and Payllion)
    crypto = "crypto",
    p2p = "p2p",

    cassy = "cassy",
}

export interface IPaymentMethod {
    id: any;
    label: string;
    method: string;
    minAmount: number;
    maxAmount: number | null;
    currency: string;
    icon: string;
    provider: PaymentProviderType;
    type: PaymentType;
    exchangeRate: number;
}

export interface ReferralStatsData {
    stats: ReferralStats;
    general: GeneralReferralStats;
}
export interface GeneralReferralStats {
    balance: number;
    url: string;
    rate: number;
}
export interface ReferralStats {
    games?: ReferralStatsNumbers;
    rake?: ReferralStatsNumbers;
    lost?: ReferralStatsNumbers;
    won?: ReferralStatsNumbers;
    getFromTable?: ReferralStatsNumbers;
    putOnTable?: ReferralStatsNumbers;
    revenue: ReferralStatsNumbers;
    users: ReferralStatsNumbers;
}
export interface ReferralStatsNumbers {
    today: number;
    total: number;
}

export interface ReferralUser {
    username: string;
    totalGames?: string;
    totalRake?: number;
    totalWon?: string;
    totalLost?: string;
    totalGetFromTable?: string;
    totalPutOnTable?: string;
    revenue: string;
    registered: Date;
}
export enum EModalsLabels {
    friendFightInit = "friendFightInit",
    friendFightReceive = "friendFightReceive",
    lobbyInitiatorAwaiting = "lobbyInitiatorAwaiting",
    lobbyConfirmGame = "lobbyConfirmGame",
    tournamentSecondModal = "tournamentSecondModal",
}

export interface IJackpot {
    amount: number;
    rate: number;
    createdAt: Date;
}

export interface IGameTable {
    id: string | null;
    type: GameType | null;
    state: "lobby" | "game" | null;
    isMenu: boolean;
}

export enum NotificationActionTypes {
    internal_redirect = "internal_redirect",
    external_redirect = "external_redirect",
    custom_action = "custom_action",
}

export enum NotificationCustomActionNames {
    reward_rank_progression = "reward_rank_progression",
}

export interface INotificationAction {
    tagType: "button";
    disabled: boolean;
    actionType: NotificationActionTypes;
    actionProperties: {
        actionName?: NotificationCustomActionNames;
        tagLabel?: string;
        tagLocaleProps?: Record<string, any>;
        path?: string;
        link?: string;
    };
}

export interface INotification {
    id: string;
    locale: {
        label: {
            title: string;
            description: string;
            props?: Record<string, string | number>;
        };
        title?: {
            ru: string;
            en: string;
        };
        description?: {
            ru: string;
            en: string;
        };
    };
    additionalData?: Record<string, any>;
    isRead: boolean;
    isGlobal: boolean;
    actions?: INotificationAction[];
    created: Date;
}

export enum BannerTypes {
    stream = "stream",
    new_game = "new_game",
}

export enum RedirectTypes {
    internal_redirect = "internal_redirect",
    external_redirect = "external_redirect",
}

export enum StyleTypes {
    // TODO: fix naming
    withBackground,
    withAvatar,
}

export interface IBanner {
    _id: string;
    text: string;
    type: BannerTypes;

    backgroundColor: string;
    image: {
        desktop: {
            url: string;
            filename: string;
        };
        mobile: {
            url: string;
            filename: string;
        };
    };
    button: {
        backgroundColor: string;
        borderColor: string;
    };
    styleType: StyleTypes;

    date: Date;

    redirectType: RedirectTypes;

    redirectTo: string;
}

export interface IVariable {
    name: VariableName | string;
    value: any;
}

export enum VariableName {
    pokerAvailability = "pokerAvailability",
}

export enum EGameChatTab {
    chat = "chat",
    history = "history",
    fastGame = "fastGame",
    watchers = "watchers",
}

export interface IGameChatTab {
    [key: string]: string;
}

export interface IStickerDetail {
    id: string;
    filename: string;
}

export interface IStickerRequest {
    id: string;
    pack: string;
}

export interface IStickerPackList {
    stickerPackName: string;
    stickersDetails: IStickerDetail[];
}

// ==================

export enum GameCreateFen {
    one = 1,
    two = 2,
}
export enum GameCreateTime {
    one = 1,
    three = 3,
    five = 5,
    ten = 10,
    twenty = 20,
}

export enum DirectionAccepts {
    one = 1,
    two = 2,
}

export interface PlayerDiretionData {
    email: string;
    isSystemAvatar: boolean;
    nickname: string;
    _id: string;
}

export interface ILobbyInvites {
    createdAt: string;
    details: {
        type: number;
        fenType: GameCreateFen;
        bank: number;
    };
    time: GameCreateTime;
    from: PlayerDiretionData;
    to: PlayerDiretionData;
    _id: string;
    players: [IPlayer] | [IPlayer, IPlayer];
}

export interface ILobbyInvitesAccepts {
    createdAt: string;
    details: {
        type: number;
        fenType: GameCreateFen;
        bank: number;
    };
    direction: DirectionAccepts;
    time: GameCreateTime;
    from: PlayerDiretionData;
    to: PlayerDiretionData;
    _id: string;
    // prevId
    // time
    players: [IPlayer] | [IPlayer, IPlayer];
}

export interface ILobbyGame {
    availableMoves: any[]; // Тип данных не указан, предполагается any[]
    extra: object; // Тип данных не указан, предполагается object
    fen: string;
    fenType: number;
    players: [IPlayer] | [IPlayer, IPlayer];
    relativeId: null; // Тип данных не указан, предполагается null
    startedAt: string;
    state: number;
    timeLeft: number[];
    type: number;
    _id: string;
}

export interface ILobbyInvitesArray {
    invites: ILobbyInvites[];
}

export interface IGamesNewArray {
    games: ILobbyGame[];
}

export interface IFriendsNewArray {
    friends: unknown[];
}

export interface ITournamentNewArray {
    tournaments: unknown[];
}

export interface ILobbyInvitesAcceptsArray {
    invitesAccepts: ILobbyInvitesAccepts[];
}

export interface IStateFromSocket
    extends IGamesNewArray,
        ILobbyInvitesArray,
        ILobbyInvitesAcceptsArray,
        ITournamentNewArray,
        IFriendsNewArray {}
