import { makeStyles } from "@mui/styles";

import {
    DARKGREEN_ALERTS,
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
} from "src/pages/game-new/constants";
import theme from "src/theme";

import { appColors, backgroundColors } from "src/consts/app-config";

const buttonsMediaStyles = {
    [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
        flexBasis: "unset",
        flexGrow: "unset",
    },
};

const useStyles = makeStyles({
    rematchActionContainer: {
        width: "100%",
        background: backgroundColors.darkBlue,
        position: "relative",
        borderRadius: 8,
        maxWidth: 390,
        padding: "15px",
        margin: 0,
        filter: "drop-shadow(0px 9.65126px 9.65126px rgba(0, 0, 0, 0.25))",
        "&.darkBlue": {
            [theme.breakpoints.up(MAX_CHANGE_SCREEN_WIDTH)]: {
                background: backgroundColors.darkBlue,
                border: "1px solid #353A57",
            },
        },
        "&.darkGreen": {
            [theme.breakpoints.up(MAX_CHANGE_SCREEN_WIDTH)]: {
                background: backgroundColors.darkGreen,
                border: "1px solid #004348",
            },
        },
        "&.deepBlack": {
            [theme.breakpoints.up(MAX_CHANGE_SCREEN_WIDTH)]: {
                background: backgroundColors.darkBlue,
                border: "1px solid #353A57",
            },
        },

        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
            padding: "10px 18px",
        },
        [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            padding: "10px 5px",
            "&.darkBlue": {
                background: backgroundColors.darkBlue,
                border: "1px solid #353A57",
                "@media (orientation: portrait)": {
                    background: "none",
                },
            },
            "&.darkGreen": {
                background: backgroundColors.darkGreen,
                border: "1px solid #004348",
                "@media (orientation: portrait)": {
                    background: "none",
                },
            },
            "&.deepBlack": {
                background: backgroundColors.darkBlue,
                border: "1px solid #353A57",
                "@media (orientation: portrait)": {
                    background: "none",
                },
            },
        },

        [`@media screen and (max-width: ${MAX_L_TABLET}px)`]: {
            padding: "10px 18px",
            background: "none",
        },
        "@media screen and (max-width: 400px)": {
            padding: "10px 13px",
        },
        "&.isMultiTable": {
            padding: "5px",
            [theme.breakpoints.up(1800)]: {
                width: "fit-content",
                transform: "scale(1)",
                marginBottom: 0,
                padding: "10px",
                minWidth: "auto",
            },
            "@media screen and (max-height: 850px)": {
                transform: "scale(0.85)",
                margin: 0,
            },
        },
    },
    avatarBlockWrapper: {
        marginTop: -45,
        paddingBottom: 5,
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
            marginTop: -30,
            paddingBottom: 14,
        },
    },
    avatarBlock: {
        width: 120,
        aspectRatio: "1 / 1",
        padding: 2,
        "&.isMultiTable": {
            width: 80,
            height: 80,
            padding: 1,
        },
        "& .nickname-box": {
            fontSize: 24,
        },
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
            width: 90,
            height: 90,
            padding: 2,
            "& .nickname-box": {
                fontSize: 15,
            },
        },
        [theme.breakpoints.down(420)]: {
            width: 70,
            height: 70,
        },
    },
    flexContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        marginBottom: 20,
        [`@media screen and (max-width: ${MAX_L_TABLET}px)`]: {
            display: "none",
        },
        // [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
        //     marginBottom: 12,
        //     display: 'none',
        // },
        "&.isXlTablet": {
            display: "none",
        },
        "&.isMultiTable": {
            marginBottom: 10,
        },
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-around",
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
            justifyContent: "center",
        },
        maxWidth: 300,
        margin: "auto",
    },

    buttonBox: {
        display: "flex",
        justifyContent: "center",
    },
    rejectButton: {
        flexBasis: "40%",
        flexGrow: 1,
        ...buttonsMediaStyles,
    },
    countdown: {
        flexBasis: "20%",
        flexGrow: 0,
        ...buttonsMediaStyles,
    },
    playButton: {
        flexBasis: "40%",
        flexGrow: 1,
        ...buttonsMediaStyles,
    },
    divider: {
        minWidth: 10,
        [`@media screen and (max-width: ${MAX_L_TABLET}px)`]: {
            minWidth: 10,
        },
        // [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
        //     minWidth: 30,
        // },
        "@media screen and (max-width: 450px)": {
            minWidth: 22,
        },
        "@media screen and (max-width: 390px)": {
            minWidth: 10,
        },
    },
    title: {
        fontSize: 24,
        fontWeight: 400,
        color: "#fff",
        margin: 0,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
            fontSize: 20,
        },
        "&.isMultiTable": {
            fontSize: 18,
        },
    },
    playerName: {
        fontWeight: 900,
    },
    description: {
        color: "#B7B9C2",
        fontSize: 18,
        margin: 0,
        textAlign: "center",
        [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`]: {
            fontSize: 16,
        },
    },
    message: {
        fontSize: 18,
        fontWeight: 500,
        color: "#fff",
        margin: 0,
        marginBottom: 10,
        textAlign: "center",
    },
});

export default useStyles;
