import loadable from "@loadable/component";
import { useMediaQuery, Box } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";
import React, {
    useEffect,
    useRef,
    useState,
    useMemo,
    useCallback,
} from "react";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";

import RatingCrown from "src/components/RaitingCrownBox";
import paths from "src/consts/paths";
import useChangeTheme from "src/hooks/useChangeTheme";
import useOnClickOutside from "src/hooks/useOnClickOutside";
import useStores from "src/hooks/useStores";
import { MAX_L_TABLET } from "src/pages/game-new/constants";
import { useLocale } from "src/providers/LocaleProvider";
import {
    GameModeType,
    GameStatus,
    GameType,
    UserBalanceType,
} from "src/store/models";

import { ButtonType, GreenButton } from "../../buttons/greenButton";
import { ModalTypes, ModalProvider } from "../../../pages/landing/utils/context";
import LoginForm from "../../../dialogs/login/LoginForm";
import { RegisterForm } from "../../../pages/register/RegisterForm";

import BurgerMenu from "./components/burger-menu";
import Info from "./components/info/Info";
import Logo from "./components/logo/Logo";
import beturangaIcon from "./images/logo.png";
import diceChessIcon from "./images/logo_classic.png";
import { useStyles } from "./styles";
import DesktopNav from "./components/desktopNav";
import LanguagePicker from "./components/LanguagePicker";

const JackPotComponent = loadable(() => import("src/components/JackPot"));

const AppBar = (props: { isGamePage: boolean; sideMenuIsOpen: boolean }) => {
    const location = useLocation();
    const {
        mainLanding: {
            registeR, logIn
        },
    } = useLocale();
    const { authStore, generalStore, lobbyStore, achievementsStatStore } =
        useStores();
    const {
        login,
        register,
        recoveryPassword,
        alg1xBet,
        algaltente,
        liveDealer: liveDealerPath,
        jackpoker,
        sports,
        logout,
        games,
    } = paths;
    const isPoker = generalStore.gameType === GameType.jackpoker;
    const topPlayers = achievementsStatStore?.topPlayers;

    const styles = useStyles({
        isGamePage: props.isGamePage,
        isPoker: isPoker,
    });
    const appearanceTheme = useChangeTheme();
    const [increaseRakeBar, calcIncreaseRakeBar] = useState<Array<number>>([]);
    const user = authStore.currentUser;

    const isGameScreen = useRouteMatch("/game/:id");
    const isPurchaseDiceCoins = lobbyStore.isPurchaseDiceCoins;

    const userRakeBack = user?.balance.rakeBack;
    const isDesktop = useMediaQuery("(min-width: 1025px)");
    const isTablet = useMediaQuery("(max-width: 1024px)");
    const isMobile = useMediaQuery("(max-width: 768px)");
    const isLanding = useRouteMatch(paths.lobby);

    const isFirstPages =
        location.pathname === login ||
        location.pathname === register ||
        location.pathname === recoveryPassword ||
        location.pathname === logout;

    const hideJackPot =
        location.pathname === alg1xBet ||
        location.pathname === algaltente ||
        location.pathname === liveDealerPath ||
        location.pathname === jackpoker ||
        location.pathname.includes("slot") ||
        location.pathname.includes("live-dealer") ||
        location.pathname === sports;

    const isMultitablePage = location.pathname === games;

    const refTypePopup = useRef();
    const refTypeButton = useRef();

    const refBetPopup = useRef();
    const refBetButton = useRef();

    const refTimePopup = useRef();
    const refTimeButton = useRef();

    useOnClickOutside([refTypePopup, refTypeButton], () => { });

    useOnClickOutside([refBetPopup, refBetButton], () => {
        lobbyStore.toggleBetFilterForm(false);
    });

    useOnClickOutside([refTimePopup, refTimeButton], () => {
        lobbyStore.toggleTimeFilterForm(false);
    });

    const baseLimit = 20;
    useEffect(() => {
        if (
            !achievementsStatStore.isLoadedRating &&
            (!topPlayers || topPlayers.length < baseLimit)
        ) {
            achievementsStatStore.getTopPlayers({
                skip: 0,
                limit: baseLimit,
            });
        }
    }, []);

    useEffect(() => {
        if (typeof userRakeBack === "number") {
            userRakeBack &&
                calcIncreaseRakeBar([...increaseRakeBar, userRakeBack]);
        }
    }, [userRakeBack]);

    const toggleOnClick = useCallback(() => {
        generalStore.toggleSideMenu();
    }, []);
    const [modalState, setModalState] = useState({
        register: false,
        login: false,
        businessMsg: false,
        notifications: false,
    });

    const openModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: true }));
    };
    const closeModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: false }));
    };
    return (
        <>
            <ModalProvider.Provider value={{ openModal, closeModal }}>
                {modalState.register && <RegisterForm />}
                {modalState.login && <LoginForm />}
            {
                    <div
                        className={cn(styles.containerNav, appearanceTheme, {
                            isPoker: isPoker,
                            isLanding: isLanding,
                            isPurchaseDiceCoins: isPurchaseDiceCoins,
                        })}
                    >
                            <Box
                                component="div"
                                className={cn(styles.appBar, appearanceTheme, {
                                    isGamePage: props.isGamePage,
                                    isPoker: isPoker,
                                    isMultitablePage: isMultitablePage,
                                })}
                            >
                                {authStore.isAuthorized ? (
                                    <>
                                        {!isDesktop && !isPoker && (
                                            <>
                                                <Box
                                                    component="div"
                                                    className={cn(
                                                        styles.burgerRatingWrapper,
                                                        {}
                                                    )}
                                                >
                                                    <BurgerMenu
                                                        onClick={toggleOnClick}
                                                        sideMenuIsOpen={
                                                            props.sideMenuIsOpen
                                                        }
                                                    />
                                                    {isTablet && <RatingCrown />}
                                                </Box>
                                            </>
                                        )}
                                        <Box
                                            component="div"
                                            className={cn(styles.logoWithNav, {
                                                isPoker: isPoker,
                                                isMultitablePage: isMultitablePage,
                                            })}
                                        >
                                            <Logo
                                                onClickToggler={() =>
                                                    generalStore.closeSideMenu()
                                                }
                                                isMultitablePage
                                            />
                                            {isDesktop && !isMultitablePage && (
                                                <DesktopNav styles={styles} isLanding={isLanding} />
                                            )}
                                        </Box>
                                            {isDesktop &&
                                                !isPoker &&
                                                !hideJackPot &&
                                                !isMultitablePage && (
                                                    <JackPotComponent isFullAnimation={false} />
                                                )}
                                        <Box
                                            component="div"
                                            className={cn(styles.infoGrid, {
                                                isMultitablePage: isMultitablePage,
                                            })}
                                        >
                                        </Box>
                                        <Info />
                                    </>
                                ) : (
                                    <>
                                        <div className={styles.logoContainerUnauthorized}>
                                            <Logo />
                                        </div>                                        <div className={styles.authWrapper}>
                                            <div className={styles.registration}>
                                                <GreenButton
                                                    type={ButtonType.signUp}
                                                    text={registeR("Sign Up")}
                                                    fontSize="1.2em"
                                                    paddingTop="0.7em"
                                                    paddingBottom="0.7em"
                                                    paddingLeft="0.8em"
                                                    paddingRight="0.8em"
                                                    onClick={() => openModal("register")}
                                                />

                                                <GreenButton
                                                    text={logIn("Log In")}
                                                    fontSize="1.2em"
                                                    type={ButtonType.logIn}
                                                    textColor={"#00BA6C"}
                                                    paddingTop="0.7em"
                                                    paddingBottom="0.7em"
                                                    paddingLeft="0.8em"
                                                    paddingRight="0.8em"
                                                    onClick={() => openModal("login")}
                                                />
                                            </div>
                                            <div className={styles.languageWrapper}>
                                                <LanguagePicker showInDropper={true} />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Box>

                    </div>
                }
            </ModalProvider.Provider>
        </>
    );
};

export default observer(AppBar);
