import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';

// const height = window.innerHeight - 150;
// const heightMobile = window.innerHeight - 30;

const height = window.innerHeight - 30;
const heightDesktop = window.innerHeight - 150;

export const useStyles = makeStyles({
    '@global': {
        '@keyframes potToMid': {
            '0%': {
                opacity: 1,
                transform: 'translate(-50%, 0%) translateY(0px)',
                padding: '10px 20px',
                borderRadius: '10px',
            },
            '20%': {
                transform: `translate(-50%, 0%)  translateY(${height / 2}px)`,
                padding: '10px 20px',
                borderRadius: '10px',
                border: `${appColors.txt400} 1px solid`,
                background: appColors.bg1100,
            },
            '90%': {
                transform: ` translate(-50%, 0%) translateY(${height / 2}px)`,
                padding: '10px 20px',
                borderRadius: '10px',
                border: `${appColors.txt400} 1px solid`,
                background: appColors.bg1100,
                opacity: 1,
            },
            '100%': {
                opacity: 0,
                transform: ` translate(-50%, 0%) translateY(${height / 2}px)`,
                visibility: 'hidden',
            },
        },
        '@keyframes timeBetMidDesk': {
            '0%': {
                opacity: 1,
                transform: 'translate(-50%, 0%) translateY(0px)',
                padding: '10px 20px',
                borderRadius: '10px',
            },
            '20%': {
                transform: `translate(-50%, 0%)  translateY(${
                    heightDesktop / 2
                }px)`,
                padding: '10px 20px',
                borderRadius: '10px',
                border: `${appColors.txt400} 1px solid`,
                background: appColors.bg1100,
            },
            '90%': {
                transform: ` translate(-50%, 0%) translateY(${
                    heightDesktop / 2
                }px)`,
                padding: '10px 20px',
                borderRadius: '10px',
                border: `${appColors.txt400} 1px solid`,
                background: appColors.bg1100,
                opacity: 1,
            },
            '100%': {
                opacity: 0,
                transform: ` translate(-50%, 0%) translateY(${
                    heightDesktop / 2
                }px)`,
                visibility: 'hidden',
            },
        },
    },

    potToTopView: {
        display: 'flex',
        alignItems: 'center',
        '&.runToMid': {
            position: 'absolute',
            left: '50%',
            zIndex: 4,
            animationName: 'potToMid',
            animationDelay: '0s',
            animationIterationCount: 1,
            animationFillMode: 'forwards',
            animationDuration: '1800ms',
        },
    },

    potToTopViewDesktop: {
        display: 'flex',
        alignItems: 'center',
        '&.runToMid': {
            position: 'absolute',
            left: '50%',
            zIndex: 4,
            animationName: 'timeBetMidDesk',
            animationDelay: '0s',
            animationIterationCount: 1,
            animationFillMode: 'forwards',
            animationDuration: '1800ms',
        },
    },

    previewBankText: {
        fontSize: '18px',
        lineHeight: '18px',
        color: appColors.white,
        margin: 0,
        padding: 0,
        fontWeight: 700,
    },
    coinIcon: {
        maxWidth: 24,
        maHeight: 24,
    },
});
