import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import { EColor } from "src/components/types";

import useStores from "./useStores";

const useChangeTheme = (): keyof typeof EColor => {
    const { authStore } = useStores();
    const whatIsBackGround = authStore.currentUser?.settings.background;
    return whatIsBackGround ? EColor[whatIsBackGround] : EColor.darkBlue;
};

export default useChangeTheme;
