export type ChangeAvatarModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export enum ShowHideUpload {
    init = 'init',
    show = 'show',
    hide = 'hide',
}

export type FormValuesType = {
    avatar: any;
    base64Avatar: null | string;
    croppedImage: any;
};

export type Avatars = {
    id: string;
    path: string;
};

export interface IItemAvatarContainer {
    nonGrid: boolean;
    isCustom: boolean;
    getAvatarId: (id: string) => void;
    choosenAvatarId: string | boolean;
    handleUpdateAvatar: (avatarId: string | boolean) => void;
    items: Avatars[] | undefined;
    showUpload: (status: string) => void;
}
