import React from 'react';
import cn from 'classnames';
import { animated, useSpring } from 'react-spring';
import PulseLoader from 'react-spinners/PulseLoader';

import { appColors, backgroundColors } from 'src/consts/app-config';
import useChangeTheme from 'src/hooks/useChangeTheme';

import { useStyles } from './styles';

type BaseAlertProps = {
    title: any;
    isOffering?: boolean | undefined;
    styleProps?: any;
};

const BaseAlert = ({ title, isOffering, styleProps }: BaseAlertProps) => {
    // easy rotae block for new player block position
    const style = useSpring({
        from: {
            opacity: 0,
            transform: 'translate3d(0,20px,0) scale(0.9) rotate(180deg)',
        },
        to: {
            opacity: 1,
            transform: `translate3d(0, 0, 0) scale(1) rotate(180deg)`,
        },
    });
    const appearanceTheme = useChangeTheme();

    const pulseProperties = {
        display: 'flex',
        margin: '0px 0px 4px 0px',
        alignItems: 'flex-end',
        ...styleProps,
    };

    const titleToDots = {
        display: 'flex',
        margin: 0,
        justifyContent: 'center',
        transform: `rotate(180deg)`,
    };

    const styles = useStyles();
    return (
        <animated.div
            style={style}
            className={cn(styles.container, appearanceTheme)}
        >
            <div className={cn(styles.arrowHolder)}>
                <div className={cn(styles.arrow, appearanceTheme)} />
            </div>
            <p style={titleToDots}>
                {title}
                {isOffering && (
                    <PulseLoader
                        color={appColors.white}
                        size={3}
                        css={pulseProperties}
                    />
                )}
            </p>
        </animated.div>
    );
};

export default BaseAlert;
