import React from 'react';
import { TextField } from '@mui/material';
import { useField } from 'formik';
import cn from 'classnames';

import useChangeTheme from 'src/hooks/useChangeTheme';

import { useModalStyles } from './styles';

type TextInputProps = {
    name: string;
    label: string;
};

const TextInput = ({ name, label }: TextInputProps) => {
    const [field] = useField(name);
    const modalStyles = useModalStyles();
    const appearanceTheme = useChangeTheme();

    return (
        <TextField
            variant='filled'
            label={label}
            className={cn(modalStyles.input, appearanceTheme)}
            type='text'
            InputProps={{ disableUnderline: true }}
            {...field}
        />
    );
};

export default TextInput;
