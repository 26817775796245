import { observer } from "mobx-react";
import React, { useContext, createContext, useState } from "react";

import ChangeAvatarModal from "src/components/modals/ChangeAvatarModal";
import ChangeNicknameModal from "src/components/modals/ChangeNicknameModal";
import ChangePasswordModal from "src/components/modals/ChangePasswordModal";
import InviteFriendPlayModal from "src/pages/lobby/components/FriendsContainer/InviteFriendPlayModal";

import DeleteFriendModal from "../components/DeleteFriendModal";
// import InviteFriendPlayModal from '../components/InviteFriendPlayModal';

type ModalProviderProps = {
    children: React.ReactNode;
};

// TODO: remove this functionality and instead use emitter

type modalsState =
    | "changeAvatar"
    | "changeNickname"
    | "changePassword"
    | "inviteFriendToPlay"
    | "deleteFriend";

type ContextType = {
    modalsState: {
        [T in modalsState]: boolean;
    };
    changeModalState: (modal: modalsState, state: boolean) => void;
};

const ModalContext = createContext<ContextType>({} as ContextType);

export const useModalControls = () => useContext(ModalContext);

export const ModalProvider = observer(({ children }: ModalProviderProps) => {
    const [modalsState, setModalsState] = useState<ContextType["modalsState"]>({
        changeAvatar: false,
        changeNickname: false,
        changePassword: false,
        inviteFriendToPlay: false,
        deleteFriend: false,
    });

    const changeModalState = (modal: modalsState, state: boolean) => {
        setModalsState((prev) => ({ ...prev, [modal]: state }));
    };

    return (
        <ModalContext.Provider value={{ modalsState, changeModalState }}>
            {children}
            {modalsState.changeAvatar && (
                // Avatar change with CUSTOM USER AVATAR
                <>
                    <ChangeAvatarModal
                        isOpen={modalsState.changeAvatar}
                        onClose={() => {
                            changeModalState("changeAvatar", false);
                        }}
                    />
                </>
            )}
            {modalsState.changePassword && (
                <ChangePasswordModal
                    isOpen={modalsState.changePassword}
                    onClose={() => {
                        changeModalState("changePassword", false);
                    }}
                />
            )}
            {modalsState.changeNickname && (
                <ChangeNicknameModal
                    isOpen={modalsState.changeNickname}
                    onClose={() => {
                        changeModalState("changeNickname", false);
                    }}
                />
            )}
            {modalsState.inviteFriendToPlay && (
                <InviteFriendPlayModal useModalControls={useModalControls} />
            )}
            {modalsState.deleteFriend && <DeleteFriendModal />}
        </ModalContext.Provider>
    );
});
