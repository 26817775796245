import { observer } from 'mobx-react';
import React from 'react';
import { useLocale } from 'src/providers/LocaleProvider';

import GeneralGameDialogNew from '../common/generalDialogNew';
import useStores from 'src/hooks/useStores';

import descRandomMessageGenerator from 'src/utils/descRandomMessageGenerator';

interface IOpponentAcceptedDoubling {
    randomValue?: number | undefined;
    isXlTablet?: boolean | undefined;
    isMultiTable?: boolean;
}

const OpponentAcceptedDoubling = ({
    randomValue,
    isXlTablet,
    isMultiTable,
}: IOpponentAcceptedDoubling) => {
    const {
        game: {
            dialogs: {
                x2: {
                    ApproveMessages,
                    opponentIsAccepted: { title },
                },
            },
        },
    } = useLocale();
    const { gameStore } = useStores();

    const opponentPlayer = gameStore.gameState.getPlayerByColor(
        gameStore.gameState.getOpponentColor()
    );
    const generatedTitle = descRandomMessageGenerator(randomValue);

    return (
        <GeneralGameDialogNew
            avatar={opponentPlayer?.avatar}
            noButtons
            isXlTablet={isXlTablet}
            titleFontSize={24}
            title={
                generatedTitle
                    ? ApproveMessages[generatedTitle].compile(
                          { nickname: opponentPlayer?.name },
                          '{{nickname}}'
                      )
                    : title.compile(
                          { nickname: opponentPlayer?.name },
                          'The {{nickname}} is accepted doubling'
                      )
            }
            isSystemAvatar={
                opponentPlayer ? opponentPlayer.isSystemAvatar : false
            }
            isMultiTable={isMultiTable}
        />
    );
};

export default observer(OpponentAcceptedDoubling);
