import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { makeStyles } from "@mui/styles";
import cx from "classnames";

import { useLocale } from "src/providers/LocaleProvider";
import useChangeTheme from "src/hooks/useChangeTheme";
import deleteIcon from "src/assets/icons/delete.svg";
import { ButtonType, GreenButton } from "src/components/buttons/greenButton";
import useStores from "src/hooks/useStores";
import { errorService, infoService } from "src/service/services";
import { getButtonColor } from "src/helpers";

import { useModalStyles } from "../styles";
import PasswordInput from "../PasswordInput";

type ChangePasswordModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const useStyles = makeStyles({
    buttonBox: {
        justifySelf: "flex-start",
        marginTop: 16,
    },
    form: {
        gridRowGap: 8,
    },
});

type FormValuesType = {
    currentPassword: string;
    password: string;
    password2: string;
};

const initialValues: FormValuesType = {
    currentPassword: "",
    password: "",
    password2: "",
};

const getValidationSchema = (passwordErrors: any) =>
    yup.object({
        currentPassword: yup
            .string()
            .trim()
            .matches(
                /^[^а-яА-Я]+$/,
                passwordErrors.unsupportedChars(
                    '"Используйте только английские буквы!"'
                )
            )
            .min(
                8,
                passwordErrors.tooShort("Пароль должен быть больше 8 символов!")
            )
            .max(
                16,
                passwordErrors.tooLong(
                    "Пароль очень длинный (макс. 16 символа)!"
                )
            )
            .required(passwordErrors.enter("Введите  текущий пароль")),
        password: yup
            .string()
            .trim()
            .matches(
                /^[^а-яА-Я]+$/,
                passwordErrors.unsupportedChars(
                    '"Используйте только английские буквы!"'
                )
            )
            .min(
                8,
                passwordErrors.tooShort("Пароль должен быть больше 8 символов!")
            )
            .max(
                16,
                passwordErrors.tooLong(
                    "Пароль очень длинный (макс. 16 символа)!"
                )
            )
            .required(passwordErrors.enter("Введите  текущий пароль")),
        password2: yup
            .string()
            .oneOf(
                [yup.ref("password")],
                passwordErrors.secondNotMatch("Пароли не совпадают!")
            )
            .required(passwordErrors.enterSecond("Введите еще раз пароль!")),
    });

const ChangePasswordModal = ({ isOpen, onClose }: ChangePasswordModalProps) => {
    const {
        profile: {
            password: {
                errors: { password, unknownError },
                messages: { passwordChangedSuccessfully },
                form: {
                    placeCurrentPassword,
                    placeNewPassword,
                    placeSecondNewPassword,
                },
                changeButton,
                changePassword,
            },
        },
    } = useLocale();
    const { authStore } = useStores();
    const localStyles = useStyles();
    const styles = useModalStyles();
    const appearanceTheme = useChangeTheme();

    const handleSubmit = async (
        values: FormValuesType,
        actions: FormikHelpers<FormValuesType>
    ) => {
        actions.setSubmitting(true);
        try {
            const { data } = await authStore.changePassword(values);
            if (data.error) {
                return errorService.sendError(data.error);
            }
            infoService.sendInfo(
                passwordChangedSuccessfully("Пароль был успешно изменен!")
            );
        } catch (e) {
            return errorService.sendError(
                unknownError("Неизвестная ошибка смены пароля")
            );
        }
        actions.setSubmitting(false);
        onClose();
    };

    return (
        <Modal open={isOpen} onClose={onClose} className={styles.modalWrapper}>
            <Box
                className={cx(
                    styles.modal,
                    appearanceTheme,
                    "changePasswordModal"
                )}
            >
                <Box className={styles.modalTopBox}>
                    <Typography className={styles.title}>
                        {changePassword("Change Password")}
                    </Typography>
                    <img
                        onClick={onClose}
                        src={deleteIcon}
                        className={styles.closeIcon}
                    />
                </Box>
                <Formik
                    initialValues={initialValues}
                    validationSchema={getValidationSchema(password)}
                    onSubmit={handleSubmit}
                >
                    {(formikProps) => (
                        <form
                            className={cx(styles.form, localStyles.form)}
                            onSubmit={formikProps.handleSubmit}
                        >
                            <PasswordInput
                                name="currentPassword"
                                label={placeCurrentPassword(
                                    "Введите текущий пароль"
                                )}
                            />
                            <PasswordInput
                                name="password"
                                label={placeNewPassword("Введите новый пароль")}
                            />
                            <PasswordInput
                                name="password2"
                                label={placeSecondNewPassword(
                                    "Введите еще раз новый пароль"
                                )}
                            />
                            <div className={localStyles.buttonBox}>
                                <GreenButton
                                    text={changeButton("Изменить")}
                                    type={getButtonColor(
                                        appearanceTheme,
                                        ButtonType
                                    )}
                                    loading={formikProps.isSubmitting}
                                    fontSize={20}
                                    buttonType="submit"
                                    isWidthFixed
                                />
                            </div>
                        </form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
};

export default ChangePasswordModal;
