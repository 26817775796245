import axios from 'axios';

import endpoints from '../../../core/endpoints';

import {
    IUpdateBalanceHiddenRequest,
    IUpdateBalanceHiddenResponse,
    IUpdateGameSettingsRequest,
    IUpdateGameSettingsResponse,
    IChangePasswordRequest,
    IChangePasswordResponse,
    IChangeAvatarRequest,
    IChangeNicknameRequest,
    IChangeUserStatusRequest,
    IGetAvatarsList,
    IChangeAvatarStaticRequest,
    IChangeUserBackGroundColor,
} from './requestResponses';

export const updateBalanceHidden = async (
    params: IUpdateBalanceHiddenRequest
) => {
    return await axios.post<IUpdateBalanceHiddenResponse>(
        endpoints.updateBalanceHidden,
        params
    );
};

export const changeGameSettings = async (
    params: IUpdateGameSettingsRequest
) => {
    return await axios.post<IUpdateGameSettingsResponse>(
        endpoints.updateProfile,
        params
    );
};

export const updatePassword = async (params: IChangePasswordRequest) => {
    return await axios.post<IChangePasswordResponse>(
        endpoints.changePassword,
        params
    );
};

export const changeAvatar = async (params: IChangeAvatarRequest) => {
    return await axios.post(endpoints.uploadAvatar, params, {
        headers: { 'Content-Type': 'multipart/form-data' },
    });
};

export const getAvatarList = async () => {
    return await axios.get(endpoints.getAvatarList);
};

export const changeStaticAvatar = async (
    params: IChangeAvatarStaticRequest
) => {
    return await axios.post(endpoints.uploadStaticAvatar, params);
};

export const changeNickname = async (params: IChangeNicknameRequest) => {
    return await axios.post(endpoints.changeNickname, params);
};

export const changeUserStatus = async (params: IChangeUserStatusRequest) => {
    return await axios.post(endpoints.changeUserStatus, params);
};

export const takeRakeBack = async () => {
    return await axios.post(endpoints.takeRakeBack);
};

export const changeBackGround = async (params: IChangeUserBackGroundColor) => {
    return await axios.post(endpoints.changeBackGroundColor, params);
};
