import { useEffect } from 'react';

import { useLocale } from 'src/providers/LocaleProvider';

import { usePlayer } from '../../../sounds/use-player';
import useStores from '../../../hooks/useStores';

export const useWinOrLostStatus = () => {
    const {
        game: {
            winCauses: {
                timeout: winByTimeout,
                capture: winByCapture,
                resign: winByResign,
                inactivity: winByInactivity,
            },
            lossCauses: {
                timeout: lossByTimeout,
                capture: lossByCapture,
                resign: lossByResign,
                inactivity: lossByInactivity,
            },
        },
    } = useLocale();
    const player = usePlayer();
    const { gameStore } = useStores();

    useEffect(() => {
        if (gameStore?.gameState?.isWon) {
            // Now turn off this because we have problem with playing sound which based on useEffects or mobx store, time after .Time we have old data in our store, or have dont actual data from backend and sound plays random or after reload page etc. At present we strive to play each sound only after user onclick if its posible
            // player('wonSoundPlay');
        } else {
            // player('lostSoundPlay');
        }
    }, []);
    const isWon = gameStore?.gameState?.isWon;
    const resultData = gameStore?.gameState?.gameResultData;

    const setting = gameStore?.gameSettings;

    const winCauses = {
        timeout: winByTimeout('Вы выиграли по времени'),
        capture: winByCapture('Вы съели короля'),
        resign: winByResign('Противник сдался'),
        inactivity: winByInactivity(
            'Вы выиграли из-за неактивности противника'
        ),
    };

    const lossCauses = {
        timeout: lossByTimeout('Вы проиграли по времени'),
        capture: lossByCapture('Соперник съел короля'),
        resign: lossByResign('Вы сдались'),
        inactivity: lossByInactivity('Вы проиграли по неактивности'),
    };

    return {
        isWon,
        resultData,
        winCauses,
        lossCauses,
        setting,
    };
};
