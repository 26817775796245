import { useEffect } from "react";
import { observer } from "mobx-react";
import { useHistory, useRouteMatch } from "react-router-dom";

import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { GameModeType } from "src/store/models";
import { MAX_XL_TABLET } from "src/pages/game-new/constants";

import useStores from "../../../../hooks/useStores";

import JoinToGameFormNew from "./joinToGameFormNew";
import ConfirmGameFormNew from "./confirmGameFormNew";

const GameEventDialogsNew = () => {
    const { lobbyStore, authStore, gameStore } = useStores();
    const history = useHistory();
    const isGameScreen = useRouteMatch("/game/:id");
    const isMobileScreen = window.innerWidth <= MAX_XL_TABLET;

    const inviteToMyGame = lobbyStore.inviteToMyGame();
    const inviteToUsersGame = lobbyStore.inviteToUsersGame();

    useEffect(() => {
        if (isGameScreen && !isMobileScreen) return;
        const players = lobbyStore?.acceptedGame?.players;
        const me = authStore.currentUser?._id;
        const isFriendInvite = lobbyStore.getChallengedGames(me);
        const JOINED_USER = 1;
        if (!me) return;
        if (Array.isArray(players) && isFriendInvite.length > 0) {
            const playersArr: string[] = [];
            players.map((elem) => playersArr.push(elem?.name));
            const isMeInAcceptgame = playersArr.indexOf(me);
            return isMeInAcceptgame === JOINED_USER
                ? lobbyStore.setJoinedGame(lobbyStore.acceptedGame)
                : lobbyStore.setJoinedGame(null);
        }
    }, [lobbyStore.acceptedGame]);

    useEffect(() => {
        if (isGameScreen && !isMobileScreen) return;
        const me = authStore.currentUser?._id;
        if (!me) return;
        const isFriendInvite = lobbyStore.getChallengedGames(me);
        if (
            isFriendInvite &&
            Array.isArray(isFriendInvite) &&
            isFriendInvite.length > 0
        ) {
            lobbyStore.setAcceptedGame(isFriendInvite[0]);
        }
    }, [lobbyStore.allMyGames]);

    // if (
    //     //Рендер если у нас не экран и игры, или мобилка
    //     (!isGameScreen || isMobileScreen) &&
    //     lobbyStore.joinedGame &&
    //     lobbyStore.joinedGame.type === GameModeType.REGULAR
    // ) {
    //     return <JoinToGameForm />;
    // } else if (
    //     (!isGameScreen || isMobileScreen) &&
    //     lobbyStore.acceptedGame &&
    //     lobbyStore.acceptedGame.type === GameModeType.REGULAR
    // ) {
    //     return <ConfirmGameForm />;
    // }
    // return null;

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    if (inviteToMyGame) {
        return (
            <Modal
                open={!!inviteToMyGame}
                onClose={() => {}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        С тобой хотят сыграть
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <strong>Никнейм:</strong> {inviteToMyGame.to?.nickname}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                        <strong>Email:</strong> {inviteToMyGame.to?.email}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                        <strong>Game ID:</strong> {inviteToMyGame._id}
                    </Typography>
                    <Box
                        sx={{
                            mt: 3,
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            onClick={() =>
                                lobbyStore.connectToNormalGame(
                                    inviteToMyGame?._id
                                )
                            }
                            variant="contained"
                            color="primary"
                            sx={{ mr: 1 }}
                        >
                            Принять
                        </Button>
                        <Button
                            onClick={() => {
                                lobbyStore.declineFreeGame(inviteToMyGame._id);
                                console.log(
                                    "Отказать inviteToMyGame ID",
                                    inviteToMyGame?._id
                                );
                            }}
                            variant="outlined"
                            color="secondary"
                        >
                            Отказать
                        </Button>
                    </Box>
                </Box>
            </Modal>
        );
    }

    if (inviteToUsersGame) {
        return <JoinToGameFormNew />;
    }
};

export default observer(GameEventDialogsNew);
