import { makeStyles } from "@mui/styles";
import { appColors } from "src/consts/app-config";

import theme from "src/theme";
import { MAX_CHANGE_SCREEN_WIDTH } from "src/pages/game-new/constants";

export const useStyles = makeStyles({
    beatPiecesCounter: {
        width: "100%",
        height: "100%",
    },

    beatPiecesParagraph: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        aspectRatio: "1 / 1",
        fontFamily: "Montserrat",
        fontSize: "24px",
        lineHeight: "38px",
        fontWeight: 400,
        color: "rgba(245, 245, 245, 0.8)",
    },
});
