import { makeStyles } from '@mui/styles';
import theme from 'src/theme';

export const useStyles = makeStyles({
    container: {
        background: 'rgba(0, 0, 0, 0.6)',
        padding: '0 1.5vw',
        '& .MuiDialog-paper': {
            padding: 0,
            margin: 0,
            width: '100%',
        },
        '& .MuiPaper-root': {
            background: 'transparent',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 1vw',
        },
    },

    dialogPaper: {
        maxWidth: 432,
        width: '100%',
        borderRadius: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '3vw 2vw',
        margin: 0,
        overflowY: 'unset',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
        [theme.breakpoints.down('xs')]: {
            padding: 18,
        },
    },

    formContainer: {
        width: '100vw',
        maxWidth: '480px',
        minWidth: '300px',
        [theme.breakpoints.down('md')]: {
            width: '70vw',
            maxWidth: '480px',
            minWidth: '300px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            maxWidth: '480px',
            minWidth: '300px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '90vw',
        },
    },

    formWrapper: {
        padding: 32,
        background: 'rgba(245, 245, 245, 0.05)',
        backdropFilter: 'blur(24px)',
        borderRadius: '1.5vw',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
        '& .signin-text': {
            cursor: 'pointer',
            color: '#50BAAE',
        },
        [theme.breakpoints.down('md')]: {
            padding: '3vw',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '3vw',
            background: 'rgba(245, 245, 245, 0.05)',
            backdropFilter: 'blur(24px)',
            borderRadius: '1.5vw',
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
            '& .signin-text': {
                cursor: 'pointer',
                color: '#50BAAE',
            },
        },
        [theme.breakpoints.down('xs')]: {
            padding: '1.8vw 1.2vw',
        },
    },

    titleGridItem: {
        width: '100%',
    },
    titleBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    closeIconWrapper: {
        marginLeft: 'auto',
    },
    enterGame: {
        fontWeight: 500,
        fontFamily: 'Montserrat Alternates',
        fontSize: '1.3vw',
        textAlign: 'left',
        color: '#FFFFFF',
        lineHeight: '29.26px',
        marginBottom: '1vw',
        [theme.breakpoints.down('xl')]: {
            fontSize: '2vw',
            fontWeight: 400,
            marginBottom: '1vw',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '2vw',
            fontWeight: 400,
            marginBottom: '1vw',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '3vw',
            fontWeight: 400,
            marginBottom: '1vw',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '5vw',
            fontWeight: 400,
            marginBottom: '1vw',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '5vw',
            fontWeight: 400,
            marginBottom: '6vw',
        },

    },
    joinGame: {
        textAlign: 'left',
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '20px',
        color: '#969BAD',
        marginBottom: 16,
        '& a': {
            textDecoration: 'none',
            color: '#50baae',
            fontSize: 20,
            lineHeight: '25px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
    closeIcon: {
        cursor: "pointer",
        width: "0.9vw",
        height: "0.9vw",
        [theme.breakpoints.down('lg')]: {
            width: "1.5vw",
            height: "1.5vw",
        },
        [theme.breakpoints.down('md')]: {
            width: "2vw",
            height: "2vw",
        },
        [theme.breakpoints.down('sm')]: {
            width: "3vw",
            height: "3vw",
        },
        [theme.breakpoints.down('xs')]: {
            width: "3vw",
            height: "3vw",
            marginBottom: '5vw',
        },
    },

    outlinedInput: {
        backgroundColor: "transparent",
        borderRadius: '0.4vw',
        color: "#fff",
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontFamily: 'Montserrat',
        fontWeight: 200,
        letterSpacing: "0.02vw",
        height: '2.5vw',
        lineHeight: '2.5vw',
        textAlign: 'left',
        fontSize: '0.8vw',
        display: "flex",
        border: '1px solid rgba(255, 255, 255, 0.3)',
        '&:focus-within': {
            border: '1px solid rgba(255, 255, 255, 1)',
            boxShadow: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        '&::placeholder': {
            color: "#969BAD",
            fontSize: "0.75rem",
            fontWeight: 700,
            lineHeight: "2vw",
            textTransform: "uppercase",
            letterSpacing: "0.05em",
            marginBottom: "0.5rem",
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            transition: 'border-color 0.3s ease',
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red',
        },
        [theme.breakpoints.down('xl')]: {
            height: '4vw',
            lineHeight: '3vw',
            fontSize: '1.4vw',
            borderRadius: '0.6vw',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            '&::placeholder': {
                fontSize: "0.9rem",
                lineHeight: "2.5vw",
            },
        },
        [theme.breakpoints.down('lg')]: {
            height: '5vw',
            lineHeight: '3vw',
            fontSize: '1.4vw',
            borderRadius: '0.6vw',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            '&::placeholder': {
                fontSize: "0.9rem",
                lineHeight: "2.5vw",
            },
        },
        [theme.breakpoints.down('md')]: {
            height: '6vw',
            lineHeight: '4vw',
            fontSize: '2vw',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            borderRadius: '0.8vw',
            '&::placeholder': {
                fontSize: "1rem",
                lineHeight: "3vw",
            },
        },
        [theme.breakpoints.down('sm')]: {
            height: '10vw',
            lineHeight: '5vw',
            fontSize: '3vw',
            backgroundColor: 'transparent',
            borderRadius: '2vw',
            '&::placeholder': {
                fontSize: "1.2rem",
                lineHeight: "4vw",
            },
        },
        [theme.breakpoints.down('xs')]: {
            height: '3vw',
            lineHeight: '6vw',
            fontSize: '2vw',
            borderRadius: '1.2vw',
            '&::placeholder': {
                fontSize: "1.4rem",
                lineHeight: "5vw",
            },
        },
    },

    inputAutoFillOverride: {
        '&:-internal-autofill-selected': {
            appearance: 'menulist-button !important',
            backgroundImage: 'none !important',
            backgroundColor: 'transparent !important',
            color: 'inherit !important',
        },
    },

    outlinedLabel: {
        color: "#969BAD",
        fontFamily: 'Montserrat',
        fontSize: 11,
        fontWeight: 700,
        lineHeight: '0.8vw',
        transition: 'all 0.2s',
        width: '100%',
        paddingBottom: '0.2vw',
        letterSpacing: '0.05em',
        [theme.breakpoints.down('lg')]: {
            fontSize: 11,
            margin: '1vw 0 0.8vw 0',
            lineHeight: '0.2w',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 11,
            margin: '1vw 0 1.5vw 0',
            lineHeight: '0.2w',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
            margin: '1vw 0 2vw 0',
            lineHeight: '0.2w',
        },
    },

    passwordHint: {
        color: '#ffffff',
        fontFamily: 'Montserrat',
        fontSize: '0.62vw',
        paddingTop: '0.15vw',
        [theme.breakpoints.down('lg')]: {
            fontSize: 8,
            margin: '0.8vw 0 2vw 0',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 10,
            margin: '0.8vw 0 2vw 0',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 11,
            margin: '1vw 0 2vw 0',
        },
    },

    lineContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    lineBox: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: '#999999',
        fontSize: 14,
        margin: '1vw 0',
        width: '100%',
        '&::before, &::after': {
            content: '""',
            flexGrow: 1,
            height: '1px',
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
        },
        '&::before': {
            margin: '0 1vw 0 0',
            width: '12vw',
            [theme.breakpoints.down('md')]: {
                width: "26vw",
            },
            [theme.breakpoints.down('sm')]: {
                width: "38vw",
            },
        },
        '&::after': {
            margin: '0 0 0 1vw',
            width: '12vw',
            [theme.breakpoints.down('md')]: {
                width: "26vw",
            },
            [theme.breakpoints.down('sm')]: {
                width: "38vw",
            },
        },
        [theme.breakpoints.down('md')]: {
            margin: '3vw 0',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '3vw 0',
        },
    },

    socialRegister: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },

    socialRegisterBtn: {
        background: 'rgba(255, 255, 255, 0.05)',
        border: '1px solid rgba(255, 255, 255, 0.3)',
        borderRadius: '0.4vw',
        padding: '0.4em 4em',
        cursor: 'pointer',
        position: 'relative',
        zIndex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xl')]: {
            padding: '0.7em 4em',
            borderRadius: '0.7vw',
        },
        [theme.breakpoints.down('lg')]: {
            padding: '1em 3.8em',
            borderRadius: '1vw',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            padding: '0.7em 3.8em',
            borderRadius: '0.7vw',
        },
        [theme.breakpoints.between(400, 600)]: {
            padding: '0.7em 2.5em',
            borderRadius: '0.7vw',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0.8em 3em',
            borderRadius: '2vw',
        },
    },

    socialIcon: {
        width: '1.5vw',
        height: '1.5vw',
        objectFit: 'cover',
        [theme.breakpoints.down('lg')]: {
            width: '2vw',
            height: '2vw',
        },
        [theme.breakpoints.down('md')]: {
            width: '3vw',
            height: '3vw',
        },
        [theme.breakpoints.down('sm')]: {
            width: '5vw',
            height: '5vw',
        },
    },

    signUpBlock: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: '1vw',
        '& p': {
            margin: 0,
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 400,
            fontSize: '0.8vw',
            lineHeight: '2vw',
            color: '#ffffff',
            [theme.breakpoints.down('xl')]: {
                fontSize: '0.9vw',
                fontWeight: 300,
            },
            [theme.breakpoints.down('lg')]: {
                fontSize: '1.1vw',
                fontWeight: 300,
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '2vw',
                fontWeight: 300,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '3.5vw',
                fontWeight: 300,
            },
        },
        [theme.breakpoints.down('md')]: {
            marginTop: '4vw',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '4vw',
        },
    },
    signUp: {
        backgroundColor: 'transparent',
        border: 'none',
        color: '#00BA6C',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 700,
        fontSize: '0.8vw',
        lineHeight: '2vw',
        cursor: 'pointer',
        [theme.breakpoints.down('xl')]: {
            fontSize: '0.9vw',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '1.1vw',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '2vw',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '3.5vw',

        },
    },

    formBtnWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '1.6vw',
    },
    checkboxField: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '1vw',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '4vw',
        },
    },
    checkboxContainer: {
        position: 'relative',
        cursor: 'pointer',
        fontSize: '0.8vw',
        userSelect: 'none',
        color: '#fff',
        display: 'flex',
        alignItems: 'center',

    },
    checkbox: {
        position: 'absolute',
        opacity: 0,
        cursor: 'pointer',
        height: 0,
        width: 0,
    },
    checkmark: {
        position: 'relative',
        display: 'inline-block',
        height: '0.9vw',
        width: '0.9vw',
        backgroundColor: 'transparent',
        border: '0.1vw solid #00BA6C',
        verticalAlign: 'middle',
        transition: 'background-color 0.3s ease',
        marginLeft: '0.1vw',
        '&:hover': {
            //backgroundColor: 'rgba(255, 255, 255, 0.2)',
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            display: 'none',
            left: '4px',
            top: '1px',
            width: '3px',
            height: '6px',
            border: 'solid #00BA6C',
            borderWidth: '0 2px 2px 0',
            transform: 'rotate(45deg)',
        },
        [theme.breakpoints.down('lg')]: {
            height: '1.5vw',
            width: '1.5vw',
            '&:after': {
                height: '0.8vw',
                width: '0.5vw',
                top: '0',
                left: '3px',
            },
        },
        [theme.breakpoints.down('md')]: {
            height: '2vw',
            width: '2vw',
            '&:after': {
                height: '1vw',
                width: '0.5vw',
                left: '0.5vw',
            },
        },
        [theme.breakpoints.down('sm')]: {
            height: '4vw',
            width: '4vw',
            '&:after': {
                height: '2.2vw',
                width: '1.2vw',
                top: '1px',
                left: '4px',
            },
        },
        [theme.breakpoints.down('xs')]: {
            height: '0.9vw',
            width: '0.9vw',
            '&:after': {
                height: '0.9vw',
                width: '0.9vw',
            },
        },
    },
    'checkbox:checked + $checkmark': {
        backgroundColor: '#50BAAE',
    },
    'checkbox:checked + $checkmark:after': {
        display: 'block',
    },
    checkmarkChecked: {
        '&:after': {
            display: 'block',
        },
    },
    checkboxText: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 13,
        lineHeight: 1.2,
        fontWeight: 400,
        color: '#999999',
        marginLeft: 9,
        transition: 'color 0.2s',
        '& a': {
            color: '#50BAAE',
            textDecoration: 'none',
        },
    },
    checkboxInput: {
        position: 'absolute',
        opacity: 0,
        cursor: 'pointer',
        height: 0,
        width: 0,
    },
    checkboxChecked: {
        '& $checkmark': {
            '&:after': {
                display: 'block',
            },
        },
    },
    recoveryPasswordText: {
        color: '#00BA6C',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 700,
        fontSize: '13px',
        textDecoration: 'none',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.6vw',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '4vw',
        },
    },

    googleButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        flexGrow: 1,
        marginRight: '1rem',
        width: '7vw',
        [theme.breakpoints.down(3500)]: {
            width: '4vw',
        },
    },

    googleButtonOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0.001,
        zIndex: 2,
        width: '100%',
        height: '100%',
    },

    googleButtonInner: {
        width: '100%',
        height: '100%',
    },

    appleButtonWrapper: {
        position: 'relative',
        flexGrow: 1,
        marginRight: '1rem',
        width: '7vw',
        [theme.breakpoints.down(3500)]: {
            width: '4vw',
        },
    },

    facebookButtonWrapper: {
        position: 'relative',
        cursor: 'pointer',
        flexGrow: 1,
        width: '7vw',
        [theme.breakpoints.down(3500)]: {
            width: '4vw',
        },
    },

    facebookLoginButton: {
        opacity: 0.001,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        width: '100%',
        height: '100%',
    },
});
