import { makeStyles } from "@mui/styles";

import {
    appColors,
    prizeModalGold,
    prizeModalSilver,
    prizeModalOther,
} from "src/consts/app-config";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MIN_CHANGE_SCREEN_WIDTH,
} from "src/pages/game-new/constants";
import theme from "src/theme";
const width = window.innerWidth;

export const useStyles = makeStyles({
    topPlayersGridBox: {
        width: "100%",
        height: "100%",
        margin: "0 auto",
        paddingTop: "12px !important",
        "&.isLobbyPage": {
            paddingTop: "0px !important",
            display: "grid",
            gridTemplateRows: "auto 25px 97%",
        },
        "&.isLobbyPageMobile": {
            maxWidth: "90%",
            minWidth: 300,
        },
        "&.isTournamentPage": {
            gridTemplateRows: "auto 20px 98%",
        },
    },

    topPlayersBox: {
        [theme.breakpoints.up("xs")]: {
            position: "relative",
            width: "100%",
            height: "calc(100dvh - 120px)",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            gridGap: "12px",
            padding: "10",
            overflow: "scroll",
            "-ms-overflow-style": "none",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
                display: "none",
                width: "0!important",
            },
            maskImage: "linear-gradient(to top, transparent 1%, black 3%)",
            "&.isLobbyPage": {
                gridGap: "7px",
            },
            marginTop: "5px",
        },
        [theme.breakpoints.up(1250)]: {
            width: "85%",
            height: "90%",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            gridGap: "10px",
            padding: "0",
            overflow: "scroll",
            "-ms-overflow-style": "none",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
                display: "none",
                width: "0!important",
            },
            maskImage: "linear-gradient(to top, transparent 1%, black 5%)",
            marginTop: "5px",
        },
        [theme.breakpoints.up("xl")]: {
            width: "80%",
            marginTop: "5px",
        },
    },

    topPlayerListTitle: {
        color: appColors.white,
        display: "flex",
        justifyContent: "center",
        gridGap: 10,
        width: "100%",
        fontSize: "16px",
        fontWeight: 700,
        alignItems: "center",
        "&.isLobbyPage": {
            margin: 0,
        },
    },

    topPlayer: {
        display: "flex",
        justifyContent: "flex-start",
        borderRadius: "8px",
        border: "1px solid #103F43",
        gridGap: "12px",
        paddingLeft: "10px",
        "&.top1": {
            background: "darkgoldenrod",
        },
        "&.top2": {
            background:
                "linear-gradient(180deg, #b0abab 0%, rgba(80, 80, 80, 0.61) 100%)",
        },
        "&.top3": {
            background:
                "linear-gradient(180deg, #98732B 0%, rgba(152, 115, 43, 0.61) 100%)",
        },
        "&.top10": {
            background: "#8080805e",
        },
        "&.me": {
            border: `1px solid ${prizeModalSilver.color1} `,
            boxShadow: "0px 0px 10px 3px rgba(255, 255, 255, 0.7) inset",
        },
        // '&.top50': {},
        // '&.top100': {},
        [theme.breakpoints.down(MIN_CHANGE_SCREEN_WIDTH)]: {
            paddingLeft: "5px",
            gridGap: "8px",
        },
    },

    topTextTitle: {
        margin: 0,
    },

    topPlayerSpinnerWrapper: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        minHeight: 30,
        height: 30,
        position: "relative",
    },

    topPlayersTitle: {
        [theme.breakpoints.up("lg")]: {
            color: appColors.white,
            textAlign: "center",
            fontSize: "16px",
            fontWeight: 500,
            width: "100%",
            margin: 0,
        },
    },

    topPlayerPosition: {
        color: appColors.txt950,
        width: "35px",
        fontSize: "20px",
        fontWeight: 700,
        margin: "0",
        display: "flex",
        justifyContent: "center",
        alignSelf: "center",
    },

    topPlayerAvatarSpan: {
        borderRadius: "50%",
        margin: "10px 0px",
    },

    topPlayerAvatar: {
        minWidth: 38,
        minHeight: 38,
        width: 38,
        height: 38,
        objectFit: "contain",
        borderRadius: "50%",
        display: "flex",
        "&.isSystemAvatar": {
            minWidth: 40,
            minHeight: 40,
            width: 40,
            height: 40,
            border: "none",
        },
        "&.isLoaded": {
            filter: "blur(2px)",
        },
        "&.skeleton": {
            width: "38px",
            height: "38px",
            backgroundSize: "1000% 100%",
            border: "1px solid white",
            background:
                "linear-gradient(60deg, rgba(160,160,160,1) 20%, rgba(255,255,255,1) 25%, rgba(160,160,160,1) 30%, rgba(160,160,160,1) 70%, rgba(255,255,255,1) 75%, rgba(160,160,160,1) 80%)",
            animationName: "$skeletonGradient",
            animationDelay: "0s",
            animationIterationCount: "infinite",
            animationFillMode: "forwards",
            animationTimingFunction: "linear",
            animationDuration: "6000ms",
        },
    },

    topPlayerNick: {
        color: appColors.white,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "0",
        marginRight: "5px",
        minWidth: "105px",
        overflow: "hidden",
        // maskImage: 'linear-gradient(to left, transparent 1%, black 3%)',
        "&.skeleton": {
            gridGap: "5px",
        },
        [theme.breakpoints.down(MIN_CHANGE_SCREEN_WIDTH)]: {
            minWidth: "60px",
        },
    },
    topPlayerNickText: {
        margin: 0,
        fontSize: "16px",
        fontWeight: 500,
        "&.top1": {
            fontSize: "20px",
            fontWeight: 700,
        },
        "&.top2": {
            fontSize: "17px",
            fontWeight: 600,
        },
        "&.top3": {
            fontSize: "17px",
            fontWeight: 600,
        },
        "&.top10": {
            color: "silver",
            fontWeight: 600,
        },
        "&.me": {
            fontSize: "20px",
            fontWeight: 700,
        },
        "&.skeleton": {
            width: "100px",
            height: "14px",
            borderRadius: "3px",
            backgroundSize: "1000% 100%",
            border: "0.5px solid white",
            background:
                "linear-gradient(60deg, rgba(160,160,160,1) 20%, rgba(255,255,255,1) 25%, rgba(160,160,160,1) 30%, rgba(160,160,160,1) 70%, rgba(255,255,255,1) 75%, rgba(160,160,160,1) 80%)",
            animationName: "$skeletonGradient",
            animationDelay: "0s",
            animationIterationCount: "infinite",
            animationFillMode: "forwards",
            animationTimingFunction: "linear",
            animationDuration: "6000ms",
        },
        [theme.breakpoints.down(MIN_CHANGE_SCREEN_WIDTH)]: {
            whiteSpace: "nowrap",
        },
    },

    refreshClickerBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "25px",
        aspectRatio: 1,
        borderRadius: "50%",
        opacity: 0.8,
        transform: "rotateZ(0deg)",
        transition: "300ms all ease-out",
        "&.isLobbyPage": {
            justifySelf: "center",
        },
        "&.isTournamentPage": {
            margin: "0 auto",
            height: "20px",
        },
        "&.isMobilePage": {
            margin: "0 auto",
            height: "24px",
            marginTop: "5px",
            marginBottom: "5px",
        },
        "@media (hover: hover)": {
            "&:hover": {
                opacity: 1,
                transform: "rotateZ(45deg)",
                transition: "300ms all ease-in",
                cursor: "pointer",
            },
        },
    },

    refreshImage: {
        flexGrow: 1,
        maxHeight: "100%",
        aspectRatio: 1,
    },

    topPlayerNickScore: {
        margin: 0,
        fontSize: "12px",
        fontWeight: 500,
        [theme.breakpoints.down("md")]: {
            color: "#547672",
        },
        "&.top1": {
            color: "gold",
        },
        "&.top2": {
            color: "silver",
        },
        "&.top3": {
            color: "silver",
        },
        "&.top10": {
            color: "silver",
        },
    },

    "@keyframes skeletonGradient": {
        "0%": {
            backgroundPosition: "0% 50%",
        },
        "100%": {
            backgroundPosition: "100% 50%",
        },
    },

    thumb: {
        backgroundColor: "#131522 !important",
        width: "7px !important",
        borderRadius: "5px !important",
    },
    track: {
        width: "7px !important",
        height: "calc(100% - 2px) !important",
        top: "0 !important",
        // backgroundColor: '#22263D !important',
        borderRadius: "5px !important",
    },
    scroller: {
        paddingRight: 30,
        "@media screen and (max-width: 400px)": {
            paddingRight: 20,
        },
    },

    topPlayersEllipsisBox: {
        width: "100%",
        height: "30px",
        display: "flex",
        alignItems: "center",
        marginTop: "-15px",
    },

    topPlayersEllipsis: {
        width: "100%",
        margin: "0",
        height: "fit-content",
        color: appColors.white,
        fontSize: "28px",
        fontWeight: 700,
    },

    tournamentsRaitingMobileWrapper: {
        width: "100%",
        height: "100dvh",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 6,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: 0,
        transform: `translate(${width * 2}px, 0px)`,
        "&.open": {
            opacity: 0,
            animationDelay: "0s",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDuration: "800ms",
            animationName: "OpenRaiting",
        },
        "&.close": {
            opacity: 1,
            animationDelay: "0s",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDuration: "1000ms",
            animationName: "CloseRating",
        },
        "&.darkBlue": {
            background:
                "linear-gradient(270deg, #14103C 0%, #080520 50%, #140E5A 100%), linear-gradient(0deg, #2A343D, #2A343D), linear-gradient(270deg, #201B44 0%, #2F287D 52.6%, #201B44 99.99%)",
        },
        "&.darkGreen": {
            background:
                "radial-gradient(63.19% 181.28% at 51.57% 62.5%, rgb(0, 73, 78) 0%, rgb(0, 0, 0) 100%)",
        },
        "&.deepBlack": {
            backdropFilter: "blur(12px)",
            background:
                "linear-gradient(0deg, rgba(18, 18, 18, 0.8), rgba(18, 18, 18, 0.8)), linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15))",
        },
    },

    crossTopPlayersBox: {
        position: "absolute",
        top: "12px",
        left: "15px",
        width: "27px",
        height: "27px",
        zIndex: 7,
    },

    crossTopPlayers: {
        width: "100%",
        height: "100%",
        position: "relative",
        "&::before": {
            content: '""',
            display: "block",
            width: "110%",
            height: "2px",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-webkit-transform": "translate(-50%,-50%) rotate(45deg)",
            transform: "translate(-50%,-50%) rotate(45deg)",
            backgroundColor: `${appColors.bg500}`,
            boxShadow: `1px 1px 9px ${prizeModalGold.color1}`,
        },
        "&::after": {
            content: '""',
            display: "block",
            width: "110%",
            height: "2px",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-webkit-transform": "translate(-50%,-50%) rotate(-45deg)",
            transform: "translate(-50%,-50%) rotate(-45deg)",
            backgroundColor: `${appColors.bg500}`,
            boxShadow: `1px 1px 9px ${prizeModalGold.color1}`,
        },
    },
});
