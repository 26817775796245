import { makeStyles } from "@mui/styles";

import { appColors, backgroundColors } from "src/consts/app-config";
import theme from "src/theme";

import {
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
    MIN_L_TABLET,
    MIN_CHANGE_SCREEN_WIDTH,
    MIN_XL_TABLET,
} from "../../constants";

export const buttonSizes = {
    height: 96,
    width: 96,
    "&.isMultiTable": {
        width: 60,
        height: 60,
        "@media screen and (max-height: 900px)": {
            width: 52,
            height: 52,
            fontSize: 18,
        },
    },
    "@media screen and (max-width: 1450px)": {
        width: 75,
        height: 75,
    },
    [`@media screen and (min-width: ${MIN_CHANGE_SCREEN_WIDTH}px) and (max-width: 1320px)`]:
        {
            width: 68,
            height: 68,
        },
    [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (min-height: 900px)`]:
        {
            width: 43,
            height: 43,
        },
    [`@media screen and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (max-height: 899px)`]:
        {
            width: 43,
            height: 43,
        },
    [`@media screen and (orientation: portrait) and (min-width: ${700}px) and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (min-height: 950px) `]:
        {
            width: 55,
            height: 55,
        },

    [`@media screen and (min-width: ${MAX_L_TABLET}px) and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (max-height: 700px)`]:
        {
            width: 55,
            height: 55,
        },
    [`@media screen and (min-width: ${MAX_L_TABLET}px) and (max-width: ${MAX_CHANGE_SCREEN_WIDTH}px) and (min-height: 620px)`]:
        {
            width: 53,
            height: 53,
        },
    "@media screen and (max-width: 400px)": {
        width: 40,
        height: 40,
    },
    "@media screen and (max-width: 370px)": {
        width: 40,
        height: 40,
    },
    "@media screen and (max-width: 340px)": {
        width: 40,
        height: 40,
    },
    "@media screen and (max-width: 430px) and (max-height: 670px)": {
        width: 40,
        height: 40,
    },
    "@media screen and (max-width: 430px) and (max-height: 610px)": {
        width: 36,
        height: 36,
    },
};

// Padding Dice Button

export const useStyles = makeStyles({
    wrapperX2AndDice: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        padding: 0,
        [theme.breakpoints.up(MIN_XL_TABLET)]: {
            minWidth: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            opacity: 0,
            animationName: "$wrapperAppearDesk",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "400ms",
            //====
            justifyItems: "flex-end",
            // "@media screen and (max-height: 780px)": {
            //     justifyItems: "center",
            // },
        },
        [theme.breakpoints.up(1600)]: {
            animationName: "$wrapperAppearDesk",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "400ms",
            //====
            justifyItems: "flex-end",
            // "@media screen and (max-height: 780px)": {
            //     justifyItems: "center",
            // },
        },
        [theme.breakpoints.up(1900)]: {
            animationName: "$wrapperAppearDesk",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "400ms",
            //====
            justifyItems: "center",
        },
        [theme.breakpoints.down("lg")]: {
            opacity: 0,
            animationName: "$wrapperAppear",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "300ms",
        },
    },

    "@keyframes wrapperAppearDesk": {
        "0%": {
            visibility: "hidden",
            opacity: 0,
        },
        "80%": {
            visibility: "visible",
            width: "60%",
            opacity: 0,
        },
        "100%": {
            visibility: "visible",
            width: "90%",
            opacity: 1,
        },
    },

    "@keyframes wrapperAppear": {
        "0%": {
            width: "40%",
            visibility: "hidden",
        },
        "50%": {
            visibility: "visible",
            width: "100%",
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        },
    },

    buttonHolder: {
        "&.x2ButtonMobile": {
            [theme.breakpoints.down("lg")]: {
                position: "absolute",
                top: "50%",
                right: "5%",
                transform: "translate(0%,-50%)",
                padding: 0,
                display: "flex",
                alignItems: "center",
                background: "none",
                border: "none",
                boxShadow: "none",
                zIndex: 2,
                [theme.breakpoints.down(400)]: {
                    right: "3%",
                },
            },
            "&.hideButtonHolder": {
                display: "none",
            },
        },
        "&.x2": {
            display: "flex",
            justifyContent: "flex-end",
            [theme.breakpoints.between("sm", 1023)]: {
                "@media (orientation: portrait)": {
                    position: "absolute",
                    top: "50%",
                    right: "3%",
                    transform: "translate(0%,-50%)",
                    zIndex: 2,
                    background: "none",
                    border: "none",
                    boxShadow: "none",
                    padding: 0,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                },
            },
            [theme.breakpoints.up("lg")]: {
                // marginLeft: 55,
                justifyContent: "center",
                justifySelf: "end",
                // We have 300px height on figma design block and 80px - size of Chip
                // 80 / 300 * 100 ~ 27 %
                height: "27%",
                aspectRatio: 1 / 1,
            },
        },
    },
    diceButtonHolder: {
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        [theme.breakpoints.up("lg")]: {
            aspectRatio: 1,
            maxHeight: "150px",
        },
        [theme.breakpoints.up(1800)]: {
            aspectRatio: 1,
            // maxHeight: "170px",
            //===
            width: "90%",
            maxHeight: "170px",
        },
        [theme.breakpoints.up(2400)]: {
            aspectRatio: 1,
            width: "100%",
            maxHeight: "190px",
        },
        "&.hideOnTournament": {
            display: "none",
        },
    },
    menuWrapper: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "auto auto auto",
        gridColumnGap: "22px",
        justifyContent: "center",
        alignItems: "center",
        padding: "7.5px 0px 7px",
        "@media screen and (max-width: 500px)": {
            display: "flex",
            justifyContent: "space-around",
            gridTemplateColumns: "unset",
            gridColumnGap: "unset",
        },
        "@media screen and (max-width: 540px) and (max-height: 805px)": {
            padding: "5px 0",
        },
    },
    circleButton: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        cursor: "pointer",
    },
    button: {
        width: 68,
        height: 68,
        "@media screen and (max-width: 450px)": {
            width: 48,
            height: 48,
        },
    },
    circleImage: {
        objectFit: "contain",
    },
    textButton: {
        fontSize: 12,
        fontWeight: 500,
        color: appColors.white,
        marginTop: 8,
        "@media screen and (max-width: 450px)": {
            marginTop: 5,
        },
    },
    backButton: {
        borderRadius: "50%",
        background: "#454A6C",
        display: "grid",
        placeItems: "center",
    },
    closeIcon: {
        width: 23,
        height: 23,
        objectFit: "contain",
    },
    wrapperX2AndDiceAnimation: {
        [theme.breakpoints.between("xs", "lg")]: {
            "@media (orientation: portrait)": {
                width: "100% !important",
                alignItems: "center",
                "&.x2ForTabletandMobile": {
                    marginRight: 5,
                },
                "&.diceButtonForTabletandMobile": {
                    margin: 0,
                    position: "absolute",
                },
            },
        },
        [theme.breakpoints.up(1800)]: {
            marginBottom: "20px",
        },
    },
});
