import { makeStyles } from "@mui/styles";
import theme from "src/theme";

import { appColors, chesboardSquares } from "src/consts/app-config";

export const useStyles = makeStyles({
    wrapperHNotation: ({
        borderWidth,
        cellSize,
    }: {
        borderWidth: number;
        cellSize: number;
    }) => ({
        // bottom: -borderWidth + 1,
        // left: borderWidth,
        // right: 12,
        // height: borderWidth,
        // alignItems: "center",
        fontSize: 10,
        position: "absolute",
        justifyContent: "space-around",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        alignItems: "flex-end",
        paddingLeft: cellSize ? `${(cellSize * 2) / 100}px` : "4px",
        "@media screen and (max-width: 768px)": {
            bottom: 0,
            fontSize: 8,
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
            "& > div": {
                justifySelf: "flex-end",
                paddingBottom: 2,
                paddingRight: 2,
            },
        },
        "&.isMultiTable": {
            fontSize: 8,
        },
        "@media screen and (max-width: 400px)": {
            fontSize: 7,
        },
    }),

    hNotationItem: ({
        borderWidth,
        cellSize,
    }: {
        borderWidth: number;
        cellSize: number | undefined;
    }) => ({
        height: "fit-content",
        width: "100%",
        fontFamily: "Montserrat",
        // mixBlendMode: "difference",
        color: chesboardSquares.deepBlack.white,
        //104 / 24 = 4.333 - average lineheight and font size for 1920*1080,
        fontSize: cellSize ? `${cellSize / 4.3}px` : "24px",
        "&.odd": {
            color: chesboardSquares.deepBlack.black,
        },
        "&.deepBlack": {
            color: chesboardSquares.deepBlack.white,
            "&.odd": {
                color: chesboardSquares.deepBlack.black,
            },
        },
    }),
});
