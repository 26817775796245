export function getLocale(
    options: {
        locale?: string;
        defLocale?: string;
        data?: { [s: string]: any };
        onUnknown?: (path: string, value: string) => void;
    } = {}
) {
    let { locale = '', defLocale = 'en', data = {}, onUnknown } = options;

    if (!locale) {
        //@ts-ignore
        locale = `${navigator.language || navigator.userLanguage}`.replace(
            /^([^-]+)(-?.*)?$/,
            '$1'
        );
    }

    if (!onUnknown) {
        onUnknown = (path, value) => {
            console.warn(
                `LOCALE: not found locale for queried path '${path}' ${
                    value ? `having default value '${value}'` : ''
                }`
            );
        };
    }

    const value = data[locale] || {};
    const valueDefault = data[defLocale] || {};

    return localeValue({
        value,
        valueDefault,
        path: `[${locale}]`,
        onUnknown,
    });
}

const sentLocales: { [s: string]: boolean } = {};
function localeValue({ value, valueDefault, path, onUnknown }) {
    const getValue = (def) => {
        if (!value) {
            onUnknown(path, valueDefault || def);

            return valueDefault || def || path;
        }

        return value;
    };

    const extract = (proxy) => (label, defaultValue) =>
        label.split('.').reduce((locale, label) => locale[label], proxy)(
            defaultValue
        );

    const compile = (value, props) => {
        const rex = /{{2}\s*([^}\s]+)\s*}{2}/gm;
        const tokens = (value.match(rex) || [])
            .map((match) => [match, match.replace(rex, '$1')])
            .reduce((map, [match, value]) => {
                map.set(match, value);
                return map;
            }, new Map());

        const getPropsKey = (key) => (key && props[key]) || '';

        return Array.from(tokens.entries()).reduce(
            (template: string, [token, name]) =>
                template.replace(
                    new RegExp(
                        `${token}`.replace(
                            /[-[\]{}()*+!<=:?./\\^$|#,]/g,
                            '\\$&'
                        ),
                        'g'
                    ),
                    `${getPropsKey(name)}`
                ),
            value
        );
    };

    return new Proxy(function () {}, {
        get(target, prop: string, proxy) {
            if (prop === 'extract') {
                return extract(proxy);
            }

            if (prop === 'compile') {
                return (props, defaultValue) =>
                    compile(getValue(defaultValue), props);
            }

            if (prop === 'extractCompiled') {
                return (label, props, defaultValue) =>
                    compile(extract(proxy)(label, defaultValue), props);
            }

            const currentPath = `${path}.${prop}`;

            /*if(!sentLocales[currentPath]){
                markLocaleAsUsed(currentPath.split('.').slice(1).join('.'));
                sentLocales[currentPath] = true;
            }*/

            return localeValue({
                value: value && value[prop],
                valueDefault: valueDefault && valueDefault[prop],
                path: currentPath,
                onUnknown,
            });
        },
        apply(target, thisArg, [def]) {
            return getValue(def);
        },
    });
}
