import cn from "classnames";

import x2Mobile from "src/assets/icons/x2ButtonMobile.png";
import { usePlayer } from "src/sounds/use-player";
import useWindowSize from "src/hooks/useWindowSize";

import quaterChip from "src/assets/icons/gameChips/quarterChip.svg";
import halfChip from "src/assets/icons/gameChips/halfChip.svg";
import threeFourthsChip from "src/assets/icons/gameChips/threeFourthsChip.svg";
import maxChip from "src/assets/icons/gameChips/maxChip.svg";

import { useStyles } from "./styles";

import React, { useState } from "react";
import useStores from "../../../../../hooks/useStores";

const GameChips = () => {
    const classes = useStyles();
    const [chipTogger, setChipToggler] = useState<boolean>(false);
    const { gameStore, authStore } = useStores();

    const extra = gameStore?.currentGameNew?.extra;
    const waitingBetApply = extra
        ? extra["waitingBetApply"] &&
          gameStore?.currentGameNew?.curMove !== authStore.currentUser?._id
        : false;

    const myMove =
        authStore.currentUser?._id === gameStore.currentGameNew?.curMove;

    const handleAcceptClick = () => {
        gameStore.gameBetNew({
            gameId: gameStore?.currentGameNew?._id || "",
            bet:
                (gameStore?.currentGameNew?.extra?.currentBank || 0) -
                (gameStore?.currentGameNew?.bank || 0),
        });
    };

    const handleChipClick = (chip, coef) => {
        if (!chipTogger) return;

        gameStore
            .gameBetNew({
                gameId: gameStore?.currentGameNew?._id || "",
                bet: (gameStore?.currentGameNew?.bank || 0) * coef,
            })
            .catch(console.error);
    };

    return (
        <>
            {!waitingBetApply && myMove && (
                <div
                    className={cn(classes.chipButtonMain, {
                        open: chipTogger,
                    })}
                    onClick={() => setChipToggler(!chipTogger)}
                >
                    <img
                        className={cn(classes.chipButton, {
                            quarterChip: chipTogger,
                        })}
                        onClick={() => handleChipClick("1/4", 0.25)}
                        src={quaterChip}
                        alt={"1/4"}
                    />
                    <img
                        className={cn(classes.chipButton, {
                            halfChip: chipTogger,
                        })}
                        onClick={() => handleChipClick("1/2", 0.5)}
                        src={halfChip}
                        alt={"1/2"}
                    />

                    <img
                        className={cn(classes.chipButton, {
                            threeFourthsChip: chipTogger,
                        })}
                        onClick={() => handleChipClick("3/4", 0.75)}
                        src={threeFourthsChip}
                        alt={"3/4"}
                    />

                    <img
                        className={cn(classes.chipButton, {
                            maxChip: chipTogger,
                        })}
                        onClick={() => handleChipClick("Max", 1)}
                        src={maxChip}
                        alt={"max"}
                    />
                </div>
            )}

            {waitingBetApply && !myMove && (
                <button onClick={() => handleAcceptClick()}>Accept</button>
            )}
        </>
    );
};

export default GameChips;
