import { action, computed, makeAutoObservable, runInAction } from "mobx";
import { Socket } from "socket.io-client";
import { errorService } from "src/service/services";

import {
    ICommunicationChatMessage,
    ICommunicationGameHistory,
    ICommunicationGameNotification,
} from "./types";

import {
    IBetRequest,
    IApplyBetRequest,
} from "./../../service/api/game/requestResponses";
import GameStore from "./index";

class GameCommunicationState {
    constructor(gameStore: GameStore) {
        makeAutoObservable(this);
        this.gameStore = gameStore;
    }
    socket: Socket;
    gameStore: GameStore;
    messages: ICommunicationChatMessage[] = [];
    // history: ICommunicationGameHistory[] = [];
    history: any;
    notifications: ICommunicationGameNotification[] = [];
}

export default GameCommunicationState;
