import React, { memo, useCallback } from "react";
import { useDrop } from "react-dnd";
import { observer } from "mobx-react";
import cn from "classnames";

import useStores from "src/hooks/useStores";
import { appColors, squareShineColors } from "src/consts/app-config";
import { EColor } from "src/components/types";

import iOSDetect from "../../../../utils/iOsDetect";
import { usePlayer } from "../../../../sounds/use-player";

import { useStyles } from "./styles";

interface Props {
    i: number;
    square: string;
    mobileHover: boolean;
    appearanceTheme: string;
    highlight: boolean;
    onSquareClick?: (target: string) => void;
    onDrop?: (t: { from: string; to: string }) => void;
}

interface ComponentProps {}

const Square = (props: Props & ComponentProps) => {
    const classes = useStyles();
    const player = usePlayer();

    const isHighlightMove = "isHighlight";
    const emptyPieces = "empty";

    const { onDrop, onSquareClick, i, square, mobileHover, highlight } = props;
    const isHighlight = highlight ? isHighlightMove : null;

    const [{ isOver }, drop] = useDrop({
        accept: [
            "wp",
            "wr",
            "wn",
            "wb",
            "wq",
            "wk",
            "bp",
            "br",
            "bn",
            "bb",
            "bq",
            "bk",
            emptyPieces,
        ],

        drop: (props) => {
            //@ts-ignore
            onDrop && onDrop({ from: props.from, to: square });
            iOSDetect() && player("pieceSoundPlay");
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    return (
        <div
            ref={drop}
            key={i}
            className={cn(classes.noselect, isHighlight, props.appearanceTheme)}
            style={{
                transform: isHighlight
                    ? `translate(${(i % 8) * 100}%,${
                          Math.trunc(i / 8) * 100
                      }%) scale(0.4)`
                    : `translate(${(i % 8) * 100}%,${
                          Math.trunc(i / 8) * 100
                      }%)`,
            }}
            onMouseDown={() => {
                onSquareClick && onSquareClick(square);
            }}
            onTouchStart={() => {
                onSquareClick && onSquareClick(square);
            }}
            onTouchMove={() => {
                onSquareClick && onSquareClick(square);
            }}
        >
            <div
                data-type={emptyPieces}
                className={cn(classes.squareBox, {
                    hoverMobile: isOver && mobileHover && !isHighlight,
                    hoverDesktop: isOver && !mobileHover && !isHighlight,
                })}
            />
        </div>
    );
};

const MemoizedSquare = memo(Square);

const SquareWrapper = (props: Props) => {
    const { gameStore } = useStores();

    return (
        <Square
            {...props}
            //highlight={squareStyles[props.square]}
            //onSquareClick={onSquareClick}
            //onDrop={onDrop}
        />
    );
};

export default observer(SquareWrapper);
