import React, { useState, useEffect } from "react";
import { Dialog, Box } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";
import { useHistory, useRouteMatch } from "react-router-dom";

import useStores from "src/hooks/useStores";
import { GameStatus, EModalsLabels, EGameChatTab } from "src/store/models";
import { usePlayer } from "src/sounds/use-player";
import { useLocale } from "src/providers/LocaleProvider";
import { REJECT_OR_ACCEPT_MODAL_TIMEOUT_JOIN } from "src/consts/lobby-params";
import {
    iHaveBeenChallenged,
    isGameBetweenFriends,
} from "src/utils/game-between-friends";
import useChangeTheme from "src/hooks/useChangeTheme";
import ModalTitles from "src/components/modals/ModalGameComponents/ModalTitles/ModalTitles";
import ModalImagesBox from "src/components/modals/ModalGameComponents/ModalImagesBox/ModalImagesBox";
import ModalButtonsBox from "src/components/modals/ModalGameComponents/ModalButtonsBox/ModalButtonsBox";
import GameStore from "src/store/gameStore";

import ProgressBar from "../../ProgressBar";

import { useStyles } from "./styles";

// TODO: refactor
const JoinToGameForm = ({ placePosition }: any) => {
    const { lobbyStore, authStore, gamesStore, generalStore } = useStores();
    const [gameStore, setGameStore] = useState<GameStore | null>(null);
    const [isRequestedToJoin, setIsRequestedToJoin] = useState(false);
    const ratio = window.innerWidth / window.innerHeight;
    const appearanceTheme = useChangeTheme();
    const classes = useStyles({ ratio });
    const player = usePlayer();
    const history = useHistory();
    const isGameScreen = useRouteMatch("/game/:id");
    const isMultiTablePage = useRouteMatch("/games");

    const {
        lobby: {
            userInteraction: { cancelGame },
            awaitingDecision,
        },
    } = useLocale();

    const user = authStore.currentUser;
    const lobbyGame = lobbyStore.joinedGame;

    const inviteToUsersGame = lobbyStore.inviteToUsersGame();

    const isBetweenFriends = lobbyGame && isGameBetweenFriends(lobbyGame);
    const game = gameStore?.currentGame;
    const activeGames = lobbyStore.getActiveGames();
    const participatingGames = lobbyStore.getParticipatingGames();

    useEffect(() => {
        if (isGameScreen && isBetweenFriends) return;
        const activeGame = activeGames.find((g) => g.id === lobbyGame?.id);
        if (activeGame && activeGame.status === GameStatus.IN_PROGRESS) {
            lobbyStore.setJoinedGame(null);
            if (
                isMultiTablePage &&
                generalStore.isMultiTableAvailable &&
                !gameStore?.currentGame?.id &&
                lobbyGame
            ) {
                const isAssigned = gamesStore.createAndAssignToGameTable(
                    lobbyGame.id
                );
                if (!isAssigned) {
                    gamesStore.addGameIdToChoose(lobbyGame.id);
                }
                gameStore?.reset();
                return;
            }
            // Why do we need this second condition? Maybe we should remove this
            if (
                isMultiTablePage &&
                generalStore.isMultiTableAvailable &&
                gameStore?.currentGame?.id
            ) {
                const isAssigned = gamesStore.createAndAssignToGameTable(
                    gameStore?.currentGame?.id
                );
                if (!isAssigned) {
                    gamesStore.addGameIdToChoose(gameStore?.currentGame?.id);
                }
                gameStore?.reset();
                return;
            }
            if (!gameStore?.currentGame?.id && lobbyGame) {
                if (
                    generalStore.isMultiTableAvailable &&
                    participatingGames.length > 1
                )
                    history.push(`/games?id=${lobbyGame.id}`);
                else history.push(`/game/${lobbyGame.id}`);
                gameStore?.reset();
            } else {
                if (
                    generalStore.isMultiTableAvailable &&
                    participatingGames.length > 1
                )
                    history.push(`/games?id=${gameStore?.currentGame?.id}`);
                else history.push(`/game/${gameStore?.currentGame?.id}`);
                gameStore?.reset();
            }
        }
    }, [
        activeGames,
        gameStore?.currentGame?.id,
        gameStore?.currentGame?.players,
        gameStore?.currentGame?.status,
    ]);

    useEffect(() => {
        if (!isGameScreen && !isBetweenFriends) return;
        const game = activeGames.find((g) => g.id === lobbyGame?.id);
        if (isMultiTablePage && generalStore.isMultiTableAvailable && game) {
            const isAssigned = gamesStore.createAndAssignToGameTable(game.id);
            if (!isAssigned) {
                gamesStore.addGameIdToChoose(game.id);
            }
            gameStore?.reset();
            return;
        }
        if (!isMultiTablePage && !generalStore.isMultiTableAvailable && game) {
            lobbyStore.setJoinedGame(null);
            history.push(`/game/${game.id}`);
            gameStore?.reset();
            return;
        }
    }, [activeGames]);

    useEffect(() => {
        if (lobbyGame) {
            gameStore?.reset();
            const newGameStore = gamesStore.createGameStore();
            newGameStore.setGameId(lobbyGame.id);
            setGameStore(newGameStore);
        }
    }, [lobbyGame?.id]);

    const joinHandler = () => {
        player("joinSoundPlay");
        gameStore?.join();
    };

    useEffect(() => {
        if (
            game &&
            game.players.length === 1 &&
            !gameStore.joined &&
            gameStore.socket &&
            !isRequestedToJoin
        ) {
            setIsRequestedToJoin(true);
            joinHandler();
        }
    }, [game, gameStore?.socket]);

    const player1 = game?.players ? game.players[0] : null;

    useEffect(() => {
        return () => {
            gameStore?.reset();
        };
    }, []);

    const cancelJoinHandler = () => {
        inviteToUsersGame && lobbyStore.declineFreeGame(inviteToUsersGame._id);
    };

    if (placePosition === EGameChatTab.fastGame) {
        return (
            <Box
                component="div"
                className={cn(classes.dialogJoinGame, placePosition)}
            >
                <ModalTitles
                    title={`${awaitingDecision("Awaiting decision")}...`}
                    placePosition={placePosition}
                />
                <ModalImagesBox
                    label={EModalsLabels.lobbyInitiatorAwaiting}
                    nickname={player1?.name || "player"}
                    avatar={player1?.avatar || ""}
                    isSystemAvatar={player1?.isSystemAvatar || false}
                    placePosition={placePosition}
                />
                <ProgressBar
                    seconds={REJECT_OR_ACCEPT_MODAL_TIMEOUT_JOIN}
                    showTime={true}
                    progressColor="linear-gradient(90deg, #20766C 0%, #5DC5BB 101.95%)"
                    progressShortTimeColor="linear-gradient(90deg, #A60F0F 0%, #FF4236 101.95%)"
                    progressWarningColor="linear-gradient(90deg, #DB640E 0%, #FFD336 101.95%)"
                    onEnd={cancelJoinHandler}
                />
                <ModalButtonsBox
                    label={EModalsLabels.lobbyInitiatorAwaiting}
                    rejectActions={cancelJoinHandler}
                    rejectText={cancelGame("Cancel")}
                />
            </Box>
        );
    }

    return (
        <Dialog
            open={!!inviteToUsersGame?._id}
            className={cn(classes.dialogJoinGame)}
            PaperProps={{
                className: cn(classes.dialogPaperJoinAwaiting, appearanceTheme),
            }}
        >
            <div
                className={cn(
                    classes.pattern,
                    appearanceTheme,
                    EModalsLabels.lobbyInitiatorAwaiting
                )}
            />
            <ModalTitles
                title={`${awaitingDecision("Awaiting decision")}...`}
            />
            <ModalImagesBox
                label={EModalsLabels.lobbyInitiatorAwaiting}
                nickname={inviteToUsersGame?.from?.nickname}
                avatar={inviteToUsersGame?.from?._id}
                isSystemAvatar={inviteToUsersGame?.from.isSystemAvatar}
            />
            {/* <ProgressBar
                seconds={REJECT_OR_ACCEPT_MODAL_TIMEOUT_JOIN}
                onEnd={cancelJoinHandler}
                showTime={true}
            /> */}
            <ProgressBar
                // initialProgress={
                //     (game.willBeDeletedIn && getTimerValue(game)) ??
                //     REJECT_OR_ACCEPT_MODAL_TIMEOUT_FRIENDS
                // }
                seconds={REJECT_OR_ACCEPT_MODAL_TIMEOUT_JOIN}
                showTime={true}
                progressColor="linear-gradient(90deg, #20766C 0%, #5DC5BB 101.95%)"
                progressShortTimeColor="linear-gradient(90deg, #A60F0F 0%, #FF4236 101.95%)"
                progressWarningColor="linear-gradient(90deg, #DB640E 0%, #FFD336 101.95%)"
                // onEnd={cancelJoinHandler}
                onEnd={() => {}}
            />
            <ModalButtonsBox
                label={EModalsLabels.lobbyInitiatorAwaiting}
                rejectActions={cancelJoinHandler}
                rejectText={cancelGame("Cancel")}
            />
        </Dialog>
    );
};

export default observer(JoinToGameForm);
