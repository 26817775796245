import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    formControlMain: {
        '&.MuiFormControl-root': {
            position: 'relative',
            minWidth: 'auto',
            width: 'auto',
            border: 'none',
            borderRadius: '4px',
            background: 'rgba(245, 245, 245, 0.05)',
            padding: '5px 7px',
            marginRight: '50px',
            color: 'white',
            '&:hover': {
                border: 'none',
            },
            '@media (max-width: 1001px)': {
                marginRight: '3vw',
            },
            '@media (max-width: 801px)': {
                marginRight: '3vw',
            },
            '@media (max-width: 701px)': {
                marginRight: '3vw',
            },
        },
    },

    formSelect: {
        padding: '0.3vw 0.9vw',
        color: 'white',
        '& > div': {
            padding: '0',
            color: 'white',
            fontSize: '1.4vw',
            '&:focus': {
                backgroundColor: 'transparent',
            },

        },
        '& > fieldset': {
            borderColor: 'transparent !important',
        },
        '& > svg': {
            color: 'white',
            fontSize: '1.4vw',
            marginRight: '0.6vw',
            '@media (max-width: 1001px)': {
                fontSize: '3vw',
            },
            '@media (max-width: 801px)': {
                fontSize: '5vw',
            },
            '@media (max-width: 701px)': {
                fontSize: '5vw',
            },
        },
    },

    menuItemContainer: {
        padding: 0,
        '&.MuiMenuItem-root': {
            backgroundColor: 'rgb(22, 56, 19,  0.05)',
            width: '8.5vw',
            '&:hover': {
                backgroundColor: 'rgba(245, 245, 245, 0.05)',
            },
            '@media (max-width: 1001px)': {
                fontSize: '3vw',
            },
            '@media (max-width: 801px)': {
                fontSize: '3vw',
            },
            '@media (max-width: 701px)': {
                width: '21vw',
                fontSize: '3vw',
                backgroundColor: 'rgb(22, 56, 19,  0.05)',
            },
        },
        '&.MuiMenuItem-root.Mui-selected': {
            backgroundColor: 'rgba(245, 245, 245, 0.05)',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
            },
        },
    },
    menuItemBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '0.6vw 0.9vw',
    },
    menuItemImg: {
        display: 'block',
        objectFit: 'contain',
        width: '1.5vw',
        height: '1.5vw',
        marginRight: '0.6vw',
        '@media (max-width: 1001px)': {
            width: '2vw',
            height: '2vw',
        },
        '@media (max-width: 801px)': {
            width: '2vw',
            height: '2vw',
        },
        '@media (max-width: 701px)': {
            width: '3vw',
            height: '2.5vw',
        },
    },
    menuItemTitle: {
        margin: 0,
        padding: 0,
        fontSize: '16px',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        lineHeight: '19.2px',
        verticalAlign: 'middle',
    },
});