import { makeStyles } from "@mui/styles";
import { appColors, backgroundColors } from "src/consts/app-config";
import { MIN_XL_TABLET } from "src/pages/game-new/constants";
import theme from "src/theme";

export const useStyles = makeStyles({
    linkBoxWrapper: {
        display: "none",
        "@media (min-width: 320px)": {
            width: "fit-content",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            gridGap: "15px",
            marginBottom: "0px",
            "@media (max-width: 340px)": {
                gridGap: "15px",
            },
        },
        [theme.breakpoints.up("md")]: {
            marginBottom: "2px",
            gridGap: "28px",
        },
        "&.main": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gridGap: "20px",
            alignItems: "center",
            [theme.breakpoints.down(MIN_XL_TABLET)]: {
                gridGap: "10px",
            },
        },
    },

    supportText: {
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: 500,
        [theme.breakpoints.up(1500)]: {
            fontSize: "24px",
            lineHeight: "28px",
            fontWeight: 500,
        },
    },

    linkBox: {
        [theme.breakpoints.up("xs")]: {
            width: "100%",
            height: "100%",
            position: "relative",
        },
        [theme.breakpoints.up("lg")]: {
            "&:hover .open": {
                visibility: "visible",
                opacity: 1,
                transform: "translate(-50%, -50%) translateY(-10px)",
                paddingBottom: "5px",
            },
        },
    },

    linkBoxSpan: {
        display: "none",
        [theme.breakpoints.up("lg")]: {
            visibility: "hidden",
            display: "block",
            position: "absolute",
            fontSize: "14px",
            color: appColors.white,
            top: "0px",
            left: "50%",
            transform: "translate(-50%, -50%) translateY(0px)",
            opacity: 0,
            transition: "0.6s ease",
        },
    },

    linkItem: {
        [theme.breakpoints.up("xs")]: {
            display: "block",
            width: "100%",
            height: "100%",
        },
        [theme.breakpoints.up("lg")]: {
            textDecoration: "none",
        },
    },

    linkImage: {
        [theme.breakpoints.up("xs")]: {
            width: "20px",
            height: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            objectFit: "contain",
        },
        [theme.breakpoints.up("lg")]: {
            "&.span": {
                color: "white",
                fontSize: "12px",
            },
        },
    },
});
