import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import _get from "lodash/get";

import { useLocale } from "src/providers/LocaleProvider";
import BetConfig from "src/pages/lobby/components/LobbyTable/HeaderBlock/CreateGameBlock/components/betConfig";
import TimeConfig from "src/pages/lobby/components/LobbyTable/HeaderBlock/CreateGameBlock/components/timeConfig";
import useStores from "src/hooks/useStores";
import { errorService } from "src/service/services";
import useChangeTheme from "src/hooks/useChangeTheme";
import ModalTitles from "src/components/modals/ModalGameComponents/ModalTitles/ModalTitles";
import ModalImagesBox from "src/components/modals/ModalGameComponents/ModalImagesBox/ModalImagesBox";
import ModalDivider from "src/components/modals/ModalGameComponents/ModalDivider/ModalTitles";
import ModalButtonsBox from "src/components/modals/ModalGameComponents/ModalButtonsBox/ModalButtonsBox";
import { EModalsLabels } from "src/store/models";
import { MAX_NUMBER_OF_GAMES } from "src/consts/lobby-params";
import { RoundsConfig } from "src/features/createGame";

import { useStyles } from "./styles";

const InviteFriendPlayModal = ({ useModalControls }: any) => {
    const { changeModalState } = useModalControls();
    const { authStore } = useStores();
    const me = authStore.currentUser;
    const classes = useStyles();
    const appearanceTheme = useChangeTheme();
    const {
        common: { newGame },
        friends: {
            requests: { cancelFriendRequest },
            inviteFriendToPlay: { playButton },
        },
        lobby: {
            messages: { cannotCreateGameNetworkError },
        },
    } = useLocale();
    const locales = useLocale();
    const { friendsStore, lobbyStore } = useStores();
    const [isDisabled, setIsDisabled] = useState(false);
    const [selectedRounds, setSelectedRounds] = useState(1);

    const friend = friendsStore.selectedFriend;
    const getAllParticipatingGames = lobbyStore.getAllMyParticipatingGames(
        friend?.id
    );
    const getAllMyParticipatingGames = lobbyStore.getAllMyParticipatingGames(
        authStore.currentUser?._id
    );

    const isMaxNumberOfGames =
        getAllParticipatingGames.length >= MAX_NUMBER_OF_GAMES ||
        getAllMyParticipatingGames.length >= MAX_NUMBER_OF_GAMES;

    if (!friend) return null;

    const handleCloseModal = () => {
        changeModalState("inviteFriendToPlay", false);
    };

    const handleCreateGame = async () => {
        setIsDisabled(true);
        try {
            await lobbyStore.createGameBetweenFriends(friend.id, {
                rounds: selectedRounds,
            });
            handleCloseModal();
        } catch (e) {
            const error = e.response?.data?.error;
            if (error) {
                errorService.sendError(
                    _get(locales, error?.label)(error?.defaultValue || "")
                );
                return;
            }
            errorService.sendError(
                cannotCreateGameNetworkError(
                    "Cannot create game! Network error!"
                )
            );
        } finally {
            setIsDisabled(false);
        }
    };
    if (!me) return null;

    return (
        <Dialog
            open={true}
            maxWidth={false}
            className={classes.dialog}
            onClose={handleCloseModal}
            PaperProps={{
                className: cn(classes.dialogPaperInviteFriend, appearanceTheme),
            }}
        >
            <div className={cn(classes.pattern, appearanceTheme)} />
            <ModalTitles
                title={newGame("New Game")}
                label={EModalsLabels.friendFightInit}
            />
            <ModalImagesBox
                myNickname={me?.nickname}
                myAvatar={me?.avatar}
                myIsSystemAvatar={me?.isSystemAvatar}
                nickname={friend?.name || ""}
                avatar={friend?.avatar}
                isSystemAvatar={friend.isSystemAvatar}
                label={EModalsLabels.friendFightInit}
            />
            {/*<SmallRankInfo rank={friend?.rank} />*/}
            <ModalDivider label={EModalsLabels.friendFightInit} />
            <div className={classes.gameFiltersHolder}>
                <BetConfig position="top" />
                <TimeConfig position="top" />
                <RoundsConfig
                    value={selectedRounds}
                    onChange={setSelectedRounds}
                    includeEntry
                />
            </div>
            <ModalButtonsBox
                acceptText={playButton("Let’s play")}
                rejectText={cancelFriendRequest("Cancel")}
                acceptActions={handleCreateGame}
                rejectActions={handleCloseModal}
                isDisabled={isDisabled}
                label={EModalsLabels.friendFightInit}
                isMaxNumberOfGames={isMaxNumberOfGames}
            />
        </Dialog>
    );
};

export default observer(InviteFriendPlayModal);
