import { makeStyles } from "@mui/styles";

import { appColors, backgroundColors } from "src/consts/app-config";
import theme from "src/theme";

export const useStyles = makeStyles({
    dialog: {
        padding: "0 10px",
    },
    dialogPaper: {
        maxWidth: 388,
        width: "100%",
        background:
            "radial-gradient(40.69% 165.03% at 51.57% 62.5%, #343A59 0%, rgba(52, 58, 89, 0.437526) 45.01%, rgba(52, 58, 89, 0) 100%), #353A57",
        border: "1px solid #0B0C12",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
        borderRadius: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "32px 40px 32px",
        margin: 0,
        overflowY: "unset",
        [theme.breakpoints.down("sm")]: {
            padding: 24,
        },
        "&.darkBlue": {
            background:
                "radial-gradient(40.69% 165.03% at 51.57% 62.5%, #343A59 0%, rgba(52, 58, 89, 0.437526) 45.01%, rgba(52, 58, 89, 0) 100%), #202233",
        },
        "&.darkGreen": {
            background: "#374949",
        },
        "&.deepBlack": {
            background:
                "radial-gradient(40.69% 165.03% at 51.57% 62.5%, #343A59 0%, rgba(52, 58, 89, 0.437526) 45.01%, rgba(52, 58, 89, 0) 100%), #202233",
        },
    },
    title: {
        color: appColors.white,
        fontSize: 24,
        fontWeight: 700,
        textShadow: "0px 3.19791px 0px rgba(0, 0, 0, 0.15)",
        margin: 0,
    },
    text: {
        color: appColors.white,
        fontSize: 16,
        fontWeight: 400,
        margin: "12px 0 24px",
        textAlign: "center",
        "& span": {
            fontWeight: 700,
        },
    },
    buttonGroup: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: "12px",
        alignItems: "center",
    },
    button: {
        "& .button": {
            padding: "8px 0",
        },
        "& .button, & .black-wrapper, & .steel-wrapper": {
            width: "100%",
        },
    },
});
