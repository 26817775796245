import {
    action,
    computed,
    makeAutoObservable,
    runInAction,
    reaction,
} from "mobx";

import { rootStore } from "src/hooks/useStores";
import {
    getBonuses,
    getMyNotifications,
    markNotificationAsViewed,
} from "src/service/api/notifications";
import { IMarkNotificationAsViewedRequest } from "src/service/api/notifications/requestResponses";
import {
    IChangePasswordRequest,
    IUpdateGameSettingsRequest,
} from "src/service/api/profile/requestResponses";
import { emitter } from "src/shared/emitter";
import { LocaleHelper } from "src/shared/helpers/LocaleHelper";
import { URLHelper } from "src/shared/helpers/URLHelper";
import { setGameChatTab, getChatStickerPacks } from "src/service/api/gameChat";
import { Nullable } from "src/shared/types";
import { EGameChatTab } from "./models";

import {
    checkAuth,
    impersonateUser,
    loginByToken,
    logout,
    signIn,
    signUp,
    updateVerificationInfo,
    updateVerificationPhoto,
    signInNew,
    signUpNew,
    checkAuthByTokenNew,
} from "../service/api/auth";
import { IImpersonateUserRequest } from "../service/api/auth/requestResponses";
import {
    changeAvatar,
    changeBackGround,
    changeGameSettings,
    changeNickname,
    changeStaticAvatar,
    changeUserStatus,
    getAvatarList,
    takeRakeBack,
    updateBalanceHidden,
    updatePassword,
} from "../service/api/profile";
import {
    getBusinessMsg,
    getProfileForReferralsData,
    getProfileForReferralsUsers,
    getReferralWithdraw,
} from "../service/api/profileForReferral";
import { canAccessLocalStorage } from "../utils/isLocalStorageAvailable";

import { IOpponentSettingsAxios } from "./../service/api/opponentSettings/requestResponses";
import {
    IStickerPackList,
    GameMode,
    GameTypes,
    GeneralReferralStats,
    INotification,
    IUser,
    ReferralStats,
    ReferralUser,
    UserBalanceType,
    ELanguages,
    IUserNew,
} from "./models";

import { RootStore } from "./index";

export type Avatars = {
    id: string;
    path: string;
};

export class AuthStore {
    rootStore: RootStore;
    // currentUser?: IUser;
    currentUser?: IUserNew;
    isAuthorized: boolean = false;
    isLoading: boolean = true;
    isAvatarLoadingError: boolean = false;
    avatarList: Avatars[] = [];
    isStickersLoading: boolean = false;
    stickers: IStickerPackList[] = [];
    referralId: string | null = null;
    showSpinner: boolean = false;
    // TODO: delete this key "notAuthUser" and rename it to the "selectedLanguage" or just "language"
    notAuthUser?: string | null = null;
    selectedLanguage: Nullable<ELanguages> = null;
    referralStats: ReferralStats = {
        games: { today: 0, total: 0 },
        rake: { today: 0, total: 0 },
        revenue: { today: 0, total: 0 },
        users: { today: 0, total: 0 },
        lost: { today: 0, total: 0 },
        won: { today: 0, total: 0 },
        getFromTable: { today: 0, total: 0 },
        putOnTable: { today: 0, total: 0 },
    };

    referralGeneralInfo: GeneralReferralStats = {
        balance: 0,
        url: "",
        rate: 0,
    };
    referralUsers: ReferralUser[] = [];
    notifications: INotification[] = [];
    isOpenUserSettings: boolean = false;
    isVisibleWebApp: boolean = true;
    increasedRakeBack: number = 0;
    increasedRP: number = 0;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    async getMeNew() {
        const existToken = this.getAuthToken();
        const impersonateToken = URLHelper.getSearchParam("impersonateToken");
        const integrationToken = URLHelper.getSearchParam("token");

        if (impersonateToken) {
            await this.logout(true);
            await this.impersonateUser({ token: impersonateToken! });
            URLHelper.deleteSearchParam("impersonateToken");
        }

        if (integrationToken) {
            await this.logout(true);
            await this.loginByToken(integrationToken);
            URLHelper.deleteSearchParam("token");
        }
        try {
            const res = await checkAuthByTokenNew(existToken);

            if (res.status === 200 && res.data._id) {
                // const defaultUserBalance = {
                //     hidden: false,
                //     real: 10,
                //     coins: 10,
                //     play: 10,
                //     referralBonus: 10,
                //     referralRevenueShare: 10,
                //     rakeBack: 10,
                // };
                // const mockedBalance = {
                //     balance: defaultUserBalance,
                //     gameSettings: {
                //         background: "deepBlack",
                //     },
                //     ...res.data,
                // };
                this.setCurrentUserNew(res.data);
                runInAction(() => {
                    this.isLoading = false;
                    this.selectedLanguage =
                        this.currentUser?.settings?.language ?? ELanguages.EN;
                    if (res.data.isAggregator) {
                        rootStore.generalStore.setMode(GameMode.AGGREGATOR);
                    }
                });
                if (res.data.token) {
                    this.saveAuthToken(res.data?.token || "");
                }
                this.showSpinner = false;
                return;
            } else {
                runInAction(() => {
                    this.isLoading = false;
                    this.isAuthorized = false;
                });
                this.showSpinner = false;
            }
        } catch (e) {
            const error = LocaleHelper.extractFromError(e);
            if (error) emitter.emit("locale:error", error);
        }
    }

    async getMe() {
        const impersonateToken = URLHelper.getSearchParam("impersonateToken");
        const integrationToken = URLHelper.getSearchParam("token");

        if (impersonateToken) {
            await this.logout(true);
            await this.impersonateUser({ token: impersonateToken! });
            URLHelper.deleteSearchParam("impersonateToken");
        }

        if (integrationToken) {
            await this.logout(true);
            await this.loginByToken(integrationToken);
            URLHelper.deleteSearchParam("token");
        }

        try {
            const res = await checkAuth();
            if (res.status === 200 && res.data._id) {
                this.setCurrentUser(res.data);
                runInAction(() => {
                    this.isLoading = false;
                    this.selectedLanguage =
                        this.currentUser?.settings?.language ?? ELanguages.EN;
                    if (res.data.isAggregator) {
                        rootStore.generalStore.setMode(GameMode.AGGREGATOR);
                    }
                });
                if (res.data.token) {
                    this.saveAuthToken(res.data?.token || "");
                }
                this.showSpinner = false;
                return;
            } else {
                runInAction(() => {
                    this.isLoading = false;
                    this.isAuthorized = false;
                });
                this.showSpinner = false;
            }
        } catch (e) {
            const error = LocaleHelper.extractFromError(e);
            if (error) emitter.emit("locale:error", error);
        }
    }

    async loginByToken(token: string) {
        const res = await loginByToken({
            token,
        });
        if (!res.data.error) {
            this.setCurrentUser(res.data.user);
            this.saveAuthToken(res.data.user?.token || "");
        }
        return res.data.error;
    }

    async signIn(
        username: string,
        password: string,
        language: ELanguages,
        remember: boolean
        // visitorInfo: IVisitorInfo
    ) {
        try {
            // const res = await signIn({
            //     username,
            //     password,
            //     language,
            //     remember,
            //     // visitorInfo,
            // });

            const res = await signInNew({
                email: username,
                password,
                language,
                // visitorInfo,
            });

            const data = res.data;
            if ("auth_token" in data) {
                this.saveAuthToken(data.auth_token || "");
            }
        } catch (e) {
            return e.response?.data?.message || "Sign error";
        }
    }

    // const error = LocaleHelper.extractFromError(e);
    // if (error) emitter.emit("locale:error", error);

    async signUp(
        email: string,
        nickname: string,
        password: string,
        agreement: boolean,
        language: ELanguages,
        referralId?: string | null,
        subscribeToEmails?: boolean,
        countryId?: string // поле countryId
        // visitorInfo: IVisitorInfo,
    ) {
        try {
            // const res = await signUp({
            //     user: {
            //         email,
            //         password,
            //     },
            //     agreement,
            //     subscribeToEmails,
            //     // visitorInfo,
            //     referralId,
            // });

            const res = await signUpNew({
                email,
                nickname,
                password,
                agreement,
                referralId,
                language,
                countryId, // поле countryId
            });

            const data = res.data;
            if ("auth_token" in data) {
                this.saveAuthToken(data.auth_token || "");
            }
        } catch (e) {
            return e.response?.data?.message || "Sign error";
            // const error = LocaleHelper.extractFromError(e);
            // if (error) emitter.emit("locale:error", error);
        }
    }

    async getBusinessMsg(contact: string, text: string) {
        const res = await getBusinessMsg({ contact: contact, text: text });
        if (res.status !== 200) {
            console.log("err");
        }
        return res;
    }

    async updateVerificationInfo(
        documentId: string,
        documentType: string,
        fullname: string
    ) {
        const res = await updateVerificationInfo({
            documentId: documentId,
            documentType: documentType,
            fullname: fullname,
        });
        if (res.status === 200) await this.getMe();
        return res;
    }

    async uploadVerificationPhoto(data: FormData) {
        const res = await updateVerificationPhoto(data);
        if (res.status === 200) await this.getMe();
        return res;
    }

    async getReferralData(gameType: string) {
        const res = await getProfileForReferralsData({ gameType: gameType });
        if (res.status !== 200) {
            console.log("err");
        } else {
            this.referralStats = res.data.stats;
            this.referralGeneralInfo = res.data.general;
        }
        return res.data;
    }
    async getReferralUsers(gameType: string) {
        const res = await getProfileForReferralsUsers({
            gameType: gameType,
        });
        if (res.status !== 200) {
            console.log("err");
        } else {
            this.referralUsers = res.data.users;
        }
        return res.data;
    }
    async getReferralWithdraw() {
        const res = await getReferralWithdraw();
        if (res.status !== 200) {
            console.log("err");
        }
        return res.data;
    }

    // TODO: rename this method
    @action
    setNotLoginUserLang(language: string | null) {
        console.log("language action", language);
        this.notAuthUser = language;
    }

    async logout(isLoading?: boolean) {
        try {
            // const res = await logout();
            // const { data } = res;
            // data &&
            //     runInAction(() => {
            //         this.isAuthorized = false;
            //         this.currentUser = undefined;
            //         this.isLoading = isLoading ?? false;
            //     });
            this.removeAuthToken();
            runInAction(() => {
                this.isAuthorized = false;
                this.currentUser = undefined;
                this.isLoading = isLoading ?? false;
                this.removeAuthToken();
            });
        } catch (error) {
            runInAction(() => {
                this.isLoading = isLoading ?? false;
            });
            return error;
        }
    }

    @action
    setCurrentUserNew(user: IUserNew) {
        this.currentUser = user;
        this.isAuthorized = true;
    }

    @action
    setCurrentUser(user: any) {
        console.log("user setCurrentUser", user);
        this.currentUser = user;
        this.isAuthorized = true;
    }
    @action
    setReferralId(id: string | null) {
        this.referralId = id;
    }

    @action
    setIncreasedRakeBack(newValue: number) {
        if (
            this.currentUser?.balance.rakeBack &&
            this.currentUser?.balance.rakeBack <= newValue
        ) {
            this.increasedRakeBack = +(
                newValue - this.currentUser?.balance.rakeBack
            ).toFixed(3);
        }
    }

    @action
    setIncreasedRP(newValue: number) {
        this.increasedRP = +newValue.toFixed(1);
    }

    async updateHiddenBalance(value: boolean) {
        this.changeHiddenBalance(value);
        const response = await updateBalanceHidden({ balanceHidden: value });
        if (response.status === 200) this.getMe();
    }

    async getStaticAvatar() {
        try {
            this.isAvatarLoadingError = false;
            const response = await getAvatarList();
            if (response.status === 200) await this.getMe();
            const {
                data: { avatars },
            } = response;
            avatars &&
                runInAction(() => {
                    this.avatarList = avatars;
                });
        } catch (error) {
            runInAction(() => {
                this.isAvatarLoadingError = true;
            });
            return error;
        }
    }

    @action
    setStikersInStore(stickerPack: IStickerPackList[]) {
        this.stickers = stickerPack;
    }

    async getChatStickerPacks() {
        if (this.stickers.length > 0) return;
        this.showSpinner = true;
        try {
            const response = await getChatStickerPacks();
            if (response.status === 200) {
                const { data } = response;
                console.log("data", data);
                this.setStikersInStore(data);
            }
        } catch (error) {
            runInAction(() => {
                console.log("error", error);
                this.isStickersLoading = true;
                return error;
            });
        } finally {
            this.showSpinner = false;
        }
    }

    @action
    get balanceHidden() {
        return this.currentUser?.balance?.hidden || false;
    }

    @action
    updateBalance(newValue: {
        [UserBalanceType.play]: number;
        [UserBalanceType.coins]: number;
        [UserBalanceType.referralBonus]: number;
    }) {
        if (!this.currentUser) return;
        this.currentUser.balance.play = newValue[UserBalanceType.play];
        this.currentUser.balance.coins = newValue[UserBalanceType.coins];
        if (!this.currentUser.balance.bonus) {
            this.currentUser.balance.bonus =
                newValue[UserBalanceType.referralBonus];
        } else {
            this.currentUser.balance.bonus =
                newValue[UserBalanceType.referralBonus];
        }
    }

    @action
    changeLanguage(language: Nullable<ELanguages> | any) {
        this.selectedLanguage = language;
    }

    @action
    changeHiddenBalance(hidden: boolean) {
        if (!this.currentUser) return;
        this.currentUser.balance.hidden = hidden;
    }

    @computed
    get generalGameSettings() {
        if (!this.currentUser) return;
        return this.currentUser.settings;
    }

    get balance() {
        return this.currentUser?.balance.play || 0;
    }

    get coinsBalance() {
        return this.currentUser?.balance.coins || 0;
    }

    async uploadAvatar(formData: FormData) {
        const response = await changeAvatar(formData);
        if (response.status === 200) await this.getMe();
    }

    async uploadStaticAvatar(avatarId: string) {
        const response = await changeStaticAvatar({ avatarId });
        if (response.status === 200) await this.getMe();
    }

    async updateGameSettings(data: IUpdateGameSettingsRequest) {
        if (!this.isAuthorized) return;
        //temporary turn off this logical - path incorrect
        // const response = await changeGameSettings(data);
        // if (response.status === 200) this.getMe();
    }

    async changePassword(data: IChangePasswordRequest) {
        const response = await updatePassword(data);
        if (response.status === 200) this.getMe();
        return response;
    }

    async changeNickname(nickname: string) {
        const response = await changeNickname({ nickname });
        if (response.status === 200) await this.getMe();
    }

    async changeUserStatus(status: string) {
        const response = await changeUserStatus({ status });
        if (response.status === 200) await this.getMe();
    }

    async takeRakeBack() {
        const response = await takeRakeBack();
        if (response.status === 200) await this.getMe();
    }

    async changeBackGround(backGroundColor: string, diceColor: string) {
        if (!this.currentUser) return;
        if (this.currentUser.settings.background === backGroundColor) return;
        this.showSpinner = true;
        this.currentUser.settings.background = backGroundColor;
        try {
            const response = await changeBackGround({
                background: backGroundColor,
                diceColor: diceColor,
            });
            console.log("response", response);
            if (response.status === 200) await this.getMe();
        } catch (error) {
            console.log("error", error);
            this.showSpinner = false;
        }
    }

    async setGameChatTab(tab: keyof typeof EGameChatTab) {
        if (!this.currentUser) return;
        this.showSpinner = true;
        try {
            const response = await setGameChatTab({ mode: tab });
            console.log("response:", response);
            //
            if (response.status === 200) await this.getMe();
        } catch (error) {
            console.log("setGameChatTab Error:", error);
            this.showSpinner = false;
        } finally {
            this.showSpinner = false;
            //Logical in case requests are not working yet
            //
            this.currentUser.settings = {
                ...this.currentUser.settings,
                dicechessBoardRightBlockMode: tab,
            };
        }
    }

    async getBonuses(id: string) {
        if (!this.currentUser) return;
        try {
            const response = await getBonuses(id);
            if (response.status === 200) await this.getMe();
        } catch (error) {
            console.log(error);
        }
    }
    @action
    setNotifications(data: INotification[]) {
        this.notifications = data;
    }

    @action
    setVisibleWebAppStatus(isVisible: boolean) {
        if (this.isVisibleWebApp !== isVisible) {
            this.isVisibleWebApp = isVisible;
        }
    }

    async getMyNotifications() {
        try {
            const { data } = await getMyNotifications();
            this.setNotifications(data.notifications);
        } catch (e) {}
    }

    async markNotificationAsViewed(params?: IMarkNotificationAsViewedRequest) {
        try {
            await markNotificationAsViewed(params);
            await this.getMe();
        } catch (e) {}
    }

    async impersonateUser(params: IImpersonateUserRequest) {
        try {
            await impersonateUser(params);
        } catch (e) {}
    }

    @action
    toggleUserSettingsPage(status: boolean) {
        this.isOpenUserSettings = status;
    }

    @computed
    get userCoinBalance() {
        if (this.currentUser?.balance?.play) {
            return this.currentUser?.balance?.play?.toFixed(2);
        } else {
            return 0;
        }
    }

    @computed
    get userFreeCoinBalance() {
        if (this.currentUser?.balance?.coins) {
            return this.currentUser?.balance?.coins?.toFixed(2);
        } else {
            return 0;
        }
    }

    saveAuthToken(token: string) {
        const isLSAvailable = canAccessLocalStorage();
        if (isLSAvailable) {
            localStorage.setItem("t", token);
        } else {
            // @ts-ignore
            window.authState = { token };
        }
    }

    removeAuthToken() {
        const isLSAvailable = canAccessLocalStorage();
        if (isLSAvailable) {
            localStorage.removeItem("t");
        } else {
            // @ts-ignore
            window.authState = undefined;
        }
    }

    getAuthToken() {
        const isLSAvailable = canAccessLocalStorage();
        if (isLSAvailable) {
            return localStorage.getItem("t");
        }
        // @ts-ignore
        return window.authState?.token;
    }

    checkIfUserHasAccessToSection(section: GameTypes) {
        const blockedSections = new Set(
            this.currentUser?.blockedSections ?? []
        );
        return !blockedSections.has(section);
    }

    getLanguage() {
        return this.selectedLanguage ?? ELanguages.EN;
    }
}

export default AuthStore;
