import React from 'react';
import { observer } from 'mobx-react';

import useStores from 'src/hooks/useStores';

import { RematchStage } from '../../../../../store/gameStore/dialogs';

import MobileAcceptOrReject from './AcceptOrReject';
import MobileWaitForOpponent from './WaitForOpponent';
import MobileOpponentReject from './OpponentReject';

const RematchDialogs = () => {
    const { gameStore } = useStores();
    const { isOpen, stage } = gameStore.dialogs.rematchDialog;
    if (!isOpen) return null;

    if (stage === RematchStage.waitingForOpponentToDecide) {
        return <MobileWaitForOpponent />;
    } else if (stage === RematchStage.acceptOrRejectRematch) {
        return <MobileAcceptOrReject />;
    } else if (stage === RematchStage.rejected) {
        return <MobileOpponentReject />;
    }

    return null;
};

export const MobileRematchDialogs = observer(RematchDialogs);
