import React from "react";
import { observer } from "mobx-react";
import { Box, Container, Grid } from "@mui/material";
import endpoints from "src/core/endpoints";
import calcNameLength from "src/utils/calcNameLength";
import cn from "classnames";
import useChangeTheme from "src/hooks/useChangeTheme";
import { PlayerInfoExistPosition } from "src/store/models";

import { IWatchingList, WatchersStatusType } from "../../types/watchingTypes";
import WatchingToggler from "../../WatchingToggler";

import { useStyles } from "./styles";

function DesktopWatchingList({
    setOpen,
    isOpen,
    watchingArray,
    openStatus,
}: IWatchingList): JSX.Element {
    const classes = useStyles();
    const clientWidth = window.innerWidth;
    const appearanceTheme = useChangeTheme();

    return (
        <Box
            className={
                isOpen === WatchersStatusType.show
                    ? cn(classes.watchingListShow, appearanceTheme)
                    : isOpen === WatchersStatusType.hide
                    ? cn(classes.watchingListHide, appearanceTheme)
                    : cn(classes.watchingListReload, appearanceTheme)
            }
        >
            <Container className={classes.watchingListContainerDesktop}>
                <WatchingToggler
                    setOpen={(properties) => setOpen(properties)}
                    openStatus={openStatus}
                    isOpen={isOpen}
                    watchingArray={watchingArray}
                    dryStyle={true}
                    elementPlace={PlayerInfoExistPosition.inProgressPreview}
                />
                <Grid
                    container
                    spacing={1.5}
                    justifyContent="center"
                    alignItems="center"
                    className={classes.watchingMainGrid}
                >
                    {watchingArray.map((user, index) => (
                        <Grid
                            key={user.id}
                            item
                            xs={4}
                            style={{ minWidth: "50px", maxWidth: "80px" }}
                        >
                            <Box
                                key={`${user.id}_box`}
                                className={classes.watchingUserBox}
                            >
                                <img
                                    src={`${endpoints.avatarPath}${user.avatar}`}
                                    className={classes.watchingUserAvatar}
                                />
                                <p className={classes.watchingUserNick}>
                                    {calcNameLength(user.nickname, 450, 9)}
                                </p>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}

export default observer(DesktopWatchingList);
