import React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import { infoService } from "src/service/services";
import { useLocale } from "src/providers/LocaleProvider";
import { IPlayer, GameFinishReason, UserBalanceType } from "src/store/models";
import paths from "src/consts/paths";
import { ColorVariant, RankType } from "src/store/models";

import { useWinOrLostStatus } from "../../hooks/useWinOrLostStatus";
import useStores from "../../../../hooks/useStores";
import { useChessBoardProps } from "../../hooks/useChessBoardProps";

import WinDialog from "./components/win";
import LostDialog from "./components/lost";
import DrawDialog from "./components/draw";

interface IWinOrLostAlert {
    isTabletMobile?: boolean | undefined;
    // opponent?: IPlayer | undefined | null;
    opponent?: any;
}

const WinOrLostAlert = ({
    isTabletMobile,
    opponent: opponentProps,
}: IWinOrLostAlert) => {
    const history = useHistory();
    const { gameStore, achievementsStatStore } = useStores();
    const { resultData, winCauses, lossCauses, setting, isWon } =
        useWinOrLostStatus();
    const { isMultiTable, onLobbyClose } = useChessBoardProps();
    const {
        game: {
            chat: { unlockedUserMessage },
        },
    } = useLocale();

    const opponent = {
        avatar: "string",
        color: ColorVariant.black,
        id: "string",
        name: "string",
        rating: 1,
        score: 1,
        winInGames: 1,
        isSystemAvatar: false,
        rank: { title: RankType.pawn3 },
        status: "string",
        pathCompressed: "string",
        ...opponentProps,
    };

    const AmIblockedFriend = achievementsStatStore.getMyCurrentOpponent(
        opponent?.id
    )?.isGamePlayBlocked;

    const unLockPlayerInfo = () => {
        infoService.sendInfo(unlockedUserMessage("Opponent Unlocked"));
    };

    const shouldUnlockOpponent = async (
        opponent: IPlayer | undefined | null
    ) => {
        if (AmIblockedFriend && opponent?.id) {
            await achievementsStatStore.setOpponentSettings({
                user: opponent?.id,
                isGamePlayBlocked: false,
            });
            !achievementsStatStore.isLoadedOpponent && unLockPlayerInfo();
        }
        gameStore.dialogs.requestRematch();
    };

    console.log("ResultData", resultData, "isWon:", isWon);

    if (!resultData) return null;

    // Temporary turn off
    // if (!setting) return null;
    // if (resultData.cause === GameFinishReason.STANDOFF) {
    //     return <DrawDialog />;
    // }

    if (isWon) {
        return (
            <WinDialog
                // Turn off temporary
                //   amount={(
                //     gameStore.gameState.bank *
                //     (1 - 2 * setting.rake)
                // ).toFixed(2)}
                //cause={resultData.cause}
                // message={winCauses[resultData.cause]}
                message={"You Win!"}
                cause={GameFinishReason.STANDOFF}
                amount={gameStore.currentGameNew?.bank.toString() || "2"}
                onApprove={() => {
                    shouldUnlockOpponent(opponent);
                }}
                onCancel={() => {
                    if (isMultiTable) {
                        onLobbyClose?.();
                        return;
                    }
                    gameStore.dialogs.rejectRematch();
                    gameStore.currentGame?.balanceType === UserBalanceType.play
                        ? history.push(paths.diceChessLobby)
                        : history.push(paths.lobbyFreePlay);
                }}
                isXlTablet={isTabletMobile}
                isMultiTable={isMultiTable}
            />
        );
    }

    return (
        <LostDialog
            amount={gameStore.gameState.userBet.toFixed(2)}
            // Turn off temporary
            // cause={resultData.cause}
            // message={lossCauses[resultData.cause]}
            message={"You Lose!"}
            cause={GameFinishReason.STANDOFF}
            onApprove={() => {
                shouldUnlockOpponent(opponent);
            }}
            onCancel={() => {
                if (isMultiTable) {
                    onLobbyClose?.();
                    return;
                }
                gameStore.dialogs.rejectRematch();
                gameStore.currentGame?.balanceType === UserBalanceType.play
                    ? history.push(paths.diceChessLobby)
                    : history.push(paths.lobbyFreePlay);
            }}
            isXlTablet={isTabletMobile}
            isMultiTable={isMultiTable}
        />
    );
};

export default observer(WinOrLostAlert);
