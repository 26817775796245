import React from 'react';
import { useLocale } from 'src/providers/LocaleProvider';

import ViewModeModal, { textType } from '../ViewModeModal';
import useStores from 'src/hooks/useStores';
import { observer } from 'mobx-react';
import { ColorVariant } from 'src/store/models';

const OfferedRematchModal = () => {
    const { gameStore } = useStores();

    const player = gameStore.gameState.getPlayerByColor(
        gameStore.rematch.b ? ColorVariant.black : ColorVariant.white
    );

    const {
        game: {
            dialogs: {
                rematch: {
                    offeringRematch: { isOfferingToOpponent },
                },
            },
        },
    } = useLocale();

    return (
        <ViewModeModal
            title={isOfferingToOpponent.compile(
                {
                    playerWhoOffers: player?.name ?? 'Anonymous',
                },
                '{{playerWhoOffers}} offered rematch'
            )}
            titleType={textType.gold}
            messsageType={textType.gray}
        />
    );
};

export default observer(OfferedRematchModal);
