import React, { useState, useEffect } from "react";
import cn from "classnames";

import useChangeTheme from "src/hooks/useChangeTheme";
import { ColorVariant } from "src/store/models";

import { useStyles } from "./styles";

const DIGITS = [8, 7, 6, 5, 4, 3, 2, 1];

interface IVNotationProps {
    orientation;
    borderWidth;
    isMultiTable?: boolean;
    cellSize?: number | undefined;
}

export const VNotation = ({
    orientation,
    borderWidth,
    isMultiTable,
    cellSize,
}: IVNotationProps) => {
    const [notation, setNotation] = useState<number[]>([]);
    const appearanceTheme = useChangeTheme();
    const classes = useStyles({ borderWidth, cellSize });

    useEffect(() => {
        setNotation(
            orientation[0] === ColorVariant.white
                ? DIGITS
                : DIGITS.slice().reverse()
        );
    }, [orientation]);

    return (
        <div
            className={cn(classes.wrapperVNotation, appearanceTheme, {
                isMultiTable,
            })}
        >
            {notation.map((n, index) => (
                <p
                    className={cn(classes.vNotationItem, appearanceTheme, {
                        isMultiTable,
                        odd: (index + 1) % 2 !== 0,
                    })}
                    key={n}
                >
                    {n}
                </p>
            ))}
        </div>
    );
};
