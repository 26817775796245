import React, { useState, useEffect } from "react";
import cn from "classnames";

import SelectFiguresAlert from "./select-figures-alert";
import { ColorVariant } from "src/store/models";
import useStores from "../../../../hooks/useStores";
import useStyles from "./styles";
import FadeInOutVertical from "src/components/Animated";
import useChangeTheme from "src/hooks/useChangeTheme";
import {
    MAX_L_TABLET,
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_XL_TABLET,
    MIN_XL_TABLET,
} from "../../constants";

const SelectFiguresAlertWrapper = ({ setNoanimation, isTabletMobile }) => {
    const { gameStore } = useStores();
    const classes = useStyles();

    const appearanceTheme = useChangeTheme();

    return (
        <FadeInOutVertical>
            <div
                className={cn(
                    isTabletMobile
                        ? classes.mobileFiguresAlertWrapper
                        : classes.figuresAlertWrapper,
                    appearanceTheme
                )}
            >
                <SelectFiguresAlert
                    setFigure={(figure) => {
                        const { from, to } =
                            gameStore.dialogs.selectFiguresDialog;
                        if (!from || !to) return;
                        const normalizeFigure =
                            gameStore.gameState.getMyColor() ===
                            ColorVariant.black
                                ? figure.toLowerCase()
                                : figure;
                        // Тут мы отправляем мув с цветом фигуры
                        gameStore.move(from + to + normalizeFigure);
                        gameStore.dialogs.closeSelectFiguresDialog();
                        setNoanimation(true);
                    }}
                />
            </div>
        </FadeInOutVertical>
    );
};

export default SelectFiguresAlertWrapper;
