import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
    useMemo,
} from "react";
import _get from "lodash/get";
import cn from "classnames";
import { observer } from "mobx-react";
import { useMediaQuery } from "@mui/material";

import useStores from "src/hooks/useStores";
import useWindowSize from "src/hooks/useWindowSize";
import useChangeTheme from "src/hooks/useChangeTheme";
import { LeftLayoutPropsContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import PiecesComponent from "src/pages/game-new2/layouts/components/center/piecesComponent";
import LeftLayout from "src/pages/game-new2/layouts/components/left";
import RightLayout from "src/pages/game-new2/layouts/components/right";

import {
    WatchingType,
    WatchersStatusType,
} from "src/components/WatchingPlayers/components/types/watchingTypes";
import { GameModeType, ColorVariant, ScreenType } from "src/store/models";

import Chessboard from "src/pages/game-new2/layouts/components/center/chessboard-custom";
import shortColor from "src/utils/shortColor";
import { useStyles } from "src/pages/game-new2/layouts/layoutTypes/viewChessboardWrapper/styles";
import { useChessBoardProps } from "src/pages/game-new2/hooks/useChessBoardProps";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MIN_CHANGE_SCREEN_WIDTH,
} from "src/pages/game-new2/constants";
import ViewModeModals from "src/pages/game-new2/components/modals/index";

interface IViewChessboardProps {
    players: any;
    playersOnline: any;
    rndPrefix: number;
    diceClickRnd: number;
    elemHeight: number;
    piecesSize: number;
    mobileTabletLayoutContainer: React.RefObject<HTMLDivElement>;
}

const ViewDesktopChessboardWrapper = (props: any) => {
    const {
        rndPrefix,
        diceClickRnd,
        mobileTabletLayoutContainer,
    }: IViewChessboardProps = props;
    const { gameStore, authStore, lobbyStore, viewHistoryGameStore } =
        useStores();

    const gameContainerWidth = props.gameContainerRef?.offsetWidth;
    const gameContainerHeight = props.gameContainerRef?.offsetHeight;

    const refBoardView = useRef<HTMLInputElement>(null);
    const desktopRightItemRef = useRef<HTMLDivElement>(null);
    const desktopRightItemWidth = desktopRightItemRef?.current?.offsetWidth;
    const desktopRightItemHeight = desktopRightItemRef?.current?.offsetHeight;

    const resign = gameStore?.gameState?.gameResultData?.cause;
    const winner = gameStore?.gameState?.gameResultData?.winner;
    const watchers = gameStore.guests;
    const showWatchersBlock = gameStore.getShowWatchersBlock;
    const gameType = gameStore.currentGame?.type;
    const isMatchGame = gameStore.currentGame?.settings?.rounds! > 1;
    const isTournamentGame = gameType === GameModeType.TOURNAMENT;
    const diceWrapperRef = useRef<HTMLDivElement>(null);
    const { chessBoardWidth, chessBoardRef, isMultiTable } =
        useChessBoardProps();
    const windowSize = useWindowSize();
    const [elemHeight, setHeight] = useState<any>();
    // const [piecesSize, setPiecesSize] = useState<any>();
    const [elemHeightType, setHeightType] = useState<string | undefined>();
    const [uniqWatchers, setUniqWatchers] = useState<WatchingType[]>(watchers);
    const [isOpen, setOpen] = useState<boolean | WatchersStatusType>(false);
    const actualWidth = window.innerWidth;
    const appearanceTheme = useChangeTheme();
    const changeScreenStyles = useMediaQuery(
        `(max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`
    );

    const classes = useStyles({ elemHeight, resign });

    const { onChatToggler, chatMobileType, chatDesktopType, chatTogglerState } =
        useLeftLayoutContext();
    const { width, height } = useWindowSize();

    const isActivePlayer = (color, clock) =>
        shortColor(color) === _get(clock, "color", "unknown");

    const viewMode = gameStore.isViewMode();
    const activeClock = gameStore.gameState.activeClock;
    const yourColor = viewMode
        ? ColorVariant.black
        : gameStore.gameState.getOpponentColor();
    const myColor = viewMode
        ? ColorVariant.white
        : gameStore.gameState.getMyColor();
    // const killedPieces = gameStore.gameState.killedPieces;
    const killedPieces = gameStore?.gameState?.killedPieces;

    const fen = gameStore.gameState.fen;

    const yourTime = gameStore.gameState.playerTimeLeft(yourColor);
    const myTime = gameStore.gameState.playerTimeLeft(myColor);
    const meOrWhitePlayer = gameStore.gameState.getPlayerByColor(
        ColorVariant.white
    );
    const opponent = gameStore.gameState.getPlayerByColor(ColorVariant.black);

    const setOpenUseCallback = useCallback(
        (properties) => setOpen(properties),
        []
    );

    useEffect(() => {
        if (Array.isArray(watchers) && watchers.length > 0) {
            const uniqWatchingObject = {
                //@ts-ignore
                ...[...new Set(watchers.map((guest) => guest.id))],
            };
            const uniqWatchingArray = watchers.filter(
                (guest, index) => guest.id === uniqWatchingObject[index]
            );
            setUniqWatchers(uniqWatchingArray);
        }
        if (Array.isArray(watchers) && watchers.length === 0) {
            setUniqWatchers([]);
        }
    }, [watchers.length]);

    useEffect(() => {
        if (isOpen === WatchersStatusType.hide) {
            setTimeout(() => {
                setOpen(false);
            }, 500);
        }
    }, [isOpen]);

    useEffect(() => {
        if (actualWidth < MIN_CHANGE_SCREEN_WIDTH && elemHeight) {
            if (gameType === GameModeType.REGULAR)
                return setHeightType(`${elemHeight + 20}px`);
            if (gameType === GameModeType.TOURNAMENT)
                return setHeightType(`${elemHeight - 17}px`);
        }
        return setHeightType("inherit");
    }, [elemHeight, actualWidth]);

    const getSize = <
        T extends number | undefined,
        U extends number | undefined,
        P extends number
    >(
        gameContainerWidth: T,
        gameContainerHeight: U,
        piecesWrapper: P
    ): number | undefined => {
        if (gameContainerWidth && gameContainerHeight) {
            let maxSize = gameContainerWidth / 2 - piecesWrapper * 2;
            let calcSize =
                Math.min(gameContainerWidth, gameContainerHeight) -
                piecesWrapper * 2;
            if (maxSize >= calcSize) return calcSize;
            else return maxSize;
        }
        return undefined;
    };

    const calcDiceSize = <
        W extends number | undefined,
        H extends number | undefined
    >(
        diceWrapperWidthParams: W,
        diceWrapperHeightParams: H
    ): number => {
        if (diceWrapperWidthParams && diceWrapperHeightParams) {
            //Max size - 20% of Height
            let diceHeight = diceWrapperHeightParams * 0.2;
            let calcSize = Math.min(diceWrapperWidthParams / 3, diceHeight);
            return calcSize * 0.7;
        }
        return 65;
    };

    const minSize = useMemo(
        () => getSize(gameContainerWidth, gameContainerHeight, 60),
        [gameContainerWidth, gameContainerHeight, width, height]
    );

    useEffect(() => {
        let actualSize = calcDiceSize(
            desktopRightItemWidth,
            desktopRightItemHeight
        );
        gameStore.setPiecesSize(actualSize, ScreenType.desktop);
    }, [desktopRightItemWidth, desktopRightItemHeight]);

    console.log("minSize", gameContainerWidth, gameContainerHeight);

    if (!meOrWhitePlayer || !opponent) return null;

    return (
        <>
            <div
                className={cn(classes.desktopLayoutContainer, { isMultiTable })}
                style={{ gridTemplateColumns: `1fr ${minSize}px 1fr` }}
            >
                <>
                    {!isMultiTable && !props.tabletRatio && (
                        <div className={classes.desktopLeftBox}>
                            <LeftLayoutPropsContext.Provider
                                value={{
                                    chatDesktopType,
                                }}
                            >
                                <div
                                    className={cn(
                                        classes.desktopLeftRightItem,
                                        "left"
                                    )}
                                >
                                    <LeftLayout />
                                </div>
                            </LeftLayoutPropsContext.Provider>
                        </div>
                    )}
                    <div
                        className={cn(classes.desktopCenterBox, {
                            isMultiTable,
                        })}
                    >
                        <div
                            className={cn(
                                classes.desktopCenterPiecesWrapper,
                                "top",
                                {
                                    isMultiTable,
                                }
                            )}
                        >
                            <PiecesComponent
                                killedPieces={killedPieces[myColor]}
                                color={yourColor}
                                desktopWidth={minSize}
                            />
                        </div>
                        <div
                            className={cn(classes.desktopCenterSquaredWrapper, {
                                isMultiTable,
                            })}
                        >
                            {/* <ChessboardWrapper {...props} /> */}
                            {changeScreenStyles && viewMode && (
                                <div className={classes.viewModeModalsWrapper}>
                                    <ViewModeModals />
                                </div>
                            )}
                            <Chessboard
                                marginTop={0}
                                marginBottom={0}
                                border
                                fen={fen.split(" ")[0]}
                                myColor={myColor}
                                viewMode={viewMode}
                                isMultiTable={isMultiTable}
                                isMultitableСhessBoardHeight={undefined}
                            />
                        </div>
                        <div
                            className={cn(
                                classes.desktopCenterPiecesWrapper,
                                "bottom",
                                {
                                    isMultiTable,
                                }
                            )}
                        >
                            <PiecesComponent
                                killedPieces={killedPieces[yourColor]}
                                color={myColor}
                                desktopWidth={minSize}
                            />
                        </div>
                    </div>
                    {!props.tabletRatio && (
                        <div className={cn(classes.desktopRightBox, {})}>
                            <div
                                ref={desktopRightItemRef}
                                className={cn(
                                    classes.desktopLeftRightItem,
                                    "right"
                                )}
                            >
                                <RightLayout {...props} />
                            </div>
                        </div>
                    )}
                </>
            </div>
        </>
    );
};

export default observer(ViewDesktopChessboardWrapper);
