import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { usePlayer } from "src/sounds/use-player";

import { useChessBoardProps } from "../../hooks/useChessBoardProps";
import useDoublingStageStatus from "../../hooks/useDoublingStageStatus";
import X2Dialog from "../x2Alert";

import OpponentAcceptedDoubling from "./OpponentAcceptedDoubling";

const X2Dialogs = ({
    showOpponentAcceptedDoubling = true,
    randomValue,
    isTabletMobile,
}: {
    showOpponentAcceptedDoubling?: boolean;
    randomValue?: number | undefined;
    isTabletMobile?: boolean | undefined;
}) => {
    const { showAcceptOrRejectDialog, showOpponentAcceptedDoublingDialog } =
        useDoublingStageStatus();
    const { isMultiTable } = useChessBoardProps();
    const player = usePlayer();

    useEffect(() => {
        showAcceptOrRejectDialog && player("bidIncreaseSoundPlay");
    }, [showAcceptOrRejectDialog]);

    if (showAcceptOrRejectDialog) {
        return (
            <X2Dialog isXlTablet={isTabletMobile} isMultiTable={isMultiTable} />
        );
    } else if (
        showOpponentAcceptedDoublingDialog &&
        showOpponentAcceptedDoubling
    ) {
        return (
            <OpponentAcceptedDoubling
                randomValue={randomValue}
                isXlTablet={isTabletMobile}
                isMultiTable={isMultiTable}
            />
        );
    }

    return null;
};

export default observer(X2Dialogs);
