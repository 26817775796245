import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';
import { IconProps } from './types';

function TelegramIconGenerator({ width, height, fillColor }: IconProps) {
    return (
        <SVGUniqueID>
            <svg
                fill={fillColor}
                width={width}
                height={height}
                viewBox='-0.48 -0.48 24.96 24.96'
                xmlns='http://www.w3.org/2000/svg'
                stroke='#000000'
                stroke-width='0.12000000000000002'
            >
                <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
                <g
                    id='SVGRepo_tracerCarrier'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke='#CCCCCC'
                    stroke-width='0.144'
                ></g>
                <g id='SVGRepo_iconCarrier'>
                    <path d='m12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12c0-6.627-5.373-12-12-12zm5.894 8.221-1.97 9.28c-.145.658-.537.818-1.084.508l-3-2.21-1.446 1.394c-.14.18-.357.295-.6.295-.002 0-.003 0-.005 0l.213-3.054 5.56-5.022c.24-.213-.054-.334-.373-.121l-6.869 4.326-2.96-.924c-.64-.203-.658-.64.135-.954l11.566-4.458c.538-.196 1.006.128.832.941z'></path>
                </g>
            </svg>
        </SVGUniqueID>
    );
}

export default TelegramIconGenerator;
