import React, { useState, memo, useCallback, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import Badge from "@mui/material/Badge";

import { appColors, backgroundColors } from "src/consts/app-config";
import useStores from "src/hooks/useStores";
import useChangeTheme from "src/hooks/useChangeTheme";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import { EyeHidden } from "../icons/eye-hidden.icon";
import { Eye } from "../icons/eye.icon";
import { useStyles } from "../styles";
import { IBadgeDeskMobile } from "../types/IChat";

const BadgeDesktop = observer(
    ({ currentTab, showHideOpponent, isChatBlocked }: IBadgeDeskMobile) => {
        const appearanceTheme = useChangeTheme();
        const { gameStore, authStore, tournamentsStore } = useStores();

        const { chatDesktopType } = useLeftLayoutContext();

        const shouldHide = tournamentsStore.isHideChat;
        const chessboardSize = gameStore?.chessboardSize;
        const chatMessagesCount = gameStore?.newChatMessage?.length;
        const stickers = authStore.stickers;

        const classes = useStyles({
            shouldHide,
            showHideOpponent,
            appearanceTheme,
            chessboardSize,
            stickers,
        });

        return (
            <div
                className={cn(
                    classes.boxChatShadow,
                    appearanceTheme,
                    chatDesktopType
                )}
            >
                <button
                    className={classes.eyeButton}
                    onClick={() =>
                        tournamentsStore.regularGameChatToggler(!shouldHide)
                    }
                >
                    {/* <Badge
                        className={cn(classes.badgeNotifications, "badgeEye", {
                            emptyNote:
                                chatMessagesCount === 0 ||
                                isChatBlocked ||
                                !shouldHide,
                            moreThan99: chatMessagesCount > 99,
                        })}
                        badgeContent={false}
                        color="primary"
                    >
                        {shouldHide ? <EyeHidden /> : <Eye />}
                    </Badge> */}
                </button>
            </div>
        );
    }
);

export default memo(BadgeDesktop);
