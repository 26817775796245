import React from 'react';
import GiveupIcon from './icons/giveup';
import { useLocale } from 'src/providers/LocaleProvider';
import { observer } from 'mobx-react';
import useStores from '../../../../hooks/useStores';
import GeneralGameDialogNew from '../common/generalDialogNew';
import { useChessBoardProps } from 'src/pages/game-new/hooks/useChessBoardProps';

const GiveUpDialog = () => {
    const {
        game: {
            dialogs: {
                giveUp: { title, approve, cancel, message },
            },
        },
    } = useLocale();
    const { gameStore } = useStores();
    const { isMultiTable } = useChessBoardProps();
    const onApprove = () => {
        gameStore.dialogs.confirmGiveUp();
    };

    const onCancel = () => {
        gameStore.dialogs.cancelGiveUp();
    };
    const player = gameStore.gameState.getPlayerByColor(
        gameStore.gameState.getMyColor()
    );
    if (!player) return null;

    return (
        <GeneralGameDialogNew
            avatar={player?.avatar}
            noButtons={false}
            onApprove={onApprove}
            onCancel={onCancel}
            title={title('You are about to resign')}
            message={message('You will lose your bet')}
            approveText={approve('Ok')}
            cancelText={cancel('Cancel')}
            isMultiTable={isMultiTable}
        />
    );
};

export default observer(GiveUpDialog);
