import React, { memo, useState, useLayoutEffect } from "react";
import cn from "classnames";
import PulseLoader from "react-spinners/PulseLoader";

import avatarPlaceholder from "src/assets/images/defaultPlaceholder.png";
import LazyImages from "src/components/lazyImages";
import { appColors, backgroundColors } from "src/consts/app-config";
import { IStickerPackList } from "src/store/models";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import { fileHelper } from "src/shared/helpers/FileHelper";

import { useStyles } from "./styles";

export interface IStickerContainer {
    onSendSticker?: (id: string, packName: string) => void;
    stickerPack?: IStickerPackList | undefined;
}

const StickerContainer = ({
    stickerPack,
    onSendSticker,
}: IStickerContainer) => {
    const classes = useStyles();
    const { chatMobileType } = useLeftLayoutContext();
    const [skeletonState, setSkeletons] = useState<any[] | null>(null);
    const [isLoaded, setLoaded] = useState<boolean>(false);
    const skeletonArray = () => {
        const stickersDetails: { id: number }[] = Array.from(
            { length: 14 },
            (_, i) => ({ id: i })
        );
        return stickersDetails;
    };

    useLayoutEffect(() => {
        if (skeletonState) return;
        const dataSkeleton = skeletonArray();
        setSkeletons(dataSkeleton);
    }, []);

    return (
        <React.Fragment key={`StickerContainer_mainFragment`}>
            {stickerPack && stickerPack.stickerPackName && (
                <React.Fragment
                    key={`${stickerPack.stickerPackName}_maindivFragment`}
                >
                    <div
                        key={`${stickerPack.stickerPackName}_mainDiv`}
                        className={cn(classes.stickersListBox, chatMobileType)}
                    >
                        <p className={cn(classes.stickersTitle)}>
                            {stickerPack.stickerPackName}
                        </p>
                        <div
                            className={cn(classes.stickersList)}
                            key={`${stickerPack.stickerPackName}_stickersList`}
                        >
                            {stickerPack?.stickersDetails.map((sticker, i) => {
                                return (
                                    <>
                                        <LazyImages
                                            key={`${sticker.filename}_${i}`}
                                            className={cn(classes.stickersImg, {
                                                isLoaded: isLoaded,
                                            })}
                                            placeholderImg={avatarPlaceholder}
                                            src={fileHelper.getFileURL(
                                                sticker.filename
                                            )}
                                            errorImg={avatarPlaceholder}
                                            setLoaded={(status: boolean) =>
                                                setLoaded(status)
                                            }
                                            onClick={() =>
                                                onSendSticker?.(
                                                    sticker.id,
                                                    stickerPack.stickerPackName
                                                )
                                            }
                                        />
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </React.Fragment>
            )}
            {!stickerPack && skeletonState && (
                <>
                    <div className={cn(classes.stickersTitle, "pulseLoader")}>
                        <PulseLoader
                            key={"pulse"}
                            color={appColors.semiwhite}
                            size={5}
                            margin="3px"
                        />
                    </div>
                    <div className={cn(classes.stickersList)}>
                        {skeletonState.map((item) => {
                            return (
                                <span
                                    key={item?.id}
                                    className={cn(classes.stickersSkeleton, {
                                        loadingSkeleton: !stickerPack,
                                    })}
                                />
                            );
                        })}
                    </div>
                </>
            )}
        </React.Fragment>
    );
};

export default memo(StickerContainer);
