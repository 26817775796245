import { Box, Button } from "@mui/material";
import cn from "classnames";
import React, { useEffect, useState, useMemo, memo } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";

import { EColor } from "src/components/types";
import paths from "src/consts/paths";
import useChangeTheme from "src/hooks/useChangeTheme";
import useStores from "src/hooks/useStores";
import { useLocale } from "src/providers/LocaleProvider";
import { GameType, UserBalanceType } from "src/store/models";

import SocialLinks from "../SocialLinksBox";
import { Spinner } from "../spinner";

import { useStyles } from "./styles";

interface ISideMenu {
    sideMenuIsOpen: boolean;
    setReloadStatus: (status: string) => void;
    reloadStatus: string;
    isGamePage: any;
}

export enum ESocial {
    social = "social",
    support = "support",
}

const SideMenu = observer(
    ({
        sideMenuIsOpen,
        setReloadStatus,
        reloadStatus,
        isGamePage,
    }: ISideMenu) => {
        const styles = useStyles();
        const appearanceTheme = useChangeTheme();
        const navigate = useHistory();
        const { authStore, lobbyStore, generalStore, tournamentsStore } =
            useStores();
        const [isLoading, setLoading] = useState<boolean>(false);

        const activeLobbyGames = lobbyStore.getActiveGames();
        const currentLocation = useLocation();
        const user = authStore.currentUser;

        const {
            header: {
                cashBattle,
                profile,
                gamesHistory,
                logout,
                tournaments,
                lobbyFreePlay,
                altente,
                liveDealer,
                mainLanding,
                cashbox: { title },
                referral,
            },
        } = useLocale();

        const navList: Record<string, any> = [
            generalStore.isDicechessMode
                ? {
                      to: paths.lobby,
                      label: mainLanding("All games") as string,
                  }
                : null,
        ];

        if (generalStore.gameType === GameType.dicechess) {
            navList.push(
                {
                    to: paths.diceChessLobby,
                    label: cashBattle("Cash Battle") as string,
                },
                {
                    to: paths.tournaments,
                    label: tournaments("Tournaments") as string,
                }
                // {
                //     to: paths.lobbyFreePlay,
                //     label: lobbyFreePlay("Free Play") as string,
                // }
                // {
                //     to: paths.gamesRules,
                //     label: rules('Rules'),
                // },
                // {
                //     to: paths.faq,
                //     label: support('Support'),
                // },
            );
            // TODO: refactor
            if (authStore.isAuthorized) {
                navList.push({
                    to: paths.gamesHistory,
                    label: gamesHistory("Games history"),
                });
            }
        }

        if (generalStore.gameType === GameType.slots) {
            navList.push({
                to: paths.algaltente,
                label: altente("All Slots"),
            });
        }

        if (generalStore.gameType === GameType.liveDealer) {
            navList.push({
                to: paths.liveDealer,
                label: liveDealer("All Dealers"),
            });
        }

        if (authStore.isAuthorized && generalStore.isDicechessMode) {
            navList.push(
                {
                    to: paths.profile,
                    label: profile("Profile") as string,
                },
                {
                    to: paths.cashbox,
                    label: title("Cashbox") as string,
                }
            );
        }

        if (user?.hasRefProgram)
            navList.push({
                to: paths.profileForRef,
                label: referral("Referral"),
            });

        const logOutMobile = async () => {
            setLoading(true);
            await authStore.logout();
            if (!authStore.isAuthorized) {
                navigate.push(paths.login);
                tournamentsStore.resetTournaments();
                //reset mobile create game block
                lobbyStore.showmobileGameOrFriends();
            }
            setLoading(false);
            generalStore.closeSideMenu();
            authStore.changeLanguage(null);
        };

        useEffect(() => {
            if (sideMenuIsOpen && reloadStatus === EColor.reload) {
                return setReloadStatus(EColor.open);
            }
            if (sideMenuIsOpen && reloadStatus === EColor.close) {
                return setReloadStatus(EColor.open);
            }
            if (!sideMenuIsOpen && reloadStatus !== EColor.reload) {
                return setReloadStatus(EColor.close);
            }
            if (!sideMenuIsOpen && reloadStatus === EColor.reload) {
                return setReloadStatus(EColor.reload);
            }
        }, [sideMenuIsOpen]);

        const showReturnToTournament = tournamentsStore.hasActiveGames;

        const isCashBattle = useMemo(() => {
            if (activeLobbyGames) {
                return activeLobbyGames.filter(
                    (game) => game.balanceType === UserBalanceType.play
                );
            }
        }, [activeLobbyGames]);

        const isFreePlay = useMemo(() => {
            if (activeLobbyGames) {
                return activeLobbyGames.filter(
                    (game) => game.balanceType === UserBalanceType.coins
                );
            }
        }, [activeLobbyGames]);

        const isCashBattleDot = useMemo(() => {
            if (!isCashBattle) return false;
            return (
                activeLobbyGames &&
                activeLobbyGames?.length > 0 &&
                !!isCashBattle.length &&
                !currentLocation.pathname.includes(isCashBattle[0].id) &&
                !currentLocation.pathname.includes(paths.diceChessLobby)
            );
        }, [activeLobbyGames, isCashBattle]);

        const isFreePlayDot = useMemo(() => {
            if (!isFreePlay) return false;
            return (
                activeLobbyGames &&
                activeLobbyGames?.length > 0 &&
                !!isFreePlay.length &&
                !currentLocation.pathname.includes(isFreePlay[0].id) &&
                !currentLocation.pathname.includes(paths.lobbyFreePlay)
            );
        }, [activeLobbyGames, isFreePlay]);

        const isTournamentDot = useMemo(() => {
            const isNotInGamePage = tournamentsStore.myGamesList.every(
                (g) => !currentLocation.pathname.includes(g?.id)
            );
            return (
                showReturnToTournament &&
                !isNotInGamePage &&
                !currentLocation.pathname.includes(paths.tournaments)
            );
        }, [showReturnToTournament, tournamentsStore.myGamesList]);

        return (
            <Box
                style={{
                    height: `calc(100dvh - 48px)`,
                }}
                className={cn(styles.wrapperMobileBurger, appearanceTheme, {
                    isGamePage: isGamePage,
                    watchingListShow: reloadStatus === EColor.open,
                    watchingListHide: reloadStatus === EColor.close,
                })}
            >
                <ul className={styles.navList}>
                    {navList
                        .filter((t) => t != null)
                        .map((elem) => {
                            if (!elem) return null;

                            return (
                                <li
                                    className={styles.navListItem}
                                    key={elem?.label}
                                >
                                    <NavLink
                                        to={elem.to}
                                        className={styles.navListLink}
                                        onClick={() => {
                                            generalStore.closeSideMenu();
                                        }}
                                    >
                                        {elem.label}
                                        {elem.to === paths.diceChessLobby &&
                                            isCashBattleDot && (
                                                <div
                                                    className={cn(
                                                        styles.gameInfoDot,
                                                        {}
                                                    )}
                                                ></div>
                                            )}
                                        {elem.to === paths.tournaments &&
                                            isTournamentDot && (
                                                <div
                                                    className={cn(
                                                        styles.gameInfoDot,
                                                        {}
                                                    )}
                                                ></div>
                                            )}
                                        {elem.to === paths.lobbyFreePlay &&
                                            isFreePlayDot && (
                                                <div
                                                    className={cn(
                                                        styles.gameInfoDot,
                                                        {}
                                                    )}
                                                ></div>
                                            )}
                                    </NavLink>
                                </li>
                            );
                        })}
                    {authStore.isAuthorized && generalStore.isDicechessMode && (
                        <li
                            className={styles.navListItem}
                            key={"logoutBurgerTab"}
                        >
                            {isLoading ? (
                                <Button className={styles.navListLink}>
                                    <Spinner size={30} />
                                </Button>
                            ) : (
                                <div
                                    className={styles.navListLink}
                                    onClick={() => {
                                        logOutMobile();
                                    }}
                                >
                                    {logout("Logout")}
                                </div>
                            )}
                        </li>
                    )}
                </ul>

                <div className={styles.socLinksAndInfo}>
                    <SocialLinks linkTypes={ESocial.social} />
                    <SocialLinks linkTypes={ESocial.support} />
                </div>
            </Box>
        );
    }
);

export default memo(SideMenu);
