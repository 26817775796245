import React, { useMemo, useEffect, memo, useState } from "react";
import { useDrag, useDrop, DragPreviewImage } from "react-dnd";
import cn from "classnames";
import { observer } from "mobx-react";

import { pieceName } from "src/consts/game-params";
import useStores from "src/hooks/useStores";
import { ColorVariant } from "src/store/models";

import { useStyles } from "./styles";

const DragPieceComponent = (props) => {
    const {
        piece,
        pieces,
        square,
        isAllowDrag,
        getTargetHighLight,
        orientation,
    } = props;
    const { gameStore } = useStores();

    //@ts-ignore
    const styles = useStyles();

    const type = useMemo(
        () =>
            (piece.toUpperCase() === piece
                ? `w${piece}`
                : `b${piece}`
            ).toLowerCase(),
        [piece]
    );

    const [{ isDragging }, drag, preview] = useDrag({
        canDrag: isAllowDrag,
        type,
        item: () => {
            gameStore?.gameState.setCurrentSquare(square);
            return {
                from: square,
            };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            opacity: monitor.isDragging() ? 0.2 : 1,
        }),
    });

    const [saveType, setType] = useState<string>(`${square}_${piece}_${type}`);
    const [runAnimation, setAnimation] = useState<boolean | string>(false);

    const chooseKilledPiecesAnimation = (
        data: string,
        piecesList: {
            [key: string]: string;
        }
    ) => {
        const killedPiece = data.slice(-2).toLocaleLowerCase().slice(-1);
        return piecesList[killedPiece];
    };

    useEffect(() => {
        if (saveType === `${square}_${piece}_${type}`) return;
        if (saveType !== `${square}_${piece}_${type}`) {
            const targetAnimation = chooseKilledPiecesAnimation(
                saveType,
                pieceName
            );
            setAnimation(targetAnimation);
            setTimeout(() => {
                setType(`${square}_${piece}_${type}`);
                setAnimation(false);
            }, 800);
        }
    }, [square, piece, type]);

    const isMyPiece = useMemo(() => {
        if (orientation === ColorVariant.white) {
            return piece.toUpperCase() === piece ? true : false;
        } else if (orientation === ColorVariant.black) {
            return piece.toLowerCase() === piece ? true : false;
        }
        return false;
    }, [piece, orientation]);

    if (isDragging) return null;
    //У нас есть начальное положение фигуры - square и понимание, цвета фигуры - square
    return (
        <>
            {/* <DragPreviewImage connect={preview} src={pieces[type]} /> */}
            <div
                ref={isMyPiece ? drag : null}
                key={`${square}_${piece}_${type}`}
                className={cn(styles.killedAnimation, runAnimation)}
                style={{
                    display: isDragging ? "none" : "block",
                }}
            >
                {/* {runAnimation && <SmokeContainer runAnimation={runAnimation} />} */}
                <span
                    data-type={"empty"}
                    className={cn(styles.killerPieceAnimateSpan, {
                        // runAnimation: runAnimation,
                    })}
                >
                    {pieces[type]}
                </span>
            </div>
        </>
    );
};

export default DragPieceComponent;
