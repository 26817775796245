import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import _get from "lodash/get";
import Box from "@mui/material/Box";
import { useSpring, animated } from "react-spring";

import useDoublingStageStatus from "src/pages/game-new/hooks/useDoublingStageStatus";
import CoinAnimationContainer from "src/components/controls/bank/coinAnimation/coinAnimationContainer";
import GameScore from "src/components/gameScore";
import Bank from "src/components/controls/bank";
import DicesMainWrapper from "src/components/DiceThreeJS";
import {
    ChessboardType,
    ColorVariant,
    GameModeType,
    GameModeTypeNew,
    GameStatus,
    ScreenType,
} from "src/store/models";

import { useWinOrLostStatus } from "src/pages/game-new2/hooks/useWinOrLostStatus";
import { MAX_XL_TABLET } from "src/pages/game-new2/constants";

import useStores from "src/hooks/useStores";
import { useChessBoardProps } from "src/pages/game-new2/hooks/useChessBoardProps";

import DicesWrapper from "src/pages/game-new2/components/dicesWrapper";
import X2Button from "src/pages/game-new2/components/x2ButtonAndRollDice/index";
import GiveUpDialog from "src/pages/game-new2/alerts/giveUpDialog";
import RematchDialog from "src/pages/game-new2/alerts/rematchAlerts";
import DrawDialog from "src/pages/game-new2/alerts/drawDialog/";
import PlayerThinking from "src/pages/game-new2/components/playerThinking";
import WinOrLostAlert from "src/pages/game-new2/alerts/winOrLostAlerts";
import X2Dialogs from "src/pages/game-new2/alerts/X2Dialogs";
import RakeBackModal from "src/pages/game-new2/components/modals/RakeBackModal";
import StartMatch from "src/pages/game-new2/components/startMatch";
import SelectFiguresAlertWrapper from "src/pages/game-new2/alerts/select-figure-alert/index";

import { useStyles } from "./styles";
import PlayerInfoRedesign from "src/components/PlayerInfoRedesign";

import WatchingList from "src/components/WatchingPlayers/components/WatchingList";
import {
    WatchersStatusType,
    WatchingType,
} from "src/components/WatchingPlayers/components/types/watchingTypes";

import useChangeTheme from "src/hooks/useChangeTheme";
import useCheckFullScreen from "src/hooks/useCheckFullScreen";
import useOrientation from "src/hooks/useOrientation";

import useWindowSize from "src/hooks/useWindowSize";

import { usePlayer } from "src/sounds/use-player";
import { UserBalanceType, PlayerInfoExistPosition } from "src/store/models";
import shortColor from "src/utils/shortColor";

import {
    MAX_CHANGE_SCREEN_WIDTH,
    MIN_L_TABLET,
    MIN_XL_TABLET,
} from "src/pages/game-new2/constants";

import { toNamespacedPath } from "path";

export interface RightLayoutProps {
    rndPrefix?;
    diceClickRnd?;
    setDiceClickRnd?;
}

const RightLayout = (props: RightLayoutProps) => {
    const {
        gameStore,
        authStore: { currentUser },
    } = useStores();
    const { isMultiTable, isTwoGameTable } = useChessBoardProps();
    const style = useStyles();
    const location = useLocation();
    const { showOpponentAcceptedDoublingDialog } = useDoublingStageStatus();
    const { isWon } = useWinOrLostStatus();
    const [randomValue, setRandomValue] = useState<number>(1);

    // const userRakeBack = currentUser?.balance?.rakeBack || 123;
    // const userRP = currentUser?.rating.elo || 123;
    //====
    const userRakeBack = 123;
    const userRP = 123;
    const currentPlayer = gameStore.gameState.getCurrentPlayer();
    const doubleThinking = gameStore.dialogs.oponentThinkingDialog.isOpen;
    const myMove = gameStore.isMyMove;
    // const gameType = gameStore.currentGame?.type;
    const gameType = gameStore.currentGameNew?.type;
    const winner = gameStore?.gameState?.gameResultData?.winner;

    const viewMode = useMemo(() => {
        return gameStore.viewGameMode;
    }, [gameStore.viewGameMode]);

    // Сначала получаем цвет
    // const myColor = useMemo(() => {
    //     return viewMode ? ColorVariant.white : gameStore.gameState.getMyColor();
    // }, [viewMode]);
    //======
    const myColor = useMemo(() => {
        return gameStore.getMyColorNew();
    }, [
        viewMode,
        location.pathname,
        gameStore.gameId,
        gameStore?.currentGameNew?._id,
    ]);

    const opponentColor = useMemo(() => {
        return gameStore.getOpponentColorNew();
    }, [
        viewMode,
        location.pathname,
        gameStore.gameId,
        gameStore?.currentGameNew?._id,
    ]);

    // Затем получаем Наши данные по ранее полученному цвету
    // const meOrWhitePlayer = viewMode
    //     ? gameStore.gameState.getPlayerByColor(ColorVariant.white)
    //     : gameStore.gameState.getPlayerByColor(myColor);

    // const meOrWhitePlayer = useMemo(() => {
    //     return viewMode
    //         ? gameStore.gameState.getPlayerByColor(ColorVariant.white)
    //         : gameStore.gameState.getPlayerByColor(myColor);
    // }, [viewMode]);
    //=======
    const meOrWhitePlayer = gameStore?.currentGameNew?.players.find(
        (item) => item._id === currentUser?._id
    );

    const meOrWhitePlayerIndex: number =
        gameStore?.currentGameNew?.players.findIndex(
            (item) => item._id === currentUser?._id
        ) || 0;

    // const oponentColor = useMemo(() => {
    //     return viewMode
    //         ? ColorVariant.black
    //         : gameStore.gameState.getOpponentColor();
    // }, [viewMode]);
    //======
    const oponentColor = ColorVariant.black;

    // const opponent = useMemo(() => {
    //     return viewMode
    //         ? gameStore.gameState.getPlayerByColor(ColorVariant.black)
    //         : gameStore.gameState.getPlayerByColor(oponentColor);
    // }, [viewMode]);
    //====s
    const opponent = gameStore?.currentGameNew?.players.find(
        (item) => item._id !== currentUser?._id
    );

    useEffect(() => {
        if (doubleThinking || showOpponentAcceptedDoublingDialog) {
            let generateRandomNumber = (Math.random() * 10) | 0;
            if (randomValue === generateRandomNumber) {
                setRandomValue(9 - generateRandomNumber);
            } else {
                setRandomValue(generateRandomNumber);
            }
        }
    }, [doubleThinking, showOpponentAcceptedDoublingDialog]);

    const isMultiRoundGame =
        gameStore.currentGame?.settings.rounds! > 1 ||
        gameType === GameModeTypeNew.REGULAR;

    const { authStore, lobbyStore, tournamentsStore, achievementsStatStore } =
        useStores();

    const { chessBoardWidth, chessBoardRef, numberOfGames } =
        useChessBoardProps();

    const soundPlayer = usePlayer();

    const user = authStore.currentUser;
    const appearanceTheme = useChangeTheme();

    const watchers = gameStore.guests;
    const resign = gameStore?.gameState?.gameResultData?.cause;

    //
    const userCoinBalance = authStore.userCoinBalance;
    const userFreeCoinBalance = authStore.userFreeCoinBalance;
    const balanceType = gameStore.gameBalanceType;

    const activeClock = gameStore.gameState.activeClock;

    //Тут And Now Try To create depend wich contain some comditions^ if viewMode changed, get some funtions

    const yourColor = useMemo(() => {
        return viewMode
            ? ColorVariant.black
            : gameStore.gameState.getOpponentColor();
    }, [viewMode, location.pathname, gameStore.gameId]);

    const yourTime = useMemo(() => {
        return (
            gameStore?.currentGameNew?.timeLeft[1 - meOrWhitePlayerIndex] || 0
        );
        // return viewMode
        //     ? gameStore.gameState.playerTimeLeft(yourColor)
        //     : gameStore.gameState.opponentTimeLeft;
    }, [
        viewMode,
        yourColor,
        gameStore?.currentGameNew?.timeLeft,
        gameStore.gameId,
        activeClock,
    ]);

    const myTime = useMemo(() => {
        return gameStore?.currentGameNew?.timeLeft[meOrWhitePlayerIndex] || 0;
    }, [
        viewMode,
        myColor,
        gameStore.gameId,
        gameStore?.currentGameNew?.timeLeft,
        activeClock,
    ]);

    const [elemHeight, setHeight] = useState<number | undefined>();

    const [isOpenWatchers, setOpenWatchers] = useState<
        boolean | WatchersStatusType
    >(false);
    const [chessBoardHeight, setChessBoardHeight] = useState<
        number | undefined
    >(undefined);
    const [uniqWatchers, setUniqWatchers] = useState<WatchingType[]>(watchers);
    const classes = useStyles({ elemHeight });
    const [isOpen, setIsOpen] = useState(true);
    const springProps = useSpring({
        transform: isOpen ? "translateY(-100%)" : "translateY(10%)",
        config: { tension: 150, friction: 20 },
    });

    const getAllMyParticipatingGames = lobbyStore.getParticipatingGames();

    const isActivePlayer = (color, clock) =>
        shortColor(color) === _get(clock, "color", "unknown");

    const getCurrentCashbox = useMemo(() => {
        if (balanceType === UserBalanceType.play) {
            return userCoinBalance;
        } else {
            return userFreeCoinBalance;
        }
    }, [balanceType]);

    // const killedPieces = useMemo(() => {
    //     return gameStore.gameState.killedPieces;
    // }, [gameStore.gameState?.plainFen]);

    const killedPieces = useMemo(() => {
        return gameStore.gameState.killedPiecesNew;
    }, [gameStore.stateNew.movesHistory]);

    const getBeatCounter = (): {
        [key: string]: number;
    } => {
        if (!killedPieces)
            return {
                w: 0,
                b: 0,
            };

        // P: 0,
        // N: 0,
        // B: 0,
        // R: 0,
        // Q: 0,
        // K: 0,
        const multiplicator = [1, 3, 3, 5, 9, 1];
        const whitebeat = Object.values(killedPieces[ColorVariant.white])
            .map((item, index) => {
                return item * multiplicator[index];
            })
            .reduce((acc, item) => {
                return acc + item;
            }, 0);

        const blackbeat = Object.values(killedPieces[ColorVariant.black])
            .map((item, index) => {
                return item * multiplicator[index];
            })
            .reduce((acc, item) => {
                return acc + item;
            }, 0);

        return {
            w: whitebeat - blackbeat,
            b: blackbeat - whitebeat,
        };
    };

    const piecesBeatCounter = useMemo(() => {
        const data = getBeatCounter();
        return data;
    }, [killedPieces]);

    if (!opponent || !meOrWhitePlayer) return;

    const opponentMock = {
        avatar: "string",
        color: ColorVariant.black,
        id: opponent._id,
        name: opponent?.nickname || "opponent",
        rating: 0,
        score: 0,
        winInGames: 0,
        ...opponent,
    };

    const meOrWhitePlayerMock = {
        avatar: "string",
        color: ColorVariant.white,
        id: meOrWhitePlayer._id,
        toNamespacedPath: meOrWhitePlayer?.nickname || "Me",
        rating: 0,
        score: 0,
        winInGames: 0,
        ...meOrWhitePlayer,
    };

    if (
        gameStore.dialogs.selectFiguresDialog.isOpen &&
        !gameStore.dialogs.rematchDialog.isOpen &&
        !gameStore.isShowWinOrLostDialog
    ) {
        // Как только мы получаем инфу о ходе пешки на 8 линию сразу открываем Алерт
        // TODO: check setNoAnimation
        return (
            <SelectFiguresAlertWrapper
                isTabletMobile={false}
                setNoanimation={() => {}}
            />
        );
    }
    if (GameModeTypeNew.TOURNAMENT && (!meOrWhitePlayer || !opponent))
        return null;

    if (window.innerWidth >= MAX_XL_TABLET) {
        return (
            <div
                className={cn(style.rightPartNew, {
                    isGameOver: gameStore.gameState.isGameOver(),
                    isMultiTable,
                    isTwoGameTable,
                })}
            >
                {gameStore.isShowWinOrLostDialog &&
                    userRakeBack &&
                    gameType === GameModeTypeNew.REGULAR && <RakeBackModal />}
                {opponent && (
                    <PlayerInfoRedesign
                        resign={resign || "false"}
                        winner={winner || "false"}
                        noTimerSound={false}
                        avatar={opponentMock.avatar}
                        name={opponentMock.name || "Unknown"}
                        initTime={yourTime || 60}
                        activeClock={activeClock || ColorVariant.black}
                        // active={isActivePlayer(yourColor, activeClock || false)}
                        active={!myMove}
                        killedPieces={killedPieces[myColor] || {}}
                        color={yourColor || ColorVariant.black}
                        isSystemAvatar={opponentMock.isSystemAvatar || false}
                        isMultiTable={isMultiTable || false}
                        isTwoGameTable={isTwoGameTable || false}
                        isOpponent={true}
                        opponent={opponentMock}
                        elementPlace={PlayerInfoExistPosition.inGame}
                        piecesBeatCounter={piecesBeatCounter[opponentColor]}
                    />
                )}
                <div
                    className={cn(style.actionAndDiceCenterBox, {
                        isSoloGameEnd: gameStore.gameState.isGameOver(),
                        isMultiTable: isMultiTable,
                    })}
                >
                    {/* <button
                        style={{
                            position: "absolute",
                            top: "80%",
                            zIndex: 10000,
                        }}
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        Toggle Dropdown
                    </button> */}
                    {/* {!gameStore.gameState.isMyTurn() &&
                            !gameStore.gameState.hasSpecialActivity() &&
                            currentPlayer && (
                                <PlayerThinking
                                    player={currentPlayer}
                                    randomValue={randomValue}
                                />
                            )} */}
                    <animated.div
                        className={cn(style.alertInteractiveData)}
                        style={{
                            ...springProps,
                            display: isOpen ? "block" : "block",
                        }}
                    >
                        <p className={cn(style.alertInteractiveParagraph)}>
                            Is thinking...
                        </p>
                    </animated.div>

                    <div
                        className={cn(style.bankWrapperNew, {
                            isMultiRoundGame: isMultiRoundGame,
                        })}
                    >
                        {/* {gameStore.currentGame?.settings.rounds === 1 &&
                            gameType === GameModeType.REGULAR && (
                                <Bank>
                                    <CoinAnimationContainer
                                        balanceType={
                                            gameStore?.currentGame?.balanceType
                                        }
                                        isMultiTable={isMultiTable}
                                        isTwoGameTable={isTwoGameTable}
                                        chessboardType={
                                            ChessboardType.desktopGame
                                        }
                                    />
                                </Bank>
                            )} */}
                        {gameType === GameModeTypeNew.REGULAR && (
                            <Bank>
                                <CoinAnimationContainer
                                    balanceType={
                                        gameStore?.currentGameNew?.gameType
                                    }
                                    isMultiTable={isMultiTable}
                                    isTwoGameTable={isTwoGameTable}
                                    chessboardType={ChessboardType.desktopGame}
                                />
                            </Bank>
                        )}
                        {(gameStore.currentGame?.settings?.rounds! > 1 ||
                            gameType === GameModeTypeNew.TOURNAMENT) && (
                            <GameScore
                                winner={winner}
                                stage={
                                    gameStore?.currentGame?.tournament?.stage
                                }
                                // @ts-ignore
                                title={
                                    gameStore?.currentGame?.tournament?.title
                                }
                            />
                        )}
                    </div>

                    <div className={style.dicesWrapperNew}>
                        {/* {!gameStore.gameState.isGameOver() && (
                            <>
                                <StartMatch
                                    rolled={gameStore.gameIsStart}
                                    isMultitable={isMultiTable}
                                />
                                {gameStore.gameIsStart && (
                                    <DicesWrapper
                                        rndPrefix={props.rndPrefix}
                                        diceClickRnd={props.diceClickRnd}
                                        myColor={gameStore.gameState.getMyColor()}
                                        isMultiTable={isMultiTable}
                                    />
                                )}
                            </>
                        )} */}
                        <DicesWrapper
                            rndPrefix={props.rndPrefix}
                            diceClickRnd={props.diceClickRnd}
                            myColor={ColorVariant.white}
                            isMultiTable={false}
                            isDesktop
                            viewMode={viewMode}
                        />
                    </div>

                    {(gameStore.isShowWinOrLostDialog ||
                        gameStore.dialogs.rematchDialog.isOpen) && (
                        <>
                            <div
                                className={cn(
                                    style.winOrLoseAndRematchDialogWrapperNew,
                                    {
                                        isMultiTable: isMultiTable,
                                    }
                                )}
                            >
                                {gameStore.isShowWinOrLostDialog && (
                                    <WinOrLostAlert opponent={opponent} />
                                )}
                                {gameStore.dialogs.rematchDialog.isOpen &&
                                    !viewMode &&
                                    gameType !== GameModeTypeNew.TOURNAMENT && (
                                        <RematchDialog />
                                    )}
                            </div>
                        </>
                    )}
                    {!gameStore.gameState.isGameOver() && (
                        <div className={cn(style.showPopupContainerNew)}>
                            {gameStore.dialogs.giveUpDialog.isOpen && (
                                <GiveUpDialog />
                            )}

                            {gameStore.dialogs.doublingDialog.isOpen &&
                                !gameStore.dialogs.giveUpDialog.isOpen && (
                                    <X2Dialogs randomValue={randomValue} />
                                )}
                            {gameStore.dialogs.drawDialog.isOpen && (
                                <DrawDialog isTabletMobile={false} />
                            )}
                            {/* 
                            Old logical
                            {gameStore.gameState.isMyTurn() &&
                                !gameStore.gameState.hasSpecialActivity() &&
                                !viewMode && (
                                    <X2Button
                                        isTournamentGame={
                                            gameType === GameModeType.TOURNAMENT
                                        }
                                        isMultiTable={isMultiTable}
                                        isTwoGameTable={isTwoGameTable}
                                    />
                                )} */}

                            <X2Button
                                isTournamentGame={
                                    gameType === GameModeTypeNew.TOURNAMENT
                                }
                                isMultiTable={isMultiTable}
                                isTwoGameTable={isTwoGameTable}
                            />
                            {!gameStore.gameState.isMyTurn() &&
                                !gameStore.gameState.hasSpecialActivity() &&
                                currentPlayer && (
                                    <PlayerThinking
                                        player={currentPlayer}
                                        randomValue={randomValue}
                                    />
                                )}
                        </div>
                    )}
                </div>
                {meOrWhitePlayer && (
                    // <PlayerInfoRedesign
                    //     resign={resign}
                    //     winner={winner}
                    //     avatar={meOrWhitePlayer.avatar}
                    //     name={meOrWhitePlayer.name || "Unknown"}
                    //     initTime={myTime}
                    //     activeClock={activeClock}
                    //     active={isActivePlayer(myColor, activeClock)}
                    //     killedPieces={killedPieces[yourColor]}
                    //     color={myColor}
                    //     reverse
                    //     userCashBox={getCurrentCashbox}
                    //     isSystemAvatar={user?.isSystemAvatar}
                    //     isTournamentGame={gameType === GameModeType.TOURNAMENT}
                    //     isMultiTable={isMultiTable}
                    //     isTwoGameTable={isTwoGameTable}
                    //     elementPlace={PlayerInfoExistPosition.inGame}
                    // />
                    <PlayerInfoRedesign
                        resign={resign}
                        winner={winner}
                        avatar={meOrWhitePlayerMock.avatar}
                        name={meOrWhitePlayerMock.nickname || "Unknown"}
                        initTime={myTime}
                        activeClock={activeClock}
                        active={myMove}
                        // active={isActivePlayer(myColor, activeClock)}
                        killedPieces={killedPieces[yourColor] || {}}
                        color={myColor}
                        reverse
                        userCashBox={getCurrentCashbox}
                        isSystemAvatar={user?.isSystemAvatar}
                        isTournamentGame={
                            gameType === GameModeTypeNew.TOURNAMENT
                        }
                        isMultiTable={isMultiTable}
                        isTwoGameTable={isTwoGameTable}
                        elementPlace={PlayerInfoExistPosition.inGame}
                        piecesBeatCounter={piecesBeatCounter[myColor]}
                    />
                )}
            </div>
        );
    }
};

export default observer(RightLayout);
