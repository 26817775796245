import React, { useEffect, useState } from 'react';
import { useStyles } from 'src/components/fastChessboard/styles';

const DIGITS_Y_COORDS = [3.5, 15.75, 28.25, 40.75, 53.25, 65.75, 78.25, 90.75];
const LETTERS_X_COORDS = [10.5, 23.4, 36, 48, 60.5, 73.4, 85.8, 98];

const DIGITS = [8, 7, 6, 5, 4, 3, 2, 1];
const LETTERS = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];

export const NotationSVG = ({ orientation = 'white' }) => {
    const classes = useStyles();

    const [digits, setDigits] = useState(DIGITS);
    const [letters, setLetters] = useState(LETTERS);

    useEffect(() => {
        const isWhite = orientation === 'white';

        setDigits(isWhite ? DIGITS : DIGITS.slice().reverse());
        setLetters(isWhite ? LETTERS : LETTERS.slice().reverse());
    }, [orientation]);

    return (
        <svg
            viewBox='0 0 100 100'
            style={{
                position: 'absolute',
                left: 0,
                top: 0,
            }}
        >
            {DIGITS_Y_COORDS.map((y, i) => {
                return (
                    <text
                        key={i}
                        x='0.75'
                        y={y}
                        className={i % 2 ? classes.dark : classes.light}
                    >
                        {digits[i]}
                    </text>
                );
            })}

            {LETTERS_X_COORDS.map((x, i) => {
                return (
                    <text
                        key={i}
                        x={x}
                        y='99'
                        className={i % 2 ? classes.light : classes.dark}
                    >
                        {letters[i]}
                    </text>
                );
            })}
        </svg>
    );
};
