import React from "react";

import { GameModeType, GameFinishReason } from "src/store/models";
import { useLocale } from "src/providers/LocaleProvider";
import LocaleText from "src/components/locale-text/locale.text";

import { TITLE_COLORS } from "../../../common/generalGameDialog/dialogContent";
import { ButtonType } from "../../../../../../components/buttons/greenButton";
import FinishedGameGeneralDialog from "../../../common/finishedGameGeneralDialog";
import useStores from "../../../../../../hooks/useStores";

interface PropsWithButton {
    cause: GameFinishReason;
    onApprove: () => void;
    onCancel: () => void;
    amount: string;
    message: string;
    isXlTablet?: boolean | undefined;
    isMultiTable?: boolean;
}

const WinDialog = ({
    cause = GameFinishReason.RESIGN,
    amount,
    message,
    isXlTablet,
    isMultiTable,
    ...props
}: PropsWithButton) => {
    const {
        game: {
            dialogs: {
                win: { approve, cancel, toTournaments },
            },
        },
    } = useLocale();

    const { gameStore } = useStores();
    const winner = gameStore.initData?.over?.winner;
    const gameType = gameStore.currentGame?.type;
    const player = winner ? gameStore.gameState.getPlayerByColor(winner) : null;

    // Temporary Turn off
    // if (!winner) return null;
    // if (!player) return null;
    const titleElement = (
        <span>
            {/* <span style={{ fontWeight: 900 }}>{player.name}</span>{" "} */}
            <span style={{ fontWeight: 900 }}>You Win!</span>{" "}
            <LocaleText defaultValue={`won`} label="game.dialogs.win.title" />
        </span>
    );

    return (
        <FinishedGameGeneralDialog
            // avatar={player.avatar}
            avatar={"string"}
            approveButtonType={ButtonType.primary}
            onApprove={props.onApprove}
            onCancel={props.onCancel}
            title={titleElement}
            titleColor={TITLE_COLORS.GOLDEN}
            message={message}
            amount={`+${amount}`}
            approveText={approve("Реванш")}
            cancelText={
                gameType === GameModeType.REGULAR
                    ? cancel("В Лобби")
                    : toTournaments("To Tournaments")
            }
            // isSystemAvatar={player.isSystemAvatar}
            isSystemAvatar={false}
            isXlTablet={isXlTablet}
            isMultiTable={isMultiTable}
        />
    );
};

export default WinDialog;
