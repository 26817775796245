import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react";

import useStores from "src/hooks/useStores";
import { isGameBetweenFriends } from "src/utils/game-between-friends";
import { GameModeType, GameStatus } from "src/store/models";
import paths from "src/consts/paths";
import useChangeTheme from "src/hooks/useChangeTheme";

//=======
import { LeftLayoutPropsContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import LeftLayout from "src/pages/game-new2/layouts/components/left";
import SpinnerContainer from "./components/spinnerContainer/SpinnerContainer";
import { getDiceSize } from "./utils/getDiceSize";
import { ChessBoardPropsContext } from "./hooks/useChessBoardProps";
import Layout from "./layouts/layout";

interface IGameContainerProps {
    numberOfGames: number;
    isMultiTable?: boolean;
    onLobbyClose?: () => void;
    itemHeight?: number | null;
    itemWidth?: number | null;
    tableIndex?: number;
}

const GameContainer = ({
    numberOfGames,
    isMultiTable,
    onLobbyClose,
    itemHeight,
    itemWidth,
    tableIndex,
}: IGameContainerProps) => {
    const { lobbyStore, gameStore, authStore } = useStores();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        lobbyStore.setJoinedGame(null);
        lobbyStore.setAcceptedGame(null);
    }, []);

    useEffect(() => {
        //Its logical for avoiding problem with wrong acceptmodal on chatbox
        if (!isMultiTable) {
            lobbyStore.setJoinedGame(null);
            lobbyStore.setAcceptedGame(null);
        }
    }, [gameStore.currentGame?.id]);

    useEffect(() => {
        const game = gameStore.currentGame;
        if (!game) return;

        const status = gameStore.currentGame?.status;
        if (
            status === GameStatus.IN_PROGRESS &&
            gameStore.currentGame?.type === GameModeType.TOURNAMENT
        ) {
        } else if (
            status === GameStatus.AWAITING &&
            !isGameBetweenFriends(game)
        ) {
            history.push(paths.diceChessLobby);
        }
    }, [gameStore.currentGame?.status]);

    useEffect(() => {
        return () => {
            gameStore.reset();
        };
    }, [id]);

    const [chessBoardWidth, setChessBoardWidth] = useState(0);
    const [rotateDecorator, setDecorator] = useState<boolean>(false);
    const chessBoardRef = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        if (!chessBoardRef.current || !gameStore.isReady) return;
        setChessBoardWidth(chessBoardRef.current?.offsetWidth);
    }, [chessBoardRef.current, gameStore.isReady]);

    useEffect(() => {
        const rematchInfo = gameStore.rematch;
        if (!rematchInfo) return;
        if (
            rematchInfo.b &&
            rematchInfo.w &&
            rematchInfo.gameId &&
            gameStore.isViewMode()
        ) {
            history.push(`/game/${rematchInfo.gameId}`);
        }
    }, [gameStore.rematch]);

    useEffect(() => {
        if (
            !(
                gameStore.currentGame?.status === GameStatus.FINISHED &&
                !gameStore.isReady
            )
        )
            return;
        if (isMultiTable) {
            onLobbyClose?.();
        } else {
            history.push(paths.diceChessLobby);
        }
    }, [gameStore.currentGame?.status]);

    useEffect(() => {
        if (!gameStore.isReadyNew) return setDecorator(true);
        setTimeout(() => {
            setDecorator(false);
        }, 1500);
    }, [gameStore.isReadyNew]);

    const showSpinner = !gameStore.gameState.isMyTurn();
    const currentSquare = gameStore.gameState.currentSquare;
    const appearanceTheme = useChangeTheme();
    const viewMode = gameStore.isViewMode();

    const isAllowDragOrMoveDisabled =
        viewMode || gameStore.dialogs.selectFiguresDialog.isOpen || showSpinner;

    //there are temporary logical for test game chat

    // if (!gameStore.isReadyNew) {
    //     return (
    //         <>
    //             <SpinnerContainer
    //                 isAbsolute={isMultiTable}
    //                 isMultiTable={isMultiTable}
    //                 rotateDecorator={false}
    //             />
    //         </>
    //     );
    // }

    return (
        <ChessBoardPropsContext.Provider
            value={{
                chessBoardWidth,
                chessBoardRef,
                diceWidth: getDiceSize(chessBoardWidth),
                allowDragDisabled: isAllowDragOrMoveDisabled,
                allowMoveDisabled: isAllowDragOrMoveDisabled,
                numberOfGames,
                isMultiTable: isMultiTable ?? numberOfGames > 1,
                isTwoGameTable: false,
                onLobbyClose,
                tableIndex,
                currentGameNew: gameStore.currentGameNew,
            }}
        >
            {rotateDecorator && (
                <>
                    <SpinnerContainer
                        isMultiTable={isMultiTable}
                        rotateDecorator={rotateDecorator}
                        isAbsolute={isMultiTable}
                    />
                    {/* {isMultiTable && (
                        <SpinnerContainer
                            isMultiTable={isMultiTable}
                            rotateDecorator={rotateDecorator}
                            isAbsolute={isMultiTable}
                        />
                    )} */}
                </>
            )}
            <Layout />
        </ChessBoardPropsContext.Provider>
    );

    if (!gameStore.isReady) {
        return (
            <>
                <SpinnerContainer
                    isAbsolute={isMultiTable}
                    isMultiTable={isMultiTable}
                    rotateDecorator={false}
                />
            </>
        );
    }

    return (
        <ChessBoardPropsContext.Provider
            value={{
                chessBoardWidth,
                chessBoardRef,
                diceWidth: getDiceSize(chessBoardWidth),
                allowDragDisabled: isAllowDragOrMoveDisabled,
                allowMoveDisabled: isAllowDragOrMoveDisabled,
                numberOfGames,
                isMultiTable: isMultiTable ?? numberOfGames > 1,
                isTwoGameTable: false,
                onLobbyClose,
                tableIndex,
                currentGame: gameStore.currentGame,
            }}
        >
            {rotateDecorator && (
                <>
                    {isMultiTable && (
                        <SpinnerContainer
                            isMultiTable={isMultiTable}
                            rotateDecorator={rotateDecorator}
                            isAbsolute={isMultiTable}
                        />
                    )}
                </>
            )}
            <Layout />
        </ChessBoardPropsContext.Provider>
    );
};

export default observer(GameContainer);
