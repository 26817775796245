type GetBlobFromCanvasReturnType = {
    blob: Blob;
    blobUrl: string;
    revokeUrl: string;
};

export const createImage = (url): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.src = url;
    });

const getBlobFromCanvas = (
    canvas,
    file
): Promise<GetBlobFromCanvasReturnType> =>
    new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
            if (blob) {
                blob.name = file.name;
                blob.lastModified = file.lastModified;

                let blobUrl, revokeUrl;

                resolve({ blob, blobUrl, revokeUrl });
            } else {
                reject(new Error('Canvas is empty'));
            }
        }, file.type);
    });

export const getCroppedImg = async (imageElm: HTMLImageElement, file, crop) => {
    const canvas = document.createElement('canvas'),
        scaleX = imageElm.naturalWidth / imageElm.width,
        scaleY = imageElm.naturalHeight / imageElm.height,
        pixelRatio = window.devicePixelRatio,
        ctx = canvas.getContext('2d');
    if (!ctx) return null;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
        imageElm,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
    );

    return await getBlobFromCanvas(canvas, file);
};
