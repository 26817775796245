import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    dicesWrapper: {
        display: "flex",
        justifyContent: "space-evenly",
        width: "100%",
        height: "100%",
        containerType: "size",
        "&.isMultiTable": {
            maxWidth: 300,
            padding: "0 5px",
        },
    },
});
