import cn from 'classnames';

import x2Mobile from 'src/assets/icons/x2ButtonMobile.png';
import { usePlayer } from 'src/sounds/use-player';
import useWindowSize from 'src/hooks/useWindowSize';
import theme from 'src/theme';

import x2WrapperIcon from '../../../icons/double.png';

import { useStyles } from './styles';

interface Props {
    amount?: number;
    onClick: () => void;
    xFactor: number;
    hide: boolean;
    isMultiTable?: boolean;
}

export const X2Button = ({
    amount,
    onClick,
    xFactor,
    hide,
    isMultiTable,
}: Props) => {
    const classes = useStyles();
    const player = usePlayer();
    const { width } = useWindowSize();

    return (
        <div
            className={'x2buttonWrapper'}
            style={{
                opacity: hide ? 0 : 1,
                display: hide ? 'none' : 'block',
            }}
        >
            <div className={classes.x2Buttonwrapper}>
                {width && width > theme.breakpoints.values.md ? (
                    <>
                        <img
                            src={x2WrapperIcon}
                            className={classes.x2Image}
                            alt=''
                        />
                        <div
                            className={cn(classes.x2Button, {
                                isMultiTable,
                            })}
                            onClick={() => {
                                onClick();
                            }}
                        >
                            X{xFactor}
                            {amount && (
                                <div className={classes.amount}>{amount}</div>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <img
                            src={x2Mobile}
                            className={classes.x2Image}
                            alt=''
                        />
                        <div
                            className={cn(classes.x2Button, {
                                isMultiTable,
                            })}
                            onClick={() => {
                                onClick();
                                player('bidIncreaseSoundPlay');
                            }}
                        ></div>
                    </>
                )}
            </div>
        </div>
    );
};
