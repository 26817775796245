import { useEffect } from "react";
import { observer } from "mobx-react";

import { usePlayer } from "src/sounds/use-player";
import useStores from "src/hooks/useStores";
import { useSocket } from "src/core/useSocket";
import {
    IPrizeTournamentData,
    TournamentStatus,
    ITopPlayers,
    ITournaments,
    IFinishedTournament,
    IGame,
} from "src/store/models";
import { emitter } from "src/shared/emitter";

import endpoints from "../../core/endpoints";

const TournamentSocket = () => {
    const { authStore, tournamentsStore } = useStores();
    const [initSocket, socket] = useSocket({
        token: authStore.getAuthToken(),
    });
    const player = usePlayer();

    useEffect(() => {
        if (!socket || !authStore.isAuthorized) return;
        socket.on("tournament:lobby:add", (games: IGame[]) => {
            console.log("tournament:lobby:add", games);
            tournamentsStore.addMyGamesToList(games);
        });
        socket.on("tournament:add", (data: { tournament: ITournaments }) => {
            console.log("tournament:add", data.tournament);
            tournamentsStore.tournamentJackpotStatus(
                data?.tournament?.id,
                data?.tournament?.status
            );
            tournamentsStore.updateModalJackpotStatus(
                data?.tournament?.id,
                data?.tournament?.status
            );
        });

        socket.on(
            "tournament:lobby:numberOfPlayers",
            (data: { id: string; numberOfPlayers: number }) => {
                tournamentsStore.updateNumberOfPlayersOnTournament(
                    data.id,
                    data.numberOfPlayers
                );
            }
        );
        socket.on(
            "tournament:lobby:status",
            (data: {
                id: string;
                status: TournamentStatus;
                winner: ITopPlayers | null;
            }) => {
                console.log("tournament:lobby:status");
                tournamentsStore.tournamentStatus(data);
            }
        );
        socket.on("tournament:finished", (data: IFinishedTournament) => {
            console.log("tournament:finished", data);
            // tournamentsStore.setTournamentWinnerAlert(data);
        });
        socket.on("tournament:started", (data) => {
            console.log("tournament:started", data);
            player("gameCreate");
            // tournamentsStore.setTournamentWinnerAlert(data);
        });
        socket.on(
            "tournament:prizePlace:game:confirmed",
            ({ player1, player2, startDate, id }) => {
                tournamentsStore.updateThirdOrFourth(player1, player2);
            }
        );
        socket.on("tournament:prizeWinner", (data: IPrizeTournamentData) => {
            console.log("tournament:prizeWinner", data);
            tournamentsStore.setTournamentWinnerAlert(data);
        });
    }, [socket]);

    useEffect(() => {
        if (authStore.isVisibleWebApp && socket) {
            emitter.emit("tournament:lobby:reconnect");
        }
    }, [authStore.isVisibleWebApp]);

    useEffect(() => {
        if (!socket) return;

        emitter.on("tournament:lobby:reconnect", () => {
            socket.emit("tournament:lobby:reconnect");
        });

        return () => {
            emitter.removeAllListeners("tournament:lobby:reconnect");
        };
    }, [socket]);

    useEffect(() => {
        if (!authStore.isAuthorized) {
            initSocket(null);
            return;
        }
        if (authStore.isAuthorized) {
            socket?.connect();
            initSocket(endpoints.tournamentSocket);
        }
    }, [authStore.isAuthorized]);

    return null;
};

export default observer(TournamentSocket);
