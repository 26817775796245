import React, { useRef, useCallback, useEffect, useState, memo } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import cn from "classnames";

import useWindowSize from "src/hooks/useWindowSize";
import { appColors } from "src/consts/app-config";

import { useStyles } from "./styles";
// import calcButtonWidth from '../../../utils/calcButtonWidth';
import calcButtonWidth from "src/utils/calcButtonWidth";
import calcButtonTextSize, {
    SizePropsType,
    calcButtonPulseSize,
} from "src/utils/calcButtonTextSize";

type ButtonTagType = "button" | "reset" | "submit" | undefined;

export enum ButtonTypeNew {
    primary = "linear-gradient(19.92deg, #0D5C52 13.3%, #50BAAE 86.7%)",
    purple = "linear-gradient(19.92deg, #220D5C 13.3%, #6C50BA 86.7%)",
    negative = "linear-gradient(19.92deg, #341010 13.3%, #831D1D 86.7%)",
    secondary = "linear-gradient(19.92deg, #220D5C 13.3%, #6C50BA 86.7%)",
    info = "linear-gradient(19.92deg, #0D235C 13.3%, #506EBA 86.7%)",
    yellow = "linear-gradient(180deg, #F8BF44 0%, #A16529 100%)",
    blue = "radial-gradient(47.56% 8.33% at 50% 8.33%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(49.97% 10% at 50% 90%, #FFFFFF 0%, rgba(255, 255, 255, 0) 69.87%), linear-gradient(19.92deg, #0D235C 13.3%, #506EBA 86.7%)",
    deepBlack_primary = "rgba(0, 186, 108, 1)",
    deepBlack_secondary = "rgba(0, 186, 108, 1)",
    deepBlack_yellow = "rgba(0, 186, 108, 1)",
    deepBlack_negative = "rgba(255, 28, 41, 1)",
}

export enum ButtonTrafficLight {
    primary = "primary",
    secondary = "secondary",
    yellow = "yellow",
    negative = "negative",
}

const defaultProps = {
    ratio: 1,
    color: "#edf4ff",
    disabled: false,
    loading: false,
    icon: undefined,
    iconPosition: "start",
    onClick: () => {},
    text: "",
    buttonType: "button" as ButtonTagType,
    fontSize: 16,
};
interface Props {
    appearanceTheme: string;
    text: string;
    bgColor?: string;
    buttonType?: ButtonTagType;
    className?: string;
    color?: string;
    disabled?: boolean;
    fontSize?: number;
    height?: number | undefined;
    classNameTextAnimation?: string;
    icon?: any;
    iconPosition?: "start" | "end";
    loaderSize?: number;
    loading?: boolean;
    offTextAnimation?: boolean;
    onClick?: () => void;
    padding?: number | string | undefined;
    ratio?: number;
    widthWrapper?: number | string | undefined;

}

interface IButtonSize {
    width: number;
    height: number;
}

interface IButtonContain {
    icon?: string;
}

export const MainActionButton = memo((props: Props) => {
    const mainActionButtonRef = useRef<HTMLButtonElement>(null);
    const { width: windowWidth, height: windowsHeight } = useWindowSize();
    const [buttonSize, setButtonSize] = useState<IButtonSize>({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        if (mainActionButtonRef.current) {
            const width = mainActionButtonRef?.current?.clientWidth;
            const height = mainActionButtonRef?.current?.clientHeight;
            if (buttonSize.width !== width || buttonSize.height !== height) {
                setButtonSize({ width, height });
            }
        }
    }, [windowWidth, windowsHeight]);

    let t = {
        ...defaultProps,
        ...props,
        width: buttonSize.width,
        height: buttonSize.height,
    };

    const {
        classNameTextAnimation,
        offTextAnimation,
        onClick,
        disabled,
        bgColor,
        color,
        text,
        loading = false,
        fontSize,
        icon,
        iconPosition,
        loaderSize,
        padding,
        widthWrapper,
        appearanceTheme,
        width,
        height,
    } = t;

    const classes = useStyles(t);

    const pulseSize = useCallback(() => {
        const defaultSize = 16;
        const sizeRefactor = 6;
        if (loading && buttonSize.width && buttonSize.height) {
            const calcPulse = calcButtonPulseSize(
                buttonSize.width,
                buttonSize.height,
                sizeRefactor,
                SizePropsType.pulse
            );
            return Math.min(calcPulse, defaultSize);
        }
        return defaultSize;
    }, [loading, buttonSize]);

    const Icon = ({ icon }: { icon: string }) => {
        return (
            <img
                className={cn(classes.buttonIcon, appearanceTheme)}
                src={icon}
                alt="icon"
            />
        );
    };

    const Loader = ({ size, color }: { size: () => number; color: string }) => {
        return <PulseLoader size={size()} color={color} loading />;
    };

    const Text = ({ text }: { text: string }) => {
        return (
            <div className={cn(classes.buttonText, appearanceTheme)}>
                <p
                    className={cn(
                        classes.buttonText,
                        appearanceTheme,
                        !offTextAnimation &&
                            (classNameTextAnimation || "defaultTextAnimation"),
                        {
                            offTextAnimation: offTextAnimation,
                        }
                    )}
                >
                    {text}
                </p>
            </div>
        );
    };

    return (
        <button
            ref={mainActionButtonRef}
            className={cn(
                classes.mainActionButtonWrapper,
                t.className,
                appearanceTheme
            )}
            onClick={(e) => {
                e.stopPropagation();
                if (!disabled && !loading) onClick();
            }}
        >
            {icon && !loading ? (
                <Icon icon={icon} />
            ) : loading ? (
                <Loader size={pulseSize} color={appColors.white} />
            ) : (
                <Text text={text} />
            )}
        </button>
    );
});
