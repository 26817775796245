import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';
import { MAX_CHANGE_SCREEN_WIDTH } from 'src/pages/game-new/constants';
import theme from 'src/theme';

const currentHeight = window.innerHeight;

export const useStyles = makeStyles({
    modalRangWrapper: {
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        gridTemplateColumns: 'auto 1fr',
        width: 'fit-content',
        height: 'fit-content',
        gridGap: '15px',
        '&.friendFightReceive': {
            width: 'fit-content',
            gridGap: '15px',
            gridTemplateColumns: 'auto 1fr',
        },
        [theme.breakpoints.down('md')]: {
            maxHeight: '75px',
            height: 'fit-content',
        },
    },

    modalRangBox: {
        maxWidth: '110px',
        height: '80px',
        justifySelf: 'center',
        background:
            'linear-gradient(87.41deg, #101320 28.82%, #1F2336 120.76%)',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.15)',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        '&.fastGame': {
        }
    },

    modalRangeTitle: {
        fontFamily: 'Sans Caption',
        fontWeight: 500,
        textAlign: 'center',
        color: '#B0B8DE',
        margin: 0,
        '&.title': {
          fontSize: '24px',
        },
        '&.paragraph': {
          fontSize: '12px',
          opacity: 0.5,
        },
    },

    modalRankParagraph: {
      fontSize: '16px',
      margin: 0,
      color: appColors.white,
      [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
          margin: 0,
      },
      '&.rank': {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        minHeight: '32px',
        fontSize: '26px',
        [theme.breakpoints.down('md')]: {
          minHeight: '32px',
          fontSize: '22px',
      },
        [theme.breakpoints.down('sm')]: {
          minHeight: '32px',
          fontSize: '18px',
        },
      },
      '&.rankCount': {
        display: 'flex',
        minHeight: '32px',
        fontSize: '26px',
        alignItems: 'center',
        fontWeight: 600,
        [theme.breakpoints.down('md')]: {
          minHeight: '32px',
          fontSize: '22px',
        },
        [theme.breakpoints.down('sm')]: {
          minHeight: '32px',
          fontSize: '18px',
        },
      },
      '&.fastGame': {
        fontSize: '22px',
        [theme.breakpoints.down('xl')]: {
          fontSize: '20px',
        },
        [theme.breakpoints.down(MAX_CHANGE_SCREEN_WIDTH)]: {
          fontSize: '16px',
        },
        [theme.breakpoints.down(1150)]: {
          fontSize: '14px',
        },
      },
  },

    statsItemSpan: {
      zIndex: 1,
      paddingLeft: '3px',
      '&.name': {
          background: 'linear-gradient(120deg, #EAEAEA, white 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontWeight: 'bold',
          maxWidth: '100px',
          minWidth: '60px',
          maskImage: 'linear-gradient(to right,black 80%,transparent 100%)',
      },
      '&.RP': {
          background: 'linear-gradient(180deg, #F8BF44 50%, #A16529 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontWeight: 'bold',
      },
  },
    '@keyframes uploadAvatarWrapper': {
        '0%': {
            opacity: 0,
            transform: ` translate(0%, 0%) translateY(${currentHeight}px)`,
            borderRadius: '10px',
        },
        '100%': {
            opacity: 1,
            borderRadius: '35px',
            transform: `translate(0%, 0%) translateY(${0}px)`,
        },
    },
});
