import { makeStyles } from "@mui/styles";

import theme from "src/theme";
// import '~react-gif-player/src/GifPlayer';

export const useStyles = makeStyles({
    "@global": {
        "@keyframes x2DepositLogoAnimateMobile": {
            "0%": {
                transform: "scale(1)",
            },
            "50%": {
                transform: "scale(0.9)",
            },
            "100%": {
                transform: "scale(1)",
            },
        },
    },
    containerLobby: () => ({
        position: "relative",
        top: 0,
        width: "100%",
        height: "100vh",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        left: "50%",
        transform: "translate(-50%, 0%)",
    }),
    " *": {
        boxSizing: "border-box",
    },
    logoIcon: {
        maxWidth: "30vw",
        width: "100%",
        objectFit: "contain",
        margin: "0 3vw",
        "@media (max-width: 520px)": {
            maxWidth: "30vw",
        },
        "@media (max-width: 440px)": {
            maxWidth: "27vw",
        },
    },
    jackPotComponentBox: {
        zIndex: 11,
        width: "70px",
        height: "auto",
        position: "absolute",
        top: "26%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },

    x2DepositLogo: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
        animationName: "x2DepositLogoAnimateMobile",
        animationDelay: "0s",
        transform: "scale(1)",
        animationIterationCount: "infinite",
        animationFillMode: "forwards",
        animationDuration: "6500ms",
        cursor: "pointer",
    },

    divider: {
        width: "100%",
        height: "2px",
        background:
            "radial-gradient(circle, rgba(153,167,164,1) 0%, rgba(56,59,59,1) 90%)",
        margin: "0px 0 3px 0",
    },

    socialIcons: {
        display: "flex",
        flexDirection: "column",
    },

    footerContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#101211",
        padding: "5px 10px",
        flexWrap: "wrap",
        [theme.breakpoints.down("md")]: {
            padding: "5px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "5px 2px 2px 2px",
            minHeight: "auto",
        },
    },

    userActionLinksBox: {
        display: "flex",
        justifyContent: "space-around",
        gridGap: "5px",
        width: "100%",
        "@media (max-width: 840px)": {},
    },

    socialIconsContainer: {
        display: "flex",
        position: "relative",
    },
    socialIcon: {
        objectFit: "cover",
        width: 25,
        marginRight: 7,
    },
    main: {
        width: "100%",
        height: "100%",
    },

    backgroundContainer: {
        width: "100%",
    },

    background: {
        position: "absolute",
        width: "100%",
        height: "67%",
        objectFit: "cover",
    },
    header: {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr 1fr",
        backgroundColor: "rgb(21, 23, 25)",
        width: "100%",
        zIndex: 10,
    },
    logoBalance: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // [theme.breakpoints.down('sm')]: {
        //     justifyContent: 'space-evenly'
        // }
    },

    dices: {
        display: "flex",
        width: "calc(40vw + 0px)",
        height: "100%",
        margin: "auto",
        position: "relative",
        "@media (max-width: 890px)": {
            width: "calc(40vw + 30px)",
        },
        "@media (max-width: 840px)": {
            width: "calc(40vw + 50px)",
        },
        "@media (max-width: 730px)": {
            width: "calc(40vw + 80px)",
        },
        "@media (max-width: 660px)": {
            width: "calc(40vw + 120px)",
        },
        "@media (max-width: 600px)": {
            width: "calc(40vw + 150px)",
        },
        "@media (max-width: 380px)": {
            width: "calc(40vw + 180px)",
        },
        "@media (min-height: 840px)": {
            marginTop: "calc(5% + 5px)",
        },
        "@media (min-height: 1045px) and (max-width: 450px)": {
            marginTop: "calc(5% + 30px)",
        },
        "@media (min-height: 1200px) and (max-width: 450px)": {
            marginTop: "calc(5% + 40px)",
        },
    },
    dice: {
        width: "170px",
        position: "absolute",
        top: "18%",
        left: "50%",
        transform: "translateX(-85px)",
        filter: "blur(1.5px)",
        "@media (max-width: 730px)": {
            scale: "0.8",
            top: "33%",
        },
        "@media (max-width: 500px)": {
            // top: '18%',
            top: "33%",
            left: "43%",
        },
        "@media (max-height: 690px)": {
            // top: '20%',
            top: "33%",
        },
        "@media (max-height: 660px)": {
            // top: '20%',
            top: "33%",
        },
        [theme.breakpoints.up(730)]: {
            top: "28%",
            left: "52%%",
        },
    },

    leftDice: {
        width: "160px",
        position: "absolute",
        // top: '2%',
        top: "5%",
        // right: '0',
        right: "-5%",
        filter: "blur(0.8px)",
        "@media (max-width: 730px)": {
            scale: "0.8",
        },
        "@media (max-height: 690px)": {
            top: "0%",
        },
        [theme.breakpoints.up(730)]: {
            right: "-5%",
            top: "5%",
        },
    },
    rightDice: {
        width: "125px",
        position: "absolute",
        top: "5%",
        // left: '5%',
        left: "0%",
        "@media (max-width: 730px)": {
            // top: '4%',
            top: "6%",
            left: "0%",
            scale: "0.7",
        },
        "@media (max-height: 690px)": {
            top: "2%",
        },
        [theme.breakpoints.up(730)]: {
            top: "6%",
            left: "0%",
        },
    },

    lobby: {
        position: "absolute",
        // bottom: '12%',
        bottom: "16%",
        left: 0,
        right: 0,
        "@media (max-height: 732px)": {
            // bottom: '11%',
            bottom: "17%",
        },
        "@media (min-height: 1300px) and (max-width: 1030px)": {
            bottom: "19%",
        },
        [theme.breakpoints.down("lg")]: {
            bottom: "180px",
        },
        [theme.breakpoints.down("md")]: {
            bottom: "175px",
        },
        [theme.breakpoints.down(550)]: {
            bottom: "195px",
        },
        [theme.breakpoints.down("sm")]: {
            bottom: "155px",
        },
    },

    lobbyTitle: {
        width: "100%",
        justifyContent: "center",
        color: "white",
        zIndex: 10,
        marginBottom: 30,
        "@media (max-height: 740px)": {
            marginBottom: -10,
        },
        [theme.breakpoints.down("md")]: {
            //display: 'none',
        },
    },
    firstTitle: {
        fontSize: "27px",
        fontWeight: 400,
        margin: 0,
        textShadow: "8.48px 5.299px 6.5px rgba(0,0,0,0.75)",
        "@media (max-width: 690px)": {
            fontSize: "23px",
        },
        "@media (max-width: 450px)": {
            textAlign: "center",
        },
        "@media (max-width: 410px)": {
            fontSize: "21px",
        },
    },
    chessTitle: {
        fontSize: "50px",
        fontWeight: 400,
        marginTop: -7,
        marginBottom: 0,
        textShadow: "8.48px 5.299px 28.5px rgba(0,0,0,0.75)",
    },
    joinApp: {
        color: "white",
        padding: "10px",
        fontSize: "12px",
        background:
            "linear-gradient(90deg, rgba(16,18,17,1) 1.44%, rgba(22,56,19,1) 98.62%)",
        display: "flex",
        alignItems: "center",
        "@media (max-width: 450px)": {
            width: "37%",
            fontSize: "8px",
            padding: "5px 0 5px 10px",
            right: 0,
            bottom: 20,
        },
        "@media (max-width: 410px)": {
            fontSize: "8px",
            bottom: 10,
        },
    },
    joinAppHeader: {
        display: "flex",
        color: "white",
        padding: "5px 5px 5px 0",
        fontSize: "10px",
        marginRight: 5,
    },

    phoneIcon: {
        width: 35,
    },

    advContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
    },

    join: {
        color: "white",
        margin: "0 0 10px 0",
        fontSize: "12px",
        textAlign: "center",
        "@media (max-width: 400px)": {
            fontSize: "8px",
        },
        "@media (max-width: 1000px)": {
            fontSize: "2vw",
        },
    },

    playButton: {
        borderRadius: "0.2vw",
        border: "none",
        background: "#00BA6C",
        fontSize: "1.2vw",
        fontWeight: "700",
        fontFamily: "Montserrat",
        lineHeight: "1.4vw",
        padding: "0.7vw 0.9vw",
        color: "#11141C",
        cursor: "pointer",
        position: "absolute",
        marginBottom: "1.2vw",
        "&:hover": {
            opacity: 0.9,
        },

        [theme.breakpoints.down("lg")]: {
            fontSize: "3vw",
            width: "90%",
            margin: "0 auto",
        },
        [theme.breakpoints.down("md")]: {
            fontSize: "3vw",
            width: "30%",
            margin: "2vw auto",
            padding: "3vw 0",
            borderRadius: "0.5vw",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "4vw",
            width: "80%",
            margin: "3vw auto",
            padding: "4vw 0",
            borderRadius: "1vw",
        },
        [theme.breakpoints.down("xs")]: {
            fontSize: "4vw",
            width: "80%",
            margin: "3vw auto",
            padding: "4vw 0",
            borderRadius: "1vw",
        },
    },

    appBarElement: {
        marginLeft: "0%",
        width: "200px",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "auto",
        },
        "@media (max-width: 480px)": {
            width: "auto",
        },
        "@media (max-width: 330px)": {
            width: "auto",
        },
    },
    avatarWrapper: {
        position: "relative",
        display: "flex",
        padding: "10px",
        zIndex: 10,
        "@media (max-width: 330px)": {
            width: "auto",
        },
    },

    appBarElementReg: {
        fontFamily: "Montserrat",
        gap: "2vw",
        maxWidth: "50%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        fontSize: "1vw",
        zIndex: 11,
    },
    appBarElementRegLang: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        "@media (max-width: 500px)": {
            flexDirection: "column",
            justifyContent: "flex-end",
            gap: "3px",
        },
    },
    languageWrapper: {
        marginLeft: 10,
        padding: "5px 15px",
        alignSelf: "center",
        "@media (max-width: 1000px)": {
            display: "block",
        },
        "@media (max-width: 800px)": {
            marginLeft: 5,
        },
        "@media (max-width: 700px)": {
            marginLeft: 0,
            marginRight: 5,
        },
        // '@media (max-width: 500px)': {
        //     alignSelf: 'end'
        // },
        "@media (max-width: 430px)": {
            marginRight: 10,
            padding: 3,
        },
    },

    balanceLang: {
        display: "flex",
        "@media (max-width: 370px)": {
            flexDirection: "column",
        },
    },
    app: {
        color: "white",
        fontSize: 12,
        padding: "5px 10px",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            padding: "0",
        },
    },

    downloadIcon: {
        width: 24,
        "@media (max-width: 400px)": {
            width: "8vw",
        },
    },
    business: {
        display: "flex",
        alignItems: "center",
        color: "white",
        border: "2px solid #ffffff",
        borderRadius: 20,
        fontSize: 12,
        padding: 10,
        // marginLeft: 20,
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
            height: "inherit",
            padding: "0px 5px",
            borderRadius: 10,
            justifyContent: "space-between",
        },
        [theme.breakpoints.down(375)]: {
            justifyContent: "center",
        },
    },

    businessTitle: {
        [theme.breakpoints.down(375)]: {
            display: "none",
        },
    },

    businessIcon: {
        width: 25,
        marginRight: 10,
        [theme.breakpoints.down("sm")]: {
            marginRight: 0,
        },
    },
    badgeNotifications: {
        "&.MuiBadge-root > span": {
            zIndex: "2",
            background:
                "linear-gradient(19.92deg, rgb(34, 13, 85) 13.3%, rgb(108, 80, 200) 86.7%)",
        },
        "&.emptyNote": {
            "&.MuiBadge-root > span": {
                display: "none",
            },
        },
    },

    content: {
        flexGrow: "1",
        overflowY: "auto",
        scrollbarWidth: "none",
    },

    heroSection: {
        width: "100%",
        display: "flex",
        position: "relative",
        background: "#151719",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: 0,
    },

    heroVideo: {
        width: "100%",
        height: "auto",
        objectFit: "cover",
    },

    underHeroContent: {
        background: "#151719",
        padding: "2.4vw",
        gap: "1.2vw",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down("lg")]: {
            flexDirection: "row",
        },
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },

    pockerSlots: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#2E3136",
        padding: "0.6vw",
        borderRadius: "0.9vw",
        boxShadow: "0 0.2vw 0.6vw rgba(0, 0, 0, 0.1)",
        position: "relative",
    },

    imageWrapper: {
        position: "relative",
        width: "100%",
    },

    pockerImage: {
        width: "100%",
        height: "42vw",
        objectFit: "cover",
        borderRadius: "0.6vw",
        marginBottom: "1.2vw",
        [theme.breakpoints.down("lg")]: {
            width: "100%",
            height: "50vw",
            padding: "2vw",
            borderRadius: "3vw",
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
            height: "45vw",
            padding: "2vw",
            borderRadius: "3vw",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            height: "50vw",
            padding: "1.2vw",
        },
    },
    arrow: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 3vw",
        pointerEvents: "none",
        [theme.breakpoints.down("lg")]: {
            width: "100%",
            padding: "0 3vw",
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
            padding: "0 3vw",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: "0 3vw",
        },
    },

    leftArrow: {
        cursor: "pointer",
        pointerEvents: "auto",
        [theme.breakpoints.down("lg")]: {
            width: "10%",
            height: "10%",
        },
        [theme.breakpoints.down("md")]: {
            width: "10%",
            height: "10%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "10%",
            height: "10%",
        },
    },

    rightArrow: {
        cursor: "pointer",
        pointerEvents: "auto",
        transform: "rotate(180deg)",
        [theme.breakpoints.down("lg")]: {
            width: "10%",
            height: "10%",
        },
        [theme.breakpoints.down("md")]: {
            width: "10%",
            height: "10%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "10%",
            height: "10%",
        },
    },

    button: {
        background: "#2e3136",
        color: "#00BA6C",
        padding: "0.7vw 0.9vw",
        border: "0.1vw solid #00BA6C",
        borderRadius: "0.2vw",
        cursor: "pointer",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24.38px",
        width: "100%",
        height: "5vw",
        [theme.breakpoints.down("lg")]: {
            padding: "0",
        },
        [theme.breakpoints.down("md")]: {
            padding: "0",
            width: "96%",
            marginBottom: "2vw",
            margin: "0 2vw",
        },
        [theme.breakpoints.down("sm")]: {
            height: "7vw",
            width: "97%",
            marginBottom: "2vw",
        },
    },

    slotsContainer: {
        backgroundColor: "#151719",
        padding: "1.2vw 2.4vw",
    },

    slotsTitle: {
        color: "#F5F5F5",
        fontSize: "1.7vw",
        fontFamily: "Montserrat",
        lineHeight: "2.3vw",
        fontWeight: "500",
        marginBottom: "0",
        padding: "0.5vw 0",
    },

    slotsGrid: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        overflowX: 'scroll',
        scrollBarWidth: 'none',
        '@media screen and (min-width: 0px)': {
            '&::-webkit-scrollbar': {
                display: 'none', // Для Chrome, Safari и Opera
            },
        },
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr 1fr",
        },
    },

    slotImage: {
        width: "22%",
        marginRight: "4%",
        objectFit: "cover",
        borderRadius: "4px",
        marginBottom: "4vw",
        "&:nth-child(4n)": {
            marginRight: 0
        },

        [theme.breakpoints.down("sm")]: {
            width: "48%",
            marginBottom: "6vw",
            "&:nth-child(2n)": {
                marginRight: 0
            },
            "&:nth-child(n+9)": {
                display: 'none'
            },
        },
    },

    slotsHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "1.2vw",
        padding: "0 0.9vw",
    },

    viewAllButton: {
        marginTop: "1.4vw",
        fontSize: "1.2vw",
        fontFamily: "Montserrat",
        lineHeight: "1.4vw",
        fontWeight: "700",
        color: "#00BA6C",
        background: "transparent",
        border: "none",
    },

    line: {
        width: "100%",
        border: "0.1vw solid rgba(255, 255, 255, 0.1)",
    },

    liveCasinoBlock: {
        backgroundColor: "#151719",
        padding: "1.2vw 2.4vw",
    },

    streamChannelsBlock: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#151719",
        padding: "1.2vw",
        borderRadius: "0.5vw",
        marginBottom: "1.2vw",
        maxWidth: "100%",
    },

    streamGrid: {
        display: "flex",
        gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        gap: "1rem",
        justifyItems: "center",
        width: "100%",
        overflowX: "scroll",
        scrollBarWidth: "none",
        "@media screen and (min-width: 0px)": {
            "&::-webkit-scrollbar": {
                display: "none", // Для Chrome, Safari и Opera
            },
        },
    },

    streamHeader: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginBottom: "1.2vw",
        padding: "0 0.9vw",
    },

    streamTitle: {
        color: "#F5F5F5",
        fontSize: "1.6vw",
        fontFamily: "Montserrat",
        lineHeight: "2.3vw",
        fontWeight: "600",
        marginBottom: "0",
    },

    streamImage: {
        width: "100%",
        height: "auto",
        objectFit: "cover",
        borderRadius: "0.6vw",
        [theme.breakpoints.between("md", "lg")]: {
            width: "20vw",
        },
        [theme.breakpoints.between("sm", "md")]: {
            width: "40vw",
        },
        [theme.breakpoints.down("sm")]: {
            width: "30vw",
        },
    },

    buttonContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "0.6vw",
        width: "100%",
        marginTop: "auto",
        position: "relative",
        zIndex: 1,
    },

    disabledButton: {
        backgroundColor: "#808080",
        cursor: "not-allowed",
    },

    streamSlots: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#2E3136",
        padding: "0.5rem",
        borderRadius: "0.5rem",
        boxShadow: "0 0.1rem 0.3rem rgba(0, 0, 0, 0.1)",
        width: "100%",
        maxWidth: "100vw",
        aspectRatio: "16/9",
        position: "relative",
    },

    streamInfo: {
        display: "flex",
    },

    streamName: {
        color: "#F5F5F5",
        fontSize: "1rem",
        fontFamily: "Montserrat",
        lineHeight: "2vw",
        fontWeight: "600",
        marginTop: "2vw",
        position: "relative",
        zIndex: 1,
        [theme.breakpoints.between("md", "lg")]: {
            fontWeight: "500",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "3vw",
            fontWeight: "500",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "3vw",
            fontWeight: "500",
        },
    },

    streamStatus: {
        color: "#00BA6C",
        fontSize: "0.8rem",
        fontFamily: "Montserrat",
        lineHeight: "1.5",
        fontWeight: "400",
        display: "flex",
        alignItems: "center",
        marginTop: "0.5rem",
        position: "relative",
        zIndex: 1,
        "&::before": {
            content: '""',
            display: "inline-block",
            width: "0.5rem",
            height: "0.5rem",
            backgroundColor: "#00BA6C",
            borderRadius: "50%",
            marginRight: "0.3rem",
        },
    },

    online: {
        "&::before": {
            content: '""',
            display: "inline-block",
            backgroundColor: "#00BA6C",
            borderRadius: "50%",
            marginRight: "0.3vw",
        },
    },

    offline: {
        color: "#808080",
        "&::before": {
            content: '""',
            display: "inline-block",
            backgroundColor: "#808080",
            borderRadius: "50%",
            marginRight: "0.3vw",
        },
    },

    streamButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#2e3136",
        color: "#00BA6C",
        border: "1px solid #00BA6C",
        borderRadius: "0.25rem",
        marginTop: "0.5rem",
        cursor: "pointer",
        fontSize: "0.8rem",
        fontWeight: "bold",
        textTransform: "uppercase",
        flexBasis: "calc(50% - 0.2rem)",
        flexGrow: 1,
        padding: "1vw",
    },

    menuFooter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#151719",
        padding: "1.2vw",
        borderRadius: "0.5vw",
    },

    iconStyle: {
        width: "2.5vw",
        height: "2.5vw",
        [theme.breakpoints.between("sm", "md")]: {
            width: "2vw",
            height: "2vw",
        },
        [theme.breakpoints.down("sm")]: {
            width: "2vw",
            height: "2vw",
        },
    },
});
