import React, {
    ReactNode,
    useEffect,
    useMemo,
    useState,
    useCallback,
    memo,
    useRef,
} from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";
import { useStyles } from "./styles";

interface IBeatPiecesCounter {
    piecesBeatCounter?: number;
    className?: string;
}

const BeatPiecesCounter = observer((props: IBeatPiecesCounter) => {
    const classes = useStyles();
    const borderCounter = 0;
    const { piecesBeatCounter, className } = props;

    if (!piecesBeatCounter) return;

    return (
        <Box
            component="div"
            className={cn(classes.beatPiecesCounter, className)}
        >
            {piecesBeatCounter > borderCounter ? (
                <p className={cn(classes.beatPiecesParagraph, className)}>
                    + {piecesBeatCounter}
                </p>
            ) : null}
        </Box>
    );
});

export default BeatPiecesCounter;
