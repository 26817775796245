import { breakpoints } from "./../../../shared/helpers/ThemeHelper/constants";
import { makeStyles } from "@mui/styles";
import theme from "src/theme";
import { appColors, chesboardSquares } from "src/consts/app-config";

export const useStyles = makeStyles({
    wrapperVNotation: ({
        borderWidth,
        cellSize,
    }: {
        borderWidth: number;
        cellSize: number;
    }) => ({
        // width: borderWidth,
        // top: 12,
        // bottom: 12,
        // left: 0,
        //alignItems: "center",
        width: "calc(100% / 8)",
        height: "100%",
        fontSize: 10,
        position: "absolute",
        justifyContent: "space-around",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        //2 / 100 = 2% in figma params for 1920 1080
        paddingLeft: cellSize ? `${(cellSize * 2) / 100}px` : "4px",
        "@media screen and (max-width: 768px)": {
            top: 0,
            bottom: 0,
            fontSize: 8,
            display: "grid",
            gridTemplateColumns: "1fr",
            gridTemplateRows: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
            justifyContent: "unset",
            flexDirection: "unset",
            "& > div": {
                alignSelf: "flex-start",
                paddingTop: 2,
                paddingLeft: 2,
            },
        },
        "&.isMultiTable": {
            fontSize: 8,
        },
        "@media screen and (max-width: 400px)": {
            fontSize: 7,
        },
        // [theme.breakpoints.up(1800)]: {
        //     left: 14,
        // },
    }),

    vNotationItem: ({
        borderWidth,
        cellSize,
    }: {
        borderWidth: number;
        cellSize: number | undefined;
    }) => ({
        height: "100%",
        fontFamily: "Montserrat",
        // mixBlendMode: "difference",
        color: chesboardSquares.deepBlack.black,
        //104 / 24 = 4.333 - average lineheight and font size for 1920*1080,
        fontSize: cellSize ? `${cellSize / 4.3}px` : "24px",
        "&.odd": {
            color: chesboardSquares.deepBlack.white,
        },
        "&.deepBlack": {
            color: chesboardSquares.deepBlack.black,
            "&.odd": {
                color: chesboardSquares.deepBlack.white,
            },
        },
    }),
});
