import { makeStyles } from "@mui/styles";

import theme from "src/theme";
import { EColor } from "src/components/types";
import { appColors } from "src/consts/app-config";

import {
    MAX_CHANGE_SCREEN_WIDTH,
    MIN_CHANGE_SCREEN_WIDTH,
    MIN_XL_TABLET,
    MIN_L_TABLET,
} from "../../constants";

const chatTabWrapperRoot = {
    minWidth: "20%",
    maxWidth: "100px",
    minHeight: "40px",
    maxHeight: "45px",
    fontWeight: "700",
    color: appColors.white,
    borderRadius: "4px",
    opacity: 0.75,
    transition: "opacity ease-out 300ms",
    margin: 0,
    padding: 0,
    padding: "0px 0px",
    letterSpacing: "-0.5px",
};

export const useStyles = makeStyles({
    "@global": {
        "@keyframes stickersWrapperOpen": {
            "0%": {
                transform: "translate(-100%, 100%)",
                opacity: 0,
                maxHeight: 0,
            },
            "60%": {
                transform: "translate(0%, 0%)",
                opacity: 1,
                maxHeight: 250,
            },
            "80%": {
                transform: "translate(0%, 0%)",
                opacity: 1,
                maxHeight: 250,
            },
            "100%": {
                transform: "translate(0%, 0%)",
                opacity: 1,
                maxHeight: 250,
            },
        },
        "@keyframes stickersWrapperHide": {
            "0%": {
                transform: "translate(0%, 0%)",
                opacity: 1,
                maxHeight: 250,
            },
            "100%": {
                transform: "translate(-100%, 100%)",
                opacity: 0,
                maxHeight: 0,
                minHeight: 0,
            },
        },
    },

    chatBoxMain: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        borderRadius: 10,
        boxSizing: "border-box",
        [theme.breakpoints.up(MIN_XL_TABLET)]: {
            height: "100%",
        },
        // [theme.breakpoints.up(MIN_XL_TABLET)]: {
        //     height: 380,
        // },
        // [theme.breakpoints.up(MIN_CHANGE_SCREEN_WIDTH)]: {
        //     height: 400,
        // },
        "&.darkBlue": {
            background: "#111427",
            outline: "0.5px solid #3E466C",
            boxShadow: "0px 0px 30px rgba(117, 117, 117, 0.7)",
        },
        "&.darkGreen": {
            background: "#001312",
            outline: "0.5px solid #1C6871",
            // boxShadow: '15px 20px 18px rgba(0, 0, 0, 0.5)',
            boxShadow: "0px 0px 30px rgba(117, 117, 117, 0.7)",
        },
        "&.deepBlack": {
            background: "none",
            // outline: "0.5px solid #3E466C",
            // boxShadow: "0px 0px 30px rgba(117, 117, 117, 0.7)",
        },
        "&.mobileType": {
            height: "100%",
            boxShadow: "0px 0px 0px rgba(117, 117, 117, 0.0)",
            outline: "0px solid #1C6871",
            // background: appColors.deepBlack,
            // boxShadow: "0px 0px 30px rgba(117, 117, 117, 0.7)",
        },
    },

    hideChatButton: {
        display: "flex",
        width: 30,
        height: 50,
        border: "1px solid gray",
        bottom: "80px",
        right: "-30px",
        position: "absolute",
        zIndex: 2,
        alignItems: "center",
        justifyContent: "center",
        transform: "translate(0%, 0%) rotateY(180deg)",
        transition: "transform 700ms ease-out",
        transform: "rotateY(180deg)",
        background: "linear-gradient(35deg, #36363669 20%, #79797975 80%)",
        borderRadius: "0px",
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
        "&.mobileType": {
            "&.open": {
                transform: "translate(-100%, 0%) rotateY(180deg)",
                transition: "transform 700ms ease-out",
                right: "-30px",
                position: "absolute",
                borderRadius: "10px",
            },
            "&.hide": {
                transform: "translate(0%, 0%) rotateY(180deg)",
                transition: "transform 700ms ease-out",
                right: "-30px",
                position: "absolute",
                borderRadius: "0px",
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
            },
        },
        "&.desktopType": {
            display: "none",
        },
        "&.deepBlack": {
            display: "none",
        },
    },

    messagesBox: {
        height: "100%",
        flexGrow: 1000,
        overflow: "hidden",
        position: "relative",
        display: "flex",
        "&.fastGame": {
            flexDirection: "column",
        },
    },
    messagesBoxContainer: {
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column-reverse",
        overflow: "hidden auto",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        /* width */
        "&::-webkit-scrollbar": {
            width: 4,
        },
        /* Track */
        "&::-webkit-scrollbar-track": {
            background: "transparent",
        },
        /* Handle */
        "&::-webkit-scrollbar-thumb": {
            background: "#0A0817",
            borderRadius: 8,
        },
        /* Handle on hover */
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#0A0817",
            borderRadius: 8,
        },
        "&.fastGame": {
            flexDirection: "column",
            top: "55px",
            height: "calc(100% - 55px)",
            maskImage: "linear-gradient(to bottom,black 93%,transparent 100%)",
            gridGap: "3px",
        },
    },

    boxChatShadow: ({ shouldHide, appearanceTheme }) => ({
        position: "absolute",
        height: shouldHide ? "calc(100% - 50px)" : "fit-content",
        width: shouldHide ? "100%" : "fit-content",
        top: "50px",
        right: 0,
        borderRadius: 10,
        zIndex: 1,
        "&.darkBlue": {
            background: shouldHide ? "rgba(34,38,61,1)" : "none",
            zIndex: shouldHide ? 2 : 1,
        },
        "&.darkGreen": {
            background: shouldHide ? "rgba(0,0,0,1)" : "none",
            zIndex: shouldHide ? 2 : 1,
        },
        ...(appearanceTheme === EColor.deepBlack && {
            background: shouldHide ? "black" : "none",
            zIndex: shouldHide ? 2 : 1,
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
        }),
        "&.mobileType": {
            display: "none",
        },
    }),

    eyeButton: {
        position: "absolute",
        top: 8,
        right: 8,
        background: "transparent",
        border: "none",
        outline: 0,
        cursor: "pointer",
        color: "#A8B1CE",
        [theme.breakpoints.up(1800)]: {
            transform: "scale(1.5)",
            top: 12,
            right: 12,
        },
    },

    opponentAbsoluteComponent: {
        position: "absolute",
        top: 8,
        right: 15,
        background: "transparent",
        border: "",
        outline: 0,
        cursor: "pointer",
        color: "#A8B1CE",
    },

    badgeNotifications: {
        "&.MuiBadge-root > span": {
            margin: 0,
            padding: 0,
            outline: "0.5px solid #ffffff47",
            background:
                "linear-gradient(19.92deg, rgb(34, 13, 85) 13.3%, rgb(108, 80, 200) 86.7%)",
            [theme.breakpoints.up("xs")]: {
                transform: "translate(50%, -15%)",
                maxWidth: 16,
                minWidth: 16,
                maxHeight: 16,
                minHeight: 16,
            },
        },
        "&.mobileToggler": {
            "&.MuiBadge-root > span": {
                [theme.breakpoints.up("xs")]: {
                    transform: "translate(-100%, -50%)",
                    maxWidth: 12,
                    minWidth: 12,
                    maxHeight: 12,
                    minHeight: 12,
                },
            },
        },
        "&.badgeEye": {
            "&.MuiBadge-root > span": {
                [theme.breakpoints.up("xs")]: {
                    transform: "translate(50%, -25%)",
                    maxWidth: 10,
                    minWidth: 10,
                    maxHeight: 10,
                    minHeight: 10,
                },
            },
        },
        "&.open": {
            transform: "translate(85%, -47%) rotateY(0deg)",
            transition: "transform 300ms ease-out",
        },
        "&.hide": {
            transform: "translate(85%, -47%) rotateY(180deg)",
            transition: "transform 300ms ease-out",
        },
        "&.emptyNote": {
            "&.MuiBadge-root > span": {
                display: "none",
            },
        },
        "&.moreThan99": {
            "&.MuiBadge-root > span": {
                fontSize: "7px",
            },
        },
    },

    watchersTab: {},

    watchingTabContainer: {
        height: "auto",
        padding: 0,
        width: "100%",
        position: "relative",
        overflow: "hidden",
    },

    chatElementsWrapper: {
        justifyContent: "flex-start",
        alignItems: "center",
        maxHeight: "45px !important",
        minHeight: "45px !important",
        margin: "5px 0px",
        flexGrow: 1,
        // maskImage: "linear-gradient(to right,black 88%,transparent 100%)",
        // "&.finalBox": {
        //     maskImage: "linear-gradient(to left,black 88%,transparent 100%)",
        // },
        // TODO: Надо вынести общие стили, много повторяющихся стилей
        "&.darkBlue": {
            "& .MuiTabs-flexContainer": {
                justifyContent: "space-evenly",
                alignItems: "center",
            },
            "& .MuiTab-root": {
                ...chatTabWrapperRoot,
            },

            "& .MuiTabs-indicator": {
                background: "none",
            },
            "& .Mui-selected": {
                transform: "scale(1.1)",
                opacity: 1,
                transition: "all ease-in 300ms",
                color: `${appColors.white} !important`,
            },
            [theme.breakpoints.up(1800)]: {
                "& .MuiTabs-flexContainer": {
                    justifyContent: "space-evenly",
                    alignItems: "center",
                },
                "& .MuiTab-root": {
                    fontSize: "14px",
                    fontWeight: "700",
                    color: appColors.white,
                    borderRadius: "8px",
                    maxHeight: "45px",
                    minHeight: "34px",
                    minWidth: "30px",
                    opacity: 0.75,
                    transition: "opacity ease-out 300ms",
                },
                "& .MuiTabs-indicator": {
                    background: "none",
                },
                "& .Mui-selected": {
                    // background: '#27475f',
                    // color: `${appColors.white} !important`,
                    transform: "scale(1.1)",
                    opacity: 1,
                    transition: "all ease-in 300ms",
                    color: `${appColors.white} !important`,
                },
                [theme.breakpoints.up(1800)]: {
                    "& .MuiTab-root": {
                        transform: "scale(1.1)",
                    },
                    "& .MuiTabs-indicator": {
                        background: "none",
                    },
                    "& .Mui-selected": {
                        transform: "scale(1)",
                    },
                },
            },
        },
        "&.darkGreen": {
            "& .MuiTabs-flexContainer": {
                justifyContent: "space-evenly",
                alignItems: "center",
            },
            "& .MuiTab-root": {
                ...chatTabWrapperRoot,
            },
            "& .MuiTabs-indicator": {
                background: "none",
            },
            "& .Mui-selected": {
                // background: '#144542',
                // color: `${appColors.white} !important`,
                opacity: 1,
                transform: "scale(1.1)",
                transition: "all ease-in 300ms",
                color: `${appColors.white} !important`,
            },
            [theme.breakpoints.up(1800)]: {
                "& .MuiTab-root": {
                    transform: "scale(1.1)",
                },
                "& .MuiTabs-indicator": {
                    background: "none",
                },
                "& .Mui-selected": {
                    transform: "scale(1)",
                },
            },
        },
        "&.deepBlack": {
            outline: "1px solid rgba(255, 255, 255, 0.3)",
            borderRadius: "12px",
            margin: "5px 10px",
            "& .MuiTabs-flexContainer": {
                justifyContent: "space-evenly",
                alignItems: "center",
            },
            "& .MuiTab-root": {
                ...chatTabWrapperRoot,
            },
            "& .MuiTabs-indicator": {
                background: "none",
            },
            "& .Mui-selected": {
                opacity: 1,
                transition: "opacity ease-in 100ms",
                color: `${appColors.white} !important`,
                background: appColors.bg200,
            },
            "&. .MuiTab-iconWrapper": {
                marginRight: "0px",
            },
            "& .MuiTab-root > img": {
                marginRight: "2px",
            },
            [theme.breakpoints.up("lg")]: {
                outline: "0px solid rgba(255, 255, 255, 0.3)",
                borderRadius: "0px",
                margin: 0,
            },
            [theme.breakpoints.up(1800)]: {
                outline: "1px solid rgba(255, 255, 255, 0.3)",
                borderRadius: "12px",
                marginBottom: "10px",
                minHeight: "56px !important",
                "& .MuiTabs-flexContainer": {
                    justifyContent: "space-evenly",
                    alignItems: "center",
                },
                "& .MuiTab-root": {
                    ...chatTabWrapperRoot,
                    minWidth: "23%",
                    padding: 0,
                },

                "& .MuiTabs-indicator": {
                    background: "none",
                },
                "& .Mui-selected": {
                    transform: "scale(1)",
                    opacity: 1,
                    transition: "all ease-in 300ms",
                    color: `${appColors.white} !important`,
                    backgoround: appColors.bg200,
                },
                "& .MuiTab-root > img": {
                    marginRight: "0px",
                    width: "22px",
                    height: "22px",
                },
            },
        },
    },

    chatElementEye: {
        "&.deepBlack": {
            ...chatTabWrapperRoot,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&.shouldHide": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: 1,
                transition: "opacity ease-in 100ms",
                color: `${appColors.white} !important`,
                background: appColors.bg200,
            },
            [theme.breakpoints.down(MIN_XL_TABLET)]: {
                display: "none",
            },
            [theme.breakpoints.up(1800)]: {
                minWidth: "23%",
            },
        },
        [theme.breakpoints.down(MIN_XL_TABLET)]: {
            display: "none",
        },
    },

    stickersListWrapper: ({ stickers }) => ({
        width: "100%",
        transform: "translate(-0%, 0%)",
        overflowY: "scroll",
        padding: 0,
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(330px, 1fr))",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        opacity: 1,
        maxHeight: 250,
        height: stickers.length > 1 ? "100%" : "fit-content",
        minHeight: stickers.length > 1 ? 160 : 125,
        "&.open": {
            height: stickers.length > 1 ? "100%" : "fit-content",
            minHeight: stickers.length > 1 ? 160 : 125,
            animationName: "stickersWrapperOpen",
            animationDelay: "0s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "600ms",
            maskImage: "linear-gradient(to bottom,black 95%,transparent 100%)",
        },
        "&.hide": {
            height: stickers.length > 1 ? "100%" : "fit-content",
            minHeight: stickers.length > 1 ? 160 : 125,
            animationName: "stickersWrapperHide",
            animationDelay: "0s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "400ms",
        },
    }),

    stickersList: {
        display: "flex",
        width: "inherit",
        height: "inherit",
        gridGap: "5px",
        overflow: "scroll",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        "-ms-overflow-style": "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
            width: "0!important",
        },
    },

    stickersImg: {
        cursor: "pointer",
        width: "45px",
        height: "45px",
        transform: "scale(1)",
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
            transform: "scale(1.1)",
            transition: "transform 0.3s ease-in-out",
        },
        [theme.breakpoints.between("lg", MAX_CHANGE_SCREEN_WIDTH)]: {
            width: "40px",
            height: "40px",
        },
    },

    textAreaBox: {
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderRadius: "12px",
        border: "1px solid rgba(255, 255, 255, 0.3)",
    },
});
