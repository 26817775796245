import React from "react";
import cn from "classnames";

import TimeIcon from "src/components/TimeIcon";
import { useLocale } from "src/providers/LocaleProvider";
import coinIcon from "src/assets/icons/coin.svg";
import freeCoinIcon from "src/assets/icons/freeCoin.png";
import { RoundsPreview } from "src/shared/ui/RoundsPreview";

import { UserBalanceType } from "../../../../store/models";

import { useStyles } from "./styles";

interface Props {
    timeControl: {
        time: number;
        increment: number;
    };
    bet: number;
    type: UserBalanceType;
    rounds: number;
}

const TimeAndBetPreview = ({ timeControl, bet, rounds, type }: Props) => {
    const {
        lobby: {
            gameType: { timeDetails },
        },
    } = useLocale();
    const styles = useStyles();

    return (
        <div
            className={cn(styles.wrapper, {
                [styles.includeRounds]: rounds > 1,
            })}
        >
            <div className={styles.item}>
                <img
                    src={
                        type === UserBalanceType.play ? coinIcon : freeCoinIcon
                    }
                    alt="Bet icon"
                    className={styles.icon}
                />
                <span className={styles.betText}>{bet}</span>
            </div>
            <div className={styles.item}>
                <TimeIcon time={timeControl.time} />
                <span className={styles.timeDetails}>
                    {timeDetails.compile(
                        {
                            time: timeControl.time,
                            increment: timeControl.increment,
                        },
                        `${timeControl.time} min + ${timeControl.increment} sec`
                    )}
                </span>
            </div>
            {rounds > 1 && (
                <div className={styles.item}>
                    <RoundsPreview value={rounds} />
                </div>
            )}
        </div>
    );
};

export default TimeAndBetPreview;
