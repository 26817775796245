import { Box } from "@mui/material";
import cn from "classnames";
import React, { useMemo, memo } from "react";
import { useLocation } from "react-router-dom";

import { UserBalanceType } from "src/store/models";
import paths from "src/consts/paths";
import useStores from "src/hooks/useStores";

import { useStyles } from "./styles";

type BurgerMenuProps = {
    onClick: () => void;
    sideMenuIsOpen: boolean;
};

const BurgerMenu = ({ onClick, sideMenuIsOpen }: BurgerMenuProps) => {
    const styles = useStyles();
    const { lobbyStore, tournamentsStore } = useStores();
    const currentLocation = useLocation();
    const activeLobbyGames = lobbyStore.getActiveGames();

    const showReturnToTournament = tournamentsStore.hasActiveGames;

    const isCashBattle = useMemo(() => {
        if (activeLobbyGames) {
            return activeLobbyGames.filter(
                (game) => game.balanceType === UserBalanceType.play
            );
        }
    }, [activeLobbyGames]);

    const isFreePlay = useMemo(() => {
        if (activeLobbyGames) {
            return activeLobbyGames.filter(
                (game) => game.balanceType === UserBalanceType.coins
            );
        }
    }, [activeLobbyGames]);

    const isCashBattleDot = useMemo(() => {
        if (!isCashBattle) return false;
        return (
            activeLobbyGames &&
            activeLobbyGames?.length > 0 &&
            !!isCashBattle.length &&
            !currentLocation.pathname.includes(isCashBattle[0].id) &&
            !currentLocation.pathname.includes(paths.diceChessLobby)
        );
    }, [activeLobbyGames, isCashBattle]);

    const isFreePlayDot = useMemo(() => {
        if (!isFreePlay) return false;
        return (
            activeLobbyGames &&
            activeLobbyGames?.length > 0 &&
            !!isFreePlay.length &&
            !currentLocation.pathname.includes(isFreePlay[0].id) &&
            !currentLocation.pathname.includes(paths.lobbyFreePlay)
        );
    }, [activeLobbyGames, isFreePlay]);

    // Maybe we should move this function into separate module/hook and use it?
    const isTournamentDot = useMemo(() => {
        const isNotInGamePage = tournamentsStore.myGamesList.every(
            (g) => !currentLocation.pathname.includes(g?.id)
        );
        return (
            showReturnToTournament &&
            !isNotInGamePage &&
            !currentLocation.pathname.includes(paths.tournaments)
        );
    }, [showReturnToTournament, tournamentsStore.myGamesList]);

    return (
        <>
            <Box
                component="div"
                className={styles.wrapperBurger}
                onClick={() => {
                    onClick();
                }}
            >
                <Box
                    component="div"
                    className={cn(styles.burgerLine, "line1", {
                        line1Open: sideMenuIsOpen,
                    })}
                    key={"wrapperBurger_1"}
                />
                <Box
                    component="div"
                    className={cn(styles.burgerLine, "line2", {
                        line2Open: sideMenuIsOpen,
                    })}
                    key={"wrapperBurger_2"}
                />
                <Box
                    component="div"
                    className={cn(styles.burgerLine, "line3", {
                        line3Open: sideMenuIsOpen,
                    })}
                    key={"wrapperBurger_3"}
                />
                {(isCashBattleDot || isTournamentDot) && (
                    <div className={cn(styles.gameInfoDot, {})}></div>
                )}
            </Box>
        </>
    );
};

export default memo(BurgerMenu);
