import React from 'react';
import cn from 'classnames';

import useChangeTheme from 'src/hooks/useChangeTheme';

import { useStyles } from './styles';

export const AMOUNT_COLORS = {
    GREEN: 'linear-gradient(45deg, #298671 0%, #2AE7BD 70%)',
    WHITE: 'linear-gradient(0deg, #A1A1A1 0%, #FFFFFF 50%, #A9A9A9 90%)',
    RED: 'linear-gradient(30deg, #8A2F2F 0%, #BD4A4A 40%, #FF3636 80%)',
};
export const TITLE_COLORS = {
    GOLDEN: 'linear-gradient(0deg, #E08632 0%, #F8BF44 70%)',
    WHITE: 'linear-gradient(0deg, #A1A1A1 0%, #FFFFFF 50%, #A9A9A9 90%)',
};

interface Props {
    icon: JSX.Element;
    title: string;
    message: string;
    amount: string;
    amountColor: string;
    titleColor: string;
    titleFontSize: number;
}

const defaultProps: Pick<
    Props,
    'amountColor' | 'titleColor' | 'titleFontSize'
> = {
    amountColor: AMOUNT_COLORS.RED,
    titleColor: TITLE_COLORS.GOLDEN,
    titleFontSize: 16,
};

const DialogContent = (props: Partial<Props>) => {
    const t = {
        ...defaultProps,
        ...props,
    };
    const {
        icon,
        title,
        message,
        amount,
        amountColor,
        titleColor,
        titleFontSize,
    } = t;

    const classes = useStyles({
        amountColor,
        titleColor,
        titleFontSize,
    });
    const appearanceTheme = useChangeTheme();

    return (
        <div className={cn(classes.root, appearanceTheme)}>
            <div className={classes.icon}>{icon}</div>
            <div className={classes.messageBlock}>
                <div className={classes.title}>{title}</div>
                <div
                    className={classes.message}
                    style={{ display: message ? '' : 'none' }}
                >
                    {message}
                </div>
                <div className={classes.amount}>{amount}</div>
            </div>
        </div>
    );
};

export default DialogContent;
