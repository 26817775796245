import "react-image-lightbox/style.css";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/scrollbar";

import { StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import { CookiesProvider } from "react-cookie";
import { isAndroid, isMobileSafari } from "react-device-detect";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { BrowserRouter, Route, Switch, useParams } from "react-router-dom";

import LandscapeBlocker from "./components/LandscapeBlocker";
import loadableWithFallback from "./components/LoadableWithFallback";
import Wrapper from "./components/wrapper";
import paths from "./consts/paths";
import useStores from "./hooks/useStores";
import { InfoMessage } from "./pages/internet-speed/types";
import NewLobby from "./pages/lobbyNew";
import MainSocket from "./socket/main-socket";
import TournamentSocket from "./socket/tournament-socket";
import TournamentBracketSocket from "./socket/tournamentBracket-socket";
import TournamentModalSocket from "./socket/tournamentModal-socket";
import theme from "./theme";
import SnowFallContainer from "./components/NewYearSnowFall";

// import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';

const Lobby = loadableWithFallback(() => import("./pages/lobby"));

Lobby.preload();

const Profile = loadableWithFallback(() => import("./pages/profile"));
const GamesHistory = loadableWithFallback(
    () => import("./pages/games-history")
);
const Game = loadableWithFallback(() => import("./pages/game-new"), undefined, {
    isGamePage: true,
});

Game.preload();

// const GameNewDesign = loadableWithFallback(
//     () => import("./pages/game-new2"),
//     undefined,
//     {
//         isGamePage: true,
//     }
// );

// GameNewDesign.preload;

import GameNewDesign from "./pages/game-new2";

const ViewHistoryGame = loadableWithFallback(
    () => import("./pages/viewHistoryGame")
);
const TournamentsPage = loadableWithFallback(
    () => import("./pages/tournaments")
);
const BracketContainer = loadableWithFallback(
    () => import("./pages/tournaments/components/bracketContainer")
);
const Logout = loadableWithFallback(() => import("./pages/logout"));
const RecoveryPassword = loadableWithFallback(
    () => import("./pages/recovery-password")
);
const GamesRules = loadableWithFallback(() => import("./pages/games-rules"));
const Games = loadableWithFallback(() => import("./pages/games"));
const PWAInstruction = loadableWithFallback(() => import("./pages/iosPWA"));
const LiveDealerGame = loadableWithFallback(
    () => import("./pages/live-dealer/pages/game")
);
const LiveDealer = loadableWithFallback(() => import("./pages/live-dealer"));
const AltenteGamePage = loadableWithFallback(
    () => import("./pages/altente/gamePage")
);
const AltenteLobby = loadableWithFallback(
    () => import("./pages/altente/lobby")
);
const ProfileForReferrals = loadableWithFallback(
    () => import("./pages/profileForReferrals")
);
const Jackpoker = loadableWithFallback(() => import("./pages/jackpoker"));
const Bettings = loadableWithFallback(() => import("./pages/betting"));
const Faq = loadableWithFallback(() => import("./pages/faq"));
const Cashbox = loadableWithFallback(() => import("./pages/cashbox"));
const ResetPassword = loadableWithFallback(
    () => import("./pages/reset-password")
);
const PolicyNew = loadableWithFallback(() => import("./pages/policy/indexNew"));
const AboutUsNew = loadableWithFallback(
    () => import("./pages/about-us/indexNew")
);
const ResponsibleGaming = loadableWithFallback(
    () => import("./pages/responsible-gaming")
);
const ResponsibleGamingNew = loadableWithFallback(
    () => import("./pages/responsible-gaming/indexNew")
);
const ContactUs = loadableWithFallback(() => import("./pages/contact-us"));
const TermAndConditions = loadableWithFallback(
    () => import("./pages/term-and-conditions")
);
const TermAndConditionsNew = loadableWithFallback(
    () => import("./pages/term-and-conditions/indexNew")
);
const PrivacyPolicy = loadableWithFallback(
    () => import("./pages/privacy-policy")
);
const PrivacyPolicyNew = loadableWithFallback(
    () => import("./pages/privacy-policy/indexNew")
);
const WithdrawalPolicyAndRefundPolicy = loadableWithFallback(
    () => import("./pages/withdrawal-policy-and-refund-policy")
);

const TypedDndProvider = DndProvider as any;

function App() {
    const { generalStore } = useStores();

    const hasNative =
        document &&
        //@ts-ignore
        (document.elementsFromPoint || document.msElementsFromPoint);

    function getDropTargetElementsAtPoint(x, y, dropTargets) {
        return dropTargets.filter((t) => {
            const rect = t.getBoundingClientRect();
            return (
                x >= rect.left &&
                x <= rect.right &&
                y <= rect.bottom &&
                y >= rect.top
            );
        });
    }

    window.onblur = function () {
        generalStore.setApplicationStatus(false);
    };

    window.onfocus = function () {
        if (
            generalStore.internetStatus === InfoMessage.connected ||
            generalStore.internetStatus === null
        ) {
            generalStore.setApplicationStatus(true);
        }
    };

    const iOSSafariOptions = {
        getDropTargetElementsAtPoint:
            !hasNative && getDropTargetElementsAtPoint,
    };

    const desktopOptions = {
        enableTouchEvents: false,
        enableMouseEvents: true,
    };

    const isMobile = isAndroid || isMobileSafari;

    const options = !isMobile
        ? desktopOptions
        : isMobileSafari
        ? iOSSafariOptions
        : {};
    return (
        <Sentry.ErrorBoundary>
            {/* <FpjsProvider
                loadOptions={{
                    apiKey: '9Cfs6WBABEgoq1lvEGY7',
                    region: 'eu',
                }}
            > */}
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <MainSocket />
                    {/* <SnowFallContainer /> */}
                    <TournamentSocket />
                    <TournamentBracketSocket />
                    <TournamentModalSocket />
                    <CookiesProvider>
                        <TypedDndProvider
                            options={options}
                            backend={TouchBackend}
                        >
                            <Switch>
                                <StyledEngineProvider injectFirst>
                                    <Wrapper>
                                        <LandscapeBlocker>
                                            <Route
                                                path="/lobby"
                                                exact
                                                component={NewLobby}
                                            />
                                            <Route
                                                path="/profile"
                                                exact
                                                component={Profile}
                                            />
                                            <Route
                                                path="/referral-program"
                                                exact
                                                component={ProfileForReferrals}
                                            />
                                            <Route
                                                path="/cashbox"
                                                exact
                                                component={Cashbox}
                                            />
                                            {/*  <Route
                                                path='/create-fen'
                                                exact
                                                component={CreateFen}
                                            />*/}

                                            <Route
                                                path="/support"
                                                exact
                                                component={ContactUs}
                                            />
                                            <Route
                                                path="/policy"
                                                exact
                                                component={PolicyNew}
                                            />
                                            <Route
                                                path="/about-us"
                                                exact
                                                component={AboutUsNew}
                                            />
                                            <Route
                                                path="/responsible-gaming"
                                                exact
                                                component={ResponsibleGamingNew}
                                            />
                                            <Route
                                                path="/games-rules"
                                                exact
                                                component={GamesRules}
                                            />
                                            <Route
                                                path="/term-and-conditions"
                                                exact
                                                component={TermAndConditionsNew}
                                            />
                                            <Route
                                                path="/privacy-policy"
                                                exact
                                                component={PrivacyPolicyNew}
                                            />
                                            <Route
                                                path="/withdrawal-policy-and-refund-policy"
                                                exact
                                                component={
                                                    WithdrawalPolicyAndRefundPolicy
                                                }
                                            />

                                            {/* <Route
                                                path='/login'
                                                exact
                                                component={Login}
                                            /> */}
                                            <Route
                                                path="/logout"
                                                exact
                                                component={Logout}
                                            />
                                            {/* <Route
                                                path='/register'
                                                exact
                                                component={RegisterForm}
                                            /> */}
                                            <Route
                                                path="/recovery-password"
                                                exact
                                                component={RecoveryPassword}
                                            />
                                            <Route
                                                path="/reset-password/:token"
                                                exact
                                                component={ResetPassword}
                                            />

                                            <Route
                                                path="/diceChessLobby"
                                                exact
                                                component={Lobby}
                                            />
                                            <Route
                                                path="/lobby-free-play"
                                                exact
                                                component={Lobby}
                                            />

                                            <Route
                                                path="/games-history"
                                                exact
                                                component={GamesHistory}
                                            />
                                            {/* <Route
                                                path="/game/:id"
                                                exact
                                                component={Game}
                                            /> */}
                                            <Route
                                                path="/games"
                                                exact
                                                component={Games}
                                            />
                                            <Route
                                                path="/faq"
                                                exact
                                                component={Faq}
                                            />
                                            <Route
                                                path="/login"
                                                exact
                                                component={NewLobby}
                                            />
                                            <Route
                                                path="/tournaments"
                                                exact
                                                component={TournamentsPage}
                                            />
                                            <Route
                                                path="/tournaments/:id"
                                                exact
                                                component={BracketContainer}
                                            />
                                            <Route
                                                path={paths.algaltente}
                                                exact
                                                component={AltenteLobby}
                                            />
                                            <Route
                                                path="/slots/:tableId/play"
                                                exact
                                                component={AltenteGamePage}
                                            />
                                            <Route
                                                path="/live-dealer"
                                                exact
                                                component={LiveDealer}
                                            />
                                            <Route
                                                path="/live-dealer/:tableId/play"
                                                exact
                                                component={LiveDealerGame}
                                            />
                                            <Route
                                                path="/poker"
                                                exact
                                                component={Jackpoker}
                                            />
                                            <Route
                                                path="/sports"
                                                exact
                                                component={Bettings}
                                            />
                                            <Route
                                                path="/game/history/view/:id"
                                                exact
                                                component={ViewHistoryGame}
                                            />
                                            <Route
                                                path="/PWA-instruction"
                                                exact
                                                component={PWAInstruction}
                                            />
                                            <Route
                                                path="/game/:id"
                                                exact
                                                component={GameNewDesign}
                                            />
                                        </LandscapeBlocker>
                                    </Wrapper>
                                </StyledEngineProvider>
                            </Switch>
                        </TypedDndProvider>
                    </CookiesProvider>
                </BrowserRouter>
            </ThemeProvider>
            {/* </FpjsProvider> */}
        </Sentry.ErrorBoundary>
    );
}

export default App;
