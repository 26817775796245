import loadable from "@loadable/component";
import { Box, useMediaQuery } from "@mui/material";
import Badge from "@mui/material/Badge";
import cn from "classnames";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { isAndroid, isIOS, isSafari } from "react-device-detect";
import GifPlayer from "react-gif-player";
import { NavLink, useHistory, useLocation } from "react-router-dom";

import virtualSportIcon from "src/assets/icons/virtualSports.png";
import pockerSlot from "src/assets/images/pockerSlot.png";
import sportSlot from "src/assets/images/sportSlot.png";
import slot1 from "src/assets/images/slot1.png";
import slot2 from "src/assets/images/slot2.png";
import slot3 from "src/assets/images/slot3.png";
import casino1 from "src/assets/images/casino1.png";
import casino2 from "src/assets/images/casino2.png";
import casino3 from "src/assets/images/casino3.png";
import cardIcon from "src/assets/icons/cardsIcon.svg";
import diceChessIcon from "src/assets/icons/diceChessIcon.svg";
import rouletteIcon from "src/assets/icons/rouletteIcon.svg";
import slotsIcon from "src/assets/icons/slotsIcon.svg";
import sportIcon from "src/assets/icons/sportIcon.svg";
import LogoIcon from "src/components/controls/app-bar/images/logo.png";
import { useLocale } from "src/providers/LocaleProvider";
import { GameTypes, UserBalanceType } from "src/store/models";
import lightIcon from "src/pages/lobbyNew/assets/backgrounds/backgroundBlur.svg";
import blurOnHover from "src/pages/lobbyNew/assets/backgrounds/backgroundBlurWild.svg";
import SocialLinks from "src/components/SocialLinksBox";
import { ESocial } from "src/components/SideMenu";
import YoutubeIconGenerator from "src/assets/icons/social/youtubeIcon";
import DiscordIconGenerator from "src/assets/icons/social/discordIcon";
import { MainActionButton } from "src/components/buttons/newDesignButtons/mainActionButton";
import { Footer } from "src/components/wrapper/footer/index";
import AppBar from "src/components/controls/app-bar/index";
import { getLiveDealers, getSlots } from "src/service/api/externalGames/index";
import chessVideo from "src/assets/chessVideo.mp4";
import leftArrowIcon from "src/assets/icons/ArrowLeft.svg";

import { Avatar } from "../../components/Avatar";
import Balance from "../../components/balance";
import { ButtonType, GreenButton } from "../../components/buttons/greenButton";
import LanguagePicker from "../../components/controls/app-bar/components/LanguagePicker";
import paths from "../../consts/paths";
import { endpointsRoot } from "../../core/endpoints";
import LoginForm from "../../dialogs/login/LoginForm";
import useStores from "../../hooks/useStores";
import { errorService } from "../../service/services";
import { ModalProvider, ModalTypes } from "../landing/utils/context";
import { RegisterForm } from "../register/RegisterForm";
import {
    IGetLiveDealerGameResponse,
    IGetSlotsResponse,
} from "../../entities/externalGame";
import { fileHelper } from "../../shared/helpers/FileHelper";

import mainDice from "./assets/Cube1PD.gif";
import mainDiceWebm from "./assets/Cube1PD.webm";
import leftDice from "./assets/Cube2PD.gif";
import leftDiceWebm from "./assets/Cube2PD.webm";
import rightDice from "./assets/Cube3PD.gif";
import rightDiceWebm from "./assets/Cube3PD.webm";
import backgroundOld from "./assets/backgrounds/background.webp";
import background from "./assets/backgrounds/backgroundNew.webp";
import businessIcon from "./assets/briefcase.svg";
import mainDiceImg from "./assets/img.png";
import leftDiceImg from "./assets/img_1.png";
import rightDiceImg from "./assets/img_2.png";
import phoneIcon from "./assets/smartphone.svg";
import MobileVersion from "./components/Mobile/mobileVersion";
import BannerContainer from "./components/banners";
import { useStyles as useLobbyStyles, useStyles } from "./styles";
const BusinessModal = loadable(() => import("./components/BusinessModal"));
const Notifications = loadable(() => import("src/components/Notifications"));
const StreamAnnounceContainer = loadable(
    () => import("./components/StreamAnnounceContainer")
);
// TODO: refactor
const NewLobbyPage = () => {
    const isNotMobile = useMediaQuery("(min-width: 1025px)");
    const history = useHistory();
    const { authStore, generalStore, lobbyStore, generalLobbyStore } =
        useStores();
    const user = authStore.currentUser;

    const {
        mainLanding: {
            balance: { errorOnSaveState },
            register,
            logIn,
            poker,
            sport,
            slots,
            liveDealer,
            app,
            era,
            skillGames,
            cashBattle,
            tournaments,
        },
    } = useLocale();

    const [isMobile, setIsMobile] = useState<boolean>(false);

    const checkIsMobile = () => {
        let regMobile =
            /iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
        regMobile || window.innerWidth <= 450
            ? setIsMobile(true)
            : setIsMobile(false);
    };

    useEffect(() => {
        checkIsMobile();
    }, [window.innerWidth]);

    useEffect(() => {
        if (authStore.isAuthorized && generalStore.isAggregatorMode) {
            history.push(paths.diceChessLobby);
        }
    }, [authStore.isAuthorized]);

    const [modalState, setModalState] = useState({
        register: false,
        login: false,
        businessMsg: false,
        notifications: false,
    });

    const openModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: true }));
    };

    const closeModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: false }));
    };

    const ratio = window.innerWidth / window.innerHeight;
    const styles = useStyles({ ratio });

    // Данные для стримов
    const streamChannels = [
        {
            name: "Alexandra Chess",
            imageUrl: "src/assets/images/slot1.png",
            isOnline: true,
        },
        {
            name: "Veronica White",
            imageUrl: "src/assets/images/slot1.png",
            isOnline: true,
        },
        {
            name: "Paul Whisper",
            imageUrl: "src/assets/images/slot1.png",
            isOnline: true,
        },
        {
            name: "ChessTV",
            imageUrl: "src/assets/images/slot1.png",
            isOnline: true,
        },
        {
            name: "Angelina1994",
            imageUrl: "https://example.com/angelina1994.jpg",
            isOnline: false,
        },
        {
            name: "Tim Tomson",
            imageUrl: "https://example.com/tim-tomson.jpg",
            isOnline: false,
        },
    ];

    //Тест для игр
    const [slotsItems, setSlots] = useState<IGetSlotsResponse[]>([]);
    const [liveDealers, setLiveDealers] = useState<
        IGetLiveDealerGameResponse[]
    >([]);

    useEffect(() => {
        const fetchSlots = async () => {
            try {
                const response = await getSlots({
                    isDesktop: true,
                    limit: 21,
                    offset: 0,
                });
                setSlots(response.data);
            } catch (error) {
                console.error("Error fetching slots:", error);
            }
        };

        const fetchLiveDealers = async () => {
            try {
                const response = await getLiveDealers({
                    isDesktop: true,
                    limit: 21,
                    offset: 0,
                });
                setLiveDealers(response.data);
            } catch (error) {
                console.error("Error fetching live dealers:", error);
            }
        };

        fetchSlots();
        fetchLiveDealers();
    }, []);

    //Карусель спорт
    const [currentIndex, setCurrentIndex] = useState(0);
    const images = [
        { src: "image1.jpg", alt: "Image 1" },
        { src: "image2.jpg", alt: "Image 2" },
        { src: "image3.jpg", alt: "Image 3" },
    ];

    return (
        <>
            {!isNotMobile ? (
                <>
                    <MobileVersion />
                </>
            ) : (
                <ModalProvider.Provider value={{ openModal, closeModal }}>
                    {modalState.register && <RegisterForm />}
                    {modalState.login && <LoginForm />}
                    {modalState.businessMsg && <BusinessModal />}
                    {modalState.notifications && <Notifications />}

                    <Box component="div" className={styles.containerLobby}>
                        <AppBar isGamePage={false} sideMenuIsOpen={false} />
                        {/*<div className={styles.header}>
                                <div className={styles.appBarElement}>
                                    {authStore.isAuthorized ? (
                                        <>
                                            <Balance
                                                amount={
                                                    user?.balance[
                                                        lobbyStore.lobbyMode
                                                    ]?.available || 0
                                                }
                                                hidden={authStore.balanceHidden}
                                                backGroundColor={
                                                    authStore.currentUser
                                                        ?.settings.background ||
                                                    "blue"
                                                }
                                                onChange={onHiddenChange}
                                                type={lobbyStore.lobbyMode}
                                                onClick={() => {
                                                    generalStore.isDicechessMode &&
                                                        history.push(
                                                            paths.cashbox
                                                        );
                                                }}
                                            />
                                            <div
                                                style={{
                                                    position: "relative",
                                                }}
                                                onClick={() => {
                                                    generalLobbyStore.setOpenNotificationsModal(
                                                        true
                                                    );
                                                    openModal("notifications");
                                                }}
                                            >
                                                {user && (
                                                    <Badge
                                                        color="error"
                                                        badgeContent={
                                                            newNotifications
                                                        }
                                                        className={cn(
                                                            styles.badgeNotifications,
                                                            {
                                                                emptyNote:
                                                                    !newNotifications ||
                                                                    newNotifications <
                                                                        1,
                                                            }
                                                        )}
                                                    >
                                                        <Avatar
                                                            width={50}
                                                            nickname={
                                                                user.nickname
                                                            }
                                                            avatarUrl={`${endpointsRoot}/avatars/${user.avatar}`}
                                                        />
                                                    </Badge>
                                                )}
                                            </div>
                                            <div
                                                className={
                                                    styles.languageWrapper
                                                }
                                            >
                                                <LanguagePicker
                                                    showInDropper={true}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                className={styles.registration}
                                            >
                                                <GreenButton
                                                    type={ButtonType.primary}
                                                    text={register(
                                                        "Registration"
                                                    )}
                                                    fontSize={16}
                                                    isWidthFixed
                                                    onClick={() =>
                                                        openModal("register")
                                                    }
                                                />
                                                <GreenButton
                                                    text={logIn("Log In")}
                                                    fontSize={16}
                                                    type={ButtonType.yellow}
                                                    isWidthFixed
                                                    onClick={() =>
                                                        openModal("login")
                                                    }
                                                />

                                                <div
                                                    className={
                                                        styles.languageWrapper
                                                    }
                                                >
                                                    <LanguagePicker
                                                        showInDropper={true}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>*/}

                        <Box component="div" className={styles.main}>
                            <Box component="div" className={styles.menu}>
                                <nav className={styles.menuLinks}>
                                    {!generalStore.isHalyk && (
                                        <div
                                            className={styles.menuLink}
                                            onClick={() => {
                                                history.push(
                                                    paths.diceChessLobby
                                                );
                                            }}
                                        >
                                            <div className={styles.mainIcon}>
                                                <img
                                                    className={styles.linkIcon}
                                                    src={diceChessIcon}
                                                />
                                            </div>
                                            <p className={styles.link}>
                                                Dicechess
                                            </p>
                                            <img
                                                className={styles.blurOnHover}
                                                src={blurOnHover}
                                            />
                                        </div>
                                    )}
                                    <div
                                        className={styles.menuLink}
                                        onClick={() => {
                                            if (
                                                !authStore.checkIfUserHasAccessToSection(
                                                    GameTypes.jackpoker
                                                )
                                            )
                                                return;
                                            history.push(paths.jackpoker);
                                        }}
                                    >
                                        <div className={styles.mainIcon}>
                                            <img
                                                className={styles.linkIcon}
                                                src={cardIcon}
                                            />
                                        </div>
                                        <p className={styles.link}>
                                            {poker("Poker")}
                                        </p>
                                        <img
                                            className={styles.blurOnHover}
                                            src={blurOnHover}
                                        />
                                    </div>
                                    <div
                                        className={styles.menuLink}
                                        onClick={() => {
                                            if (
                                                !authStore.checkIfUserHasAccessToSection(
                                                    GameTypes.sports
                                                )
                                            )
                                                return;
                                            history.push(paths.sports);
                                        }}
                                    >
                                        <div className={styles.mainIcon}>
                                            <img
                                                className={styles.linkIcon}
                                                src={sportIcon}
                                            />
                                        </div>
                                        <p className={styles.link}>
                                            {sport("Sport")}
                                        </p>
                                        <img
                                            className={styles.blurOnHover}
                                            src={blurOnHover}
                                        />
                                    </div>
                                    <div
                                        className={styles.menuLink}
                                        onClick={() => {
                                            if (
                                                isIOS ||
                                                isSafari ||
                                                !authStore.checkIfUserHasAccessToSection(
                                                    GameTypes.slots
                                                )
                                            )
                                                return;
                                            history.push(paths.algaltente);
                                        }}
                                    >
                                        <div className={styles.mainIcon}>
                                            <img
                                                className={styles.linkIcon}
                                                src={slotsIcon}
                                            />
                                        </div>
                                        <p className={styles.link}>
                                            {slots("Slots")}
                                        </p>
                                        <img
                                            className={styles.blurOnHover}
                                            src={blurOnHover}
                                        />
                                    </div>
                                    <div
                                        className={styles.menuLink}
                                        onClick={() => {
                                            if (
                                                !authStore.checkIfUserHasAccessToSection(
                                                    GameTypes.liveDealer
                                                )
                                            )
                                                return;
                                            history.push(paths.liveDealer);
                                        }}
                                    >
                                        <div className={styles.mainIcon}>
                                            <img
                                                className={styles.linkIcon}
                                                src={rouletteIcon}
                                            />
                                        </div>
                                        <p className={styles.link}>
                                            {liveDealer("Live Casino")}
                                        </p>
                                        <img
                                            className={styles.blurOnHover}
                                            src={blurOnHover}
                                        />
                                    </div>
                                    <div
                                        className={styles.menuLink}
                                        onClick={() => {
                                            if (
                                                !authStore.checkIfUserHasAccessToSection(
                                                    GameTypes.liveDealer
                                                )
                                            )
                                                return;
                                            history.push(paths.liveDealer);
                                        }}
                                    >
                                        <div className={styles.mainIcon}>
                                            <img
                                                className={styles.linkIcon}
                                                src={virtualSportIcon}
                                            />
                                        </div>
                                        <p className={styles.link}>
                                            {liveDealer("Virtual Sport")}
                                        </p>
                                        <img
                                            className={styles.blurOnHover}
                                            src={blurOnHover}
                                        />
                                    </div>
                                    { !generalStore.isHalyk && <div className={styles.socialBlock}>
                                        <p className={styles.followUs}>
                                            Follow us
                                        </p>
                                        <div className={styles.socialIcons}>
                                            <SocialLinks
                                                linkTypes={ESocial.social}
                                            />
                                        </div>
                                    </div> }
                                </nav>
                            </Box>

                            <Box component="div" className={styles.content}>
                                <div className={styles.lobbyTitle}>
                                    {!generalStore.isHalyk && (
                                        <div className={styles.heroSection}>
                                            <video
                                                autoPlay
                                                muted
                                                loop
                                                playsInline
                                                className={styles.heroVideo}
                                            >
                                                <source
                                                    src={chessVideo}
                                                    type="video/mp4"
                                                />
                                            </video>
                                            <MainActionButton
                                                appearanceTheme="primary"
                                                text="I want Dicechess"
                                                className={styles.playButton}
                                                onClick={() => {
                                                    history.push(
                                                        paths.diceChessLobby
                                                    );
                                                }}
                                            />
                                        </div>
                                    )}

                                    {generalStore.isHalyk && <div className={styles.underHeroContentHalyk}>Video Loading...</div>}



                                    {!generalStore.isHalyk && <div className={generalStore.isHalyk ? styles.underHeroContentHalyk : styles.underHeroContent}>
                                        <div className={styles.pockerSlots}>
                                            <img
                                                src={pockerSlot}
                                                alt="Pocker Slots"
                                                className={styles.pockerImage}
                                            />
                                            <MainActionButton
                                                appearanceTheme="primary"
                                                text="Poker"
                                                className={styles.button}
                                                onClick={() => {
                                                    history.push(
                                                        paths.jackpoker
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className={styles.pockerSlots}>
                                            <img
                                                src={sportSlot}
                                                alt="Sport Slots"
                                                className={styles.pockerImage}
                                            />
                                            <div className={styles.arrow}>
                                                <img
                                                    src={leftArrowIcon}
                                                    alt="Left Arrow"
                                                    className={styles.leftArrow}
                                                />
                                                <img
                                                    src={leftArrowIcon}
                                                    alt="Right Arrow"
                                                    className={
                                                        styles.rightArrow
                                                    }
                                                />
                                            </div>
                                            <MainActionButton
                                                appearanceTheme="primary"
                                                text="Sport"
                                                className={styles.button}
                                                onClick={() => {
                                                    // Действие, которое должно произойти при нажатии на кнопку
                                                }}
                                            />
                                        </div>
                                    </div>}

                                    <div className={styles.slotsContainer}>
                                        <div className={styles.line}></div>
                                        <div className={styles.slotsHeader}>
                                            <h2 className={styles.slotsTitle}>
                                                Slots
                                            </h2>
                                            <MainActionButton
                                                appearanceTheme="primary"
                                                text="View all"
                                                className={styles.viewAllButton}
                                                onClick={() => {
                                                    history.push(
                                                        paths.algaltente
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className={styles.slotsGrid}>
                                            {slotsItems.map((slot, index) => (
                                                <div
                                                    key={index}
                                                    onClick={async () => {
                                                        history.push(
                                                            `/slots/${slot.id}/play`
                                                        );
                                                    }}
                                                    className={
                                                        styles.slotItem
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.slotBody
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                fileHelper.getMediaFileURL(
                                                                    slot.image
                                                                ) || ""
                                                            }
                                                            className={
                                                                styles.slotImage
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className={styles.liveCasinoBlock}>
                                        <div className={styles.line}></div>
                                        <div className={styles.slotsHeader}>
                                            <h2 className={styles.slotsTitle}>
                                                Live Casino
                                            </h2>
                                            <MainActionButton
                                                appearanceTheme="primary"
                                                text="View all"
                                                className={styles.viewAllButton}
                                                onClick={() => {
                                                    history.push(
                                                        paths.liveDealer
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className={styles.slotsGrid}>
                                            {liveDealers.map(
                                                (dealer, index) => (
                                                    <div
                                                        key={index}
                                                        onClick={async () => {
                                                            history.push(
                                                                `/live-dealer/${dealer.id}/play`
                                                            );
                                                        }}
                                                        className={
                                                            styles.slotItem
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.slotBody
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    dealer.image
                                                                        ? fileHelper.getMediaFileURL(
                                                                              dealer.image
                                                                          )
                                                                        : ""
                                                                }
                                                                className={
                                                                    styles.slotImage
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>

                                    </div>


                                    {generalStore.isHalyk &&<div className={styles.liveCasinoBlock}>
                                        <div className={styles.line}></div>
                                    </div> }
                                    {generalStore.isHalyk && <div className={generalStore.isHalyk ? styles.underHeroContentHalyk : styles.underHeroContent}>

                                        <div className={styles.pockerSlots}>
                                            <img
                                                src={pockerSlot}
                                                alt="Pocker Slots"
                                                className={styles.pockerImage}
                                            />
                                            <MainActionButton
                                                appearanceTheme="primary"
                                                text="Poker"
                                                className={styles.button}
                                                onClick={() => {
                                                    history.push(
                                                        paths.jackpoker
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div className={styles.pockerSlots}>
                                            <img
                                                src={sportSlot}
                                                alt="Sport Slots"
                                                className={styles.pockerImage}
                                            />
                                            <div className={styles.arrow}>
                                                <img
                                                    src={leftArrowIcon}
                                                    alt="Left Arrow"
                                                    className={styles.leftArrow}
                                                />
                                                <img
                                                    src={leftArrowIcon}
                                                    alt="Right Arrow"
                                                    className={
                                                        styles.rightArrow
                                                    }
                                                />
                                            </div>
                                            <MainActionButton
                                                appearanceTheme="primary"
                                                text="Sport"
                                                className={styles.button}
                                                onClick={() => {
                                                    // Действие, которое должно произойти при нажатии на кнопку
                                                }}
                                            />
                                        </div>
                                    </div>}


                                    {!generalStore.isHalyk && (
                                        <div
                                            className={
                                                styles.streamChannelsBlock
                                            }
                                        >
                                            <div className={styles.line}></div>
                                            <div
                                                className={styles.streamHeader}
                                            >
                                                <h2
                                                    className={
                                                        styles.streamTitle
                                                    }
                                                >
                                                    Our Stream Channels
                                                </h2>
                                            </div>
                                            <div className={styles.streamGrid}>
                                                <div
                                                    className={
                                                        styles.streamGrid
                                                    }
                                                >
                                                    {streamChannels.map(
                                                        (channel, index) => (
                                                            <div
                                                                key={index}
                                                                className={
                                                                    styles.streamSlots
                                                                }
                                                            >
                                                                {/*<img src={channel.imageUrl} alt="Stream Slots" className={styles.streamImage} />*/}
                                                                <img
                                                                    src={
                                                                        casino1
                                                                    }
                                                                    alt="Stream Slots"
                                                                    className={
                                                                        styles.streamImage
                                                                    }
                                                                />
                                                                <p
                                                                    className={
                                                                        styles.streamName
                                                                    }
                                                                >
                                                                    {
                                                                        channel.name
                                                                    }
                                                                </p>
                                                                <p
                                                                    className={`${
                                                                        styles.streamStatus
                                                                    } ${
                                                                        channel.isOnline
                                                                            ? styles.online
                                                                            : styles.offline
                                                                    }`}
                                                                >
                                                                    {channel.isOnline
                                                                        ? "Online"
                                                                        : "Offline"}
                                                                </p>
                                                                <div
                                                                    className={
                                                                        styles.buttonContainer
                                                                    }
                                                                >
                                                                    <button
                                                                        className={
                                                                            styles.streamButton
                                                                        }
                                                                    >
                                                                        <YoutubeIconGenerator
                                                                            width={
                                                                                20
                                                                            }
                                                                            height={
                                                                                20
                                                                            }
                                                                            fillColor="#00BA6C"
                                                                        />
                                                                    </button>
                                                                    <button
                                                                        className={
                                                                            styles.streamButton
                                                                        }
                                                                    >
                                                                        <DiscordIconGenerator
                                                                            width={
                                                                                20
                                                                            }
                                                                            height={
                                                                                20
                                                                            }
                                                                            fillColor="#00BA6C"
                                                                        />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className={styles.menuFooter}>
                                    <Footer isMainPage={true} />
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </ModalProvider.Provider>
            )}
        </>
    );
};

export default observer(NewLobbyPage);
