import React from 'react';
import { observer } from 'mobx-react';
import { Box, Container, Grid } from '@mui/material';
import cn from 'classnames';

import endpoints from 'src/core/endpoints';
import calcNameLength from 'src/utils/calcNameLength';
import useChangeTheme from 'src/hooks/useChangeTheme';

import { IWatchingList, WatchersStatusType } from '../../types/watchingTypes';
import WatchingToggler from '../../WatchingToggler';

import { useStyles } from './styles';

function MobileWatchingList({
    setOpen,
    isOpen,
    watchingArray,
    openStatus,
    isMobileGamePage,
}: IWatchingList): JSX.Element {
    const classes = useStyles();
    const clientWidth = window.innerWidth;
    const appearanceTheme = useChangeTheme();

    return (
        <Box
            style={{
                zIndex: isMobileGamePage ? 7 : 1,
            }}
            className={
                isOpen === WatchersStatusType.show
                    ? cn(classes.watchingListShow, appearanceTheme)
                    : isOpen === WatchersStatusType.hide
                    ? cn(classes.watchingListHide, appearanceTheme)
                    : cn(classes.watchingListReload, appearanceTheme)
            }
        >
            <Container className={classes.watchingListContainer}>
                <WatchingToggler
                    setOpen={(properties) => setOpen(properties)}
                    openStatus={openStatus}
                    isOpen={isOpen}
                    watchingArray={watchingArray}
                    dryStyle={true}
                />
                <Grid
                    container
                    spacing={1}
                    justifyContent='flex-end'
                    alignItems='center'
                    className={classes.watchingMainGrid}
                >
                    {watchingArray.map((user) => (
                        <Grid key={user.id} item xs={4}>
                            <Box
                                key={`${user.id}_box`}
                                className={classes.watchingUserBox}
                            >
                                <img
                                    src={`${endpoints.avatarPath}/${user.avatar}`}
                                    className={classes.watchingUserAvatar}
                                />
                                <p>
                                    {calcNameLength(
                                        user.nickname,
                                        clientWidth,
                                        10
                                    )}
                                </p>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}

export default observer(MobileWatchingList);
