import { makeStyles } from "@mui/styles";

import theme from "src/theme";
import { MAX_CHANGE_SCREEN_WIDTH } from "src/pages/game-new/constants";
import { appColors } from "src/consts/app-config";

export const useStyles = makeStyles({
    nicknameWrapper: {
        textAlign: "left",
        padding: 0,
        fontSize: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        overflow: "hidden",
        [theme.breakpoints.up(1023)]: {
            textAlign: "center",
            "@media (orientation: landscape)": {
                textAlign: "center",
                "&.isLargeNick": {
                    textAlign: "left",
                },
            },
            "@media (orientation: portrait)": {
                textAlign: "left",
                "&.isLargeNick": {
                    textAlign: "left",
                },
            },
        },
        "&.isMultiTable": {
            textAlign: "center!important",
        },
    },

    playerName: {
        width: "100%",
        "&.isMultiTable": {
            display: "flex",
            paddingRight: 5,
        },
    },

    playerNameParagraph: {
        fontSize: 20,
        fontWeight: 900,
        display: "inline-block",
        width: "100%",
        opacity: 0.9,
        margin: "0 auto",
        fontFamily: "Montserrat",
        "&.darkBlue": {
            opacity: 1,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            whiteSpace: "nowrap",
            background:
                "linear-gradient(178.82deg, #A2A2A2 31.6%, #FFFFFF 50.51%, #A9A9A9 76.41%)",
            textShadow: "0px 0px 15.2259px rgba(0, 0, 0, 0.5)",
            maskImage: "linear-gradient(to right,black 90%,transparent 100%)",
            [theme.breakpoints.between(600, "xl")]: {
                "@media (orientation: portrait)": {
                    maskImage: "none",
                },
            },
        },
        "&.darkGreen": {
            opacity: 1,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            whiteSpace: "nowrap",
            background: "linear-gradient(180deg, #F8BF44 0%, #A16529 100%)",
            textShadow: "0px 2.28327px 0px rgba(0, 0, 0, 0.1)",
            maskImage: "linear-gradient(to right,black 90%,transparent 100%)",
            [theme.breakpoints.between(600, "xl")]: {
                "@media (orientation: portrait)": {
                    maskImage: "none",
                    width: "100%",
                },
            },
        },
        "&.deepBlack": {
            fontFamily: "Montserrat",
            fontWeight: 400,
            color: appColors.semiwhite,
        },
        [theme.breakpoints.down("md")]: {
            fontSize: 18,
            lineHeight: "18px",
            paddingTop: 2,
        },
        [theme.breakpoints.down("sm")]: {
            // width: "80px",
            width: "100%",
        },
        [theme.breakpoints.down(380)]: {
            fontSize: "4vw",
            lineHeight: "4vw",
        },
        "&.isMultiTable": {
            fontSize: 18,
            lineHeight: "18px",
            textAlign: "start",
            [theme.breakpoints.down(1700)]: {
                fontSize: 16,
                lineHeight: "16px",
            },
            [theme.breakpoints.down(1500)]: {
                fontSize: 14,
                lineHeight: "14px",
            },
            "@media screen and (max-height: 900px)": {
                fontSize: 13,
                lineHeight: "13px",
            },
        },
        "&.isLargeNick": {
            fontWeight: 800,
            [theme.breakpoints.between(1023, MAX_CHANGE_SCREEN_WIDTH)]: {
                fontSize: "12px",
                maxWidth: "140px",
            },
            [theme.breakpoints.up(MAX_CHANGE_SCREEN_WIDTH)]: {
                fontSize: "14px",
                maxWidth: "100%",
            },
            [theme.breakpoints.up("xl")]: {
                fontSize: "16px",
            },
            [theme.breakpoints.up(1800)]: {
                // fontSize: "18px",
                fontSize: "24px",
            },
        },
        "&.isOpponent": {
            [theme.breakpoints.between("xs", "md")]: {
                "@media (orientation: portrait)": {
                    maxWidth: "100%",
                    width: "100%",
                },
            },
            [theme.breakpoints.up("md")]: {
                "@media (orientation: portrait)": {
                    width: "100%",
                },
            },
        },
        [theme.breakpoints.up(1800)]: {
            fontSize: "24px",
        },
    },
});
