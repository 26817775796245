import { makeStyles } from "@mui/styles";

import { appColors, backgroundColors } from "src/consts/app-config";
import theme from "src/theme";

export const useStyles = makeStyles({
    "@global": {
        "@keyframes headerCreateAnimationPC": {
            "0%": {
                opacity: 1,
            },
            "50%": {
                opacity: 0.5,
            },
            "100%": {
                opacity: 1,
            },
        },

        "@keyframes headerCreateAnimationMobile": {
            "0%": {
                opacity: 0,
            },
            "100%": {
                opacity: 1,
            },
        },
        "@keyframes mobileNewGameAppear": {
            "0%": {
                opacity: 0,
            },
            "100%": {
                opacity: 1,
            },
        },
    },

    wrapperLobbyGameHandler: {
        position: "relative",
        zIndex: 3,
        [theme.breakpoints.down("md")]: {
            width: "100%",
            position: "fixed",
            left: 0,
            bottom: 10,
            padding: "0 18px",
            zIndex: 100,
            animation: "mobileNewGameAppear",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDelay: "0.0s",
            animationDuration: "1000ms",
        },
        "@media screen and (max-width: 768px) and (max-height: 400px)": {
            transform: "scale(0.8) translateY(15%)",
            bottom: 0,
        },
        "@media screen and (max-width: 768px) and (max-width: 450px)": {
            padding: "0 8px",
            bottom: 25,
        },
    },

    headerGamecontainer: {
        background: "linear-gradient(0deg, #303449, #303449), #202233",
        border: "1px solid #534B63",
        borderRadius: 12,
        padding: "10px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "67px",
        // '&.isCreateGameBlock': {
        //     background: 'linear-gradient(0deg, #303449, #303449), #202233',
        //     border: '1px solid #534B63',
        // },
        // '&.isWaitingForGameBlock': {
        //     background: 'linear-gradient(0deg, #2D1C5E, #2D1C5E), #392670',
        //     border: '1px solid #5A3C99',
        // },
        "&.darkBlue": {
            background: "linear-gradient(0deg, #303449, #303449), #202233",
            boxShadow: "0px 23px 25px -15px  rgba(168, 177, 206, 0.3)",
            [theme.breakpoints.down("md")]: {
                padding: "16px 16px 13px",
                height: "auto",
            },
        },
        "&.darkGreen": {
            background: "linear-gradient(0deg, #000606, #000606), #1B3236",
            boxShadow: "0px 23px 25px -15px  #113C4F",
            border: "1px solid #1C6871",
            [theme.breakpoints.down("md")]: {
                padding: "16px 16px 13px",
                height: "auto",
            },
        },
        "&.deepBlack": {
            background: "linear-gradient(0deg, #303449, #303449), #202233",
            boxShadow: "0px 23px 25px -15px  rgba(168, 177, 206, 0.3)",
            [theme.breakpoints.down("md")]: {
                padding: "16px 16px 13px",
                height: "auto",
            },
        },

        [theme.breakpoints.down("sm")]: {
            padding: "16px 12px",
            bottom: 5,
        },
    },

    headerGamecontainerAnimate: {
        // background: 'linear-gradient(0deg, #2D1C5E, #2D1C5E), #392670',
        // border: '1px solid #5A3C99',
        background: "linear-gradient(0deg, #303449, #303449), #202233",
        border: "1px solid #534B63",
        boxShadow: "0px 0px 40px 10px #131525",
        borderRadius: 12,
        padding: "10px 20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "67px",
        "&.darkBlue": {
            background: "linear-gradient(0deg, #303449, #303449), #202233",
            boxShadow: "0px 23px 25px -12px rgba(168, 177, 206, 0.3)",
            [theme.breakpoints.down("md")]: {
                padding: "16px 16px 13px",
                height: "auto",
            },
        },
        "&.darkGreen": {
            background: "linear-gradient(0deg, #000606, #000606), #1B3236",
            boxShadow: "0px 23px 25px -15px  #113C4F",
            border: "1px solid #1C6871",
            [theme.breakpoints.down("md")]: {
                padding: "16px 16px 13px",
                background: "linear-gradient(0deg, #000606, #000606), #1B3236",
                height: "auto",
            },
        },
        "&.deepBlack": {
            margin: 0,
            background: "none",
            boxShadow: "none",
            padding: "8px",
            borderRadius: "8px",
            border: "1px solid rgba(255, 255, 255, )",
            [theme.breakpoints.down("md")]: {
                padding: "16px 16px 13px",
                height: "auto",
            },
        },
        "&.animateContainer": {
            [theme.breakpoints.up("xs")]: {
                animation: "headerCreateAnimationMobile",
                animationIterationCount: "1",
                animationFillMode: "forwards",
                animationDelay: "0.0s",
                animationDuration: "400ms",
            },
            [theme.breakpoints.up(1250)]: {
                animation: "headerCreateAnimationPC",
                animationIterationCount: "1",
                animationFillMode: "forwards",
                animationDelay: "0.0s",
                animationDuration: "250ms",
            },
        },

        [theme.breakpoints.down("sm")]: {
            padding: "16px 12px",
            bottom: 5,
        },
    },

    userInfoBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    avatarBox: {
        width: 43,
        height: 43,
        padding: 3,
        "& .nickname-box": {
            fontSize: 10,
        },
    },
    nicknameBox: {
        marginLeft: 12,
        color: appColors.white,
        fontSize: 16,
        fontWeight: 900,
        "@media screen and (max-width: 1366px)": {
            display: "none",
        },
    },
    opponentRejectedAlert: {
        position: "absolute",
        right: 0,
        bottom: "110%",
    },

    // NEW LOBBY
});

export const useBlockCommonStyles = {
    container: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
        },
    },
    title: {
        fontWeight: 700,
        fontSize: 18,
        color: appColors.white,
        height: "fit-content",
        [theme.breakpoints.down("md")]: {
            marginBottom: 12,
        },
    },
    createGameBoxItemTime: {
        width: 180,
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    createGameBoxItemBet: {
        // width: 120,
        width: 145,
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    betConfigContainer: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        width: "100%",
        background: "rgba(113, 121, 148, 0.3)",
        borderRadius: 24,
        padding: "7px 12px",
        justifyContent: "space-between",
        height: "40px",
    },
    timeConfigContainer: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        width: "100%",
        background: "rgba(113, 121, 148, 0.3)",
        borderRadius: 24,
        padding: "7px 12px",
        justifyContent: "space-between",
        height: "40px",
    },
    icon: {
        width: "25px !important",
        display: "flex",
        alignItems: "center",
    },
};
