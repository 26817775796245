import { GameTime, GameTimeValues } from "./lobbyStore";

export const getTimeAndIncrement = (t: GameTimeValues) => {
    if (t === GameTimeValues["3m"]) {
        return {
            time: 3,
            increment: 3,
        };
    } else if (t === GameTimeValues["5m"]) {
        return {
            time: 5,
            increment: 5,
        };
    } else if (t === GameTimeValues["10m"]) {
        return {
            time: 10,
            increment: 0,
        };
    } else if (t === GameTimeValues["20m"]) {
        return {
            time: 20,
            increment: 0,
        };
    } else {
        return {
            time: 10,
            increment: 10,
        };
    }
};
