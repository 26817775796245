import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';
import theme from 'src/theme';

const currentHeight = window.innerHeight;

export const useStyles = makeStyles({
    modalImagesBox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        padding: 0,
        height: 'fit-content',
        overflow: 'hidden',
        '&.friendFightReceive': {
            minHeight: '220px',
            '&.fastGame': {
              minHeight: '130px',
            }
        },
        '&.lobbyInitiatorAwaiting': {
            minHeight: '220px',
            '&.fastGame': {
              minHeight: '130px',
            }
        },
        '&.lobbyConfirmGame': {
            minHeight: '220px',
            '&.fastGame': {
              minHeight: '130px',
            }
        },
    },

    modalImagesBoxVersusFriend: {
        display: 'grid',
        justifyContent: 'center',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr 1fr',
        width: '95%',
        height: 'fit-content',
        '&.friendFightInit': {
            width: '100%',
            gridTemplateColumns: '1fr 40px 1fr',
        },
        '&.friendFightReceive': {
            width: '100%',
            gridTemplateColumns: '1fr',
        },
        '&.lobbyInitiatorAwaiting': {
            width: '100%',
            gridTemplateColumns: '1fr',
        },
        '&.lobbyConfirmGame': {
            width: '100%',
            gridTemplateColumns: '1fr',
        },
        [theme.breakpoints.down('md')]: {
            height: 'fit-content',
        },
    },

    modalImagesContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        padding: 0,
        height: 'fit-content',
        overflow: 'hidden',
        gridGap: '10px',
        '&.friendFightReceive': {
            position: 'relative',
        },
    },

    avatarImage: {
        borderRadius: '50%',
        objectFit: 'cover',
        width: 116,
        height: 116,
        justifySelf: 'center',
        boxShadow: '0px 0px 30px 10px rgba(188,199,255,0.28)',
        marginTop: 5,
        '&.isSystemAvatar': {
            width: '100%',
            height: '100%',
            borderRadius: '50%',
        },
        '&.friendFightInit': {
            width: 116,
            height: 116,
            '&.fastGame': {
              width: 85,
              height: 85,
              marginTop: 5,
              '@media screen and (min-height: 760px)': {
                marginTop: 5,
                width: 110,
                height: 110,
              },
            },
        },
        '&.friendFightReceive': {
            width: 116,
            height: 116,
            '&.fastGame': {
              width: 85,
              height: 85,
              marginTop: 5,
              '@media screen and (min-height: 760px)': {
                marginTop: 5,
                width: 110,
                height: 110,
              },
            },
        },
        '&.lobbyConfirmGame': {
            width: 116,
            height: 116,
            '&.fastGame': {
              width: 85,
              height: 85,
              marginTop: 5,
              '@media screen and (min-height: 760px)': {
                marginTop: 5,
                width: 110,
                height: 110,
              },
            },
        },
        [theme.breakpoints.down('md')]: {
            width: 88,
            height: 88,
            boxShadow: '0px 0px 15px 10px rgba(188,199,255,0.20)',
            '&.friendFightInit': {
                boxShadow: '0px 0px 15px 10px rgba(188,199,255,0.20)',
            },
            '&.friendFightReceive': {
                marginTop: '0px',
            },
            '&.lobbyConfirmGame': {
                marginTop: '0px',
            },
        },
        [theme.breakpoints.down(375)]: {
            width: 70,
            height: 70,
            '&.friendFightInit': {
                boxShadow: '0px 0px 15px 10px rgba(188,199,255,0.15)',
            },
            '&.friendFightReceive': {
                marginTop: '0px',
            },
            '&.lobbyConfirmGame': {
                marginTop: '0px',
            },
        },
        '&.isLoaded': {
            filter: 'blur(4px)',
            borderRadius: '50%',
        },
    },

    modalVersusText: {
        fontFamily: 'Sans Caption',
        fontWeight: 700,
        fontSize: '24px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        background: 'linear-gradient(180deg, #FFF480 0%, #F4B548 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        margin: 0,
        justifyContent: 'center',
    },

    '@keyframes uploadAvatarWrapper': {
        '0%': {
            opacity: 0,
            transform: ` translate(0%, 0%) translateY(${currentHeight}px)`,
            borderRadius: '10px',
        },
        '100%': {
            opacity: 1,
            borderRadius: '35px',
            transform: `translate(0%, 0%) translateY(${0}px)`,
        },
    },
});
