import React, { useMemo, useEffect, memo, useCallback, useState } from "react";
import { useDrag, useDrop, DragPreviewImage } from "react-dnd";
import cn from "classnames";
import { observer } from "mobx-react";

import { IPiecesMap } from "../../types";
import useStores from "../../../../hooks/useStores";
import iOSDetect from "../../../../utils/iOsDetect";
import { usePlayer } from "../../../../sounds/use-player";

import DragPieceComponent from "./DragPieceComponent";
import { useStyles } from "./styles";
import { ColorVariant } from "src/store/models";

interface Props {
    i: number;
    mobileHover: boolean;
    square: string;
    piece: string;
    pieces: IPiecesMap;
    appearanceTheme: string;
    orientation: string;
    //isAllowDrag: boolean;
    highlight: boolean;
    onSquareClick?: (target: string) => void;
    onDrop?: (t: { from: string; to: string }) => void;
}

interface ComponentProps {
    isAllowDrag: boolean;
    highlight: boolean;
    appearanceTheme: string;
    orientation: string;
}

const SquareWithFigure = (props: Props & ComponentProps) => {
    const { gameStore } = useStores();

    const {
        i,
        highlight,
        onSquareClick,
        mobileHover,
        square,
        piece,
        pieces,
        onDrop,
        appearanceTheme,
        orientation,
    } = props;

    const player = usePlayer();

    const styles = useStyles({ appearanceTheme });

    const [{ isOver }, drop] = useDrop({
        accept: [
            "wp",
            "wr",
            "wn",
            "wb",
            "wq",
            "wk",
            "bp",
            "br",
            "bn",
            "bb",
            "bq",
            "bk",
        ],

        drop: (props) => {
            //@ts-ignore
            onDrop && onDrop({ from: props.from, to: square });
            //@ts-ignore
            iOSDetect() && player("pieceSoundPlay");
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    const getTargetHighLight = useCallback(
        (
            highlight: boolean | undefined,
            piece: string,
            orientation: string
        ) => {
            if (highlight) {
                if (orientation === ColorVariant.white) {
                    return piece.toUpperCase() === piece ? "me" : "oponent";
                }
                if (orientation === ColorVariant.black) {
                    return piece.toLowerCase() === piece ? "me" : "oponent";
                }
            }
        },
        [highlight, piece]
    );

    //1. Нажимаем на свою фигуру
    //2. Если есть доступный ход, то вражеская фиргура получает статус на g7_N_Oponnent, (мы на g8_me)
    //3. Сбиваем вражескую фигуру и получаем - мы на g7 подсветки нет
    //4. Логика анимации:
    // 4.1 Если killed pieces содержит фигуру N и Статус клетки поменялся с g7_N_Oponent на g7, то
    //У нас есть начальное положение фигуры - square и понимание, цвета фигуры - square

    return (
        <div
            ref={drop}
            className={cn(
                styles.noselect,
                styles.square,
                isOver
                    ? mobileHover
                        ? styles.hoverMobile
                        : styles.hoverDesktop
                    : undefined,
                getTargetHighLight(highlight, piece, orientation),
                piece.toLowerCase()
            )}
            style={{
                transform: `translate(${(i % 8) * 100}%,${
                    Math.trunc(i / 8) * 100
                }%)`,
            }}
            onMouseDown={() => {
                onSquareClick && onSquareClick(square);
            }}
            onTouchStart={() => {
                onSquareClick && onSquareClick(square);
            }}
            onTouchMove={() => {
                onSquareClick && onSquareClick(square);
            }}
        >
            <DragPieceComponent
                pieces={pieces}
                piece={piece}
                square={square}
                isAllowDrag={props.isAllowDrag}
                orientation={orientation}
                getTargetHighLight={getTargetHighLight(
                    highlight,
                    piece,
                    orientation
                )}
            />
        </div>
    );
};

const SquareWithFigureWrapper = (props: Props) => {
    const isAllowDrag = true;

    return (
        <SquareWithFigure
            {...props}
            isAllowDrag={Boolean(isAllowDrag)}
            //highlight={squareStyles[props.square]}
            //onSquareClick={onSquareClick}
            //onDrop={onDrop}
        />
    );
};

export default observer(SquareWithFigureWrapper);
