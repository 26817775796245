import React from "react";
import cn from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { useMediaQuery } from "@mui/material";
import OutsideClickHandler from "react-outside-click-handler";

import useStores from "src/hooks/useStores";
import paths from "src/consts/paths";
import Balance from "src/components/balance";
import { UserBalanceType } from "src/store/models";
import { BonusDescriptionModal } from "src/components/modals/BonusDescriptionModal";

import { useStyles } from "./styles";
import { IInfoEdition } from "./type";

const InfoMobileEdition = ({
    onHiddenChange,
    onClickBonus,
    isBonusModalOpen,
}: IInfoEdition) => {
    const { authStore, generalStore, lobbyStore } = useStores();
    const user = authStore.currentUser;
    const isMobile = useMediaQuery("(max-width: 1024px)");
    const styles = useStyles();
    const history = useHistory();
    const location = useLocation();

    return (
        <>
            {isMobile && (
                <>
                    <div className={cn(styles.appBarElement, "balance")}>
                        {generalStore.isDicechessMode &&
                            authStore.isAuthorized && (
                                <OutsideClickHandler
                                    onOutsideClick={() => {
                                        onClickBonus(false);
                                    }}
                            >
                                {/*<Balance
                                        className={cn(
                                            styles.bonusBalance,
                                            "bonusCoin"
                                        )}
                                        amount={user?.balance?.bonus || 0}
                                        hidden={authStore.balanceHidden}
                                        backGroundColor={
                                            authStore.currentUser?.settings
                                                .background || "blue"
                                        }
                                        onChange={onHiddenChange}
                                        type={UserBalanceType.referralBonus}
                                        onClick={() => {
                                            onClickBonus(!isBonusModalOpen);
                                        }}
                                    />*/ }
                                    
                                    <BonusDescriptionModal
                                        open={isBonusModalOpen}
                                    />
                                </OutsideClickHandler>
                            )}
                        {/* {authStore.isAuthorized && (
                            <Balance
                                showEye
                                amount={
                                    user?.balance[lobbyStore.lobbyMode]
                                        ?.available ?? 0
                                }
                                hidden={authStore.balanceHidden}
                                onChange={onHiddenChange}
                                backGroundColor={
                                    authStore.currentUser?.settings
                                        .background || "blue"
                                }
                                type={lobbyStore.lobbyMode}
                                onClick={() => {
                                    generalStore.isDicechessMode &&
                                        history.push({
                                            pathname: paths.cashbox,
                                            state: {
                                                from: location.pathname,
                                            },
                                        });
                                }}
                            />
                        )}*/}
                        
                    </div>
                </>
            )}
        </>
    );
};

export default observer(InfoMobileEdition);
