import { makeStyles } from '@mui/styles';

import theme from 'src/theme';

export const useStyles = makeStyles({
    '@global': {
        '@keyframes scaleLogoAnimate': {
            '0%': {
                transform: 'scale(1)',
                filter: 'drop-shadow(0px 0px 0px #FFF1F1)',
            },
            '50%': {
                transform: 'scale(1.15)',
                filter: 'drop-shadow(0px 0px 5px #FFF1F1)',
            },
            '100%': {
                transform: 'scale(1)',
                filter: 'drop-shadow(0px 0px 0px #FFF1F1)',
            },
        },
        '@keyframes finishLogo': {
            '0%': {
                opacity: 1,
            },
            '100%': {
                opacity: 0,
                zIndex: -10,
            },
        },
    },

    fullscreenSpinner: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(0, 0, 0, 1)',
        backdropFilter: 'blur(10px)',
        zIndex: -10,
        visible: 'hidden',
        '&.isStart': {
            zIndex: 21,
            opacity: 1,
            transition: 'opacity 0.5s ease-in',
        },
        '&.isFinish': {
            zIndex: 21,
            opacity: 1,
            animationDelay: '0s',
            animationIterationCount: 1,
            animationFillMode: 'forwards',
            animationDuration: '1000ms',
            animationName: 'finishLogo',
        },
    },

    fullscreenStatic: {
        position: 'static',
    },

    absoluteWrapper: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        background: 'rgba(0, 0, 0, 1)',
        zIndex: 21,
        backdropFilter: 'blur(5px)',
        left: 0,
        top: 0,
    },

    center: {
        left: '50%',
        top: '50%',
        width: '70%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    centerLogo: {
        width: '80%',
        maxWidth: '400px',
        height: '100%',
        objectFit: 'contain',
        opacity: 1,
        '&.isStart': {
            animationDelay: '0s',
            animationIterationCount: 15,
            animationFillMode: 'ease-in-out',
            animationDuration: '5000ms',
            animationName: 'scaleLogoAnimate',
        },
    },

    halykLogo: {
        width: '80%',
        maxWidth: '200px',
        height: '100%',
        objectFit: 'contain',
        opacity: 1,
        '&.isStart': {
            animationDelay: '0s',
            animationIterationCount: 15,
            animationFillMode: 'ease-in-out',
            animationDuration: '5000ms',
            animationName: 'scaleLogoAnimate',
        },
    },
});
