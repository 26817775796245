import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { Box } from "@mui/material";
import OutsideClickHandler from "react-outside-click-handler";
import { observer } from "mobx-react";
import cn from "classnames";

import useChangeTheme from "src/hooks/useChangeTheme";
import defaultPlaceholder from "src/assets/images/defaultPlaceholder.png";
import { useLocale } from "src/providers/LocaleProvider";
import TournamentTopPlayersIcon from "src/assets/icons/tournaments/TournamentTopPlayersIcon";
import { prizeModalGold } from "src/consts/app-config";
import useStores from "src/hooks/useStores";
import { Spinner } from "src/components/spinner";
import refreshCircle from "src/assets/icons/refreshCircle.svg";

import { useStyles } from "./styles";
import TopPlayerItem from "./topPlayerBox";
import { ITopPlayersList } from "./types";

const TopPlayersMobile = ({
    paginationLimit,
    maxCountOfPaganation,
    topCountTitle,
}: ITopPlayersList) => {
    const opneOrHideAnimatonDuration = 500;
    const { authStore, achievementsStatStore } = useStores();
    const {
        achievements: {
            result: { loadingResults },
            titles: { topPlayerTitle },
        },
    } = useLocale();
    const classes = useStyles();
    const [refreshHelper, setHelper] = useState<boolean>(false);

    const topPlayerBoxSRef = useRef<HTMLDivElement>(null);
    const togglerTopPlayers = achievementsStatStore?.topPlayersToggle;
    const topPlayersFromStore = achievementsStatStore?.topPlayers;
    const myPosition = achievementsStatStore?.myPlace;
    const me = authStore?.currentUser?._id;
    const myInfo = authStore?.currentUser;
    const appearanceTheme = useChangeTheme();
    const [paginationCounter, setPaginationCounter] = useState<number>(0);

    const resetTopPlayerModal = (animationDuration: number) => {
        if (achievementsStatStore?.topPlayersToggle) {
            achievementsStatStore.toggleTopPlayersInfo(false);
            setTimeout(() => {
                achievementsStatStore.toggleTopPlayersInfo(null);
            }, animationDuration);
        }
    };

    useEffect(() => {
        // Added this for continue fetch if user get 40 top playesr, and close modal. After we will have correctly count of fetch skip
        if (topPlayersFromStore && topPlayersFromStore.length) {
            setPaginationCounter(
                Math.round(topPlayersFromStore.length / paginationLimit)
            );
        }
    }, [topPlayersFromStore]);

    const handleScroll = (
        paginationLim: number,
        paginationCounter: number,
        maxCountOfPaganation: number
    ) => {
        //Check count of paganation, in this case limit is 4 (20 exist and 80 fetch object = 100 elems in array)
        if (
            !me ||
            !topPlayersFromStore ||
            paginationCounter > maxCountOfPaganation
        )
            return;
        if (topPlayerBoxSRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                topPlayerBoxSRef.current;
            if (
                //Observ scrool down and avoid multiple fetch. Fetch is posible when prev data will be responsed
                scrollTop + clientHeight + 50 >= scrollHeight &&
                achievementsStatStore.isLoadedRating
            ) {
                achievementsStatStore.getTopPlayers({
                    skip: paginationLim * paginationCounter,
                    limit: paginationLim,
                });
                setPaginationCounter((prev) => prev + 1);
            }
        }
    };

    useLayoutEffect(() => {
        if (refreshHelper && achievementsStatStore.isLoadedRating) {
            setHelper(false);
        }
    }, [refreshHelper, achievementsStatStore.isLoadedRating]);

    if (!achievementsStatStore.isLoadedRating && refreshHelper)
        return <Spinner isAbsolute />;

    return (
        <>
            {topPlayersFromStore && (
                <OutsideClickHandler
                    onOutsideClick={(e) => {
                        !achievementsStatStore.playerSettingsInfo &&
                            resetTopPlayerModal(opneOrHideAnimatonDuration);
                    }}
                >
                    <Box
                        className={cn(
                            classes.tournamentsRaitingMobileWrapper,
                            appearanceTheme,
                            {
                                open: togglerTopPlayers,
                                close: togglerTopPlayers === false,
                            }
                        )}
                    >
                        <Box
                            className={cn(
                                classes.crossTopPlayersBox,
                                appearanceTheme,
                                {
                                    open: togglerTopPlayers,
                                    close: togglerTopPlayers === false,
                                }
                            )}
                            onClick={() =>
                                resetTopPlayerModal(opneOrHideAnimatonDuration)
                            }
                        >
                            <div
                                className={cn(
                                    classes.crossTopPlayers,
                                    appearanceTheme,
                                    {
                                        open: togglerTopPlayers,
                                        close: togglerTopPlayers === false,
                                    }
                                )}
                            ></div>
                        </Box>
                        <Box
                            className={cn(classes.topPlayersGridBox, {
                                isLobbyPageMobile: true,
                            })}
                        >
                            <Box
                                className={cn(classes.topPlayerListTitle, {
                                    isLobbyPage: true,
                                })}
                            >
                                <TournamentTopPlayersIcon
                                    fillColor={prizeModalGold}
                                    width={24}
                                    height={18}
                                />
                                <p>
                                    {topPlayerTitle.compile(
                                        { count: topCountTitle },
                                        "Top-{{count}} Players"
                                    )}
                                </p>
                            </Box>
                            <Box
                                className={cn(classes.refreshClickerBox, {
                                    isMobilePage: true,
                                })}
                                onClick={() => {
                                    setHelper(true);
                                    achievementsStatStore.resetTopPlayers();
                                    achievementsStatStore.getTopPlayers({
                                        skip: 0,
                                        limit: 20,
                                    });
                                }}
                            >
                                <img
                                    className={cn(classes.refreshImage, {
                                        isTournamentPage: true,
                                    })}
                                    src={refreshCircle}
                                    alt="refresh"
                                />
                            </Box>
                            {topPlayersFromStore ? (
                                <div
                                    ref={topPlayerBoxSRef}
                                    onScroll={() =>
                                        handleScroll(
                                            paginationLimit,
                                            paginationCounter,
                                            maxCountOfPaganation
                                        )
                                    }
                                    className={cn(classes.topPlayersBox, {
                                        isLobbyPage: true,
                                    })}
                                >
                                    {topPlayersFromStore.map(
                                        (topPlayer, index) => {
                                            return (
                                                <TopPlayerItem
                                                    index={index + 1}
                                                    topPlayer={topPlayer}
                                                    topPlayerId={topPlayer.user}
                                                    me={me}
                                                    key={`topPlayers_${topPlayer._id}_${index}`}
                                                />
                                            );
                                        }
                                    )}
                                    {typeof myPosition === "number" &&
                                        myPosition >
                                            topPlayersFromStore.length &&
                                        myInfo &&
                                        me && (
                                            <>
                                                <Box
                                                    className={cn(
                                                        classes.topPlayersEllipsisBox,
                                                        {}
                                                    )}
                                                >
                                                    <p
                                                        className={cn(
                                                            classes.topPlayersEllipsis,
                                                            {}
                                                        )}
                                                    >
                                                        ...
                                                    </p>
                                                </Box>
                                                <TopPlayerItem
                                                    index={myPosition}
                                                    topPlayer={{
                                                        id: myInfo._id,
                                                        score: 0,
                                                        rank: {
                                                            title: myInfo.rank
                                                                .title,
                                                        },
                                                        isSystemAvatar:
                                                            myInfo?.isSystemAvatar,
                                                        nickname:
                                                            myInfo?.nickname,
                                                        avatar:
                                                            myInfo?.avatar ||
                                                            defaultPlaceholder,
                                                        rating: myInfo?.rating
                                                            ?.elo,
                                                        user: myInfo._id,
                                                        stats: {
                                                            _id: myInfo._id,
                                                            wonGames:
                                                                +myInfo?.stats?.games?.won.toString() ||
                                                                0,
                                                        },
                                                    }}
                                                    topPlayerId={me}
                                                />
                                            </>
                                        )}
                                    {!achievementsStatStore.isLoadedRating && (
                                        <div
                                            className={cn(
                                                classes.topPlayerSpinnerWrapper
                                            )}
                                        >
                                            <Spinner size={30} />
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <p className={cn(classes.topPlayersTitle, {})}>
                                    {loadingResults("Loading Results")}
                                </p>
                            )}
                        </Box>
                    </Box>
                </OutsideClickHandler>
            )}
        </>
    );
};

export default observer(TopPlayersMobile);
