import { EExternalProviderType } from "src/entities/externalGame";

import { IGetLiveDealerGameResponse, ILiveDealerGame } from "./types";

export class LiveDealerModel implements ILiveDealerGame {
    public id: string;
    public name: string;
    public isOpened: boolean;
    public generalMin: number;
    public generalMax: number;
    public lastWins: number[];
    public imageUrl: string;
    public isNew: boolean;
    public isPopular: boolean;
    public isFavorite: boolean;
    public providerId: string;

    constructor(data: IGetLiveDealerGameResponse) {
        this.formatAndSave(data);
    }

    formatAndSave(data: IGetLiveDealerGameResponse) {
        this.id = data.id;
        this.name = data.name;
        this.isOpened = data.isOpened;
        this.generalMin = data.general_min;
        this.generalMax = data.general_max;
        this.imageUrl = data.image;
        this.isNew = data.isNew;
        this.isPopular = data.isPopular;
        this.isFavorite = data.isFavorite;
        this.providerId = data.providerId;
    }
}
