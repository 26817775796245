import React from "react";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";

import "./index.css";
import "./fonts.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { emitter } from "./shared/emitter";

const isProduction = process.env.NODE_ENV === "production";

if (isProduction) {
    const RELEASE = process.env.REACT_APP_SENTRY_RELEASE;
    const SENTRY_URL = process.env.REACT_APP_SENTRY_URL;

    Sentry.init({
        dsn: SENTRY_URL,
        integrations: [new Sentry.Replay()],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        release: RELEASE,
        beforeSend: (event, hint) => {
            emitter.emit("unhandledException");
            return event;
        },
    });
}

const container = document.getElementById("root");
if (container) {
    const root = createRoot(container); // createRoot(container!) if you use TypeScript
    root.render(<App />);
}

// ReactDOM.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>,
//     document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
