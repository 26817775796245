import axios from "axios";

import endpoints from "src/core/endpoints";

import {
    ICheckAuthResponse,
    IImpersonateUserRequest,
    ILoginByTokenRequest,
    ILoginByTokenResponse,
    IRecoverPasswordRequest,
    IRecoverPasswordResponse,
    ISignInRequest,
    ISignInResponse,
    ISignUpRequest,
    ISignUpResponse,
    IUpdateVerificationInfoRequest,
    IUpdateVerificationInfoResponse,
    IUpdateVerificationPhotoRequest,
    ISignRequestNew,
    ISignUpRequestNew,
    ISignResponse,
    IRecoveryResponse,
    ISignInWithGoogleRequest,
    ISignInWithFacebookRequest,
} from "./requestResponses";

export const checkAuth = async () => {
    return await axios.get<ICheckAuthResponse>(endpoints.account);
};

export const signIn = async (params: ISignInRequest) => {
    return await axios.post<ISignInResponse>(`${endpoints.login}`, params);
};

export const signInNew = async (params: ISignRequestNew) => {
    return await axios.post<ISignResponse>(`${endpoints.login}`, params);
};
export const signUpNew = async (params: ISignUpRequestNew) => {
    return await axios.post<ISignResponse>(`${endpoints.auth}`, params);
};

export const checkAuthByTokenNew = async <U extends string>(
    token: U
): Promise<any> => {
    try {
        const request = await axios.get(`${endpoints.getUser}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return request;
    } catch (error) {
        const errorText: string = error?.response?.data;
        return errorText;
    }
};

export const recoverPasswordNew = async (params: IRecoverPasswordRequest) => {
    return await axios.post<IRecoveryResponse>(
        `${endpoints.recoveryPasswordNew}`,
        params
    );
};

export const loginByToken = async (params: ILoginByTokenRequest) => {
    return await axios.post<ILoginByTokenResponse>(
        `${endpoints.loginByToken}`,
        params
    );
};

export const signUp = async (params: ISignUpRequest) => {
    return await axios.post<ISignUpResponse>(`${endpoints.auth}`, params);
};

export const updateVerificationInfo = async (
    params: IUpdateVerificationInfoRequest
) => {
    return await axios.post<IUpdateVerificationInfoResponse>(
        `${endpoints.userVerificationInfo}`,
        params
    );
};

export const updateVerificationPhoto = async (
    params: IUpdateVerificationPhotoRequest
) => {
    return await axios.post(endpoints.userVerificationPhoto, params, {
        headers: { "Content-Type": "multipart/form-data" },
    });
};

export const logout = async () => {
    return await axios.get(`${endpoints.logout}`);
};

export const recoverPassword = async (params: IRecoverPasswordRequest) => {
    return await axios.get<IRecoverPasswordResponse>(
        `${endpoints.recoveryPassword}/${params.email}`
    );
};

export const impersonateUser = (params: IImpersonateUserRequest) => {
    return axios.post(endpoints.impersonateUser, params);
};

//Выбор страны 
export const getCountries = async () => {
    return await axios.get<{ countries: { id: string; name: string }[] }>(
        '/api/3/country'
    );
};

//Регистрация гугл, фейсбук
export const signInWithGoogle = async (params: ISignInWithGoogleRequest) => {
    return await axios.post<ISignResponse>("/api/3/auth/google", params);
};

export const signInWithFacebook = async (params: ISignInWithFacebookRequest) => {
    return await axios.post<ISignResponse>("/api/3/auth/facebook", params);
};


