import React from "react";
import { observer } from "mobx-react";

import { useLocale } from "src/providers/LocaleProvider";

import { useWinOrLostStatus } from "../../../hooks/useWinOrLostStatus";
import { GameFinishReason } from "../../../../../store/models";
import BaseAlert from "../BaseAlert";

const WinOrLostAlerts = () => {
    const {
        game: {
            dialogs: {
                stalemate: { title },
            },
        },
    } = useLocale();
    const { resultData, winCauses, lossCauses, isWon } = useWinOrLostStatus();

    // Turn off King slave modal & message
    if (!resultData || resultData.cause === "capture") return null;

    if (resultData.cause === GameFinishReason.STANDOFF) {
        return <BaseAlert title={title("Ничья")} />;
    }

    if (isWon) {
        return <BaseAlert title={winCauses[resultData.cause]} />;
    }

    return <BaseAlert title={lossCauses[resultData.cause]} />;
};

export const MobileWinOrLostAlerts = observer(WinOrLostAlerts);
