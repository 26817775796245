import React, { useState, useEffect } from 'react';
import BaseAlert from '../BaseAlert';
import { observer } from 'mobx-react';
import { useLocale } from 'src/providers/LocaleProvider';
import { useParams } from 'react-router-dom';
import useDoublingStageStatus from 'src/pages/game-new/hooks/useDoublingStageStatus';
import useStores from 'src/hooks/useStores';
import descRandomMessageGenerator from 'src/utils/descRandomMessageGenerator';

interface IMobileX2Alert {
    randomValue?: number | undefined;
}

export const MobileX2Alert = ({ randomValue }: IMobileX2Alert) => {
    const {
        game: {
            dialogs: {
                x2: {
                    title,
                    ApproveMessages,
                    opponentIsAccepted: { title: opponentIsAcceptedTitle },
                },
            },
        },
    } = useLocale();
    const approveObj = ApproveMessages();

    const { showAcceptOrRejectDialog, showOpponentAcceptedDoublingDialog } =
        useDoublingStageStatus();
    const { gameStore } = useStores();
    const opponentPlayer = gameStore.gameState.getPlayerByColor(
        gameStore.gameState.getOpponentColor()
    );

    const generatedTitle = descRandomMessageGenerator(randomValue);

    if (showAcceptOrRejectDialog) {
        return <BaseAlert title={title("Let's double?")} />;
    } else if (showOpponentAcceptedDoublingDialog) {
        return (
            <BaseAlert
                title={
                    generatedTitle
                        ? ApproveMessages[generatedTitle].compile(
                              { playerNick: opponentPlayer?.name },
                              '{{playerNick}}'
                          )
                        : opponentIsAcceptedTitle.compile(
                              { nickname: opponentPlayer?.name },
                              'The {{nickname}} is accepted doubling'
                          )
                }
            />
        );
    }
    return null;
};

export default observer(MobileX2Alert);
