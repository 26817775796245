import { makeStyles } from "@mui/styles";

import { appColors, squareShineColors } from "src/consts/app-config";
import { EColor } from "src/components/types";

const {
    BACKLIGHT_DARKBLUE_COLOR,
    SQUARE_START_DARKBLUE_COLOR,
    BASE_END_DARKBLUE_COLOR,
    SQUARE_START_DARKGEEN_COLOR,
    BASE_END_DARKGREEN_COLOR,
    BACKLIGHT_DEEPBLACK_COLOR,
} = squareShineColors;

import shadowPawn from "src/assets/icons/chessboardPage/shadowChesboardPieces/shadowPawn.svg";
import shadowBishop from "src/assets/icons/chessboardPage/shadowChesboardPieces/shadowBishop.svg";
import shadowKing from "src/assets/icons/chessboardPage/shadowChesboardPieces/shadowKing.svg";
import shadowQween from "src/assets/icons/chessboardPage/shadowChesboardPieces/shadowQween.svg";
import shadowRook from "src/assets/icons/chessboardPage/shadowChesboardPieces/shadowRook.svg";
import shadowKnight from "src/assets/icons/chessboardPage/shadowChesboardPieces/shadowKnight.svg";

export const useStyles = makeStyles({
    "@global": {
        "@keyframes squareHighLightBlue": {
            "0%": {
                background: BASE_END_DARKBLUE_COLOR,
                borderRadius: "30px",
            },
            "40%": {
                background: SQUARE_START_DARKBLUE_COLOR,
                borderRadius: "0px",
            },
            "60%": {
                background: SQUARE_START_DARKBLUE_COLOR,
                borderRadius: "0px",
            },
            "100%": {
                background: "none",
                borderRadius: "30px",
            },
        },
        "@keyframes squareHighLightGreen": {
            "0%": {
                background: BASE_END_DARKGREEN_COLOR,
                borderRadius: "30px",
            },
            "40%": {
                background: SQUARE_START_DARKGEEN_COLOR,
                borderRadius: "0px",
            },
            "60%": {
                background: SQUARE_START_DARKGEEN_COLOR,
                borderRadius: "0px",
            },
            "100%": {
                background: "none",
                borderRadius: "30px",
            },
        },
        "@keyframes q": {
            "0%": {
                background: "none",
            },
            "30%": {
                background: "red",
                transform: "scale(1) rotateY(-45deg)",
            },
            "50%": {
                background: "red",
                transform: "scale(1.5) rotateY(0deg)",
            },
            "70%": {
                background: "red",
                transform: "scale(1) rotateY(0deg)",
            },
            "100%": {
                background: "none",
            },
        },
        "@keyframes b": {
            "0%": {
                background: "none",
            },
            "40%": {
                background: "yellow",
                transform: "scale(1.2)",
            },
            "60%": {
                background: "yellow",
                transform: "scale(1.2)",
            },
            "100%": {
                background: "none",
            },
        },
        "@keyframes r": {
            "0%": {
                background: "none",
            },
            "40%": {
                background: "yellow",
                transform: "scale(1.2)",
            },
            "60%": {
                background: "yellow",
                transform: "scale(1.2)",
            },
            "100%": {
                background: "none",
            },
        },
        "@keyframes k": {
            "0%": {
                background: "none",
            },
            "40%": {
                background: "yellow",
                transform: "scale(1.2)",
            },
            "60%": {
                background: "yellow",
                transform: "scale(1.2)",
            },
            "100%": {
                background: "none",
            },
        },
        "@keyframes n": {
            "0%": {
                background: "none",
            },
            "40%": {
                background: "yellow",
                transform: "scale(1.3)",
            },
            "60%": {
                background: "yellow",
                transform: "scale(1.2)",
            },
            "100%": {
                background: "none",
            },
        },
        "@keyframes p": {
            "0%": {
                background: "none",
            },
            "40%": {
                background: "green",
            },
            "60%": {
                background: "green",
            },
            "100%": {
                background: "none",
            },
        },

        "@keyframes killedAnimationMain": {
            "0%": {
                opacity: 0,
                transform: "translate(-50%, -50%)",
            },
            "40%": {
                opacity: 1,
                transform: "translate(-50%, -50%) scale(1.25)",
            },
            "60%": {
                opacity: 1,
                transform: "translate(-50%, -50%) scale(1.25)",
            },
            "100%": {
                opacity: 0,
                transform: "translate(-50%, -50%) scale(0.9)",
            },
        },
        "@keyframes killedAnimationLeft": {
            "0%": {
                opacity: 0,
                transform: "translate(-80%, -50%) scale(0.6) rotateY(45deg)",
            },
            "40%": {
                opacity: 1,
                transform: "translate(-75%, -50%) scale(0.85) rotateY(45deg)",
            },
            "60%": {
                opacity: 1,
                transform: "translate(-75%, -50%) scale(0.85) rotateY(60deg)",
            },
            "100%": {
                opacity: 0,
                transform: "translate(-80%, -50%) scale(0.5) rotateY(45deg)",
            },
        },
        "@keyframes killedAnimationRight": {
            "0%": {
                opacity: 0,
                transform: "translate(-20%, -50%) scale(0.6) rotateY(-45deg)",
            },
            "40%": {
                opacity: 1,
                transform: "translate(-25%, -50%) scale(0.85) rotateY(-45deg)",
            },
            "60%": {
                opacity: 1,
                transform: "translate(-25%, -50%) scale(0.85) rotateY(-60deg)",
            },
            "100%": {
                opacity: 0,
                transform: "translate(-20%, -50%) scale(0.5) rotateY(-45deg)",
            },
        },

        "@keyframes killedAnimationBottom": {
            "0%": {
                opacity: 0,
                transform: "translate(-50%, -20%) scale(0.6)",
            },
            "40%": {
                opacity: 1,
                transform: "translate(-50%, -15%) scale(0.85)",
            },
            "60%": {
                opacity: 1,
                transform: "translate(-50%, -15%) scale(0.85)",
            },
            "100%": {
                opacity: 0,
                transform: "translate(-50%, -20%) scale(0.5)",
            },
        },
        "@keyframes killedAnimationTop": {
            "0%": {
                opacity: 0,
                transform: "translate(-50%, -80%) scale(0.5)",
            },
            "40%": {
                opacity: 1,
                transform: "translate(-50%, -75%) scale(0.6)",
            },
            "60%": {
                opacity: 1,
                transform: "translate(-50%, -75%) scale(0.75)",
            },
            "100%": {
                opacity: 0,
                transform: "translate(-50%, -80%) scale(0.5)",
            },
        },
        "@keyframes killerPieceAnimate": {
            "0%": {
                opacity: 1,
                transform: "scale(1)",
            },
            "15%": {
                opacity: 0.85,
                transform: "scale(0.80)",
            },
            "50%": {
                opacity: 0.85,
                transform: "scale(0.80)",
            },
            "100%": {
                opacity: 1,
                transform: "scale(1)",
            },
        },
    },

    noselect: {
        "-webkit-touch-callout": "none",
        "-webkit-user-select": "none",
        "-khtml-user-select": "none",
        "-moz-user-select": "none",
        "-ms-user-select": "none",
        "user-select": "none",
    },

    square: (props: { appearanceTheme: string }) => ({
        left: 0,
        touchAction: "none",
        width: "12.5%",
        height: "12.5%",
        top: "auto",
        position: "absolute",
        backgroundSize: "100%",
        borderRadius: "0px",
        "&.me": {
            ...(props.appearanceTheme === EColor.darkBlue && {
                borderRadius: "50%",
                background: "rgba(148, 146, 255, 0.7)",
                // animationTimingFunction: 'ease-in-out',
                // animationName: 'squareHighLightBlue',
                // animationIterationCount: 'infinite',
                // animationFillMode: 'forwards',
                // animationDuration: '5000ms',
                // animationDelay: '0s',
            }),
            ...(props.appearanceTheme === EColor.darkGreen && {
                borderRadius: "50%",
                background: "rgba(0, 73, 78, 0.5)",
                // animationTimingFunction: 'ease-in-out',
                // animationName: 'squareHighLightGreen',
                // animationIterationCount: 'infinite',
                // animationFillMode: 'forwards',
                // animationDuration: '5000ms',
                // animationDelay: '0s',
            }),
            ...(props.appearanceTheme === EColor.deepBlack && {
                backgroundRepeat: "no-repeat",
                backgroundSize: "85% 85%",
                backgroundPosition: "center center",
                // background: BACKLIGHT_DEEPBLACK_COLOR,
                opacity: 1,
                "&.p": {
                    backgroundImage: `url(${shadowPawn})`,
                },
                "&.r": {
                    backgroundImage: `url(${shadowRook})`,
                },
                "&.n": {
                    backgroundImage: `url(${shadowKnight})`,
                },
                "&.b": {
                    backgroundImage: `url(${shadowBishop})`,
                },
                "&.k": {
                    backgroundImage: `url(${shadowKing})`,
                },
                "&.q": {
                    backgroundImage: `url(${shadowQween})`,
                },
            }),
        },
        "&.oponent": {
            background: "none",
            ...(props.appearanceTheme === EColor.darkBlue && {
                background: BACKLIGHT_DARKBLUE_COLOR,
            }),
            ...(props.appearanceTheme === EColor.darkGreen && {
                background: SQUARE_START_DARKGEEN_COLOR,
            }),
            ...(props.appearanceTheme === EColor.deepBlack && {
                background: BACKLIGHT_DEEPBLACK_COLOR,
            }),
        },
    }),

    hoverMobile: {
        "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "200%",
            height: "200%",
            backgroundColor: "rgba(0,0,0,0.2)",
            borderRadius: "50%",
        },
    },
    hoverDesktop: {
        backgroundColor: "rgba(0,0,0,0.2)",
        borderRadius: "50%",
    },

    killedAnimation: {
        position: "relative",
        width: "100%",
        height: "100%",
        // '&.q': {
        //     animationTimingFunction: 'ease-in-out',
        //     animationName: 'q',
        //     animationIterationCount: '1',
        //     animationFillMode: 'forwards',
        //     animationDuration: '1300ms',
        //     animationDelay: '0s',
        // },
        // '&.b': {
        //     animationTimingFunction: 'ease-in-out',
        //     animationName: 'b',
        //     animationIterationCount: '1',
        //     animationFillMode: 'forwards',
        //     animationDuration: '1000ms',
        //     animationDelay: '0s',
        // },
        // '&.r': {
        //     animationTimingFunction: 'ease-in-out',
        //     animationName: 'r',
        //     animationIterationCount: '1',
        //     animationFillMode: 'forwards',
        //     animationDuration: '1000ms',
        //     animationDelay: '0s',
        // },
        // '&.k': {
        //     animationTimingFunction: 'ease-in-out',
        //     animationName: 'k',
        //     animationIterationCount: '1',
        //     animationFillMode: 'forwards',
        //     animationDuration: '1000ms',
        //     animationDelay: '0s',
        // },
        // '&.n': {
        //     animationTimingFunction: 'ease-in-out',
        //     animationName: 'n',
        //     animationIterationCount: '1',
        //     animationFillMode: 'forwards',
        //     animationDuration: '1000ms',
        //     animationDelay: '0s',
        // },
        // '&.p': {
        //     animationTimingFunction: 'ease-in-out',
        //     animationName: 'p',
        //     animationIterationCount: '1',
        //     animationFillMode: 'forwards',
        //     animationDuration: '800ms',
        //     animationDelay: '0s',
        // },
    },

    killedAnimationImg: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "50%",
        left: "50%",
        "&.killedAnimationMain": {
            transform: "translate(-50%, -50%)",
            opacity: 1,
            animationTimingFunction: "ease-in-out",
            animationName: "killedAnimationMain",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDuration: "700ms",
            animationDelay: "0.05s",
        },
        "&.killedAnimationLeft": {
            transform: "translate(-80%, -50%) scale(0.4) rotateY(45deg)",
            opacity: 1,
            animationTimingFunction: "ease-in-out",
            animationName: "killedAnimationLeft",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDuration: "700ms",
            animationDelay: "0.1s",
        },
        "&.killedAnimationRight": {
            transform: "translate(-20%, -50%) scale(0.4)",
            opacity: 1,
            animationTimingFunction: "ease-in-out",
            animationName: "killedAnimationRight",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDuration: "700ms",
            animationDelay: "0.15s",
        },
        "&.killedAnimationBottom": {
            transform: "translate(-50%, -20%) scale(0.4)",
            opacity: 1,
            animationTimingFunction: "ease-in-out",
            animationName: "killedAnimationBottom",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDuration: "700ms",
            animationDelay: "0.2s",
        },
        "&.killedAnimationTop": {
            transform: "translate(-50%, -80%) scale(0.4)",
            opacity: 1,
            animationTimingFunction: "ease-in-out",
            animationName: "killedAnimationTop",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDuration: "750ms",
            animationDelay: "0.25s",
        },
    },

    killerPieceAnimateSpan: {
        width: "100%",
        height: "100%",
        display: "block",
        "&.runAnimation": {
            transform: "scale(1)",
            opacity: 1,
            animationTimingFunction: "ease-in-out",
            animationName: "killerPieceAnimate",
            animationIterationCount: "1",
            animationFillMode: "forwards",
            animationDuration: "2000ms",
            animationDelay: "0s",
        },
    },
});
