import { Box, useMediaQuery } from "@mui/material";
import Badge from "@mui/material/Badge";
import cn from "classnames";
import { observer } from "mobx-react";
import qs from "qs";
import { useEffect, useState } from "react";
import { isAndroid, isIOS, isSafari } from "react-device-detect";
import GifPlayer from "react-gif-player";
import OutsideClickHandler from "react-outside-click-handler";
import { useHistory, useLocation } from "react-router-dom";
import loadable from "@loadable/component";

import x2DepositLogo from "src/assets/images/promoImages/x2Deposit.png";
import LogoIcon from "src/components/controls/app-bar/images/logo.png";
import { useLocale } from "src/providers/LocaleProvider";
import { Footer } from "src/components/wrapper/footer";
import { MAX_XL_TABLET } from "src/pages/game-new/constants";
import { ESocial } from "src/components/SideMenu";
import slot1 from "src/assets/images/slot1.png";
import slot2 from "src/assets/images/slot2.png";
import slot3 from "src/assets/images/slot3.png";
import casino1 from "src/assets/images/casino1.png";
import casino2 from "src/assets/images/casino2.png";
import casino3 from "src/assets/images/casino3.png";
import pockerSlot from "src/assets/images/pockerSlot.png";
import sportSlot from "src/assets/images/sportSlot.png";
import chessVideo from "src/assets/chessVideo.mp4";
import { fileHelper } from "src/shared/helpers/FileHelper";
import leftArrowIcon from "src/assets/icons/ArrowLeft.svg";

import { Avatar } from "../../../../components/Avatar";
import SocialLinks from "../../../../components/SocialLinksBox";
import Balance from "../../../../components/balance";
import {
    ButtonType,
    GreenButton,
} from "../../../../components/buttons/greenButton";
import LanguagePicker from "../../../../components/controls/app-bar/components/LanguagePicker";
import paths from "../../../../consts/paths";
import { endpointsRoot } from "../../../../core/endpoints";
import LoginForm from "../../../../dialogs/login/LoginForm";
import useStores from "../../../../hooks/useStores";
import { errorService } from "../../../../service/services";
import { ModalProvider, ModalTypes } from "../../../landing/utils/context";
import { RegisterForm } from "../../../register/RegisterForm";
import MobileNavMenu from "../mobileNavMenu/MobileNavMenu";
import { MainActionButton } from "../../../../components/buttons/newDesignButtons/mainActionButton";
import YoutubeIconGenerator from "../../../../assets/icons/social/youtubeIcon";
import DiscordIconGenerator from "../../../../assets/icons/social/discordIcon";
import { getLiveDealers, getSlots } from "../../../../service/api/externalGames";
import AppBar from '../../../../components/controls/app-bar';

import { useStyles } from "./styles";
import { IGetLiveDealerGameResponse, IGetSlotsResponse } from "../../../../entities/externalGame";

const BusinessModal = loadable(() => import("../BusinessModal"));
const Notifications = loadable(() => import("src/components/Notifications"));

const MobileVersion = () => {
    const history = useHistory();
    const { authStore, generalLobbyStore, lobbyStore, generalStore } =
        useStores();
    const user = authStore.currentUser;
    const isMobile = useMediaQuery("(max-width: 450px)");
    const joinAppTablet = useMediaQuery(
        "(min-width: 700px) and (max-width: 1059px)"
    );
    const smallRegButtons = useMediaQuery("(max-width: 700px)");
    const extraSmallRegButtons = useMediaQuery("(max-width: 412px)");
    const [showDice, setShowDice] = useState(false);
    const [jackpotNote, showJackpotNote] = useState<boolean>(false);
    const lessThanLargeTablet = window.innerWidth <= MAX_XL_TABLET;
    const isPwa = window.matchMedia("(display-mode: standalone)").matches;

    const { search } = useLocation();

    const [mobile, setMobileStatus] = useState<string>("noMobile");

    const mobileChecker = () => {
        let regMobile =
            /iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
        regMobile || window.innerWidth <= 450
            ? setMobileStatus("mobile")
            : setMobileStatus("noMobile");
    };

    useEffect(() => {
        mobileChecker();
    }, [window.innerWidth]);

    useEffect(() => {
        setTimeout(() => {
            setShowDice(true);
        }, 500);
    }, []);

    const {
        mainLanding: {
            balance: { errorOnSaveState },
            register,
            logIn,
            social,
            strategy,
            first,
            play,
            now,
            era,
            skillGames,
        },
    } = useLocale();

    const titleLetters = skillGames("Skill Games").length;

    useEffect(() => {
        if (!authStore.isAuthorized) {
            const referralId = getRefId();
            authStore.setReferralId(referralId);
        }
    }, [authStore.isAuthorized]);

    const [modalState, setModalState] = useState({
        register: false,
        login: false,
        businessMsg: false,
        notifications: false,
    });

    const userNotifications =
        authStore.currentUser?.notification?.numberOfUnseenNotifications;
    const newNotifications = userNotifications ? userNotifications : undefined;

    const getRefId = () => {
        try {
            const { ref } = qs.parse(search.substring(1));
            if (ref) {
                localStorage.setItem("refId", ref as string);
                return ref as string;
            } else {
                return localStorage.getItem("refId");
            }
        } catch (e) {}

        return null;
    };

    const openModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: true }));
    };

    const closeModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: false }));
    };

    const onHiddenChange = async (balanceHidden: boolean) => {
        try {
            await authStore.updateHiddenBalance(balanceHidden);
        } catch (err) {
            errorService.sendError(
                errorOnSaveState(
                    "Ошибка при попытке сохранить состояния (ошибка сети)"
                )
            );
        }
    };

    const styles = useStyles();

    // Данные для стримов
    const streamChannels = [
        {
            name: "Alexandra Chess",
            imageUrl: "src/assets/images/slot1.png",
            isOnline: true,
        },
        {
            name: "Veronica White",
            imageUrl: "src/assets/images/slot1.png",
            isOnline: true,
        },
        {
            name: "Paul Whisper",
            imageUrl: "src/assets/images/slot1.png",
            isOnline: true,
        },
        {
            name: "ChessTV",
            imageUrl: "src/assets/images/slot1.png",
            isOnline: true,
        },
        {
            name: "Angelina1994",
            imageUrl: "https://example.com/angelina1994.jpg",
            isOnline: false,
        },
        {
            name: "Tim Tomson",
            imageUrl: "https://example.com/tim-tomson.jpg",
            isOnline: false,
        },
    ];

    //Тест для игр
    const [slots, setSlots] = useState<IGetSlotsResponse[]>([]); // Explicitly typed initial state
    const [liveDealers, setLiveDealers] = useState<
        IGetLiveDealerGameResponse[]
    >([]); // Explicitly typed initial state

    useEffect(() => {
        const fetchSlots = async () => {
            try {
                const response = await getSlots({
                    isDesktop: true,
                    limit: 20,
                    offset: 0,
                });
                setSlots(response.data);
            } catch (error) {
                console.error("Error fetching slots:", error);
            }
        };

        const fetchLiveDealers = async () => {
            try {
                const response = await getLiveDealers({
                    isDesktop: true,
                    limit: 20,
                    offset: 0,
                });
                setLiveDealers(response.data);
            } catch (error) {
                console.error("Error fetching live dealers:", error);
            }
        };

        fetchSlots();
        fetchLiveDealers();
    }, []);

    return (
        <Box className={styles.containerLobby}>
            <ModalProvider.Provider value={{ openModal, closeModal }}>
                {modalState.register && <RegisterForm />}
                {modalState.login && <LoginForm />}
                {modalState.businessMsg && <BusinessModal />}
                {modalState.notifications && <Notifications />}
                <div className={styles.header}>
                    <AppBar isGamePage={false} sideMenuIsOpen={false} />
                    <MobileNavMenu />
                </div>

                <Box component="div" className={styles.content}>
                    <div className={styles.lobbyTitle}>
                        {!generalStore.isHalyk && (
                            <div className={styles.heroSection}>
                                <video
                                    autoPlay
                                    muted
                                    loop
                                    playsInline
                                    className={styles.heroVideo}
                                >
                                    <source src={chessVideo} type="video/mp4" />
                                </video>
                                <MainActionButton
                                    appearanceTheme="primary"
                                    text="I want Dicechess"
                                    className={styles.playButton}
                                    onClick={() => {
                                        history.push(paths.diceChessLobby);
                                    }}
                                />
                            </div>
                        )}

                        {!generalStore.isHalyk && <div className={styles.underHeroContent}>
                            <div className={styles.pockerSlots}>
                                <img
                                    src={pockerSlot}
                                    alt="Pocker Slots"
                                    className={styles.pockerImage}
                                />
                                <MainActionButton
                                    appearanceTheme="primary"
                                    text="Poker"
                                    className={styles.button}
                                    onClick={() => {
                                        history.push(paths.jackpoker);
                                    }}
                                />
                            </div>
                            <div className={styles.pockerSlots}>
                                <div className={styles.imageWrapper}>
                                    <img
                                        src={sportSlot}
                                        alt="Sport Slots"
                                        className={styles.pockerImage}
                                    />
                                    <div className={styles.arrow}>
                                        <img
                                            src={leftArrowIcon}
                                            alt="Left Arrow"
                                            className={styles.leftArrow}
                                        />
                                        <img
                                            src={leftArrowIcon}
                                            alt="Right Arrow"
                                            className={styles.rightArrow}
                                        />
                                    </div>
                                </div>
                                <MainActionButton
                                    appearanceTheme="primary"
                                    text="Sport"
                                    className={styles.button}
                                    onClick={() => {
                                        history.push(paths.sports);
                                    }}
                                />
                            </div>
                        </div>}

                        <div className={styles.slotsContainer}>
                            <div className={styles.line}></div>
                            <div className={styles.slotsHeader}>
                                <h2 className={styles.slotsTitle}>Slots</h2>
                                <MainActionButton
                                    appearanceTheme="primary"
                                    text="View all"
                                    className={styles.viewAllButton}
                                    onClick={() => {
                                        history.push(paths.algaltente);
                                    }}
                                />
                            </div>
                            <div className={styles.slotsGrid}>
                                {slots.map((slot, index) => (
                                    <img
                                        key={index}
                                        src={fileHelper.getMediaFileURL(
                                            slot.image
                                        )}
                                        onClick={async () => {
                                            history.push(
                                                `/slots/${slot.id}/play`
                                            );
                                        }}
                                        alt={slot.name}
                                        className={styles.slotImage}
                                    />
                                ))}
                            </div>
                        </div>

                        <div className={styles.liveCasinoBlock}>
                            <div className={styles.line}></div>
                            <div className={styles.slotsHeader}>
                                <h2 className={styles.slotsTitle}>
                                    Live Casino
                                </h2>
                                <MainActionButton
                                    appearanceTheme="primary"
                                    text="View all"
                                    className={styles.viewAllButton}
                                    onClick={() => {
                                        history.push(paths.liveDealer);
                                    }}
                                />
                            </div>
                            <div className={styles.slotsGrid}>
                                {liveDealers.map((dealer, index) => (
                                    <img
                                        key={index}
                                        src={fileHelper.getMediaFileURL(
                                            dealer.image
                                        )}
                                        onClick={async () => {
                                            history.push(
                                                `/live-dealer/${dealer.id}/play`
                                            );
                                        }}
                                        alt={dealer.name}
                                        className={styles.slotImage}
                                    />
                                ))}
                            </div>
                        </div>

                        {!generalStore.isHalyk && (
                            <div className={styles.streamChannelsBlock}>
                                <div className={styles.line}></div>
                                <div className={styles.streamHeader}>
                                    <h2 className={styles.streamTitle}>
                                        Our Stream Channels
                                    </h2>
                                </div>
                                <div className={styles.streamGrid}>
                                    <div className={styles.streamGrid}>
                                        {streamChannels.map(
                                            (channel, index) => (
                                                <div
                                                    key={index}
                                                    className={
                                                        styles.streamSlots
                                                    }
                                                >
                                                    {/*<img src={channel.imageUrl} alt="Stream Slots" className={styles.streamImage} />*/}
                                                    <img
                                                        src={casino1}
                                                        alt="Stream Slots"
                                                        className={
                                                            styles.streamImage
                                                        }
                                                    />
                                                    <p
                                                        className={
                                                            styles.streamName
                                                        }
                                                    >
                                                        {channel.name}
                                                    </p>
                                                    <p
                                                        className={`${
                                                            styles.streamStatus
                                                        } ${
                                                            channel.isOnline
                                                                ? styles.online
                                                                : styles.offline
                                                        }`}
                                                    >
                                                        {channel.isOnline
                                                            ? "Online"
                                                            : "Offline"}
                                                    </p>
                                                    <div
                                                        className={
                                                            styles.buttonContainer
                                                        }
                                                    >
                                                        <button
                                                            className={
                                                                styles.streamButton
                                                            }
                                                        >
                                                            <YoutubeIconGenerator
                                                                className={
                                                                    styles.iconStyle
                                                                }
                                                                fillColor="#00BA6C"
                                                            />
                                                        </button>
                                                        <button
                                                            className={
                                                                styles.streamButton
                                                            }
                                                        >
                                                            <DiscordIconGenerator
                                                                className={
                                                                    styles.iconStyle
                                                                }
                                                                fillColor="#00BA6C"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <Footer isMainPage={true} />
                </Box>

                {/*<div className={styles.footerContainer}>
                    <div className={styles.userActionLinksBox}>
                        {mobile && !isPwa && isIOS && !isAndroid && (
                            <div
                                className={styles.app}
                                onClick={() =>
                                    history.push(paths.pwaInstruction)
                                }
                            >
                                <p
                                    style={{ color: "#75F4E5" }}
                                    className={styles.join}
                                >
                                    Join our APP
                                </p>
                                <img
                                    className={styles.downloadIcon}
                                    src={appDownloadIcon}
                                />
                            </div>
                        )}
                    </div>

                    {lessThanLargeTablet && <Footer isMainPage={true} />}
                </div>*/}
            </ModalProvider.Provider>
        </Box>
    );
};

export default observer(MobileVersion);
