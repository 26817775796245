import cn from "classnames";
import { appColors } from "./../../../consts/app-config";
import { makeStyles } from "@mui/styles";
import { MAX_CHANGE_SCREEN_WIDTH } from "../../../pages/game-new/constants";
import theme from "src/theme";

import veryfiedTick from "src/assets/images/verified.png";

export const useStyles = makeStyles({
    /*
    footer: {
    width: "100%",
    background: "transparent",
    position: "fixed",
    bottom: 5,
    left: "50%",
    transform: "translate(-50%)",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    zIndex: 1000,
    gridGap: "10px",
    [theme.breakpoints.down("lg")]: {
        width: "99%",
        position: "relative",
        paddingBottom: "5px",
    },
    "&.isMainPage": {
        width: "100%",
        paddingBottom: "0px",
        [theme.breakpoints.down("lg")]: {
            marginTop: "15px",
            flexDirection: "column",
            gridGap: "5px",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "10px",
        },
    },
    },

    footerNavlinksWrapper: {
        /*        maxWidth: "95%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        gridGap: "6px",
        [theme.breakpoints.down("md")]: {
            maxWidth: "100%",
        },
        "&.isMainPage": {
            maxWidth: "100%",
            gridGap: "4px",
            [theme.breakpoints.down("sm")]: {
                gridGap: "0px",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "14px",
                gridColumnGap: "5px",
            },
        },
    },


    link: {
        // color: appColors.txt400,
        // fontWeight: 500,
        // fontSize: 20,
        // margin: '0px 15px 10px',
        /*color: appColors.semiwhite,
        fontWeight: 600,
        fontSize: "24px",
        lineHeight: "26px",
        cursor: "pointer",
        opacity: 0.75,

        textDecoration: "none",
        backdropFilter: "blur(7px)",
        padding: "2px 5px",
        borderRadius: "5px",
        background: "#00000025",
        transition: "all ease-in 200ms",
        "@media screen and (max-width: 1536px)": {
            fontSize: "16px",
            lineHeight: "18px",
        },
        "@media (hover: hover)": {
            "&:hover": {
                transition: "all ease-in 200ms",
                opacity: 1,
            },
        },
        "&.isMainPage": {
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "16px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
                lineHeight: "14px",
            },
        },
        "&.gclogo": {
            cursor: "pointer",
            opacity: 1,
            width: "150px",
            height: "50px",
            padding: "1px 2px",
            margin: 0,
            backgroundColor: appColors.semiwhite,
            [theme.breakpoints.down("lg")]: {
                width: "120px",
                height: "40px",
            },
            [theme.breakpoints.down("md")]: {
                width: "90px",
                height: "30px",
            },
        },
        
    },

    imageGcBox: {
        /*
         width: "140px",
        height: "45px",
        float: "right",
        margin: "1px 3px 0px 5px",
        borderRadius: "5px",
        position: "relative",
        "&:before": {
            content: '""',
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "25px",
            height: "15px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundImage: `url(${veryfiedTick})`,
            transform: "translate(-50%, 50%)",
        },
        [theme.breakpoints.down("md")]: {
            width: "120px",
            height: "40px",
            "&:before": {
                content: '""',
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "22px",
                height: "12px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundImage: `url(${veryfiedTick})`,
                transform: "translate(-50%, 50%)",
            },
        },
        [theme.breakpoints.down("sm")]: {
            width: "90px",
            height: "30px",
            "&:before": {
                content: '""',
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "16px",
                height: "10px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundImage: `url(${veryfiedTick})`,
                transform: "translate(-50%, 50%)",
            },
        },
        
       
    },

    imageGcLogo: {
        width: "100%",
        height: "100%",
        objectFit: "contain",

    },

    aboutUsBox: {
        /*maxWidth: "70%",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("lg")]: {
            height: "80px",
            maxWidth: "100%",
            alignItems: "flex-start",
            overflow: "scroll",
        },
        [theme.breakpoints.down("lg")]: {
            height: "55px",
            maxWidth: "calc(100% - 6px)",
        },
        [theme.breakpoints.down("sm")]: {
            height: "50px",
        },
        
    },

    aboutUsText: {
        /*color: "white",
        fontSize: 14,
        margin: 0,
        borderRadius: "5px",
        backdropFilter: "blur(5px)",
        marginLeft: 10,
        backgroundColor: "#0000004f",
        letterSpacing: "-1px",
        padding: "0px 3px",

        textAlign: "justify",
        [theme.breakpoints.down("lg")]: {
            fontSize: 12,
            marginLeft: 0,
            textAlign: "center",
            lineHeight: "14px",
        },
        [theme.breakpoints.down("md")]: {
            padding: 0,
            fontSize: 10,
            lineHeight: "12px",
            letterSpacing: "-0.5px",
            "&.showLink": {
                textAlign: "justify",
            },
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: 8,
            lineHeight: "10px",
            "&.showLink": {
                textAlign: "justify",
            },
        },
        [theme.breakpoints.up(1800)]: {
            fontSize: 20,
            lineHeight: "20px",
            "&.showLink": {
                textAlign: "justify",
            },
        },
        [theme.breakpoints.up(2400)]: {
            fontSize: 24,
            lineHeight: "22px",
            "&.showLink": {
                textAlign: "justify",
            },
        },
        
    },
    */

    footer: {
        padding: '0 0.5vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '6vw',
        '@media (max-width: 1001px)': {
            padding: '0 1.5vw',
        },
        '@media (max-width: 801px)': {
            padding: '0 1.5vw',
        },
        '@media (max-width: 701px)': {
            padding: '0 1.5vw',
        },
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        '@media (max-width: 1001px)': {
            padding: '1.5vw 0',
        },
        '@media (max-width: 801px)': {
            padding: '1.5vw 0',
        },
        '@media (max-width: 701px)': {
            padding: '1.5vw 0',
        },
    },
    logoIcon: {
        width: '9vw',
        height: 'auto',
        '@media (max-width: 1001px)': {
            width: '20%',
        },
        '@media (max-width: 801px)': {
            width: '20%',
        },
        '@media (max-width: 701px)': {
            width: '20%',
        },
    },
    gcLogo: {
        width: '8vw',
        height: 'auto',
        '@media (max-width: 1001px)': {
            width: '20%',
        },
        '@media (max-width: 801px)': {
            width: '20%',
        },
        '@media (max-width: 701px)': {
            width: '20%',
        },
    },
    aboutUsBox: {
        width: '100%',
        textAlign: 'center',
        marginTop: '0.5vw',
    },
    aboutUsText: {
        color: '#888888',
        fontFamily: 'Montserrat, sans- serif',
        fontWeight: 400,
        fontSize: '0.8vw',
        lineHeight: '1.1vw',
        verticalAlign: 'top',
        display: 'inline-block',
        textAlign: 'justify',
        '@media (max-width: 1200px)': {
            fontSize: '1.5vw',
            lineHeight: '2.1vw'
        },
        '@media (max-width: 701px)': {
            fontSize: '2.5vw',
            lineHeight: '3.5vw'
        },
    },
    footerNavlinksWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexWrap: 'wrap',
        marginTop: '2.3vw',
        '@media (max-width: 701px)': {
            flexDirection: 'column',
        },
    },
    link: {
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        lineHeight: "1vw",
        textDecoration: 'none',
        fontSize: '0.8vw',
        '&:hover': {
            textDecoration: 'none',
        },
        '@media (max-width: 1001px)': {
            fontSize: '1.5vw',
        },
        '@media (max-width: 801px)': {
            fontSize: '1.5vw',
        },
        '@media (max-width: 701px)': {
            fontSize: '2.5vw',
            padding: '2vw 0',
            fontWeight: '500',
            lineHeight: "2vw",
            width: '100%',
        },
    },

    businessContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        '@media (max-width: 701px)': {
            flexDirection: 'column',
        },
    },

    business: {
        //height: '2.5vw',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        lineHeight: "1vw",
        fontSize: '1vw',
        padding: '0.5vw 0.8vw',
        margin: '0 2vw',
        cursor: 'pointer',
        border: '0.1vw solid #fff',
        borderRadius: "0.2vw",
        '@media (max-width: 701px)': {
            fontSize: '2.5vw',
            padding: '2vw 0',
            margin: '2vw 0',
            fontWeight: '500',
            lineHeight: "2vw",
            width: '95vw',
            height: '7vw',
            justifyContent: 'center',
        },
    },
    businessIcon: {
        marginRight: theme.spacing(1),
        width: '1vw',
        height: '1vw',
        '@media (max-width: 701px)': {
            width: '4vw',
            height: '4vw',
        },
    },
    social: {
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontWeight: '600',
        lineHeight: "0.7vw",
        fontSize: '0.85vw',
        border: '0.1vw solid #fff',
        padding: '0.4vw',
        borderRadius: "0.2vw",
        '& > span': {
            marginRight: theme.spacing(2),
        },
        '@media (max-width: 701px)': {
            fontSize: '2.5vw',
            padding: '2vw 0',
            margin: '2vw 0',
            fontWeight: '500',
            lineHeight: "2vw",
            width: '95vw',
            height: '7vw',
            justifyContent: 'center',
        },
    },

    socialIcons: {
        display: "flex",
        gap: "1.5vw",
        justifyContent: "space-between",
        '@media (max-width: 701px)': {
            gap: "3vw",
        },
    },

    iconContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: "2.6vw",
        marginBottom: "4.5vw",
        marginTop: '2vw',
        '@media (max-width: 1200px)': {
            height: "3vw",
            marginBottom: "5vw",
            marginTop: '2.5vw',
        },

        '@media (max-width: 992px)': {
            height: "3.5vw",
            marginBottom: "5.5vw",
            marginTop: '3vw',
        },

        '@media (max-width: 768px)': {
            height: "4vw",
            marginBottom: "6vw",
            marginTop: '3.5vw',
        },

        '@media (max-width: 576px)': {
            height: "5vw",
            marginBottom: "7vw",
            marginTop: '4vw',
        },
    },

    payIcon: {
        '@media (max-width: 576px)': {
            width: '10%',
        },
        width: 'auto',
        '@media (max-width: 540px)': {
            width: '10%',
        },
    },

    smallIcon: {
        [theme.breakpoints.down('sm')]: {
            width: '15px',
            height: '15px',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '20px',
            height: '20px',
        },
    },

    socialIcon: {
        width: 'auto',
        '@media (max-width: 576px)': {
            width: '15%',
        },
        '@media (max-width: 540px)': {
            width: '20%',
        },
    },
});
