import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import avatarPlaceholder from 'src/assets/images/defaultPlaceholder.png';

import { useStyles } from './style';
interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    placeholderImg?: string;
    errorImg?: string;
    alt?: string;
    id?: string;
    setLoaded: (status: boolean) => void;
    wrapperClassName?: string;
}
const LazyImages = ({
    src,
    placeholderImg,
    errorImg,
    alt,
    id,
    setLoaded,
    wrapperClassName,
    ...props
}: ImageProps) => {
    const classes = useStyles();
    const [status, setStatus] = useState<'loading' | 'error' | 'loaded'>(
        'loading'
    );
    const ref = useRef<HTMLImageElement>(null);

    const onLoad = () => {
        setStatus('loaded');
        setLoaded(true);
    };

    const onError = () => {
        setStatus('error');
    };

    useEffect(() => {
        if (ref.current?.complete) setStatus('loaded');
        else setStatus('loading');
    }, [src]);

    return (
        <>
            <div className={cn(classes.lazyImageWrapper, wrapperClassName)}>
                <img
                    {...props}
                    decoding="async"
                    ref={ref}
                    onLoad={onLoad}
                    onError={onError}
                    src={src}
                    alt={alt}
                    id={id}
                    className={cn(props.className, classes.mainImg)}
                    style={{
                      backgroundImage: `url(${placeholderImg})`,
                      visibility: status !== 'loaded' ? 'hidden' : 'visible',
                    }}
                />
                <img
                    {...props}
                    decoding="async"
                    alt={alt}
                    src={placeholderImg || errorImg}
                    id={id}
                    className={cn(classes.image, props.className)}
                    style={{
                        display: !(status === 'error' || status === 'loading')
                            ? 'none'
                            : undefined,
                    }}
                />
            </div>
        </>
    );
};

export default LazyImages;
