import { AxiosError } from 'axios';

import { ILocaleItem } from 'src/store/models';

export class LocaleHelper {
    public static extractFromError(e: AxiosError) {
        if (e.response?.data?.error?.locale) {
            return e.response?.data?.error?.locale as ILocaleItem;
        }
        return null;
    }
}
