import React, { useEffect, useState, memo, useMemo } from "react";
import moment from "moment";
import cn from "classnames";

import avatarPlaceholder from "src/assets/images/defaultPlaceholder.png";
import LazyImages from "src/components/lazyImages";
import useChangeTheme from "src/hooks/useChangeTheme";

import { IGameChatBody } from "../types/IChat";
import { fileHelper } from "../../../../../shared/helpers/FileHelper/index";

import { useStyles } from "./styles";

export const GameChatHistoryMessage = memo(
    ({ children, time, position, getStickerMessage }: IGameChatBody) => {
        const classes = useStyles({ position });
        const appearanceTheme = useChangeTheme();
        const CURRENT_FORMAT = "HH:mm:ss";
        const [isLoaded, setLoaded] = useState<boolean>(false);

        const momentTime = useMemo(() => {
            return moment(time).format(CURRENT_FORMAT);
        }, [time]);

        return (
            <div className={cn(classes.messageRow, appearanceTheme)}>
                <div className={cn(classes.messageBlock, appearanceTheme)}>
                    <div
                        className={
                            position === "center"
                                ? cn(classes.infoMessageText, appearanceTheme)
                                : cn(classes.playerMessageText, appearanceTheme)
                        }
                    >
                        {getStickerMessage ? (
                            <>
                                {!isLoaded && (
                                    <div
                                        className={cn(
                                            classes.stckerSkeletonImageWrapper,
                                            appearanceTheme,
                                            {
                                                loadingSkeleton: !isLoaded,
                                            }
                                        )}
                                    ></div>
                                )}
                                <LazyImages
                                    className={cn(
                                        classes.stickerImageWrapper,
                                        appearanceTheme,
                                        {
                                            isLoaded: isLoaded,
                                        }
                                    )}
                                    placeholderImg={avatarPlaceholder}
                                    src={fileHelper.getFileURL(
                                        getStickerMessage
                                    )}
                                    errorImg={avatarPlaceholder}
                                    setLoaded={(status: boolean) =>
                                        setLoaded(status)
                                    }
                                />
                            </>
                        ) : (
                            children
                        )}
                    </div>
                    {/* <p className={cn(classes.messageTime, appearanceTheme)}>
                        {momentTime}
                    </p> */}
                </div>
            </div>
        );
    }
);
