import {
    action,
    computed,
    makeAutoObservable,
    reaction,
    runInAction,
} from "mobx";

import { ColorVariant, GameStatus, OnlineStatus } from "../models";

import { gameMove } from "src/service/api/game";
import { errorService } from "src/service/services";

import FenHelper, { DiceStatus, IDiceItemDetails } from "./utils/helper";
import FenParser from "./utils/parser";
import { IClockPerPlayer, isGameNotification } from "./types";
import { generateMove } from "./utils";

import GameStore from "./index";

const fenParser = new FenParser(FenHelper);

class GameState {
    gameStore: GameStore;
    currentMove: {
        from: string;
        to: string;
    } | null = null;
    diceRnd: string = Math.random().toString();
    currentSquare?: string;
    localFen: string = "";
    rolled: boolean = false;
    squareStyles: { [s: string]: boolean } = {};
    diceColor: ColorVariant.white | ColorVariant.black = ColorVariant.white;
    fullDices: [number, number, number] = [-1, -1, -1];

    constructor(gameStore: GameStore) {
        makeAutoObservable(this);
        this.gameStore = gameStore;
        reaction(
            () => {
                return {
                    currentSquare: this.currentSquare,
                    // legalMoves: this.legalSquares,
                };
            },
            () => {
                this.updateSquareStyles();
            }
        );

        reaction(
            () => {
                return this.fen.split(" ")[1] as
                    | ColorVariant.white
                    | ColorVariant.black;
            },
            (value) => {
                runInAction(() => {
                    this.diceColor = value;
                });
            },
            { fireImmediately: true }
        );

        reaction(
            () => {
                return this.fen.split(" ")[4] as string;
            },
            (value) => {
                if (value.length === 3) {
                    runInAction(() => {
                        this.fullDices = value
                            .split("")
                            .map((l) => fenParser.helper.letterToNumber(l)) as [
                            number,
                            number,
                            number
                        ];
                    });
                } else if (this.fullDices[0] === -1) {
                    runInAction(() => {
                        this.fullDices = fenParser.getLastDices(
                            this.history
                        ) as [number, number, number];
                    });
                    return this.fullDices;
                }
            }
        );
    }

    @action
    setLocalFen(fen: string) {
        this.localFen = fen;
    }

    @action
    updateLocalFenWithMove(from: string, to: string) {
        this.setLocalFen(generateMove(this.localFen, from, to));
    }

    get legalMovesSquares() {
        return this.legalMoves
            .filter((s) => s.slice(0, 2) === this.currentSquare)
            .map((s) => s.slice(2, 4));
    }

    @computed
    get allowDragAndMoveDisabled() {
        return (
            this.gameStore.isViewMode() ||
            this.gameStore.dialogs.selectFiguresDialog.isOpen //||
            //!this.isMyTurn()
        );
    }

    @computed
    isAllowDrag(piece: string) {
        if (this.allowDragAndMoveDisabled) return false;
        const color = this.getMyColor();

        if (piece.toLowerCase() === piece) {
            return color[0] === ColorVariant.black;
        } else {
            return color[0] === ColorVariant.white;
        }
    }

    @computed
    shouldHandlePromotion(currentSquareLocal: string, square: string) {
        const figures = this.legalMoves
            ? this.legalMoves
                  .filter(
                      (m) =>
                          m.substring(0, 4) ===
                              `${currentSquareLocal}${square}` && m.length === 5
                  )
                  .map((m) => m.substring(4))
                  .map((figure) => figure.toUpperCase())
            : [];
        this.setMove({
            from: currentSquareLocal,
            to: square,
        });
        if (figures.length > 0 && this.currentMove) {
            this.gameStore.dialogs.showSelectFigureDialog(
                figures,
                this.currentMove?.from,
                this.currentMove?.to
            );
            return true;
        } else {
            return false;
        }
    }

    //====
    @action
    async moveNew(move: string, gameId: string) {
        try {
            const res = await gameMove({ move: move, gameId: gameId });
            if ("success" in res.data) {
                console.log("Move Response", res.data);
                return res.data.success;
            }
        } catch (e) {
            errorService.sendError(e.response?.data?.message || "Move error");
        }
    }
    async onDrop(params: { from: string; to: string }) {
        const { from: sourceSquare, to: targetSquare } = params;
        if (!this.gameStore?.currentGameNew?._id) return;

        const success = await this.moveNew(
            sourceSquare + targetSquare,
            this.gameStore.currentGameNew._id
        );

        //Return move on chessboard if error on request move
        if (!success) return;

        if (!this.shouldHandlePromotion(sourceSquare, targetSquare)) {
            if (
                this.legalMoves &&
                this.legalMoves.find((m) => m === sourceSquare + targetSquare)
            ) {
                this.updateLocalFenWithMove(sourceSquare, targetSquare);
                this.gameStore.move(sourceSquare + targetSquare);
                this.clearMoves();
            }
        } else {
            this.gameStore.gameState.setMove({
                from: sourceSquare,
                to: targetSquare,
            });
            this.updateLocalFenWithMove(sourceSquare, targetSquare);
        }
    }

    async onSquareClick(targetSquare: string) {
        const cQuare = this.legalSquares.find((s) => s === targetSquare);
        // const cQuare = this.legalSquares.find((s) => s.includes(targetSquare));
        if (cQuare) {
            // set current square if we its meetштп the conditions
            this.setCurrentSquare(targetSquare);
        } else {
            // if we have wrong data return
            if (!this.currentSquare || !this.gameStore?.currentGameNew?._id)
                return;
            //Check if we have aviliable move. If true - run request, else
            if (this.squareStyles && this.squareStyles[targetSquare]) {
                const success = await this.moveNew(
                    this.currentSquare + targetSquare,
                    this.gameStore?.currentGameNew?._id
                );
                if (!success) return;
                if (
                    !this.shouldHandlePromotion(
                        this.currentSquare,
                        targetSquare
                    )
                ) {
                    if (
                        this.legalMovesSquares.find(
                            (s) =>
                                s === targetSquare && s !== this.currentSquare
                        )
                    ) {
                        this.updateLocalFenWithMove(
                            this.currentSquare,
                            targetSquare
                        );
                        this.gameStore.move(this.currentSquare + targetSquare);
                    }
                } else {
                    this.setMove({
                        from: this.currentSquare,
                        to: targetSquare,
                    });
                }
                this.clearMoves();
            }
            //Return move on chessboard if error on request move
            this.clearMoves();
        }
    }

    @computed
    getBacklightsSquares() {
        const currentSquare = this.currentSquare;
        if (!currentSquare) return;
        const settings =
            this.gameStore.rootStore.authStore.currentUser?.settings;
        // const showLegalMovesHints = settings?.hightlightMoves
        const showLegalMovesHints = true;
        const legalMoveSquares = this.legalMovesSquares;

        if (!showLegalMovesHints && legalMoveSquares.length !== 0) {
            return {
                [currentSquare]: true,
            };
        }

        const moves =
            showLegalMovesHints &&
            legalMoveSquares &&
            legalMoveSquares.reduce((acc, s) => {
                acc[s] = true;
                return acc;
            }, {});

        if (moves && Object.keys(moves).length !== 0) {
            if (currentSquare)
                return {
                    ...moves,
                    [currentSquare]: true,
                };
            return moves;
        }

        return {};
    }

    @action
    setCurrentSquare(square?: string) {
        this.currentSquare = square;
    }

    getSquareStyles() {
        return (
            (this.currentSquare &&
                this.legalSquares.includes(this.currentSquare) &&
                this.getBacklightsSquares()) ||
            {}
        );
    }

    @action
    updateSquareStyles() {
        this.squareStyles = this.getSquareStyles();
    }

    @action
    clearMoves() {
        this.currentSquare = undefined;
    }

    get bank() {
        return this.userBet * 2;
    }

    get userBet() {
        return (this.gameStore.gameSettings?.bet || 0) * this.doublingK;
    }

    get doublingK() {
        if (!this.gameStore.initData) return 1;
        const doublingData = this.gameStore.initData.doubling;
        if (!doublingData) return 1;
        return doublingData.multiplier === 1
            ? 1
            : doublingData.accepted
            ? doublingData.multiplier
            : doublingData.multiplier / 2;
    }

    @computed
    get gameResultData() {
        return this.gameStore.initData?.over || null;
    }

    // @computed
    // get isOver() {
    //     return this.gameStore.currentGame?.status === GameStatus.FINISHED;
    // }
    //====
    @computed
    get isOver() {
        return this.gameStore.currentGameNew?.state === GameStatus.FINISHED;
    }

    @computed
    get isWon() {
        const result = this.gameResultData;
        if (result === null) return false;
        const winner = result.winner;
        const currentUserId =
            this.gameStore.rootStore.authStore.currentUser?._id;
        if (!this.gameStore.initData) return false;

        const p = this.gameStore.initData.players.find(
            (t) => t.id === currentUserId
        );
        return p?.color === winner;
    }

    //====
    @computed
    get fen() {
        // return this.gameStore.state?.fen || "8/8/8/8/8/8/8/8";
        return this.gameStore.stateNew?.fen || "8/8/8/8/8/8/8/8";
    }

    // @computed
    // get legalMoves() {
    //     return this.gameStore.state?.legalMoves || [];
    // }

    // @computed
    // get legalSquares() {
    //     return this.legalMoves.map((m) => m.slice(0, 2));
    // }
    //==
    @computed
    get legalMoves() {
        return this.gameStore.stateNew?.legalMoves || [];
    }

    @computed
    get legalSquares() {
        return this.legalMoves.map((m) => m.slice(0, 2));
    }

    // get history() {
    //     return this.gameStore.initData?.history || [];
    // }
    //====
    get history() {
        return this.gameStore.stateNew?.history || [];
    }

    @computed
    get legalPieces() {
        //return this.gameStore.state?.legalPieces || [];
        return this.gameStore.stateNew?.legalPieces || [];
    }

    @computed
    get killedPieces() {
        if (!this.fen || !this.history) return {};
        return fenParser.getKilledPieces(this.history, this.fen);
    }

    @computed
    get killedPiecesNew() {
        const killed = {
            w: {
                P: 0,
                N: 0,
                B: 0,
                R: 0,
                Q: 0,
                K: 0,
            },
            b: {
                P: 0,
                N: 0,
                B: 0,
                R: 0,
                Q: 0,
                K: 0,
            },
        };

        if (!this.gameStore.stateNew.movesHistory) return killed;
        const newArr = this.gameStore.stateNew.movesHistory.filter((item) => {
            return typeof item?.beatFigure === "string";
        });
        for (let index = 0; index < newArr.length; index++) {
            const element = newArr[index];
            const beatFigureUpper = element.beatFigure?.toUpperCase();
            if (typeof beatFigureUpper === "string") {
                if (
                    //Определяем белых
                    this.gameStore.currentGameNew?.players[0]._id ===
                    element.user
                ) {
                    killed[ColorVariant.white][beatFigureUpper] =
                        killed[ColorVariant.white][beatFigureUpper] + 1;
                }
                if (
                    this.gameStore.currentGameNew?.players[0]._id !==
                    element.user
                )
                    killed[ColorVariant.black][beatFigureUpper] =
                        killed[ColorVariant.black][beatFigureUpper] + 1;
            }
        }

        return killed;
    }

    @computed
    get plainFen() {
        return this.gameStore.state?.fen.split(" ")[0] || "8/8/8/8/8/8/8/8";
    }

    @computed
    getMyColor() {
        const players = this.gameStore.initData?.players;
        if (!players) return ColorVariant.none;
        return (
            players.find(
                (p) =>
                    this.gameStore.rootStore.authStore.currentUser?._id === p.id
            )?.color || ColorVariant.none
        );
    }

    @computed
    getMyScore() {
        const players = this.gameStore.initData?.players;
        if (!players) return ColorVariant.none;
        return (
            players.find(
                (p) =>
                    this.gameStore.rootStore.authStore.currentUser?._id === p.id
            )?.score || 0
        );
    }

    @computed
    getOpponentColor() {
        const players = this.gameStore.initData?.players;
        if (!players) return ColorVariant.none;
        return (
            players.find(
                (p) =>
                    this.gameStore.rootStore.authStore.currentUser?._id !== p.id
            )?.color || ColorVariant.none
        );
    }

    @computed
    getOpponentScore() {
        const players = this.gameStore.initData?.players;
        if (!players) return 0;
        return (
            players.find(
                (p) =>
                    this.gameStore.rootStore.authStore.currentUser?._id !== p.id
            )?.score || 0
        );
    }

    @computed
    getCurrentPlayer() {
        const players = this.gameStore.initData?.players;
        if (!players) return null;
        const clock = this.activeClock;
        if (!clock.running) return null;

        return players.find((p) => p.color === clock.color);
    }

    @computed
    getPlayerByColor(color: ColorVariant) {
        const players = this.gameStore.initData?.players;
        if (!players) return null;
        return players.find((p) => p.color === color);
    }

    @computed
    get myTimeLeft() {
        const color = this.getMyColor();
        if (!color) return 0;
        return (
            this.gameStore.clock.find((clock) => clock.color === color)
                ?.timeleft || 0
        );
    }

    @computed
    get opponentTimeLeft(): number {
        const color = this.getOpponentColor();
        return (
            this.gameStore.clock.find((clock) => clock.color === color)
                ?.timeleft || 0
        );
    }

    @computed
    playerTimeLeft(color: ColorVariant): number {
        return (
            this.gameStore.clock.find((clock) => clock.color === color)
                ?.timeleft || 0
        );
    }

    @computed
    getTimeLeftByColor(color: ColorVariant) {
        if (!color) return 0;
        return (
            this.gameStore.clock.find((clock) => clock.color === color)
                ?.timeleft || 0
        );
    }

    get activeClock(): IClockPerPlayer {
        return (
            this.gameStore.clock.find((clock) => clock.running) || {
                running: false,
                timeleft: 0,
                color: ColorVariant.none,
            }
        );
    }

    @computed
    isMyTurn(): boolean {
        const clock = this.activeClock;
        return clock.running && clock.color == this.getMyColor();
    }

    @computed
    isGameOver() {
        return this.gameResultData !== null;
    }

    @computed
    hasSpecialActivity() {
        // checks draw, resignation, doubling
        return (
            this.gameStore.dialogs.drawDialog.isOpen ||
            this.gameStore.dialogs.giveUpDialog.isOpen ||
            this.gameStore.dialogs.doublingDialog.isOpen
        );
    }

    @computed
    isRematchAccepted() {
        const rematchInfo = this.gameStore.rematch;
        return (
            rematchInfo[ColorVariant.black] && rematchInfo[ColorVariant.white]
        );
    }

    @computed
    getPlayersOnlineStatus() {
        const connectivity = this.gameStore.connectivity;
        let conn = [...this.gameStore.connectivity];

        let userIndex = this.gameStore.isMyGame ? 0 : 1;
        if (connectivity.length === 1) {
            conn = [
                ...connectivity,
                { idx: userIndex, status: OnlineStatus.online },
            ];
        }

        conn.sort((a, b) => a.idx - b.idx);
        return conn;
    }

    @computed
    get chatMessages() {
        if (!this.gameStore.messages) return;

        const getPositionByColor = (color: ColorVariant) => {
            const isPosition = {
                right: "rtl",
                left: "ltr",
            };
            const { right, left } = isPosition;
            return this.getMyColor() && this.getMyColor() === color
                ? right
                : left;
        };
        return this.gameStore.messages.map((item) => {
            return {
                ...item,
                position: isGameNotification(item)
                    ? item.position
                    : getPositionByColor(item.color),
            };
        });
    }

    get notifications() {
        return this.gameStore.notifications;
    }

    @computed
    toRoll() {
        if (this.rolled) return false;
        if (!this.isMyTurn()) return false;
        if (!this.fen || !this.history) return false;
        return fenParser.getFenData(this.fen).legal_pieces === "-";
    }

    @computed
    getDiceColor() {
        if (fenParser.getFenData(this.fen).legal_pieces === "-") {
            return this.diceColor === ColorVariant.black
                ? ColorVariant.white
                : ColorVariant.black;
        } else {
            return this.diceColor;
        }
    }

    @computed
    getCurrentDices(): [IDiceItemDetails, IDiceItemDetails, IDiceItemDetails] {
        if (!this.fen)
            return Array<IDiceItemDetails>(3).fill({
                figure: 0,
                active: false,
                status: DiceStatus.inactive,
            }) as [IDiceItemDetails, IDiceItemDetails, IDiceItemDetails];

        const t = fenParser.getDiceDetail(this.fen, this.history) as [
            IDiceItemDetails,
            IDiceItemDetails,
            IDiceItemDetails
        ];

        const figureState = this.legalPieces.reduce((memo, item) => {
            memo[item] = (memo[item] || 0) + 1;
            return memo;
        }, {});

        return t.map((diceItem, i) => {
            if (figureState[diceItem.figure]) {
                const isActive = diceItem.active || false;
                figureState[diceItem.figure]--;
                return {
                    ...diceItem,
                    isActive: isActive,
                };
            }
            return diceItem;
        }) as [IDiceItemDetails, IDiceItemDetails, IDiceItemDetails];
    }

    @computed
    getLastDicesSet(): [number, number, number] {
        if (!this.fen || !this.history) {
            return Array<number>(3).fill(-1) as [number, number, number];
        }
        //Из-за заполнения -1, мы получаем на компоненте Dice, значение index === -1,

        // return this.fullDices;
        return fenParser.getLastDices(this.history) as [number, number, number];
    }

    @action
    setMove(move: { from: string; to: string }) {
        this.currentMove = move;
    }

    @action
    resetMove() {
        this.currentMove = null;
    }

    @computed
    showX2Button() {
        if (!this.gameStore.initData) return;
        return (
            !this.rolled &&
            this.isMyTurn() &&
            !this.hasSpecialActivity() &&
            this.gameStore.initData.doubling.aggressor !== this.getMyColor()
        );
    }
}

export default GameState;

/*
 const currentPlayerDice = parser.getDiceDetailNew(fen, fenHistory);

        setNoanimation(fen === lastFen);

        if (currentPlayerDice.filter((d) => d.figure !== 0).length === 3) {
            setLastActiveDices(currentPlayerDice);
        }
        console.log(currentPlayerDice);

        setLastDices(getLastDices(fenHistory));

        setCurrentPlayerDice(
            fen === lastFen ? lastActiveDices : currentPlayerDice
        );

        setLastFen(fen);
 */
