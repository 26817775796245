import React, { memo } from "react";
import cn from "classnames";
import { observer } from "mobx-react";

import {
    GameModeType,
    GameModeTypeNew,
    UserBalanceType,
    UserBalanceTypeNew,
    ChessboardType,
} from "src/store/models";
import { useChessBoardProps } from "src/pages/game-new/hooks/useChessBoardProps";
import useStores from "src/hooks/useStores";
import LocaleText from "src/components/locale-text/locale.text";
import useChangeTheme from "src/hooks/useChangeTheme";
import { EColor } from "src/components/types";
import { MAX_CHANGE_SCREEN_WIDTH } from "src/pages/game-new/constants";
import { useWinOrLostStatus } from "src/pages/game-new/hooks/useWinOrLostStatus";

import CoinAnimationContainer from "./coinAnimation/coinAnimationContainer";
import useStyles from "./styles";
import BankBg from "./images/bankBg";

interface ICoin {
    isWon?: any;
    amount?: any;
    children?: any;
}

const Bank = ({ isWon, amount, children }: ICoin) => {
    const classes = useStyles();
    const { resultData } = useWinOrLostStatus();
    const { isMultiTable, isTwoGameTable } = useChessBoardProps();
    const { gameStore } = useStores();
    const amountProp = gameStore?.currentGameNew?.bank || 6;

    // const gameType = gameStore?.currentGame?.type;
    //====
    const gameType = gameStore?.currentGameNew?.type;
    // const balanceType = gameStore?.currentGame?.balanceType;
    //====
    const balanceType = gameStore?.currentGameNew?.gameType;
    const appearanceTheme = useChangeTheme();
    const width = window.innerWidth;

    return (
        <>
            <div
                className={cn(classes.area, {
                    isMultiTable,
                    isTwoGameTable,
                    isWinnerAndHideBank: resultData?.winner,
                })}
            >
                <div
                    className={cn(classes.contentWrapperNew, appearanceTheme, {
                        isMultiTable: isMultiTable,
                    })}
                >
                    {gameType === GameModeTypeNew.REGULAR && (
                        <>
                            {/* <div className={classes.backgroundImg}>
                                <BankBg
                                    color1={
                                        appearanceTheme === EColor.darkBlue
                                            ? darkBlue.color1
                                            : appearanceTheme ===
                                              EColor.darkGreen
                                            ? darkGreen.color1
                                            : darkBlue.color1
                                    }
                                    color2={
                                        appearanceTheme === EColor.darkBlue
                                            ? darkBlue.color2
                                            : appearanceTheme ===
                                              EColor.darkGreen
                                            ? darkGreen.color2
                                            : darkBlue.color2
                                    }
                                    width={
                                        width <= MAX_CHANGE_SCREEN_WIDTH &&
                                        '350'
                                    }
                                    height={
                                        width <= MAX_CHANGE_SCREEN_WIDTH &&
                                        '105'
                                    }
                                />
                            </div> */}
                            {children}
                            <div
                                className={cn(classes.amount, {
                                    gold:
                                        balanceType === UserBalanceTypeNew.play,
                                    silver:
                                        balanceType ===
                                        UserBalanceTypeNew.coins,
                                    isMultiTable: isMultiTable,
                                })}
                            >
                                {amountProp || amount}
                            </div>
                            {/* <LocaleText
                                label='game.pot'
                                defaultValue='Банк'
                                className={classes.potText}
                            /> */}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default observer(Bank);
