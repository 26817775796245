import { makeAutoObservable } from "mobx";

import {
    ExternalGameType,
    ILiveDealerGame,
    ISlotGame,
    LiveDealerModel,
    SlotModel,
} from "src/entities/externalGame";
import { ExternalGameService } from "src/entities/externalGame/api/externalGame";
import { getLiveDealers, getSlots } from "src/service/api/externalGames";

class ExternalGamesStore {
    slots: ISlotGame[] = [];
    liveDealers: ILiveDealerGame[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    async toggleFavorite(id: string | number, type: ExternalGameType) {
        if (type === ExternalGameType.slots) {
            this.slots = this.slots.map((game) => {
                if (game.id === id) {
                    game.isFavorite = !game.isFavorite;
                }
                return game;
            });
        } else if (type === ExternalGameType.liveDealer) {
            this.liveDealers = this.liveDealers.map((game) => {
                if (game.id === id) {
                    game.isFavorite = !game.isFavorite;
                }
                return game;
            });
        }
        try {
            await ExternalGameService.toggleFavorite(id);
        } catch (e) {}
    }

    async getSlots(isDesktop: boolean) {
        try {
            const response = await getSlots({
                isDesktop,
                offset: 0,
                limit: 10_000,
            });
            const games = response.data.map((g) => new SlotModel(g));
            this.slots = games;
        } catch (e) {}
    }
    async getLiveDealers(isDesktop: boolean) {
        try {
            const response = await getLiveDealers({
                isDesktop,
                offset: 0,
                limit: 10_000,
            });
            const games = response.data.map((g) => new LiveDealerModel(g));
            this.liveDealers = games;
        } catch (e) {}
    }
}

export default ExternalGamesStore;
