import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    buttonWrapper: ({ bgColor }: any) => ({
        color: "#fff",
        mixBlendMode: "normal",
        filter: "drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.2))",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background:
            "linear-gradient(239.36deg, #6D7491 32.89%, #2D3142 64.62%, #1B2030 93.81%)",
        ...(bgColor === "mobileNote" && {
            background: "none",
        }),
        position: "relative",
        cursor: "pointer",
        transition: "0.2s",
        "&:active": {
            filter: "drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.4))",
            transform: "scale(0.98)",
        },
    }),

    buttonSteelWrapper: ({ height }: any) => ({
        padding: 2,
        background:
            "linear-gradient(180deg, #353849 0%, #D2D6E7 51.56%, #646879 100%)",
        mixBlendMode: "normal",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        height: "auto",
        ...(height && {
            height: height,
        }),
    }),

    buttonBlackWrapper: {
        padding: 1,
        background:
            "linear-gradient(180deg, #020309 0%, #4B4F60 51.56%, #06070C 100%)",
        mixBlendMode: "normal",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },

    customButton: (props: { disabled: boolean }) => ({
        borderRadius: 10,
        padding: "8px 25px",
        // Turn Off skewomorphism buttons
        // '&::before': {
        //     content: "''",
        //     display: 'block',
        //     width: '100%',
        //     height: '12px',
        //     position: 'absolute',
        //     top: '-6px',
        //     left: '0px',
        //     background:
        //         'radial-gradient(50.07% 50.07% at 50.07% 50.07%, #FFFFFF 0%, rgba(36, 36, 50, 0) 55%)',
        // },
        // '&::after': {
        //     content: "''",
        //     display: 'block',
        //     width: '100%',
        //     height: '12px',
        //     position: 'absolute',
        //     bottom: '-6px',
        //     left: '0px',
        //     background:
        //         'radial-gradient(50.07% 50.07% at 50.07% 50.07%, #FFFFFF 0%, rgba(36, 36, 50, 0) 55%)',
        // },
        position: "relative",
        opacity: props.disabled ? 0.3 : 1,
        border: "1.5px solid rgb(100 100 100 / 80%)",
        cursor: "pointer",
        "&:active": {
            transform: "scale(1)",
        },
        "&:focus": {
            outline: "none",
        },
    }),
    buttonText: ({ bgColor }: any) => ({
        fontWeight: 700,
        textAlign: "center",
        fontStyle: "normal",
        textShadow: "0px 1px 0px rgba(0, 0, 0, 0.15)",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...(bgColor === "mobileNote" && {
            fontSize: "16px",
            color: "rgb(130, 130, 130) !important",
            padding: "10px 10px",
        }),
    }),
});
