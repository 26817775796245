import { makeStyles } from "@mui/styles";
import theme from "src/theme";

interface IPicker {
    showPicker?: boolean | undefined;
}

export const useStyles = makeStyles({
    box: {
        display: "flex",
        margin: 3,
        padding: 4,
        backgroundColor: "rgba(52,58,89,1)",
        border: "1px solid #22263D",
        borderRadius: 8,
        boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.2)",
    },
    messageBackgroundBox: {
        backgroundColor: "#rgba(0, 0, 0, 0.2)",
        display: "flex",
        margin: 0,
        padding: 4,
        // border: '1px solid #2B2E43',
        borderRadius: 8,
        boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.2)",
    },

    pickerWrapper: ({ showPicker }: IPicker) => ({
        position: "absolute",
        width: "100%",
        top: 0,
        right: 0,
        height: "calc(100% - 45px)",
        overflow: "hidden",
        display: "none",
        ...(showPicker && {
            display: "block",
        }),
    }),

    messageStickericon: {
        // height: 16,
        height: "fit-content",
        padding: "10px 12px",
        opacity: 0.8,
        color: "rgb(255,255,255)",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "0.3s",
        "&:hover": {
            transition: "0.3s",
            opacity: 1,
        },
    },
    input: {
        flexGrow: 2,
        margin: 0,
        padding: "10px 0",
        border: 0,
        width: "calc(100% - 60px)",
        background: "rgba(0, 0, 0, 0)",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "13px",
        lineHeight: "15px",
        opacity: 0.8,
        color: "rgb(255,255,255)",
        outline: "none",
        [theme.breakpoints.up(1800)]: {
            fontSize: "18px",
            lineHeight: "20px",
        },
    },
    buttonSendMessageFromChat: {
        // width: 37,
        // height: 37,
        // padding: 10,
        // boxShadow: "0px 2px 6px rgba(21, 26, 39, 0.4)",
        border: 0,
        // background: "linear-gradient(180deg, #495677 0%, #303C59 100%)",
        background: "none",
        borderRadius: "5px",
        outline: "none",
        cursor: "pointer",
    },
});
