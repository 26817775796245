export class NumberHelper {
    public static random(min: number, max: number) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    public static formatMoney(value: number, fractionDigits = 1): string {
        if (this.isFloat(value)) {
            return value.toFixed(fractionDigits);
        }

        return String(value);
    }

    public static isFloat(value: number) {
        return value % 1 !== 0;
    }
}
