import React, { useState } from 'react';
import {
    InputAdornment,
    OutlinedInputProps,
    TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useField } from 'formik';
import cn from 'classnames';
import useChangeTheme from 'src/hooks/useChangeTheme';

import openEyeIcon from 'src/assets/icons/open-eye.svg';
import closedEyeIcon from 'src/assets/icons/closed-eye.svg';

import { useModalStyles } from './styles';

type PasswordInputProps = {
    name: string;
    label: string;
};

const useStyles = makeStyles({
    showPasswordIcon: {
        width: 20,
        height: 20,
        objectFit: 'contain',
        cursor: 'pointer',
    },
});

const PasswordInput = ({ name, label }: PasswordInputProps) => {
    const [field] = useField(name);
    const styles = useStyles();
    const modalStyles = useModalStyles();
    const appearanceTheme = useChangeTheme();

    const [showPassword, setShowPassword] = useState(true);

    const handleChangePasswordState = () => {
        setShowPassword((prev) => !prev);
    };
    return (
        <TextField
            variant='filled'
            label={label}
            className={cn(modalStyles.input, appearanceTheme)}
            type={showPassword ? 'text' : 'password'}
            InputProps={
                {
                    disableUnderline: true,
                    endAdornment: (
                        <InputAdornment position='end'>
                            <img
                                src={showPassword ? openEyeIcon : closedEyeIcon}
                                className={styles.showPasswordIcon}
                                onClick={handleChangePasswordState}
                            />
                        </InputAdornment>
                    ),
                } as Partial<OutlinedInputProps>
            }
            {...field}
        />
    );
};

export default PasswordInput;
