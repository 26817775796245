import React from 'react';
import { Box } from '@mui/material';
import cn from 'classnames';

import useStores from 'src/hooks/useStores';
import { UserBalanceType } from 'src/store/models';
import coinIcon from 'src/assets/icons/coin.svg';
import freeCoin from 'src/assets/icons/freeCoin.png';

import viewGameStore from '../../../../../../../store/viewGameStore';

import { useStyles } from './styles';

interface IMobilePotView {
    amount: string | number;
    children: any;
}

function MobilePotView({ amount, children }: IMobilePotView) {
    const classes = useStyles();
    const { gameStore, viewHistoryGameStore } = useStores();
    // const resultData = gameStore?.gameState?.gameResultData;
    const resultData = gameStore?.initData?.over;
    const gameType = gameStore?.currentGame
        ? gameStore?.currentGame?.balanceType
        : viewHistoryGameStore.currentGame?.balanceType;
    // if(!resultData) return null;

    return (
        // <Box className={resultData ? classes.timeBetMid : classes.timeBetTop}>
        <Box
            className={cn(classes.potToTopView, {
                runToMid: resultData,
            })}
        >
            <p className={classes.previewBankText}>Pot:</p>
            &nbsp;&nbsp;
            {children}
            &nbsp;&nbsp;
            <p className={classes.previewBankText}>{amount}</p>
        </Box>
    );
}

export default MobilePotView;
