import axios from 'axios';

import endpoints from 'src/core/endpoints';

import {
    IOpponentSettingsAxios,
    IMyOpponentsSettings,
    IMeOpponentsSettings,
} from './requestResponses';

export const setOpponentSettings = async (params: IOpponentSettingsAxios) => {
    return await axios.post(endpoints.opponentSettings, params);
};

export const myOpponentsSettings = async () => {
    return await axios.get<IMyOpponentsSettings>(endpoints.myOpponentsSettings);
};

export const meOpponentsSettings = async () => {
    return await axios.get<any>(endpoints.meOpponentsSettings);
};
