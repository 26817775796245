import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useField } from 'formik';
import React, { useRef, useState } from 'react';
import { useLocale } from 'src/providers/LocaleProvider';

import { useStyles as useModalStyles } from './styles';
import { appColors } from './../../../consts/app-config';
import { ButtonType, GreenButton } from '../../buttons/greenButton';
import { errorService } from 'src/service/services';

type SelectFileAreaProps = {
    goToNextStep: () => void;
    handleClose: () => void;
};

const useStyles = makeStyles({
    selectFileArea: {
        width: 300,
        height: 300,
        border: '2px dashed #59688F',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
    },

    fileInput: {
        display: 'none',
    },
    selectFileText: {
        fontSize: 20,
        fontWeight: 700,
        color: appColors.txt900,
        textAlign: 'center',
    },
});

const SelectFileArea = ({ goToNextStep, handleClose }: SelectFileAreaProps) => {
    const styles = useStyles();
    const [isLoading, setLoading] = useState(false);
    const {
        profile: {
            changeAvatar: { selectFileText, uploadButton, unknownError },
        },
    } = useLocale();
    const changeAvatarModalStyles = useModalStyles();
    const inputFileRef = useRef<HTMLInputElement | null>(null);
    const [, , avatarHelpers] = useField('avatar');
    const [, , base64AvatarHelpers] = useField('base64Avatar');

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || !e.target.files.length) return;
        const file = e.target.files[0];
        setLoading(true);
        try {
            const base64Avatar = await imageToBase64(file);
            avatarHelpers.setValue(file);
            base64AvatarHelpers.setValue(base64Avatar);
            goToNextStep();
        } catch (e) {
            errorService.sendError(unknownError('Неизвестная ошибка'));
            setLoading(false);
        }
    };

    const imageToBase64 = (file: File) => {
        return new Promise<string | ArrayBuffer | null | undefined>(
            (resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onerror = () => {
                    reject();
                };
                reader.onload = (e) => {
                    resolve(e.target?.result);
                };
            }
        );
    };

    return (
        <Box className={changeAvatarModalStyles.stepAreaWrapper}>
            <label className={styles.selectFileArea}>
                <input
                    accept='image/png, image/gif, image/jpeg'
                    name='avatar-size-image'
                    id='avatar-size-image'
                    type='file'
                    className={styles.fileInput}
                    onChange={onChange}
                    ref={inputFileRef}
                />
                <Typography className={styles.selectFileText}>
                    {selectFileText('Select a file from your gallery')}
                </Typography>
            </label>
            <Box className={changeAvatarModalStyles.buttonBox}>
                <GreenButton
                    type={ButtonType.info}
                    text={uploadButton('Upload avatar')}
                    fontSize={20}
                    loading={isLoading}
                    onClick={() => inputFileRef.current?.click()}
                    isWidthFixed
                />
            </Box>
        </Box>
    );
};

export default SelectFileArea;
