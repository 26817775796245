import React, { useState, useRef, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Field, useField } from "formik";
import { useHistory, Link } from "react-router-dom";
import { ThemeProvider } from "@mui/styles";
import { ELanguages } from "src/store/models";
import paths from "src/consts/paths";
import {
    createTheme,
    Grid,
    OutlinedInput,
    InputLabel,
    FormControl,
    Box,
    Dialog,
    TextField,
    Autocomplete,
    IconButton,
} from "@mui/material";
import classNames from "classnames";
import _get from "lodash/get";
import { observer } from "mobx-react";
// import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

import { useLocale } from "src/providers/LocaleProvider";

import { errorService } from "../../service/services";
import { ButtonType, GreenButton } from "../../components/buttons/greenButton";
import useStores from "../../hooks/useStores";
import { useModal } from "../landing/utils/context";
import LocaleText from "../../components/locale-text/locale.text";
import googleLogo from "src/assets/icons/social/GoogleIcons.svg";
import appleLogo from "src/assets/icons/social/AppleIcons.svg";
import facebookLogo from "src/assets/icons/social/fbIcons.svg";
import closeIcon from "src/assets/icons/x.svg";
import CustomArrowIcon from "src/assets/icons/selectIcon.svg";
import CloseArrowIcon from "src/assets/icons/CloseArrowIcon.svg";
import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';

import { useStyles } from "./styles";
import styles from "./styles.module.scss";
import { getCountries, signInWithFacebook, signInWithGoogle } from "../../service/api/auth";

const theme = createTheme({
    palette: {
        primary: {
            main: "#5B627E",
            dark: "#5B627E",
            contrastText: "#5B627E",
            light: "#5B627E",
        },
    },
});

export const RegisterForm = observer(() => {
    const { authStore } = useStores();
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
    const [isPasswordHidden, setVisiblePassword] = useState(true);
    const inputEl = useRef<any>(null);
    const muiStyles = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    const handleFocus = () => {
        setIsOpen(true);
    };

    const handleBlur = () => {
        setIsOpen(false);
    };
    const { closeModal, openModal } = useModal();
    // const fingerprintData = useVisitorData();


    const onButtonClick = (e) => {
        e.preventDefault();
        const el = inputEl.current;
        if (!el) return;
        const input = el.querySelector("#password");
        if (isPasswordHidden) {
            setVisiblePassword(false);
            input.type = "text";
        } else {
            setVisiblePassword(true);
            input.type = "password";
        }
    };

    const btnStyle = classNames({
        hidePasswordBtn: true,
        hidePasswordBtnClosed: isPasswordHidden,
        hidePasswordBtnOpen: !isPasswordHidden,
    });

    const {
        register: {
            errors: { password, email },
            form: { placeEmail, placePassword },
            registerButton,
        },
    } = useLocale();
    const locales = useLocale();
    //страны
    const [countries, setCountries] = useState<{ id: string; name: string }[]>([]);
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await getCountries();
                setCountries(response.data.countries);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };
        fetchCountries();
    }, []);
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await getCountries();
                setCountries(response.data.countries);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, []);
    //тест стран
    //const testCountries = [
    //    {
    //        id: "1",
    //        name: "United States",
    //    },
    //    {
    //        id: "2",
    //        name: "Canada",
    //    },
    //    {
    //        id: "3",
    //        name: "United Kingdom",
    //    },
    //    {
    //        "id": "6625f90831cf705fb3c3ed32",
    //        "name": "Afghanistan"
    //    },
    //    {
    //        id: "5",
    //        name: "Germany",
    //    },
    //];
    //useEffect(() => {
    //    setCountries(testCountries);
    //}, []);


    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .trim()
            .matches(
                /^[^а-яА-Я]+$/,
                email.unsupportedChars("Use only English letters.")
            )
            .max(64, email.tooLong("The email is too long."))
            .required(email.enter("Enter your email address"))
            .email(
                email.wrongFormat(
                    "The mailbox must be in the format name@domain.com!"
                )
            ),
        password: Yup.string()
            .trim()
            .matches(
                /[a-zA-Z\d\s|!#$%^&*()_}{"'`~+,-\[\]]/g,
                password.unsupportedChars(
                    "Use only English letters"
                )
            )
            .min(8, password.tooShort("Password must be at least 8 characters long."))
            .max(
                20,
                password.tooLong("The password is very long (max. 20 characters).")
            )
            .required(password.enter("Enter your password")),
        agreement: Yup.boolean().oneOf(
            [true],
            "You must accept the terms and conditions"
        ),
        country: Yup.string().required('Please select your country'),

    });

    const onSubmitForm = async (values) => {
        setLoading(true);

        try {
            await authStore.signUp(
                values.email,
                values.nickname,
                values.password,
                values.agreement,
                ELanguages.EN,
                authStore.referralId,
                values.subscribeToEmails,
                values.country,
                //"664e0ec18bc8acbf0fc09740" // countryId
                // {
                //     visitorId: fingerprintData.data?.visitorId,
                //     visitorFound: fingerprintData.data?.visitorFound,
                //     confidence: fingerprintData.data?.confidence.score,
                //     error: fingerprintData.error,
                // },

            );
            await authStore.getMeNew();
            localStorage.removeItem("refId");
            history.push(paths.lobby);
            closeModal("register");
            return;
        } catch (e) {
            const errors = e.response?.data?.errors;
            if (typeof errors === "string" || errors instanceof String) {
                errorService.sendError(errors);
            } else {
                for (const [, value] of Object.entries(errors)) {
                    errorService.sendError(
                        _get(
                            locales,
                            (value as any).locale?.label
                        )((value as any).locale.defaultValue || "")
                    );
                }
            }
        } finally {
            setLoading(false);
        }
    };

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Dialog
            open
            onClose={() => closeModal("register")}
            maxWidth={false}
            className={muiStyles.container}
            PaperProps={{ className: muiStyles.dialogPaper }}
        >
            <Box component="div" className={muiStyles.formContainer}>
                <ThemeProvider theme={theme}>
                    <Formik
                        initialValues={{
                            email: "",
                            password: "",
                            agreement: true,
                            subscribeToEmails: true,
                            country: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={onSubmitForm}
                        render={({
                            values,
                            errors,
                            handleSubmit,
                            handleChange,
                        }) => {
                            return (
                                <form
                                    onSubmit={handleSubmit}
                                    className={muiStyles.formWrapper}
                                >
                                    <Grid
                                        component="div"
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                    >
                                        <Grid item xs={12} className={muiStyles.titleBlock}>
                                            <div className={muiStyles.enterGame}>
                                                <LocaleText
                                                    label="register.title"
                                                    defaultValue="Sign Up"
                                                />
                                            </div>
                                            <img
                                                src={closeIcon}
                                                alt="Close"
                                                className={muiStyles.closeIcon}
                                                onClick={() => closeModal("register")}
                                            />
                                            {/* <div
                                                className={classNames(
                                                    muiStyles.joinGame
                                                )}
                                            >
                                                <LocaleText
                                                    label="register.haveAccount"
                                                    defaultValue=""
                                                />
                                                &nbsp;
                                                <span
                                                    className="signin-text"
                                                    onClick={
                                                        handleOpenLoginModal
                                                    }
                                                >
                                                    <LocaleText
                                                        label="register.logIn"
                                                        defaultValue=""
                                                    />
                                                </span>
                                            </div>*/ }

                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            className={muiStyles.fieldGrid}
                                        >
                                            <div
                                                className={
                                                    muiStyles.fieldHolder
                                                }
                                            >
                                                <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                    className={muiStyles.inputContainer}
                                                >
                                                    <label
                                                        htmlFor="nickname"
                                                        className={muiStyles.outlinedLabel}
                                                    >
                                                        Nickname
                                                    </label>
                                                    <TextField
                                                        id="nickname"
                                                        onChange={handleChange}
                                                        error={!!errors.nickname}
                                                        name="nickname"
                                                        value={values.nickname}
                                                        placeholder="Enter your nickname"
                                                        variant="outlined"
                                                        InputProps={{
                                                            classes: {
                                                                root: muiStyles.outlinedInput,
                                                            },
                                                        }}
                                                        required
                                                    />
                                                    {errors.nickname && (
                                                        <div className={muiStyles.error}>
                                                            {errors.nickname as string}.
                                                        </div>
                                                    )}
                                                </FormControl>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} className={muiStyles.fieldGrid}>
                                            <div className={muiStyles.fieldHolder}>
                                                <FormControl fullWidth variant="outlined" className={muiStyles.inputSelectContainer}>
                                                    <label htmlFor="country" className={muiStyles.outlinedLabel}>
                                                        Country*
                                                    </label>
                                                    <Autocomplete
                                                        id="country"
                                                        options={countries}
                                                        getOptionLabel={(option) => option.name}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder="Choose your country"
                                                                required
                                                                error={!!errors.country}
                                                                className={muiStyles.selectInput}
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    classes: {
                                                                        root: muiStyles.outlinedInput,
                                                                        notchedOutline: muiStyles.notchedOutline,
                                                                    },
                                                                    inputProps: {
                                                                        ...params.inputProps,
                                                                        className: muiStyles.inputStyles,
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                        onChange={(event, value) => {
                                                            handleChange({ target: { name: "country", value: value ? value.id : "" } });
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={countries.find((country) => country.id === values.country) || null}
                                                        classes={{
                                                            option: muiStyles.dropdownOption,
                                                            noOptions: muiStyles.dropdownOptionDisabled,
                                                            paper: muiStyles.dropdownPaper,
                                                        }}
                                                        onOpen={handleOpen}
                                                        onClose={handleClose}
                                                        popupIcon={
                                                            <img
                                                                src={isOpen ? CustomArrowIcon : CloseArrowIcon}
                                                                alt={`Arrow Icon ${isOpen ? 'Opened' : 'Closed'}`}
                                                                className={muiStyles.popupIndicator}
                                                            />
                                                        }
                                                    />
                                                    {errors.country && <div className={muiStyles.error}>{errors.country as string}.</div>}
                                                </FormControl>
                                            </div>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            className={muiStyles.fieldGrid}
                                        >
                                            <div
                                                className={
                                                    muiStyles.fieldHolder
                                                }
                                            >
                                                <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                    className={
                                                        muiStyles.inputContainer
                                                    }
                                                >
                                                    <label
                                                        htmlFor="email"
                                                        className={muiStyles.outlinedLabel}
                                                    >
                                                        Email*
                                                    </label>
                                                    <TextField
                                                        id="email"
                                                        onChange={handleChange}
                                                        error={!!errors.email}
                                                        name="email"
                                                        value={values.email}
                                                        placeholder="Enter your email"
                                                        variant="outlined"
                                                        InputProps={{
                                                            classes: {
                                                                root: muiStyles.outlinedInput,
                                                            },
                                                        }}
                                                        required
                                                    />
                                                    {errors.email && (
                                                        <div
                                                            className={
                                                                muiStyles.error
                                                            }
                                                        >
                                                            {
                                                                errors.email as string
                                                            }.
                                                        </div>
                                                    )}
                                                </FormControl>
                                            </div>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            className={muiStyles.fieldGrid}
                                        >
                                            <div className="OutlinedInput">
                                                <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                    className={
                                                        muiStyles.inputContainer
                                                    }
                                                >
                                                    <label
                                                        htmlFor="password"
                                                        className={muiStyles.outlinedLabel}
                                                    >
                                                        Password*
                                                    </label>
                                                    <OutlinedInput
                                                        ref={inputEl}
                                                        id="password"
                                                        onChange={handleChange}
                                                        error={
                                                            !!errors.password
                                                        }
                                                        name="password"
                                                        type="password"
                                                        value={values.password}
                                                        className={`${muiStyles.outlinedInput} ${muiStyles.inputAutoFillOverride}`}
                                                        placeholder="Enter your password"
                                                        required
                                                    />

                                                    <div className={muiStyles.passwordHint}>Password must be at least 8 characters long.</div>

                                                    <div
                                                        className={btnStyle}
                                                        onClick={onButtonClick}
                                                    />
                                                    {errors.password && (
                                                        <div
                                                            className={
                                                                muiStyles.error
                                                            }
                                                        >
                                                            {
                                                                errors.password as string
                                                            }.
                                                        </div>
                                                    )}
                                                </FormControl>
                                            </div>
                                            <Grid
                                                xs={12}
                                                item
                                                className="fieldGrid checkboxField-wrap"
                                            >
                                                <label
                                                    className="checkboxField"
                                                    htmlFor="agreement"
                                                >
                                                    <Field name="agreement">
                                                        {({ field }) => (
                                                            <div
                                                                className={
                                                                    muiStyles.checkBoxHolder
                                                                }
                                                                style={{
                                                                    flexDirection:
                                                                        "column",
                                                                }}
                                                            >
                                                                <div
                                                                    className={
                                                                        muiStyles.agreementHolder
                                                                    }
                                                                >
                                                                    <input
                                                                        className={
                                                                            styles.input
                                                                        }
                                                                        {...field}
                                                                        type="checkbox"
                                                                        id="agreement"
                                                                        checked={
                                                                            values.agreement
                                                                        }
                                                                    />
                                                                    <span
                                                                        className={
                                                                            styles.checkbox
                                                                        }
                                                                    />
                                                                    <span
                                                                        className={
                                                                            muiStyles.checkBoxFieldText
                                                                        }
                                                                    >
                                                                        {/*<LocaleText*/}
                                                                        {/*    label="register.agreement"*/}
                                                                        {/*    defaultValue=""*/}
                                                                        {/*/>*/}
                                                                        I am more than 18 years old and I agree with the
                                                                        &nbsp;
                                                                        <Link to="/games-rules">
                                                                            <LocaleText
                                                                                label="register.rules"
                                                                                defaultValue="rules"
                                                                                className={
                                                                                    muiStyles.rulesLink
                                                                                }
                                                                            />
                                                                        </Link>
                                                                    </span>
                                                                </div>
                                                                {errors.agreement && (
                                                                    <div
                                                                        className={
                                                                            muiStyles.error
                                                                        }
                                                                    >
                                                                        {
                                                                            errors.agreement as string
                                                                        }
                                                                        .
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </Field>
                                                </label>
                                                <label
                                                    className="checkboxField"
                                                    htmlFor="subscribeToEmails"
                                                >
                                                    <Field name="subscribeToEmails">
                                                        {({ field }) => (
                                                            <div
                                                                className={
                                                                    muiStyles.checkBoxHolder
                                                                }
                                                            >
                                                                <input
                                                                    className={
                                                                        styles.input
                                                                    }
                                                                    {...field}
                                                                    type="checkbox"
                                                                    id="subscribeToEmails"
                                                                    checked={
                                                                        values.subscribeToEmails
                                                                    }
                                                                />
                                                                <span
                                                                    className={
                                                                        styles.checkbox
                                                                    }
                                                                />
                                                                <span
                                                                    className={
                                                                        muiStyles.checkBoxFieldText
                                                                    }
                                                                >I want to receive newsletter
                                                                    {/*<LocaleText*/}
                                                                    {/*    label="register.subscribeToEmails"*/}
                                                                    {/*    defaultValue=""*/}
                                                                    {/*/>*/}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </Field>
                                                </label>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            xs
                                            className="fieldGrid"
                                            alignContent={"flex-start"}
                                        >
                                            <Grid container>
                                                <GreenButton
                                                    loading={isLoading}
                                                    text={registerButton("Create Account")}
                                                    buttonType={"submit"}
                                                    width={'100%'}
                                                    onClick={handleSubmit}
                                                    type={ButtonType.registerBtn}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs
                                            className="fieldGrid"
                                            alignContent={"flex-start"}
                                        >
                                            <Grid container className={muiStyles.lineContainer}>
                                                <div className={muiStyles.lineBox}>
                                                    <span>or</span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs className="fieldGrid" alignContent={"flex-start"}>
                                            <Grid container className={muiStyles.socialRegister} style={{ display: 'flex' }}>
                                                <GoogleOAuthProvider clientId="GOOGLE_CLIENT_ID">
                                                    <div className={muiStyles.googleButtonWrapper}>
                                                        <button
                                                            className={muiStyles.socialRegisterBtn}
                                                            style={{ zIndex: 1 }}
                                                        >
                                                            <img src={googleLogo} alt="Google" className={muiStyles.socialIcon} />
                                                        </button>
                                                        <div className={muiStyles.googleButtonOverlay}>
                                                            <div className={muiStyles.googleButtonInner}>
                                                                <GoogleLogin
                                                                    onSuccess={async (credentialResponse) => {
                                                                        const { credential } = credentialResponse;
                                                                        if (credential) {
                                                                            try {
                                                                                const response = await signInWithGoogle({ accessToken: credential, language: ELanguages.EN });
                                                                                console.log("Google sign-in response:", response);
                                                                            } catch (error) {
                                                                                console.error("Google sign-in error:", error);
                                                                            }
                                                                        } else {
                                                                            console.error("Google sign-in error: credential is undefined");
                                                                        }
                                                                    }}
                                                                    onError={() => {
                                                                        console.log('Login Failed');
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </GoogleOAuthProvider>
                                                <div className={muiStyles.appleButtonWrapper}>
                                                    <button className={muiStyles.socialRegisterBtn}>
                                                        <img src={appleLogo} alt="Apple" className={muiStyles.socialIcon} />
                                                    </button>
                                                </div>
                                                <div className={muiStyles.facebookButtonWrapper}>
                                                    <FacebookLogin
                                                        appId="1088597931155576"
                                                        onSuccess={async (response) => {
                                                            const { accessToken } = response;
                                                            try {
                                                                const signInResponse = await signInWithFacebook({ accessToken, language: ELanguages.RU });
                                                                console.log('Ответ сервера при входе через Facebook:', signInResponse);
                                                            } catch (error) {
                                                                console.error('Ошибка при входе через Facebook:', error);
                                                            }
                                                        }}
                                                        onFail={(error) => {
                                                            console.log('Вход не удался!', error);
                                                        }}
                                                        onProfileSuccess={(response) => {
                                                            console.log('Получение профиля успешно!', response);
                                                        }}
                                                        className={muiStyles.facebookLoginButton}
                                                    />
                                                    <button
                                                        className={muiStyles.socialRegisterBtn}
                                                        style={{ position: 'relative', zIndex: 1 }}
                                                    >
                                                        <img src={facebookLogo} alt="Facebook" className={muiStyles.socialIcon} />
                                                    </button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs
                                            className="fieldGrid"
                                            alignContent={"flex-start"}
                                        >
                                            <Grid container className={muiStyles.logInBlock}>
                                                <p> Already have an account?</p>
                                                <button className={muiStyles.logIn}
                                                    onClick={() => {
                                                        closeModal("register");
                                                        openModal("login");
                                                    }}
                                                >Log In</button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            );
                        }}
                    />
                </ThemeProvider>
            </Box>
        </Dialog>
    );
});
