import { makeStyles } from "@mui/styles";

import theme from "src/theme";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MIN_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
    MIN_L_TABLET,
} from "src/pages/game-new/constants";

import bg from "./images/clockBackgroundFixed.svg";

const bgSkew = 10;

const activePlayerInfoBorder = "2px solid #DBE4F4";

const mediaPlayerFigurePosition = -10;

const cashBoxWrapperSkew = 10;

const figureBorderColor = "#1B1D2B";
const currentWidth = window.innerWidth;

export const useStyles = makeStyles({
    playerInfoNewWrapper: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 110px 1fr",
        position: "relative",
        padding: "3px 15px",
        zIndex: 0,
        [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            padding: "3px 5px",
            gridTemplateColumns: "1fr auto 1fr",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            "@media (orientation: portrait)": {
                gridTemplateColumns: "1fr 100px 1fr",
                padding: "3px 5px",
            },
        },
        [theme.breakpoints.between(600, MAX_L_TABLET)]: {
            "@media (orientation: portrait)": {
                gridTemplateColumns: "1fr 140px 1fr",
            },
        },
        [theme.breakpoints.between(MAX_L_TABLET, "xl")]: {
            "@media (orientation: portrait)": {
                gridTemplateColumns: "1fr 160px 1fr",
            },
            "@media (orientation: portrait) and (max-height: 850px)": {
                gridTemplateColumns: "1fr 130px 1fr",
            },
        },
        [theme.breakpoints.between("lg", "xl")]: {
            "@media (orientation: landscape)": {
                zIndex: 1,
            },
        },
        "&.isMultiTable": {
            gridTemplateColumns: "1fr 60px 1fr",
            padding: "4px 8px",
            "@media screen and (max-height: 900px)": {
                padding: "2px 5px",
                gridTemplateColumns: "1fr 50px 1fr",
            },
        },
        "&.isTwoGameTable": {
            gridTemplateColumns: "1fr 80px 1fr",
        },
    },
    playerInfoFigure: {
        position: "absolute",
        top: 0,
        width: "50%",
        height: "100%",
        zIndex: 0,
        background: "#141827",
        boxShadow: "0px 5.98622px 5.98622px rgba(0, 0, 0, 0.25)",
        borderTop: "1px solid #0B0C12",
        borderBottom: "1px solid #0B0C12",
        "&.active": {
            borderTop: activePlayerInfoBorder,
            borderBottom: activePlayerInfoBorder,
            "&$playerInfoLeft": {
                borderLeft: activePlayerInfoBorder,
            },
            "&$playerInfoRight": {
                borderRight: activePlayerInfoBorder,
            },
        },
        [theme.breakpoints.between("md", "xl")]: {
            width: "54%",
        },
        "@media screen and (max-width: 400px)": {
            width: "60%",
        },
        "&.darkBlue": {
            background: "#141827",
        },
        "&.darkGreen": {
            background:
                "linear-gradient(19.92deg, #091F1F 13.3%, #0E0F0F 86.7%)",
            boxShadow: "0px -6px 40px #16625C",
        },
    },
    playerInfoLeft: {
        left: 0,
        borderRadius: "10px 0 0 10px",
        transform: `skew(${bgSkew}deg, 0deg) translateX(12px)`,
        borderLeft: "1px solid #0B0C12",
        "&.onTop": {
            transform: `skew(-${bgSkew}deg, 0deg) translateX(12px)`,
            "@media screen and (min-width: 1024px) and (max-height: 700px)": {
                transform: `skew(-${bgSkew}deg, 0deg) translateX(-10px)!important`,
            },
        },
        "@media screen and (min-width: 1024px) and (max-height: 700px)": {
            transform: `skew(${bgSkew}deg, 0deg) translateX(-10px)`,
        },
        // "@media screen and (max-width: 400px)": {
        //     left: mediaPlayerFigurePosition,
        // },
        [theme.breakpoints.between("xs", "xl")]: {
            "@media (orientation: portrait)": {
                transform: `skew(${bgSkew}deg, 0deg) translateX(5px)`,
                "&.onTop": {
                    transform: `skew(-${bgSkew}deg, 0deg) translateX(5px)`,
                },
            },
        },
    },
    playerInfoRight: {
        right: 0,
        borderRadius: "0 10px 10px 0",
        transform: `skew(-${bgSkew}deg, 0deg) translateX(-15px)`,
        borderRight: "1px solid #0B0C12",
        "&.onTop": {
            transform: `skew(${bgSkew}deg, 0deg) translateX(-15px)`,
            "@media screen and (min-width: 1024px) and (max-height: 700px)": {
                transform: `skew(${bgSkew}deg, 0deg) translateX(10px)!important`,
            },
        },
        "@media screen and (min-width: 1024px) and (max-height: 700px)": {
            transform: `skew(-${bgSkew}deg, 0deg) translateX(10px)`,
        },
        // "@media screen and (max-width: 400px)": {
        //     right: mediaPlayerFigurePosition,
        // },
        [theme.breakpoints.between("xs", "xl")]: {
            "@media (orientation: portrait)": {
                transform: `skew(-${bgSkew}deg, 0deg) translateX(-5px)`,
                "&.onTop": {
                    transform: `skew(${bgSkew}deg, 0deg) translateX(-5px)`,
                },
            },
        },
    },
    avatarAndButtonWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "-20px 0px",
        // margin: '-15px 0px -15px 0px',
        // position: 'relative',
    },

    avatarLandAndButtonWrapper: {
        display: "none",
        "@media (min-width: 1024px)": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "-20px 0px",
            borderRadius: "50%",
        },
    },

    userAvatar: {
        width: 90,
        height: 90,
        padding: 1,
        borderRadius: "50%",
        "&.isMultiTable": {
            width: 52,
            height: 52,
            [theme.breakpoints.down(1700)]: {
                width: 48,
                height: 48,
            },
            "@media screen and (max-height: 900px)": {
                width: 45,
                height: 45,
            },
        },
        "&.isTwoGameTable": {
            width: 75,
            height: 75,
        },
        "& .nickname-box": {
            fontSize: 21,
        },
        "@media screen and (max-width: 768px)": {
            width: 75,
            height: 75,
            padding: 3,
            "& .nickname-box": {
                fontSize: 14,
            },
        },
        "@media screen and (max-width: 500px)": {
            width: 65,
            height: 65,
            padding: 0,
            "& .nickname-box": {
                fontSize: 12,
            },
        },
    },
    piecesWrapper: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        flexDirection: "column",
        "&.isMultiTable": {
            overflow: "hidden",
        },
    },
    killedPieces: {
        "&.isMultiTable": {
            "@media screen and (max-height: 900px)": {
                marginBottom: -2,
            },
        },
    },
    miniPieces: {
        display: "flex",
        alignItems: "flex-start",
    },
    chessMenMargin: {
        marginRight: -13,
        "&.isMultiTable": {
            marginRight: -10,
            [theme.breakpoints.down(1700)]: {
                marginRight: -8,
            },
            [theme.breakpoints.down(1450)]: {
                marginRight: -7,
            },
            "@media screen and (max-height: 900px)": {
                marginRight: -8,
            },
        },
        "&.isTwoGameTable": {
            marginRight: -10,
        },
        "@media screen and (max-width: 768px)": {
            marginRight: -10,
        },
        "@media screen and (max-width: 520px)": {
            marginRight: -12,
        },
        "@media screen and (max-width: 450px)": {
            marginRight: -10,
        },
    },
    piecesGroupWrapper: {
        display: "flex",
    },
    piecesGroupWrapperMargin: {
        marginRight: 5,
        "&.isMultiTable": {
            marginRight: 0,
            [theme.breakpoints.down(1450)]: {
                marginRight: 3,
            },
        },
        "&.isTwoGameTable": {
            marginRight: 4,
        },
        "@media screen and (max-width: 768px)": {
            marginRight: 3,
        },
        "@media screen and (max-width: 600px)": {
            marginRight: 0,
        },
        "@media screen and (max-width: 450px)": {
            marginRight: 0,
        },
    },

    leftPlayerWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        gridGap: "2px",
    },

    clockWrapper: {
        width: "100%",
        height: "100%",
        display: "grid",
        placeItems: "center",
        fontSize: 38,
        zIndex: 1,
        overflow: "hidden",
        maxWidth: "100%",
        [theme.breakpoints.between(MIN_L_TABLET, "lg")]: {
            "@media (orientation: portrait)": {
                paddingRight: 0,
                fontSize: 30,
                maxWidth: "calc(100% - 7px)",
                width: "100%",
            },
        },
        "@media screen and (max-width: 767px)": {
            paddingRight: 0,
            fontSize: 30,
            width: "100%",
        },
        "@media screen and (max-width: 500px)": {
            paddingRight: 0,
            fontSize: 18,
        },
        "&.isOpponent": {
            [theme.breakpoints.between("xs", "md")]: {
                "@media (orientation: portrait)": {
                    maxWidth: "100%",
                    width: "100%",
                    placeItems: "flex-start",
                },
            },
            [theme.breakpoints.up("md")]: {
                "@media (orientation: portrait)": {
                    maxWidth: "calc(100% - 7px)",
                    width: "100%",
                    placeItems: "flex-start",
                },
            },
        },
    },

    nikAndclockWrapperBox: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        justifyContent: "flex-start",
        [theme.breakpoints.between("md", "lg")]: {
            "@media (orientation: portrait)": {
                // width: currentWidth < MIN_CHANGE_SCREEN_WIDTH ? "auto" : "100%",
                // maxWidth: currentWidth < MIN_CHANGE_SCREEN_WIDTH ? 130 : 150,
                width: "100%",
            },
        },
        "&.isMultiTable": {
            alignItems: "center",
        },
        "&.isTwoGameTable": {
            alignItems: "center",
        },
    },

    redesignClockWrapper: {
        width: "100%",
        "@media (orientation: landscape)": {
            position: "relative",
            "@media screen and (min-width: 1024px)": {
                height: "40px",
            },
            "@media screen and (min-width: 1250px)": {
                height: "50px",
            },
        },
        "&.inGame": {},
        "&.inProgressPreview": {
            height: "25px",
        },
        "&.inProgressTournamentView": {
            height: "auto",
        },
        "&.inHistoryView": {
            height: "auto",
        },
        "@media (orientation: portrait)": {},
        "&.isMultiTable": {
            height: "auto",
        },
    },

    redesignClockBox: {},

    nicknameBgFigure: {
        position: "absolute",
        top: 0,
        width: "50%",
        height: "100%",
        zIndex: 0,
        background: "#141826",
        borderTop: `1px solid ${figureBorderColor}`,
        borderBottom: `1px solid ${figureBorderColor}`,
    },
    nicknameBgFigureLeft: {
        left: 0,
        borderLeft: `1px solid ${figureBorderColor}`,
        borderRadius: "10px 0 0 10px",
        transform: "skew(-30deg, 0deg) translateX(15px)",
    },
    nicknameBgFigureRight: {
        right: 0,
        borderRight: `1px solid ${figureBorderColor}`,
        borderRadius: "0 10px 10px 0",
        transform: "skew(30deg, 0deg) translateX(-15px)",
    },

    pawnIconWrapper: {
        zIndex: 1,
        display: "block",
        "&.isMultiTable svg": {
            width: 17,
            height: 17,
            [theme.breakpoints.down(1700)]: {
                width: 16,
                height: 16,
            },
            [theme.breakpoints.down(1600)]: {
                width: 15,
                height: 15,
            },
            "@media screen and (max-height: 900px)": {
                width: 14,
                height: 14,
            },
        },
        "&.isTwoGameTable svg": {
            width: 22,
            height: 22,
        },
        "& svg": {
            "@media screen and (max-width: 768px)": {
                width: 22,
                height: 22,
            },
            "@media screen and (max-width: 600px)": {
                width: 20,
                height: 20,
            },
            [theme.breakpoints.down("sm")]: {
                width: 18,
                height: 18,
            },
        },
    },

    backgroundWrapper: {
        display: "flex",
        justifyContent: "center",
    },
    contentWrapper: {
        paddingLeft: 30,
        paddingRight: 50,
        // position: 'absolute',
        // top: 0, left: 0,
        // width: '100%',
        // backgroundSize: '100% 100px',
        fontSize: 25,
        minHeight: 80,
        "@media screen and (max-width: 1366px)": {
            transform: "scale(0.8)",
        },
    },

    clockWrapperDisabled: {
        backgroundImage: `url(${bg})`,
        filter: "contrast(0.8)",
        backgroundRepeat: "no-repeat",
        position: "absolute",
        width: "104px",
        height: "35px",
        top: 4,
    },
    clockContainer: {
        paddingTop: 13,
        fontSize: 38,
        marginLeft: "auto",
        paddingRight: 0,
    },

    cashBoxWrapper: {
        textAlign: "center",
        padding: "3px 30px",
        zIndex: 5,
        position: "absolute",
        top: "100%",
        transform: "translateY(-70%)",
        display: "none",
        justifyContent: "center",
        alignItems: "center",
        left: "7%",
        width: "fit-content",
        "@media screen and (max-width: 768px)": {
            display: "flex",
        },
        "@media screen and (max-width: 520px)": {
            padding: "3px 25px",
            right: 8,
        },
    },
    cashBoxFigure: {
        position: "absolute",
        top: 0,
        width: "50%",
        height: "100%",
        zIndex: 0,
        background: "#141826",
        borderTop: `1px solid ${figureBorderColor}`,
        borderBottom: `1px solid ${figureBorderColor}`,
    },
    cashBoxFigureLeft: {
        left: 0,
        borderRadius: "4px 0 0 4px",
        transform: `skew(-${cashBoxWrapperSkew}deg, 0deg) translateX(15px)`,
        borderLeft: `1px solid ${figureBorderColor}`,
        opacity: "0.8",
    },
    cashBoxFigureRight: {
        right: 0,
        borderRadius: "0 4px 4px 0",
        transform: `skew(${cashBoxWrapperSkew}deg, 0deg) translateX(-15px)`,
        borderRight: `1px solid ${figureBorderColor}`,
        opacity: "0.8",
    },
    cashBoxAmount: {
        fontWeight: 500,
        fontSize: 16,
        zIndex: 1,
        background: "linear-gradient(180deg, #F8BF44 50%, #A16529 100%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        display: "flex",
        alignItems: "center",
        "@media screen and (max-width: 460px)": {
            fontSize: 14,
            display: "inline-block",
            lineHeight: "0",
            "-webkit-box-decoration-break": "clone",
        },
    },
    cashBoxAmountCoins: {
        fontWeight: 500,
        fontSize: 16,
        zIndex: 1,
        background: "linear-gradient(180.73deg, #e9eefc 9.68%, #c2c2c2 104.5%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        display: "flex",
        alignItems: "center",
        "@media screen and (max-width: 460px)": {
            fontSize: 14,
            display: "inline-block",
            lineHeight: "0",
            "-webkit-box-decoration-break": "clone",
        },
    },

    cashBoxAmountBank: {
        fontWeight: 500,
        fontSize: 16,
        zIndex: 1,
        background: "linear-gradient(180deg, #F8BF44 50%, #A16529 100%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        display: "flex",
        alignItems: "center",
        "@media screen and (max-width: 460px)": {
            fontSize: 14,
            display: "inline-block",
            lineHeight: "0",
        },
    },

    coinIcon: {
        width: 17,
        height: 17,
        objectFit: "contain",
        marginRight: 5,
        "-webkit-box-decoration-break": "clone",
        // '@media screen and (max-width: 460px)': {
        //     width: 14,
        //     height: 14,
        // },
    },
});
