import { makeStyles } from "@mui/styles";

import { appColors, backgroundColors } from "src/consts/app-config";
import freeCoin from "src/assets/icons/freeCoin.png";
import coin from "src/assets/icons/coin.svg";
import theme from "src/theme";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
} from "src/pages/game-new/constants";

interface ICoin {
    vertical: number | boolean;
    horizontal: number | boolean;
    animationLatency: number;
    transitionLatency: number;
}

const height = window.innerHeight - 30;

const useStyles = makeStyles({
    "@global": {
        "@keyframes potToMid": {
            "0%": {
                opacity: 0.7,
                transform: "translate(-50%, 0%) translateY(0px)",
                padding: "10px 20px",
                borderRadius: "10px",
            },
            "20%": {
                transform: "translate(-50%, 0%)  translateY(100}px)",
                padding: "10px 20px",
                borderRadius: "10px",
                border: `${appColors.txt400} 1px solid`,
                background: appColors.bg1100,
            },
            "35%": {
                transform: `translate(-50%, 0%)  translateY(${height / 2}px)`,
                padding: "10px 20px",
                borderRadius: "10px",
                border: `${appColors.txt400} 1px solid`,
                background: appColors.bg1100,
            },
            "95%": {
                transform: ` translate(-50%, 0%) translateY(${height / 2}px)`,
                padding: "10px 20px",
                borderRadius: "10px",
                border: `${appColors.txt400} 1px solid`,
                background: appColors.bg1100,
                opacity: 1,
            },
            "100%": {
                opacity: 0,
                transform: ` translate(-50%, 0%) translateY(${height / 2}px)`,
                visibility: "hidden",
                // opacity: 1,
                // transform: ` translate(-50%, 0%) translateY(${height / 2}px)`,
                // background: 'red'
            },
        },
    },

    potToTop: {
        display: "flex",
        alignItems: "center",
        "&.runToMid": {
            opacity: 1,
            position: "absolute",
            zIndex: 4,
            animationName: "potToMid",
            animationDelay: "0s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "2000ms",
        },
        // '@media screen and (min-width: 768px) and (max-width: 1024px)': {
        //     display: 'none',
        // },
    },

    bankTitle: {
        fontSize: 20,
        marginRight: 5,
        fontWeight: 500,
        background:
            "linear-gradient(178.82deg, #A2A2A2 31.6%, #FFFFFF 50.51%, #A9A9A9 76.41%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        "-webkit-box-decoration-break": "clone",
        "&.deepBlack": {
            "-webkit-text-fill-color": "inherit !important",
            background: "none !important",
            fontFamily: "Montserrat",
            fontSize: 16,
            fontWeight: 600,
            color: appColors.semiwhite,
        },
    },

    bankAmount: {
        fontSize: 32,
        fontWeight: 800,
        background: "linear-gradient(180deg, #F8BF44 50%, #A16529 100%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        marginLeft: 6,
        marginRight: 8,
        "&.deepBlack": {
            fontFamily: "Montserrat",
            fontSize: 28,
            fontWeight: 600,
        },
    },

    bankFreeAmount: {
        fontSize: 32,
        fontWeight: 800,
        background: "linear-gradient(180.73deg, #e9eefc 9.68%, #c2c2c2 104.5%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        marginLeft: 6,
        marginRight: 8,
        "&.deepBlack": {
            fontFamily: "Montserrat",
            fontSize: 28,
            fontWeight: 600,
        },
    },
});

export default useStyles;
