import axios from "axios";

import endpoints from "../../../core/endpoints";
import {
    EExternalProviderType,
    IGetLiveDealerGameResponse,
    IGetSlotsResponse,
} from "../../../entities/externalGame";

import {
    IAlg1xBetGetLiveDealerGamesResponse,
    IGetSportsBettingUrlResponse,
    IAlgAltenteGamesResponse,
    IJackPokerGetUrlResponse,
} from "./requestResponses";

export const getBettingUrl = async () => {
    return await axios.post<IGetSportsBettingUrlResponse>(endpoints.betting);
};

export const getAlgNetAltenteUrl = async (id: string) => {
    return await axios.post<IGetSportsBettingUrlResponse>(
        endpoints.algAltenteGetUrl,
        {
            id,
        }
    );
};

export const getJackPokerUrl = async () => {
    return await axios.post<IJackPokerGetUrlResponse>(
        endpoints.getJackpokerUrl
    );
};

export const getAlgLiveDealerUrl = async (id: string) => {
    return await axios.post<IGetSportsBettingUrlResponse>(
        endpoints.algAltenteGetUrl,
        {
            id,
        }
    );
};

export const getSlots = async ({
    isDesktop,
    offset,
    limit,
}: {
    isDesktop: boolean;
    offset: number;
    limit: number;
}) => {
    const response = await axios.get<IGetSlotsResponse[]>(
        endpoints.algAltenteGames,
        {
            params: {
                offset,
                limit,
                mode: isDesktop ? "desktop" : "mobile",
            },
        }
    );
    return response;
};

export const getLiveDealers = async ({
    isDesktop,
    offset,
    limit,
}: {
    isDesktop: boolean;
    offset: number;
    limit: number;
}) => {
    const response = await axios.get<IGetLiveDealerGameResponse[]>(
        endpoints.algLiveDealerGames,
        {
            params: {
                offset,
                limit,
                mode: isDesktop ? "desktop" : "mobile",
            },
        }
    );
    return response;
};
