import React, { useCallback } from "react";
import cn from "classnames";
import { observer } from "mobx-react";
import { Box } from "@mui/material";

import useStores from "src/hooks/useStores";

import { useLocale } from "src/providers/LocaleProvider";
import useChangeTheme from "src/hooks/useChangeTheme";
import FenParser from "src/store/gameStore/utils/parser";
import FenHelper from "src/store/gameStore/utils/helper";
import { ColorVariant, EGameChatTab } from "src/store/models";
import GameEventsDialogOnDesk from "src/pages/game-new/components/gameIventDialog";
import multiTabsIcon from "src/assets/icons/gameChatIcons/redesignIcons/multiTabsIcon.svg";
import { ISendMessageInGame } from "src/service/api/gameChat/requestResponses";
import { useStyles } from "./styles";
import { useLeftLayoutContext } from "src/pages/game-new2/layouts/components/left/leftLayoutContext";
import GameChatHistory from "src/pages/game-new2/components/game-chat-history";
import GameChatHistoryNew from "src/pages/game-new2/components/game-chat-history-new";
const LeftLayout = () => {
    const { gameStore, authStore } = useStores();
    const {
        game: {
            chat: { tabs },
        },
    } = useLocale();
    const classes = useStyles();
    const { chatMobileType, onChatToggler } = useLeftLayoutContext();
    const appearanceTheme = useChangeTheme();
    // const parse = new FenParser(FenHelper);
    // const moveFinished = !gameStore.gameState.legalPieces.length;
    // const notifications = gameStore.gameState.notifications;
    // const chatMessages = gameStore.gameState.chatMessages;
    // const history = gameStore.gameState.history;
    const currentTab =
        authStore.currentUser?.settings?.dicechessBoardRightBlockMode ||
        EGameChatTab.chat;
    // const leftColor =
    //     gameStore.gameState.getMyColor() === ColorVariant.white
    //         ? ColorVariant.black
    //         : ColorVariant.white;
    // const moves = parse.diceMessagesFromHistory(
    //     //@ts-ignore
    //     history,
    //     leftColor,
    //     [],
    //     moveFinished
    // );
    //========
    const notificationsNew = gameStore.communication.notifications;
    const chatMessagesNew = gameStore.communication.messages;
    const historyNew = gameStore.communication.history;

    // const sendChatMessage = useCallback((message) => {
    //     // gameStore.sendMessage(message);
    // }, []);

    const sendChatMessage = useCallback((message: ISendMessageInGame) => {
        gameStore.sendMessageNew(message);
    }, []);

    const onChangeGameTab = useCallback((tab: keyof typeof EGameChatTab) => {
        authStore.setGameChatTab(tab);
    }, []);

    return (
        <>
            <Box
                className={cn(
                    classes.gameChatHistoryBox,
                    chatMobileType,
                    appearanceTheme
                )}
            >
                <Box
                    component="div"
                    className={cn(
                        classes.gameChatHistoryTabBox,
                        chatMobileType,
                        appearanceTheme
                    )}
                >
                    <div
                        className={cn(
                            classes.gameChatHistoryLeftTab,
                            chatMobileType,
                            appearanceTheme
                        )}
                    >
                        <img src={multiTabsIcon} alt="multitabs" />
                        <p>{tabs("Tabs")}</p>
                    </div>
                    <div
                        onClick={() => {
                            onChatToggler && onChatToggler();
                        }}
                        className={cn(
                            classes.crossOnMobileChatTabs,
                            appearanceTheme
                        )}
                    ></div>
                </Box>

                <GameChatHistoryNew
                    chat={chatMessagesNew}
                    history={historyNew}
                    notifications={notificationsNew}
                    onMessage={sendChatMessage}
                    maxMessageSize={255}
                    onChangeTab={onChangeGameTab}
                    currentChatTab={currentTab}
                />
                <GameEventsDialogOnDesk placePosition={EGameChatTab.fastGame} />
            </Box>
        </>
    );
};

export default observer(LeftLayout);

{
    /* 
                Dont Delete! there is actual logical for chat
                <GameChatHistory
                    dialog={chatMessages}
                    history={moves}
                    notifications={notifications}
                    onMessage={sendChatMessage}
                    maxMessageSize={255}
                    onChangeTab={onChangeGameTab}
                    currentChatTab={currentTab}
                /> */
}
