import { Box } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";

import paths from "src/consts/paths";
import useStores from "src/hooks/useStores";
import { useLocale } from "src/providers/LocaleProvider";
import {
    GameModeType,
    GameStatus,
    GameType,
    UserBalanceType,
} from "src/store/models";

const DesktopNav = ({ styles, isLanding }) => {
    const {
        header: { cashBattle, lobbyFreePlay, tournaments, altente, liveDealer },
    } = useLocale();
    const { generalStore, tournamentsStore, lobbyStore } = useStores();
    const {
        diceChessLobby,
        lobbyFreePlay: lobbyFreePlayPath,
        tournaments: tournamentPath,
        algaltente,
        liveDealer: liveDealerPath,
    } = paths;
    const currentLocation = useLocation();
    const activeLobbyGames = lobbyStore.getActiveGames();

    const showReturnToTournament = tournamentsStore.hasActiveGames;

    const isCashBattle = useMemo(() => {
        if (activeLobbyGames) {
            return activeLobbyGames.filter(
                (game) => game.balanceType === UserBalanceType.play
            );
        }
    }, [activeLobbyGames]);

    const isFreePlay = useMemo(() => {
        if (activeLobbyGames) {
            return activeLobbyGames.filter(
                (game) => game.balanceType === UserBalanceType.coins
            );
        }
    }, [activeLobbyGames]);

    const isCashBattleDot = useMemo(() => {
        if (!isCashBattle) return false;
        return (
            activeLobbyGames &&
            activeLobbyGames?.length > 0 &&
            !!isCashBattle.length &&
            !currentLocation.pathname.includes(isCashBattle[0].id) &&
            !currentLocation.pathname.includes(paths.diceChessLobby)
        );
    }, [activeLobbyGames, isCashBattle]);

    const isFreePlayDot = useMemo(() => {
        if (!isFreePlay) return false;
        return (
            activeLobbyGames &&
            activeLobbyGames?.length > 0 &&
            !!isFreePlay.length &&
            !currentLocation.pathname.includes(isFreePlay[0].id) &&
            !currentLocation.pathname.includes(paths.lobbyFreePlay)
        );
    }, [activeLobbyGames, isFreePlay]);

    const isTournamentDot = useMemo(() => {
        const isNotInGamePage = tournamentsStore.myGamesList.every(
            (g) => !currentLocation.pathname.includes(g?.id)
        );
        return (
            showReturnToTournament &&
            !isNotInGamePage &&
            !currentLocation.pathname.includes(paths.tournaments)
        );
    }, [showReturnToTournament, tournamentsStore.myGamesList]);

    const links: React.ReactNode[] = [];
    if (generalStore.gameType === GameType.dicechess && !isLanding) {
        links.push([
            <Box
                component={"div"}
                className={cn(styles.navMenuItemBox, {
                    gameInfoDot: isCashBattleDot,
                })}
            >
                <NavLink
                    to={diceChessLobby}
                    className={cn(styles.navMenuItem, {})}
                    activeStyle={{ color: "#fff" }}
                >
                    {cashBattle("Cash Battle")}
                </NavLink>
                {isCashBattleDot && (
                    <div className={cn(styles.gameInfoDot, {})}></div>
                )}
            </Box>,
            <Box component="div" className={cn(styles.navMenuItemBox, {})}>
                <NavLink
                    to={tournamentPath}
                    className={cn(styles.navMenuItem, {})}
                    activeStyle={{ color: "#fff" }}
                >
                    {tournaments("Tournaments")}
                </NavLink>
                {isTournamentDot && (
                    <div className={cn(styles.gameInfoDot, {})}></div>
                )}
            </Box>,
            // <Box component='div' className={cn(styles.navMenuItemBox, {})}>
            //     <NavLink
            //         to={lobbyFreePlayPath}
            //         className={cn(styles.navMenuItem, {})}
            //         activeStyle={{ color: '#fff' }}
            //     >
            //         {lobbyFreePlay('Free play')}
            //     </NavLink>
            //     {isFreePlayDot && (
            //         <div className={cn(styles.gameInfoDot, {})}></div>
            //     )}
            // </Box>,
        ]);
    } else if (generalStore.gameType === GameType.slots) {
        links.push(
            <Box component="div" className={cn(styles.navMenuItemBox, {})}>
                <NavLink
                    to={algaltente}
                    className={cn(styles.navMenuItem, {})}
                    activeStyle={{ color: "#fff" }}
                >
                    {altente("All slots")}
                </NavLink>
            </Box>
        );
    } else if (generalStore.gameType === GameType.liveDealer) {
        links.push(
            <Box component="div" className={cn(styles.navMenuItemBox, {})}>
                <NavLink
                    to={liveDealerPath}
                    className={cn(styles.navMenuItem, {})}
                    activeStyle={{ color: "#fff" }}
                >
                    {liveDealer("All dealers")}
                </NavLink>
            </Box>
        );
    }

    return <div className={styles.navMenu}>{links}</div>;
};

export default observer(DesktopNav);
