import React, { useState, useEffect } from "react";
import { Box, Modal, Typography, Container } from "@mui/material";
import { observer } from "mobx-react";
import { Formik } from "formik";
import * as yup from "yup";
import cn from "classnames";

import { useLocale } from "src/providers/LocaleProvider";
import useChangeTheme from "src/hooks/useChangeTheme";
import deleteIcon from "src/assets/icons/delete.svg";
import useStores from "src/hooks/useStores";
import { IUser, IUserNew } from "src/store/models";
import { errorService, infoService } from "src/service/services";

import { ButtonType, GreenButton } from "../../buttons/greenButton";
import { useModalStyles } from "../styles";

import { useStyles } from "./styles";
import SelectFileArea from "./SelectFileArea";
import ImageCrop from "./ImageCrop";
import ItemAvatarContainer from "./ItemAvatarContainer";
import {
    ChangeAvatarModalProps,
    ShowHideUpload,
    FormValuesType,
} from "./avatarTypes";

const initialValues: FormValuesType = {
    avatar: null,
    base64Avatar: null,
    croppedImage: null,
};

const validationSchema = yup.object({
    avatar: yup.mixed().nullable(),
    base64Avatar: yup.string().nullable(),
    croppedImage: yup.mixed().nullable(),
});

enum StepType {
    SelectFile = "select-file",
    SavePhoto = "save-photo",
}

const ChangeAvatarModal = ({ isOpen, onClose }: ChangeAvatarModalProps) => {
    const localStyles = useStyles();
    const modalStyles = useModalStyles();
    const appearanceTheme = useChangeTheme();
    const { authStore } = useStores();
    const {
        profile: {
            changeAvatar: {
                messages: { avatarUploadedSuccessfully },
                unknownError,
            },
            gameSettings: {
                form: { changeAvatar, hideTitle },
            },
        },
    } = useLocale();
    const [choosenAvatarId, setAvatarId] = useState<string | boolean>(false);
    const [isUpload, showUpload] = useState<string>(ShowHideUpload.init);
    const [step, setStep] = useState<StepType>(StepType.SelectFile);

    const handleSubmit = async (values: FormValuesType) => {
        const formData = new FormData();
        const fileType = (values.croppedImage as unknown as Blob).type.split(
            "/"
        )[1];
        // formData.append(
        //     "file",
        //     values.croppedImage,
        //     (authStore.currentUser as IUser)._id + "_" + fileType
        // );
        formData.append(
            "file",
            values.croppedImage,
            (authStore.currentUser as IUserNew)._id + "_" + fileType
        );
        try {
            await authStore.uploadAvatar(formData);
            infoService.sendInfo(
                avatarUploadedSuccessfully("Your avatar has been uploaded")
            );
            onClose();
        } catch (e) {
            errorService.sendError(unknownError("Неизвестная ошибка"));
        }
    };

    const [nonGrid, setGrid] = useState<boolean>(false);

    const goToNextStep = () => {
        if (step === StepType.SelectFile) {
            setStep(StepType.SavePhoto);
        }
        setGrid(true);
    };

    const goPrevStep = () => {
        if (step === StepType.SavePhoto) {
            setStep(StepType.SelectFile);
        }
        setGrid(false);
    };

    const handleClose = () => {
        setStep(StepType.SelectFile);
        onClose();
    };

    useEffect(() => {
        authStore.getStaticAvatar();
    }, []);

    const getAvatarId = (id: string) => {
        setAvatarId(id);
    };

    const handleUpdateAvatar = async (avatarId: string | boolean) => {
        if (typeof avatarId !== "string") return;
        try {
            await authStore.uploadStaticAvatar(avatarId);
            infoService.sendInfo(
                avatarUploadedSuccessfully("Your avatar has been uploaded")
            );
            onClose();
        } catch (e) {
            errorService.sendError(unknownError("Неизвестная ошибка"));
        }
    };

    if (!authStore.currentUser) return null;

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            className={modalStyles.modalWrapper}
        >
            <Box className={cn(modalStyles.modal, "isAvatar", appearanceTheme)}>
                <Box className={modalStyles.modalTopBox}>
                    <Typography className={modalStyles.title}>
                        {changeAvatar("Change Avatar")}
                    </Typography>
                    <img
                        onClick={onClose}
                        src={deleteIcon}
                        className={modalStyles.closeIcon}
                    />
                </Box>
                <Container
                    className={cn(modalStyles.chooseAvatarWrapper, {
                        nonGrid: nonGrid,
                    })}
                >
                    <ItemAvatarContainer
                        nonGrid={nonGrid}
                        isCustom={false}
                        getAvatarId={getAvatarId}
                        choosenAvatarId={choosenAvatarId}
                        handleUpdateAvatar={handleUpdateAvatar}
                        items={authStore.avatarList}
                        showUpload={(status) => showUpload(status)}
                    />
                </Container>
                <Container
                    className={cn(
                        modalStyles.uploadAvatarWrapper,
                        appearanceTheme,
                        {
                            nonGrid: nonGrid,
                            isInit: isUpload === ShowHideUpload.init,
                            isUpload: isUpload === ShowHideUpload.show,
                            isUploadHide: isUpload === ShowHideUpload.hide,
                        }
                    )}
                >
                    <img
                        onClick={onClose}
                        src={deleteIcon}
                        className={cn(modalStyles.closeIcon, {
                            isUpload: isUpload === ShowHideUpload.show,
                            isUploadHide: isUpload === ShowHideUpload.hide,
                        })}
                    />
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {(formikProps) => (
                            <form
                                className={localStyles.wrapperFormik}
                                onSubmit={formikProps.handleSubmit}
                            >
                                <Box
                                    className={cn(localStyles.wrapperFormikBox)}
                                >
                                    <Box
                                        className={
                                            localStyles.currentStepWrapper
                                        }
                                    >
                                        {step === StepType.SelectFile && (
                                            <SelectFileArea
                                                goToNextStep={goToNextStep}
                                                handleClose={handleClose}
                                            />
                                        )}
                                        {step === StepType.SavePhoto && (
                                            <ImageCrop
                                                goPrevStep={goPrevStep}
                                                handleClose={handleClose}
                                            />
                                        )}
                                        {step === StepType.SelectFile && (
                                            <GreenButton
                                                isMarginTop={10}
                                                type={ButtonType.yellow}
                                                text={hideTitle("Hide")}
                                                fontSize={20}
                                                onClick={() =>
                                                    showUpload(
                                                        ShowHideUpload.hide
                                                    )
                                                }
                                                isWidthFixed
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </Modal>
    );
};

export default observer(ChangeAvatarModal);
