import React, { useMemo, useCallback, useRef, useEffect } from "react";
import uuid from "react-uuid";
import cx from "classnames";
import { observer } from "mobx-react";

import useChangeTheme from "src/hooks/useChangeTheme";
import useStores from "src/hooks/useStores";
import { useStyles } from "src/components/fastChessboard/styles";
import useWindowSize from "src/hooks/useWindowSize";

import Square from "./components/square";
import SquareWithFigureWrapper from "./components/squareWithPiece";
import SVGDefs from "./piece-memos/svgDefs";
import PieceDragLayer from "./pieceDragLayer";
import {
    WPIcon,
    WRIcon,
    WNIcon,
    WBIcon,
    WQIcon,
    WKIcon,
    BPIcon,
    BRIcon,
    BNIcon,
    BBIcon,
    BQIcon,
    BKIcon,
} from "./piece-memos-deepBlack";
import { Notation } from "./Notation";
import { IPiecesMap } from "./types";
import chessboard from "src/pages/lobby/components/GamePreview/GameInProgressPreview/components/chessboard";

interface FastChessboardProps {
    mobileHover: boolean;
    width: number;
    pieceWidth: number;
    orientation: "white" | "black";
    fen: string;
    border: boolean;
    showNotationInBorder: boolean;
    borderWidth: number;
    borderRadius: number;
    marginTop: number;
    marginBottom: number;
    isMultiTable: boolean;
}

const FastChessboard = (props: Partial<FastChessboardProps>) => {
    //@ts-ignore
    const {
        mobileHover = true,
        orientation = "white",
        fen = "8/8/8/8/8/8/8/8",
        border = false,
        showNotationInBorder = false,
        isMultiTable,
        borderWidth = isMultiTable ? 8 : 12,
        borderRadius = 6,
        marginTop = 4,
        marginBottom = 4,
        pieceWidth,
    }: FastChessboardProps = props;
    const appearanceTheme = useChangeTheme();
    const { gameStore, authStore } = useStores();
    const windowSize = useWindowSize();
    const classes = useStyles({
        borderWidth,
        appearanceTheme,
        isMultiTable,
    });
    // Принимаем фен
    const localFen = fen;
    const chessboardRef = useRef<HTMLDivElement>(null);
    const squareStyles = gameStore?.gameState.squareStyles;

    const isMyMove = gameStore.isMyMove;

    const defsParams = useMemo(
        () => ({
            wPid: uuid(),
            wRid: uuid(),
            wNid: uuid(),
            wBid: uuid(),
            wQid: uuid(),
            wKid: uuid(),
            bPid: uuid(),
            bRid: uuid(),
            bNid: uuid(),
            bBid: uuid(),
            bQid: uuid(),
            bKid: uuid(),
        }),
        []
    );

    const pieces = useMemo((): IPiecesMap => {
        return {
            wp: (
                <WPIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            wr: (
                <WRIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            wn: (
                <WNIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            wb: (
                <WBIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            wq: (
                <WQIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            wk: (
                <WKIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            bp: (
                <BPIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            br: (
                <BRIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            bn: (
                <BNIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            bb: (
                <BBIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            bq: (
                <BQIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
            bk: (
                <BKIcon
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable={isMultiTable}
                />
            ),
        };
    }, [pieceWidth, isMultiTable]);

    const dragPieces = useMemo((): IPiecesMap => {
        return {
            wp: (
                <WPIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            wr: (
                <WRIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            wn: (
                <WNIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            wb: (
                <WBIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            wq: (
                <WQIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            wk: (
                <WKIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            bp: (
                <BPIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            br: (
                <BRIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            bn: (
                <BNIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            bb: (
                <BBIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            bq: (
                <BQIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
            bk: (
                <BKIcon
                    type="drag"
                    width={pieceWidth}
                    mobileHover={mobileHover}
                    isMultiTable
                />
            ),
        };
    }, [pieceWidth, isMultiTable]);

    const isWhiteOrientation = useMemo(
        () => orientation[0] === "w",
        [orientation]
    );

    const notationVertical = useMemo(
        () =>
            isWhiteOrientation
                ? [8, 7, 6, 5, 4, 3, 2, 1]
                : [1, 2, 3, 4, 5, 6, 7, 8],
        [isWhiteOrientation]
    );

    const notationHorizontal = useMemo(
        () =>
            isWhiteOrientation
                ? ["a", "b", "c", "d", "e", "f", "g", "h"]
                : ["h", "g", "f", "e", "d", "c", "b", "a"],
        [isWhiteOrientation]
    );

    const flatFen = useMemo(() => {
        // Преобразуем фен
        const parts = localFen.split("");

        const flat = parts.reduce((acc, item) => {
            if (item === "/") return acc;
            if (Number(item)) {
                return [...acc, ...new Array(Number(item)).fill(null)];
            }
            return [...acc, item];
        }, []);

        return isWhiteOrientation ? flat : flat.reverse();
    }, [localFen, isWhiteOrientation, window.innerWidth]);

    const onSquareClick = useCallback(
        gameStore?.gameState.onSquareClick.bind(gameStore.gameState),
        []
    );
    const onDrop = useCallback(
        gameStore?.gameState.onDrop.bind(gameStore?.gameState),
        []
    );

    useEffect(() => {
        if (!chessboardRef?.current?.offsetHeight) return;
        gameStore.setChessboardSize(chessboardRef?.current?.offsetHeight);
    }, [
        chessboardRef?.current?.offsetHeight,
        windowSize,
        chessboardRef?.current?.offsetWidth,
    ]);

    return (
        <>
            <div
                className={cx(classes.fastGameMainComponent, appearanceTheme, {
                    isMultiTable: isMultiTable,
                })}
                style={{
                    marginTop,
                    marginBottom,
                }}
            >
                <SVGDefs {...defsParams} />

                <PieceDragLayer
                    pieces={dragPieces}
                    isMultiTable={isMultiTable}
                    equalizeDrag={
                        gameStore?.chessboardSize
                            ? gameStore?.chessboardSize / 8
                            : 0
                    }
                />

                <div className={cx(classes.chessboardWrapper, appearanceTheme)}>
                    <div
                        ref={chessboardRef}
                        className={cx(classes.chessboard, appearanceTheme, {
                            [classes.border]: !!border,
                            isMultiTable,
                        })}
                        style={{
                            lineHeight: "normal",
                            width: "100%",
                            height: "100%",
                            borderRadius: borderRadius,
                        }}
                    >
                        <Notation
                            borderWidth={borderWidth}
                            orientation={orientation}
                            showNotationInBorder={showNotationInBorder}
                            isMultiTable={isMultiTable}
                            cellSize={
                                gameStore?.chessboardSize
                                    ? gameStore?.chessboardSize / 8
                                    : 0
                            }
                        />
                        {flatFen.map((piece, i) => {
                            // Отдаем фен на рендер самой доски, конечная
                            const h = i % 8;
                            const v = Math.trunc(i / 8);
                            const square =
                                notationHorizontal[h] + notationVertical[v];

                            if (piece) {
                                return (
                                    <SquareWithFigureWrapper
                                        key={i}
                                        i={i}
                                        square={square}
                                        pieces={pieces}
                                        piece={piece}
                                        mobileHover={mobileHover}
                                        appearanceTheme={appearanceTheme}
                                        orientation={orientation}
                                        highlight={squareStyles?.[square]}
                                        onSquareClick={onSquareClick}
                                        onDrop={onDrop}
                                    />
                                );
                            } else {
                                return (
                                    <Square
                                        key={i}
                                        i={i}
                                        square={square}
                                        mobileHover={mobileHover}
                                        appearanceTheme={appearanceTheme}
                                        highlight={squareStyles?.[square]}
                                        onSquareClick={onSquareClick}
                                        onDrop={onDrop}
                                    />
                                );
                            }
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default observer(FastChessboard);
