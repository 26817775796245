import React, { useId } from "react";
import cn from "classnames";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { observer } from "mobx-react";
import EngSVG from "src/assets/icons/languages/gb.svg";
import FrSVG from "src/assets/icons/languages/fr.svg";
import DeSVG from "src/assets/icons/languages/de.svg";
import RuSVG from "src/assets/icons/languages/ru.svg";
import { useLocale } from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";
import { ELanguages } from "src/store/models";
import { useStyles } from "./styles";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { menuItemClasses } from '@mui/material/MenuItem'; 

interface ILanguagePicker {
  showInDropper?: boolean;
  pickerPage?: string;
}

const LanguagePicker = ({}: ILanguagePicker) => {
  const styles = useStyles();
  const { authStore } = useStores();
  const uniqUs = useId();
  const {
    languages: { english, russian, french, german },
  } = useLocale();
  const userLanguage = authStore.getLanguage();
  const languages = [
    {
      label: english("English"),
      value: ELanguages.EN,
      icon: EngSVG,
    },
    {
      label: french("French"),
      value: ELanguages.FR,
      icon: FrSVG,
    },
    {
      label: german("German"),
      value: ELanguages.DE,
      icon: DeSVG,
    },
    {
      label: russian("Русский"),
      value: ELanguages.RU,
      icon: RuSVG,
    },
  ];

  const handleChangeLanguage = async (language) => {
    if (!language.target.value) return;
    authStore.changeLanguage(language.target.value);
    try {
      await authStore.updateGameSettings({
        language: language.target.value,
      });
    } catch (e) {}
  };

  return (
    <>
      <div>
        <FormControl className={cn(styles.formControlMain)}>
          <Select
            className={cn(styles.formSelect)}
            labelId="123"
            id={uniqUs}
            value={userLanguage}
            onChange={handleChangeLanguage}
            autoWidth
            label={userLanguage}
            style={{
              padding: "0px",
            }}
            IconComponent={ArrowDropDownIcon}
          >
            {languages.map((lang) => {
              return (
                <MenuItem
                  value={lang.value}
                  key={lang.value}
                  id={lang.value}
                  className={cn(styles.menuItemContainer)} // Применяем стиль menuItemContainer
                >
                  <div className={cn(styles.menuItemBox)}>
                    <img
                      className={cn(styles.menuItemImg)}
                      src={lang.icon}
                      alt={lang.value}
                    />
                    <p className={cn(styles.menuItemTitle)}>
                      {lang.value.toUpperCase()}
                    </p>
                  </div>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default observer(LanguagePicker);