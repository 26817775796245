import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';
import theme from 'src/theme';

const currentHeight = window.innerHeight;

export const useModalStyles = makeStyles({
    modalWrapper: {
        // background: 'rgba(13, 14, 15, 0.8)',
        background: 'rgba(13, 14, 15, 0.3)',
    },
    modal: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 432,
        width: '100%',
        background: appColors.bg800,
        boxShadow: '0px 22.32369613647461px 90px 0px #00000080',
        padding: 24,
        borderRadius: 24,
        border: 'none',
        outline: 'none',
        '&.darkBlue': {
            background: appColors.bg800,
            '&.isAvatar': {
                [theme.breakpoints.up(1600)]: {
                    maxWidth: '60%',
                },
                [theme.breakpoints.up('xl')]: {
                    maxWidth: '70%',
                },
                [theme.breakpoints.up('lg')]: {
                    maxWidth: '80%',
                },
                [theme.breakpoints.up('md')]: {
                    maxWidth: '60%',
                    height: '90vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                },
                [theme.breakpoints.down('md')]: {
                    maxWidth: '90%',
                    height: '90vh',
                },
                '@media screen and (max-width: 650px)': {
                    maxWidth: '100%',
                    height: '100%',
                    borderRadius: 0,
                    padding: '20px 10px',
                },
            },
        },
        '&.darkGreen': {
            background: '#091819',
            border: '2px solid rgba(138, 104, 233, 0.4)',
            '&.isAvatar': {
                [theme.breakpoints.up(1600)]: {
                    maxWidth: '60%',
                },
                [theme.breakpoints.up('xl')]: {
                    maxWidth: '70%',
                },
                [theme.breakpoints.up('lg')]: {
                    maxWidth: '80%',
                },
                [theme.breakpoints.up('md')]: {
                    maxWidth: '60%',
                    height: '90vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                },
                [theme.breakpoints.down('md')]: {
                    maxWidth: '90%',
                    height: '90vh',
                },
                '@media screen and (max-width: 650px)': {
                    maxWidth: '100%',
                    height: '100%',
                    borderRadius: 0,
                    padding: '20px 10px',
                },
            },
        },
    },
    modalTopBox: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginBottom: 24,
        height: 50,
    },
    title: {
        fontSize: 24,
        fontWeight: 500,
        color: appColors.white,
        '&.isCustom': {
            [theme.breakpoints.up('lg')]: {
                marginBottom: 10,
            },
            [theme.breakpoints.down('lg')]: {
                margin: '0 auto',
                marginBottom: 0,
                marginTop: 20,
            },
        },
        '&.getOwn': {
            textAlign: 'center',
            [theme.breakpoints.up('lg')]: {
                display: 'flex',
                alignItems: 'center',
                fontSize: 24,
            },
            [theme.breakpoints.down('lg')]: {
                display: 'flex',
                alignItems: 'center',
                fontSize: 18,
            },
        },
    },
    closeIcon: {
        width: 24,
        height: 24,
        objectFit: 'contain',
        cursor: 'pointer',
        position: 'absolute',
        top: '20px',
        right: '10px',
        [theme.breakpoints.up('md')]: {
            right: '20px',
        },
    },

    tournamentModalTimer: {
        [theme.breakpoints.up('xs')]: {
            color: appColors.white,
            margin: 0,
            fontWeight: 500,
            fontSize: '18px',
            opacity: 0.8,
        },
    },

    chooseAvatarWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: 'calc(100% - 50px)',
        maxWidth: '100%',
        padding: 0,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
            '&.nonGrid': {
                justifyContent: 'center',
            },
        },
    },

    uploadAvatarWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        maxWidth: '100%',
        padding: 0,
        position: 'absolute',
        top: 0,
        right: 0,
        borderRadius: 8,
        transform: ` translate(0%, 50%) translateY(${currentHeight}px)`,
        opacity: 0,
        '&.darkBlue': {
            background: backgroundColors.darkBlue, border: "1px solid #343A59",
        },
        '&.darkGreen': {
          background: backgroundColors.darkGreen, border: "1px solid #004348",
        },
        '$.isInit': {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
            maxWidth: '100%',
            padding: 0,
            position: 'absolute',
            top: 0,
            right: 0,
            borderRadius: 8,
            transform: ` translate(0%, 0%) translateY(${currentHeight}px)`,
            opacity: 1,
        },
        '&.isUpload': {
            opacity: 0,
            animationName: '$uploadAvatarWrapper',
            animationDelay: '0s',
            animationIterationCount: 1,
            animationFillMode: 'forwards',
            animationDuration: '1000ms',
            transform: ` translate(0%, 0%) translateY(${currentHeight}px)`,
        },
        '&.isUploadHide': {
            opacity: 1,
            animationName: '$uploadAvatarWrapperHide',
            animationDelay: '0s',
            animationIterationCount: 1,
            animationFillMode: 'forwards',
            animationDuration: '1000ms',
            transform: ` translate(0%, 0%) translateY(${0}px)`,
        },
    },

    '@keyframes uploadAvatarWrapper': {
        '0%': {
            opacity: 0,
            transform: ` translate(0%, 0%) translateY(${currentHeight}px)`,
            borderRadius: '10px',
        },
        '100%': {
            opacity: 1,
            borderRadius: '35px',
            transform: `translate(0%, 0%) translateY(${0}px)`,
        },
    },

    '@keyframes uploadAvatarWrapperHide': {
        '0%': {
            opacity: 1,
            transform: `translate(0%, 0%) translateY(${0}px)`,
        },
        '100%': {
            opacity: 0,
            transform: `translate(0%, 0%) translateY(${currentHeight}px)`,
        },
    },

    form: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        justifyContent: 'flex-start',
    },
    input: {
        width: '100%',
        borderRadius: 12,
        '&.darkBlue': {
            '& .MuiInputLabel-root': {
                color: appColors.placeholder100,
                fontWeight: 400,
                fontSize: 20,
                '&.MuiInputLabel-filled.MuiInputLabel-shrink': {
                    transform: 'translate(12px, 10px) scale(0.5)',
                },
            },
            '& .MuiFilledInput-root': {
                border: `1px solid ${appColors.border300}`,
                overflow: 'hidden',
                borderRadius: 12,
                color: appColors.white,
                background: appColors.bg1200,
                fontSize: 16,
                fontweight: 400,
            },
        },
        '&.darkGreen': {
            '& .MuiInputLabel-root': {
                color: appColors.placeholder100,
                fontWeight: 400,
                fontSize: 20,
                '&.MuiInputLabel-filled.MuiInputLabel-shrink': {
                    transform: 'translate(12px, 10px) scale(0.5)',
                },
            },
            '& .MuiFilledInput-root': {
                border: `1px solid ${appColors.border300}`,
                overflow: 'hidden',
                borderRadius: 12,
                color: appColors.white,
                background:
                    'linear-gradient(19.92deg, rgba(1, 1, 1, 0.7) 13.3%, rgba(14, 15, 15, 0.7) 86.7%)',
                fontSize: 16,
                fontweight: 400,
            },
        },
    },
});
