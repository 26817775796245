import React, { useState } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { Box } from "@mui/material";

import LazyImages from "src/components/lazyImages";
import avatarPlaceholder from "src/assets/images/defaultPlaceholder.png";
import endpoints from "src/core/endpoints";
import { EModalsLabels } from "src/store/models";
import useChangeTheme from "src/hooks/useChangeTheme";

import ModalTitles from "../ModalTitles/ModalTitles";
import ModalUserStatusBox from "../ModalUserStatusBox/ModalUserStatusBox";

import { useStyles } from "./styles";

type IModalImagesBox = {
    myAvatar?: string | undefined;
    myNickname?: string | undefined;
    myIsSystemAvatar?: boolean | undefined;
    nickname?: string | undefined;
    avatar?: string | undefined;
    isSystemAvatar?: boolean | undefined;
    label?: string | undefined;
    pathCompressed?: string | undefined;
    status?: string | undefined;
    placePosition?: string;
};

const ModalImagesBox = ({
    myAvatar,
    myNickname,
    nickname,
    avatar,
    isSystemAvatar,
    myIsSystemAvatar,
    label,
    pathCompressed,
    status,
    placePosition,
}: IModalImagesBox) => {
    const styles = useStyles();
    const appearanceTheme = useChangeTheme();
    const [isLoaded, setLoaded] = useState<boolean>(false);

    return (
        <>
            {label === EModalsLabels.friendFightInit && (
                <Box
                    className={cn(
                        styles.modalImagesBox,
                        appearanceTheme,
                        label,
                        placePosition
                    )}
                >
                    <Box
                        className={cn(
                            styles.modalImagesBoxVersusFriend,
                            appearanceTheme,
                            label,
                            placePosition
                        )}
                    >
                        <Box
                            className={cn(
                                styles.modalImagesContainer,
                                appearanceTheme,
                                label,
                                placePosition
                            )}
                        >
                            <LazyImages
                                className={cn(
                                    styles.avatarImage,
                                    label,
                                    placePosition,
                                    {
                                        isSystemAvatar: myIsSystemAvatar,
                                        isLoaded: !isLoaded,
                                    }
                                )}
                                placeholderImg={
                                    pathCompressed
                                        ? `${endpoints.avatarPath}${pathCompressed}`
                                        : avatarPlaceholder
                                }
                                src={endpoints.avatarPath + myAvatar}
                                errorImg={avatarPlaceholder}
                                setLoaded={(status: boolean) =>
                                    setLoaded(status)
                                }
                            />
                            <ModalTitles title={myNickname} label="nicknames" />
                        </Box>
                        <p
                            className={cn(
                                styles.modalVersusText,
                                placePosition,
                                {}
                            )}
                        >
                            VS
                        </p>
                        <Box
                            className={cn(
                                styles.modalImagesContainer,
                                appearanceTheme,
                                label,
                                placePosition
                            )}
                        >
                            <LazyImages
                                className={cn(
                                    styles.avatarImage,
                                    label,
                                    placePosition,
                                    {
                                        isSystemAvatar: isSystemAvatar,
                                        isLoaded: !isLoaded,
                                    }
                                )}
                                placeholderImg={
                                    pathCompressed
                                        ? `${endpoints.avatarPath}${pathCompressed}`
                                        : avatarPlaceholder
                                }
                                src={endpoints.avatarPath + avatar}
                                errorImg={avatarPlaceholder}
                                setLoaded={(status: boolean) =>
                                    setLoaded(status)
                                }
                            />
                            <ModalTitles title={nickname} label="nicknames" />
                        </Box>
                    </Box>
                </Box>
            )}
            {(label === EModalsLabels.friendFightReceive ||
                label === EModalsLabels.lobbyConfirmGame) && (
                <Box
                    className={cn(
                        styles.modalImagesBox,
                        appearanceTheme,
                        label,
                        placePosition
                    )}
                >
                    <Box
                        className={cn(
                            styles.modalImagesBoxVersusFriend,
                            appearanceTheme,
                            label,
                            placePosition
                        )}
                    >
                        <Box
                            className={cn(
                                styles.modalImagesContainer,
                                appearanceTheme,
                                label,
                                placePosition
                            )}
                        >
                            {status && (
                                <ModalUserStatusBox
                                    userStatus={status}
                                    placePosition={placePosition}
                                />
                            )}
                            <LazyImages
                                className={cn(
                                    styles.avatarImage,
                                    label,
                                    placePosition,
                                    {
                                        isSystemAvatar: myIsSystemAvatar,
                                        isLoaded: !isLoaded,
                                    }
                                )}
                                placeholderImg={
                                    pathCompressed
                                        ? `${endpoints.avatarPath}${pathCompressed}`
                                        : avatarPlaceholder
                                }
                                src={endpoints.avatarPath + avatar}
                                errorImg={avatarPlaceholder}
                                setLoaded={(status: boolean) =>
                                    setLoaded(status)
                                }
                            />

                            <ModalTitles title={nickname} label="nicknames" />
                        </Box>
                    </Box>
                </Box>
            )}
            {label === EModalsLabels.lobbyInitiatorAwaiting && (
                <Box
                    className={cn(
                        styles.modalImagesBox,
                        appearanceTheme,
                        label,
                        placePosition
                    )}
                >
                    <Box
                        className={cn(
                            styles.modalImagesBoxVersusFriend,
                            appearanceTheme,
                            label,
                            placePosition
                        )}
                    >
                        <Box
                            className={cn(
                                styles.modalImagesContainer,
                                appearanceTheme,
                                label,
                                placePosition
                            )}
                        >
                            <LazyImages
                                className={cn(
                                    styles.avatarImage,
                                    label,
                                    placePosition,
                                    {
                                        isSystemAvatar: myIsSystemAvatar,
                                        isLoaded: !isLoaded,
                                    }
                                )}
                                placeholderImg={
                                    pathCompressed
                                        ? `${endpoints.avatarPath}${pathCompressed}`
                                        : avatarPlaceholder
                                }
                                src={endpoints.avatarPath + avatar}
                                errorImg={avatarPlaceholder}
                                setLoaded={(status: boolean) =>
                                    setLoaded(status)
                                }
                            />
                            <ModalTitles title={nickname} label="nicknames" />
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default observer(ModalImagesBox);
