// import { ReactComponent as BulletIcon } from './images/bullet.svg';
// import { ReactComponent as BlitzIcon } from './images/blitz.svg';
// import { ReactComponent as RapidIcon } from './images/rapid.svg';

// const gameRenderParams = {
//     types: [
//         { text: classicName('Классика'), value: GAME_TYPE.CLASSIC },
//         { text: doublingName('С удвоением'), value: GAME_TYPE.DOUBLE },
//     ],
//     bets: [
//         { text: 1, value: 1 },
//         { text: 3, value: 3 },
//         { text: 5, value: 5 },
//         { text: 10, value: 10 },
//         { text: 25, value: 25 },
//         { text: 50, value: 50 },
//         { text: 100, value: 100 },
//     ],

//     times: [
//         {
//             text: timeDetails.compile(
//                 { time: 3, increment: 3 },
//                 '3 мин + 3 сек'
//             ),
//             icon: <BulletIcon style={{ paddingTop: 4 }} />,
//             value: { time: 3, increment: 3 },
//         },
//         {
//             text: timeDetails.compile(
//                 { time: 5, increment: 5 },
//                 '5 мин + 5 сек'
//             ),
//             icon: <BlitzIcon />,
//             value: { time: 5, increment: 5 },
//         },
//         {
//             text: timeDetails.compile(
//                 { time: 10, increment: 10 },
//                 '10 мин + 10 сек'
//             ),
//             icon: <RapidIcon />,
//             value: { time: 10, increment: 10 },
//         },
//     ],
// };

const positionMultiplier = 1.56;
export const pieceDicePosition = Object.freeze({
    qween: { y: 0, z: 0, x: 0 },
    king: { y: positionMultiplier, z: 0, x: 0 },
    bishop: { y: positionMultiplier * 2, z: 0, x: 0 },
    rook: { y: positionMultiplier * 3, z: 0, x: 0 },
    knight: { y: 0, z: 0, x: positionMultiplier },
    pawn: { y: 0, z: 0, x: positionMultiplier * 3 },
});

export const pieceName = Object.freeze({
    q: 'q',
    b: 'b',
    r: 'r',
    k: 'k',
    p: 'p',
    n: 'n',
});
