import { makeStyles } from '@mui/styles';

import theme from 'src/theme';

export const useStyles = makeStyles({
    select: {
        '&:before': {
            borderColor: 'transparent!important',
        },
        '&:after': {
            borderColor: 'transparent!important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'transparent!important',
        },
    },
    selectIcon: {
        fill: '#FFFFFF!important',
    },
    selectRoot: {
        color: '#FFFFFF!important',
    },
    selectInput: {
        fontFamily: 'Montserrat, sans-serif!important',
        borderRadius: '0!important',
        '& .MuiInputBase-input': {
            minWidth: 'auto!important',
            maxWidth: 'none!important',
            borderRadius: '0!important',
            height: 40,
            transition: '0.3s ease',
            position: 'relative',
            backgroundColor: 'transparent',
            border: 'none',
            fontSize: 16,
            padding: '10px 15px',
            fontFamily: 'Montserrat, sans-serif!important',
            minHeight: '0px!important',
            lineHeight: '1.25',
            color: '#FFFFFF',
            fontWeight: 500,
            boxSizing: "border-box!important",
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
        },
    },
    selectItem: {
        minHeight: 0,
        minWidth: 0,
        fontFamily: 'Montserrat, sans-serif!important',
        color: '#FFFFFF!important',
        fontSize: '16px!important',
        fontWeight: '500!important',
        padding: '10px 15px',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        '&.Mui-selected': {
            backgroundColor: 'rgba(0, 186, 108, 0.2)',
            '&:hover': {
                backgroundColor: 'rgba(0, 186, 108, 0.3)',
            },
        },
    },
    menu: {
        maxHeight: 320,
    },
    paper: {
        backgroundColor: '#202224!important',
        color: '#FFFFFF',
        borderRadius: 0,
        marginTop: 0,
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
});