import { makeStyles } from '@mui/styles';
import { keyframes } from '@mui/system';

import { EColor } from 'src/components/types';
import { appColors, backgroundColors } from 'src/consts/app-config';
import theme from 'src/theme';

interface IInput {
    clicker: any;
    myColor: any;
    oponentColor: any;
}

export const useStyles = makeStyles({
    '@global': {
        '@keyframes gameScoreTitlesFirst': {
            '0%': {
                transform: 'scale(1)',
            },
            '25%': {
                fontWeight: 600,
                transform: 'scale(2)',
            },
            '35%': {
                transform: 'scale(2)',
                filter: 'drop-shadow(0px 0px 0px black)',
            },
            '50%': {
                transform: 'scale(2)',
                fontWeight: 700,
                filter: 'drop-shadow(0px 0px 4px #F8BF44)',
            },
            '65%': {
                transform: 'scale(2)',
                filter: 'drop-shadow(0px 0px 0px black)',
            },
            '75%': {
                transform: 'scale(2)',
                fontWeight: 600,
            },
            '100%': {
                transform: 'scale(1)',
            },
        },
    },

    scoreAndTournamentInfoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        justifyContent: 'center',
        alignItems: 'center',
        // padding: '10px 0px',
        '@media screen and (min-height: 880px) and (max-width: 1251px)': {
            // padding: '5px 0px 15px 0',
            padding: '0px',
        },
        '@media screen and (max-height: 790px) and (max-width: 1251px)': {
            // padding: '5px 0px',
        },
        '@media screen and (max-height: 750px) and (max-width: 1251px)': {
            // flexDirection: 'row',
        },
        '@media screen and (max-height: 700px) and (max-width: 1251px)': {
            // padding: '5px 0px',
        },
    },

    matchGameScoreTitleBox: {
        [theme.breakpoints.up(1250)]: {
            width: '100%',
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    matchRoundBox: {
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        flexShrink: 2,
        gridGap: '7px',
    },

    gameScoreTitles: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        background: 'linear-gradient(180deg, #F8BF44 0%, #A16529 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        margin: 0,
        '&.tournamentDetails': {
            fontWeight: 500,
            filter: 'drop-shadow(1px 2px 1px black)',
            oveflow: 'hidden',
            position: 'relative',
            [theme.breakpoints.up('lg')]: {
                fontSize: '28px',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                // maxWidth: '310px',
                minWidth: '310px',
                ovefrlow: 'hidden',
            },
            '@media screen and (max-height: 750px) and (max-width: 450px)': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '16px',
            },
            '&.tournamentDetailsTitle': {
                position: 'relative',
                filter: 'drop-shadow(1px 2px 1px black)',
            },
            '&.tournamentDetailsRound': {
                filter: 'drop-shadow(1px 2px 1px black)',
                [theme.breakpoints.up('lg')]: {
                    fontSize: '24px',
                },
            },
        },

        '&.score': {
            fontWeight: 600,
            fontSize: '18px',
            filter: 'drop-shadow(1px 2px 1.5px black)',
            [theme.breakpoints.up('lg')]: {
                fontSize: '28px',
            },
            '@media screen and (max-height: 750px) and (max-width: 450px)': {
                fontSize: '18px',
            },
        },
        '&.upTo': {
            fontWeight: 500,
            fontSize: '14px',
            filter: 'drop-shadow(1px 2px 1px black)',
            [theme.breakpoints.up('lg')]: {
                fontSize: '20px',
            },
            '@media screen and (max-height: 750px) and (max-width: 450px)': {
                fontSize: '14px',
            },
        },

        '&.colon': {
            fontWeight: 600,
            fontSize: '18px',
            [theme.breakpoints.up('lg')]: {
                fontSize: '28px',
            },
            '@media screen and (max-height: 750px) and (max-width: 450px)': {
                fontSize: '18px',
            },
        },
    },

    matchGameScoreTitleBoxShine: {
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 0,
        background:
            'radial-gradient(50% 4622.78% at 50% 50%, #FFF451 0%, rgba(255, 255, 255, 0) 100%)',
        opacity: 0.35,
        filter: 'blur(10px)',
        [theme.breakpoints.up('xs')]: { height: '25px' },
        [theme.breakpoints.up('lg')]: { height: '40px' },
    },

    gameScoreTitlesFirst: ({ clicker, myColor }: IInput) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        background: 'linear-gradient(180deg, #F8BF44 0%, #A16529 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        margin: 0,
        fontWeight: 500,
        fontSize: '18px',
        maxWidth: '130px',
        gridGap: '3px',

        width: '14px',
        [theme.breakpoints.up('xs')]: {
            filter: 'drop-shadow(0.5px 0.5px 1px black)',
        },
        [theme.breakpoints.up(1250)]: {
            width: 'fit-content',
            filter: 'drop-shadow(1px 1px 1px black)',
        },
        '@media screen and (max-height: 750px) and (max-width: 450px)': {
            fontSize: '18px',
        },
        animationName: 'empty',
        '&.me': {
            animationName: 'gameScoreTitlesFirst',
            animationIterationCount: '1',
            animationFillMode: 'forwards',
            animationDuration: '2300ms',
            animationDelay: '0s',
        },
    }),

    gameScoreTitlesSecond: ({ clicker, oponentColor }: IInput) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        background: 'linear-gradient(180deg, #F8BF44 0%, #A16529 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        margin: 0,
        fontWeight: 500,
        fontSize: '18px',
        maxWidth: '130px',
        gridGap: '3px',
        // animationName:
        //     clicker === oponentColor ? 'gameScoreTitlesFirst' : 'empty',
        animationDelay: '0s',
        animationIterationCount: '1',
        animationFillMode: 'forwards',
        animationDuration: '2300ms',
        width: '14px',
        filter: 'drop-shadow(1px 2px 1px black)',
        // [theme.breakpoints.up('lg')]: {
        //     fontSize: '26px',
        // },
        [theme.breakpoints.up('xs')]: {
            filter: 'drop-shadow(0.5px 0.5px 1px black)',
        },
        [theme.breakpoints.up(1250)]: {
            // width: '18px',
            width: 'fit-content',
            justifyContent: 'flex-start',
        },
        '@media screen and (max-height: 750px) and (max-width: 450px)': {
            fontSize: '18px',
        },
        animationName: 'empty',
        '&.oponent': {
            animationName: 'gameScoreTitlesFirst',
            animationIterationCount: '1',
            animationFillMode: 'forwards',
            animationDuration: '2300ms',
            animationDelay: '0s',
        },
    }),

    gameScoreTitlesName: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'start',
        background: 'linear-gradient(180deg, #F8BF44 0%, #A16529 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        fontWeight: 500,
        fontSize: '18px',
        overflow: 'hidden',
        margin: 0,
        [theme.breakpoints.up('lg')]: {
            fontSize: '26px',
        },
        '@media screen and (max-height: 750px) and (max-width: 450px)': {
            fontSize: '18px',
        },
        '&.nickname': {
            width: 'fit-content',
            maxWidth: '100px',
            whiteSpace: 'pre',
            fontSize: '20px',
            maskImage: 'linear-gradient(to right,black 95%,transparent 100%)',
            [theme.breakpoints.up(1250)]: {
                fontSize: '26px',
            },
        },
        '&.nicknameSecond': {
            // marginLeft: '5px',
        },
        '&.colon': {
            // marginLeft: '5px',
            // marginRight: '5px',
        },
        '&.score': {
            minWidth: 'fit-content',
            justifyContent: 'center',
        },
    },
});
