import { supportPath } from "src/consts/paths";

import SupportEmailIconGenerator from "src/assets/icons/social/supportEmail";
import TelegramIconGenerator from "src/assets/icons/social/telegramIcon";
import WhatsAppIconGenerator from "src/assets/icons/social/whatsappIcon";

function SupportPathsContainer(
    width: number,
    height: number,
    fillColor: string
) {
    return [
        {
            soclLink: supportPath.emailSupoort,
            socialIcon: (
                <SupportEmailIconGenerator
                    width={width}
                    height={height}
                    fillColor={fillColor}
                />
            ),
            alt: "email",
        },
        {
            soclLink: supportPath.telegram,
            socialIcon: (
                <TelegramIconGenerator
                    width={width}
                    height={height}
                    fillColor={fillColor}
                />
            ),
            alt: "telegram",
        },
        {
            soclLink: supportPath.whatsApp,
            socialIcon: (
                <WhatsAppIconGenerator
                    width={width}
                    height={height}
                    fillColor={fillColor}
                />
            ),
            alt: "whatsApp",
        },
    ];
}

export default SupportPathsContainer;
