import React, { useState, useRef, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import classNames from "classnames";
import { useHistory, Link } from "react-router-dom";
import { ThemeProvider } from "@mui/styles";

import {
    Box,
    Grid,
    OutlinedInput,
    InputLabel,
    FormControl,
    Dialog,
    createTheme,
    TextField,
} from "@mui/material";
import _get from "lodash/get";
import { observer } from "mobx-react";
// import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';

import LocaleText from "src/components/locale-text/locale.text";
import { useLocale } from "src/providers/LocaleProvider";
import { ELanguages } from "src/store/models";

import { errorService } from "../../service/services";
import Checkbox from "../../components/controls/checkbox";
import { useStyles } from "../styles/styles.js";

import { useStyles as useMuiStyles } from "./styles";

import "./style.css";
import { ButtonType, GreenButton } from "../../components/buttons/greenButton";
import useStores from "../../hooks/useStores";
import closeIcon from "src/assets/icons/x.svg";
import googleLogo from "src/assets/icons/social/GoogleIcons.svg";
import appleLogo from "src/assets/icons/social/AppleIcons.svg";
import facebookLogo from "src/assets/icons/social/fbIcons.svg";

import { useModal } from "src/pages/landing/utils/context";
import paths from "src/consts/paths";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { signInWithFacebook, signInWithGoogle } from "../../service/api/auth";
const theme = createTheme({
    palette: {
        primary: {
            main: "#5B627E",
            dark: "#fff",
            contrastText: "#5B627E",
            light: "#5B627E",
        },
    },
});

const LoginForm = () => {
    const [remember, setRemember] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isPasswordHidden, setVisiblePassword] = useState(true);
    const inputEl = useRef<any>(null);
    const { authStore } = useStores();
    const muiStyles = useMuiStyles();
    const { closeModal, openModal } = useModal();

    // const fingerprintData = useVisitorData();

    const onButtonClick = (e) => {
        e.preventDefault();
        const el = inputEl.current;
        if (!el) return;
        const input = el.querySelector("#password");
        if (!input) return;
        if (isPasswordHidden) {
            setVisiblePassword(false);
            input.type = "text";
        } else {
            setVisiblePassword(true);
            input.type = "password";
        }
    };
    // useEffect(() => {
    //     console.log(fingerprintData);
    // }, [fingerprintData.isLoading]);

    useEffect(() => {
        if (authStore.isAuthorized) history.push(paths.lobby);
    }, [authStore.isAuthorized]);

    const btnStyle = classNames({
        hidePasswordBtn: true,
        hidePasswordBtnClosed: isPasswordHidden,
        hidePasswordBtnOpen: !isPasswordHidden,
    });

    const history = useHistory();
    const classes = useStyles();

    const {
        login: {
            errors: {
                useJustEnglishLetters,
                tooLongPassword,
                enterThePassword,
                tooLongEmail,
                enterEmail,
            },
            form: { placeEmail, placePassword },
            loginButton,
        },
    } = useLocale();

    const locales = useLocale();

    const handleChangeLanguage = async (language) => {
        if (language == null) return;
        authStore.changeLanguage(language);
        await authStore.updateGameSettings({
            language: language,
        });
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .trim()
            .matches(
                /[a-zA-Z\d\s|!#$%^&*()_}{"'`~+,-\[\]]/g,
                useJustEnglishLetters("Используйте только английские буквы!")
            )
            .max(
                20,
                tooLongPassword("Пароль очень длинный (макс. 20 символа)!")
            )
            .required(enterThePassword("Введите пароль")),
        email: Yup.string()
            .max(64, tooLongEmail("Слишком длинный почтовый ящик"))
            .required(enterEmail("Введите почтовый ящик")),
    });

    const onSubmitForm = async ({ email, password }) => {
        setLoading(true);
        try {
            const error = await authStore.signIn(
                email,
                password,
                authStore.selectedLanguage || ELanguages.EN,
                remember
            );
            if (error) {
                setError(true);
                errorService.sendError(error);
            } else {
                await authStore.getMeNew();
                history.push(paths.lobby);
                closeModal("login");
                return;
            }
        } catch (err) {
            const error = err?.response?.data?.error;
            if (error) {
                errorService.sendError(
                    _get(
                        locales,
                        error.locale?.label
                    )(error.locale.defaultValue || "")
                );
            }
        } finally {
            setLoading(false);
        }
    };

    if (authStore.isAuthorized) {
        return null;
    }

    return (
        <Dialog
            open
            className={muiStyles.container}
            onClose={() => closeModal("login")}
            PaperProps={{ className: muiStyles.dialogPaper }}
        >
            <Box component="div" className={muiStyles.formContainer}>
                <ThemeProvider theme={theme}>
                    <Formik
                        initialValues={{ email: "", password: "" }}
                        validationSchema={validationSchema}
                        onSubmit={onSubmitForm}
                        render={({
                            values,
                            errors,
                            handleSubmit,
                            handleChange,
                        }) => {
                            return (
                                <form
                                    onSubmit={handleSubmit}
                                    className={muiStyles.formWrapper}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                    >
                                        <Grid
                                            xs={12}
                                            item
                                            className={muiStyles.titleGridItem}
                                        >
                                            <div className={muiStyles.titleBlock}>
                                                <div className={muiStyles.enterGame}>
                                                    <LocaleText
                                                        label="login.title"
                                                        defaultValue="Log In"
                                                    />
                                                </div>
                                                <div className={muiStyles.closeIconWrapper}>
                                                    <img
                                                        src={closeIcon}
                                                        alt="Close"
                                                        className={muiStyles.closeIcon}
                                                        onClick={() => closeModal("login")}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={classNames(
                                                    muiStyles.joinGame,
                                                    "joinGame joinGame--login"
                                                )}
                                            ></div>
                                        </Grid>
                                        <Grid item xs={12} className="fieldGrid">
                                            <div className="OutlinedInput">
                                                <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                    className="input-container"
                                                >
                                                    <label
                                                        htmlFor="email"
                                                        className={muiStyles.outlinedLabel}
                                                    >
                                                        Email*
                                                    </label>

                                                    <TextField
                                                        id="component-outlined"
                                                        onChange={handleChange}
                                                        error={!!errors.email}
                                                        name="email"
                                                        value={values.email}
                                                        style={{
                                                            backgroundColor:
                                                                "#131624",
                                                            borderRadius: 12,
                                                            color: "#fff",
                                                        }}
                                                        placeholder="Enter your email address"
                                                        InputProps={{
                                                            classes: {
                                                                root: muiStyles.outlinedInput,
                                                            },
                                                        }}
                                                        required
                                                    />
                                                    {errors.email && (
                                                        <div className="error">
                                                            {errors.email as string}.
                                                        </div>
                                                    )}
                                                </FormControl>
                                            </div>
                                        </Grid>

                                        <Grid xs={12} item className="fieldGrid">
                                            <div className="OutlinedInput">
                                                <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                >
                                                    <label
                                                        htmlFor="password"
                                                        className={muiStyles.outlinedLabel}
                                                    >
                                                        Password*
                                                    </label>
                                                    <OutlinedInput
                                                        ref={inputEl}
                                                        id="password"
                                                        onChange={handleChange}
                                                        error={!!errors.password}
                                                        name="password"
                                                        type="password"
                                                        value={values.password}
                                                        className={`${muiStyles.outlinedInput} ${muiStyles.inputAutoFillOverride}`}
                                                        placeholder="Enter your password"
                                                        required
                                                    />
                                                    <div className={muiStyles.passwordHint}>
                                                        Password must be at least 8
                                                        characters long.
                                                    </div>

                                                    <div
                                                        className={btnStyle}
                                                        onClick={onButtonClick}
                                                    />
                                                    {errors.password && (
                                                        <div className="error">
                                                            {errors.password as string}.
                                                        </div>
                                                    )}
                                                </FormControl>
                                            </div>
                                        </Grid>

                                        <Grid xs={12} item className="fieldGrid">
                                            <div className={muiStyles.checkboxField}>
                                                <div
                                                    className={muiStyles.checkboxContainer}
                                                    onClick={() => setRemember(!remember)}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        className={muiStyles.checkboxInput}
                                                        checked={remember}
                                                        readOnly
                                                    />
                                                    <span
                                                        className={classNames(muiStyles.checkmark, {
                                                            [muiStyles.checkmarkChecked]: remember,
                                                        })}
                                                    />
                                                    <span className={muiStyles.checkboxText}>Remember me</span>
                                                </div>
                                                <Link
                                                    to="/recovery-password"
                                                    className={classNames(classes.link, muiStyles.recoveryPasswordText)}
                                                    onClick={() => closeModal("login")}
                                                >
                                                    <LocaleText
                                                        label="login.forgotPasswordButton"
                                                        defaultValue="Forgot Password"
                                                    />
                                                </Link>
                                            </div>
                                        </Grid>

                                        {error && (
                                            <Grid xs={12} item className="fieldGrid">
                                                <div className="joinGame joinGame--notFound">
                                                    <LocaleText
                                                        label="login.accountNotFound"
                                                        defaultValue="Account not found"
                                                    />
                                                    &nbsp;
                                                    <Link to={paths.login}>
                                                        <LocaleText
                                                            label="login.createAccount"
                                                            defaultValue="Create Account"
                                                        />
                                                    </Link>
                                                </div>
                                            </Grid>
                                        )}

                                        <Grid item xs={12} className="fieldGrid">
                                            <div
                                                className={classNames(
                                                    muiStyles.formBtnWrap,
                                                    "formBtnWrap--login"
                                                )}
                                            >
                                                <GreenButton
                                                    loading={isLoading}
                                                    buttonType={"submit"}
                                                    text={loginButton("Continue")}
                                                    onClick={() => { }}
                                                    width={"100%"}
                                                    type={ButtonType.continueBtn}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                        className="fieldGrid"
                                        alignContent={"flex-start"}
                                    >
                                        <Grid container className={muiStyles.lineContainer}>
                                            <div className={muiStyles.lineBox}>
                                                <span>or</span>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs className="fieldGrid" alignContent={"flex-start"}>
                                        <Grid container className={muiStyles.socialRegister} style={{ display: 'flex' }}>
                                            <GoogleOAuthProvider clientId="GOOGLE_CLIENT_ID">
                                                <div className={muiStyles.googleButtonWrapper}>
                                                    <button
                                                        className={muiStyles.socialRegisterBtn}
                                                        style={{ zIndex: 1 }}
                                                    >
                                                        <img src={googleLogo} alt="Google" className={muiStyles.socialIcon} />
                                                    </button>
                                                    <div className={muiStyles.googleButtonOverlay}>
                                                        <div className={muiStyles.googleButtonInner}>
                                                            <GoogleLogin
                                                                onSuccess={async (credentialResponse) => {
                                                                    const { credential } = credentialResponse;
                                                                    if (credential) {
                                                                        try {
                                                                            const response = await signInWithGoogle({ accessToken: credential, language: ELanguages.EN });
                                                                            console.log("Google sign-in response:", response);
                                                                        } catch (error) {
                                                                            console.error("Google sign-in error:", error);
                                                                        }
                                                                    } else {
                                                                        console.error("Google sign-in error: credential is undefined");
                                                                    }
                                                                }}
                                                                onError={() => {
                                                                    console.log('Login Failed');
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </GoogleOAuthProvider>
                                            <div className={muiStyles.appleButtonWrapper}>
                                                <button className={muiStyles.socialRegisterBtn}>
                                                    <img src={appleLogo} alt="Apple" className={muiStyles.socialIcon} />
                                                </button>
                                            </div>
                                            <div className={muiStyles.facebookButtonWrapper}>
                                                <FacebookLogin
                                                    appId="1088597931155576"
                                                    onSuccess={async (response) => {
                                                        const { accessToken } = response;
                                                        try {
                                                            const signInResponse = await signInWithFacebook({ accessToken, language: ELanguages.RU });
                                                            console.log('Ответ сервера при входе через Facebook:', signInResponse);
                                                        } catch (error) {
                                                            console.error('Ошибка при входе через Facebook:', error);
                                                        }
                                                    }}
                                                    onFail={(error) => {
                                                        console.log('Вход не удался!', error);
                                                    }}
                                                    onProfileSuccess={(response) => {
                                                        console.log('Получение профиля успешно!', response);
                                                    }}
                                                    className={muiStyles.facebookLoginButton}
                                                />
                                                <button
                                                    className={muiStyles.socialRegisterBtn}
                                                    style={{ position: 'relative', zIndex: 1 }}
                                                >
                                                    <img src={facebookLogo} alt="Facebook" className={muiStyles.socialIcon} />
                                                </button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                        className="fieldGrid"
                                        alignContent={"flex-start"}
                                    >
                                        <Grid container className={muiStyles.signUpBlock}>
                                            <p> Don't have an account?</p>
                                            <button className={muiStyles.signUp}
                                                onClick={() => {
                                                    closeModal("login");
                                                    openModal("register");
                                                }}
                                            >
                                                Sign Up
                                            </button>
                                        </Grid>
                                    </Grid>
                                </form>
                            );
                        }}
                    />
                </ThemeProvider>
            </Box>
        </Dialog>
    );
};

export default observer(LoginForm); 
