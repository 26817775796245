import { usePageVisibility } from "react-page-visibility";

import { SoundFilesType } from "src/store/models";
import useStores from "src/hooks/useStores";

export const usePlayer = () => {
    const { authStore, generalStore } = useStores();
    const user = authStore.currentUser;
    const isVisibleApplication = usePageVisibility();
    const diceSoundPlay = "diceSoundPlay";
    const pieceSoundPlay = "pieceSoundPlay";

    const player = (sound: keyof SoundFilesType) => {
        const soundFilePath = generalStore.sounds[sound];
        const audio = new Audio(soundFilePath);
        const soundIsEnabled = user?.settings.sound;
        sound === "gameCreate" ? (audio.volume = 0.15) : (audio.volume = 1);
        if (
            ([diceSoundPlay, pieceSoundPlay].includes(sound) ||
                soundIsEnabled) &&
            isVisibleApplication
        ) {
            const audioPlayerPromise = audio.play();
            audioPlayerPromise.catch(() => {});
        }
    };

    return player;
};
