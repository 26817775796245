import { EExternalProviderType } from "src/entities/externalGame";

import { IGetSlotsResponse, ISlotGame } from "./types";

export class SlotModel implements ISlotGame {
    public id: string;
    public name: string;
    public imageUrl: string;
    public isNew: boolean;
    public isPopular: boolean;
    public isFavorite: boolean;
    public providerId: string;

    constructor(data: IGetSlotsResponse) {
        this.formatAndSave(data);
    }

    private formatAndSave(data: IGetSlotsResponse) {
        this.id = data.id;
        this.name = data.name;
        this.imageUrl = data.image;
        this.isNew = data.isNew;
        this.isPopular = data.isPopular;
        this.isFavorite = data.isFavorite;
        this.providerId = data.providerId;
    }
}
