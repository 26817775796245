import {
    ColorVariant,
    IGameChatTab,
    IStickerRequest,
    IStickerPackList,
} from "src/store/models";
import { ISendMessageInGame } from "src/service/api/gameChat/requestResponses";

import {
    IGameMessage,
    IGameDialogMessage,
    IGameMoveMessage,
    INotification,
    ICommunicationChatMessage,
    ICommunicationGameHistory,
    ICommunicationGameNotification,
    ICommunicationHistoryNotification,
} from "src/store/gameStore/types";

export interface IGameChatHistory {
    chat?: ICommunicationChatMessage[];
    history: ICommunicationGameHistory[];
    notifications: ICommunicationGameNotification[];
    // onMessage: (message: string | IStickerRequest) => void;
    //=====
    onMessage: (message: ISendMessageInGame) => void;
    maxMessageSize: number;
    onChangeTab: (tab: string) => void;
    currentChatTab: string;
    height?: number;
    readonly?: boolean;
    hidden?: boolean;
}

export interface IChatBase {
    currentTab: string;
    showHideOpponent: boolean;
    isChatBlocked?: boolean;
}
export interface IChatTabs extends IChatBase {
    handleTabChange: (_: React.SyntheticEvent, newValue: string) => void;
}
export interface IBadgeDeskMobile extends IChatBase {}

export interface IChatOpponentAndMeMessages extends IChatBase {
    chat?: ICommunicationChatMessage[];
}

export interface IChatHistoryMessages extends IChatBase {
    moveMessages?: ICommunicationHistoryNotification[];
}
export interface IGameChatHistoryMessage {
    pieces: {
        dice: number;
        type: string;
        moved: boolean;
        move: string;
    }[];
    color: ColorVariant;
    animated: boolean;
    time: number;
    position: string;
}

export interface IGameChatBody {
    time: string | number;
    position: string;
    stickers?: IStickerPackList[];
    children?: React.ReactNode | string | undefined;
    getStickerMessage?: string | undefined;
}

export interface IGameChatHistoryTextArea {
    text: string;
    gameID: string;
    onChange: (text: string) => void;
    // onEnter: (text: string) => void;
    //=====
    onEnter: (message: ISendMessageInGame) => void;
    onStickerToggler: () => void;
    shouldHide?: boolean | undefined;
    placeholder?: string;
}

export enum StickerStatusType {
    open = "open",
    hide = "hide",
}
