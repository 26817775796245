import { makeStyles } from "@mui/styles";

import {
    DARKGREEN_ALERTS,
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
} from "src/pages/game-new/constants";
import theme from "src/theme";
import { appColors, backgroundColors } from "src/consts/app-config";

const useStyles = makeStyles({
    spinnerDecorator: {
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 10,
        top: 0,
        left: 0,
        "&.darkBlue": {
            background:
                "radial-gradient(circle, rgba(52,58,89,1) 30%, rgba(23,27,41,1) 100%)",
        },
        "&.darkGreen": {
            background:
                "radial-gradient(63.19% 181.28% at 51.57% 62.5%, rgb(0, 73, 78) 0%, rgb(0, 0, 0) 100%)",
        },
        "&.deepBlack": {
            background:
                "radial-gradient(circle, rgba(52,58,89,1) 30%, rgba(23,27,41,1) 100%)",
        },
    },
    spinnerLoader: {
        width: "100%",
        display: "grid",
        placeItems: "center",
    },
});

export default useStyles;
