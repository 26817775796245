import React, { useRef, useEffect, useState } from "react";
import cn from "classnames";

import DiceButton from "src/components/controls/dice-button";
import useWindowSize from "src/hooks/useWindowSize";
import {
    MIN_L_TABLET,
    MIN_CHANGE_SCREEN_WIDTH,
} from "src/pages/game-new/constants";

import { useStyles } from "./styles";

const DiceButtonWithSpinner = ({
    enabled,
    onClick,
    showSpinner,
    isMultiTable = false,
}) => {
    const styles = useStyles();
    const changeWindowSize = useWindowSize();
    const diceButtonWithSpinnerRef = useRef<HTMLDivElement>(null);

    return (
        <div
            className={cn(styles.diceButtonWithSpinner, {
                // isRotate: !enabled,
            })}
            ref={diceButtonWithSpinnerRef}
        >
            <DiceButton
                enabled={enabled}
                onClick={onClick}
                isMultiTable={isMultiTable}
            />
        </div>
    );
};

export default DiceButtonWithSpinner;
