import React from 'react';
import { SVGUniqueID } from 'react-svg-unique-id';
import RadialPalette from './images/timer-circle-160.svg';
import chroma from 'chroma-js';

interface Props {
    value?: number;
    backgroundImage: any;
    invert: boolean;
    progress: number;
    strokeWidth: number;
    ballStrokeWidth: number;
    reduction: number;
    transitionDuration: number;
    transitionTimingFunction: string;
    background: string;
    gradient: { stop: number; color: string }[];
    size: number | undefined;
}

const defaultProps: Props = {
    backgroundImage: RadialPalette,
    value: undefined,
    invert: false,
    progress: 0,
    strokeWidth: 20,
    ballStrokeWidth: 1,
    reduction: 0,
    transitionDuration: 0.5,
    transitionTimingFunction: 'ease',
    background: '#343A59',
    gradient: [
        { stop: 0, color: 'green' },
        { stop: 1, color: 'red' },
    ],
    size: 84,
};
export const CircularProgress = (initialProps: Partial<Props>) => {
    const props: Props = {
        ...defaultProps,
        ...initialProps,
    };

    const sourceProgress = props.progress;
    let progress = sourceProgress > 100 ? 100 : sourceProgress;

    progress = props.invert ? -progress : progress;
    progress = Math.round(progress * 100) / 100;
    const width = 200;
    const center = width / 2;
    const height = 200;
    const [unique] = React.useState(() => Math.random().toString());
    const rotate = -90;
    const r = center - props.strokeWidth / 2 - props.ballStrokeWidth / 2;
    const circumference = Math.PI * r * 2;
    const offset =
        (circumference * (100 - progress * (1 - props.reduction))) / 100;

    const palette = chroma.scale(['e94437', 'bab652', '158F80']);

    return (
        <SVGUniqueID>
            <svg
                viewBox={`-10 -10 ${width + 20} ${height + 20}`}
                className='progress-ring-svg'
                style={{ width: props.size, height: props.size }}
            >
                <defs>
                    <pattern
                        id='image'
                        x='0'
                        y='0'
                        patternUnits='userSpaceOnUse'
                        height='100%'
                        width='100%'
                    >
                        <image
                            x='0'
                            y='0'
                            style={{
                                transform: 'scale(1.8) rotate(90deg)',
                                transformOrigin: 'center',
                            }}
                            height={200}
                            width={200}
                            xlinkHref={props.backgroundImage}
                        />
                    </pattern>

                    <linearGradient
                        id={'gradient' + unique}
                        x1='0%'
                        y1='0%'
                        x2='0%'
                        y2='100%'
                    >
                        {props.gradient.map(({ stop, color }) => (
                            <stop
                                key={stop}
                                offset={stop * 100 + '%'}
                                stopColor={color}
                            />
                        ))}
                    </linearGradient>
                </defs>

                {/*<circle
                    transform={`rotate(${rotate} ${center} ${center})`}
                    id='path'
                    cx={center}
                    cy={center}
                    r={r}
                    strokeWidth={props.strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={circumference * props.reduction}
                    fill='#22263D'
                    stroke={props.background}
                    strokeLinecap='round'
                />*/}
                {/* <circle
                    cx={center}
                    cy={center}
                    r={r + 13}
                    strokeWidth={1}
                    fill='none'
                    stroke='#41486D'
                />
                <circle
                    cx={center}
                    cy={center}
                    r={r + 12}
                    strokeWidth={1}
                    fill='none'
                    stroke='#22263D'
                /> */}

                <circle
                    style={{
                        transition: `stroke-dashoffset ${props.transitionDuration}s ${props.transitionTimingFunction}`,
                    }}
                    transform={`rotate(${rotate} ${center} ${center})`}
                    id='path'
                    cx={center}
                    cy={center}
                    r={r}
                    strokeWidth={props.strokeWidth}
                    strokeDasharray={`${circumference}`}
                    strokeDashoffset={offset}
                    fill='none'
                    stroke={palette(sourceProgress / 100) as unknown as string}
                    strokeLinecap='round'
                />

                {props.value === undefined ? (
                    <text
                        x={center}
                        y={center + 24}
                        textAnchor='middle'
                        fontSize='80'
                        fill='white'
                    >
                        {props.invert ? 100 - Math.abs(progress) : progress}
                    </text>
                ) : (
                    <text
                        x={center}
                        y={center + 24}
                        textAnchor='middle'
                        fontSize='70'
                        fill='white'
                    >
                        {props.value}
                    </text>
                )}
            </svg>
        </SVGUniqueID>
    );
};
