import { makeStyles } from "@mui/styles";
import { appColors, backgroundColors } from "src/consts/app-config";
import { MIN_XL_TABLET } from "src/pages/game-new/constants";
import theme from "src/theme";

export const useStyles = makeStyles({
    wrapperMobileBurger: {
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
        background:
            "radial-gradient(63.19% 181.28% at 51.57% 62.5%, #343A59 0%, rgb(13 17 36) 68%);",
        zIndex: 312,
        [theme.breakpoints.down("lg")]: {
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100vw",
            top: 48,
            right: 0,
            transform: `translateX(-100%)`,
            "&.isGamePage": {
                top: 48,
            },
            "&.darkBlue": {
                background:
                    "radial-gradient(63.19% 181.28% at 51.57% 62.5%, #343A59 0%, rgb(13 17 36) 68%);",
            },
            "&.darkGreen": {
                background:
                    "radial-gradient(63.19% 181.28% at 51.57% 62.5%, rgb(0, 73, 78) 0%, rgb(0, 0, 0) 100%)",
            },
            "&.watchingListShow": {
                animationName: "$watchingListShow",
                animationDelay: "0s",
                animationIterationCount: 1,
                animationFillMode: "forwards",
                animationDuration: "700ms",
            },
            "&.watchingListHide": {
                animationName: "$watchingListHide",
                animationDelay: "0s",
                animationIterationCount: 1,
                animationFillMode: "forwards",
                animationDuration: "300ms",
            },
        },
    },

    "@keyframes watchingListShow": {
        "0%": {
            opacity: 0,
            transform: `translateX(-100%)`,
        },
        "35%": {
            opacity: 0,
            transform: `translateX(-70%)`,
        },
        "100%": {
            opacity: 1,
            transform: "translateX(0px)",
        },
    },

    "@keyframes watchingListHide": {
        "0%": {
            transform: "translateX(0%)",
        },
        "100%": {
            transform: `translateX(-100%)`,
        },
    },

    navList: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        margin: "0",
        padding: "20px 40px",
        overflowY: "auto",
        "-ms-overflow-style": "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        "@media screen and (max-width: 400px)": {
            padding: "20px 30px",
        },
        "@media screen and (max-width: 340px)": {
            padding: "20px 25px",
        },
    },
    navListItem: {
        width: "100%",
        padding: "20px 8px",
        borderBottom: "1px solid #22263d",
        boxShadow: "0px 1px 0px #41486d",
        listStyleType: "none",
        "@media screen and (max-height: 720px)": {
            padding: "15px 8px",
        },
    },

    navListLink: {
        textDecoration: "none",
        color: appColors.white,
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "19px",
        display: "inline",
        cursor: "pointer",
        position: "relative",
    },

    socLinksAndInfo: {
        display: "none",
        [theme.breakpoints.down(MIN_XL_TABLET)]: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gridGap: 15,
            flexDirection: "column",
            alignItems: "center",
            padding: "20px 40px",
            color: appColors.white,
        },
        "@media screen and (max-width: 400px)": {
            padding: "10px 30px",
        },
        "@media screen and (max-width: 340px)": {
            padding: "10px 25px",
        },
        "@media screen and (max-width: 450px) and (max-height: 700px)": {
            padding: "0px 25px",
        },
        "@media screen and (max-width: 450px) and (max-height: 680px)": {
            alignItems: "center",
        },
        marginBottom: "10px",
    },

    trafficLightMobile: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        gridGap: 7,
        alignItems: "center",
        maxHeight: "20px",
    },

    trafficLightMobileText: {
        color: appColors.white,
        margin: 0,
        fontWeight: 500,
    },

    gameInfoDot: {
        display: "block",
        width: 7,
        height: 7,
        borderRadius: "50%",
        position: "absolute",
        top: "45%",
        left: "-10%",
        transform: "translate(-100%, -20%)",
        background: "#61DB8B",
        zIndex: 2,
        animationName: "$gameInfoDotMain",
        animationDelay: "0.3s",
        animationIterationCount: "infinite",
        animationFillMode: "forwards",
        animationDuration: "4000ms",
        opacity: 0,
        "&:after": {
            content: '""',
            display: "block",
            width: 9,
            height: 9,
            borderRadius: "50%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#61DB8B",
            zIndex: 2,
            animationName: "$gameInfoDot",
            animationDelay: "0.3s",
            animationIterationCount: "infinite",
            animationFillMode: "forwards",
            animationDuration: "4000ms",
        },
    },

    "@keyframes gameInfoDotMain": {
        "0%": {
            opacity: 0,
            filter: "blur(2px)",
        },
        "50%": {
            opacity: 1,
            filter: "blur(0px)",
        },
        "100%": {
            opacity: 0,
            filter: "blur(2px)",
        },
    },

    "@keyframes gameInfoDot": {
        "0%": {
            transform: "translate(-50%, -50%) scale(1)",
            opacity: 0,
        },
        "50%": {
            transform: "translate(-50%, -50%) scale(2)",
            opacity: 0.5,
            filter: "blur(5px)",
        },
        "100%": {
            transform: "translate(-50%, -50%) scale(1)",
            opacity: 0,
        },
    },
});
