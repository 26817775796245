import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';
import theme from 'src/theme';

const currentHeight = window.innerHeight;

export const useStyles = makeStyles({
    buttonModalContainer: {
        display: 'flex',
        width: '80%',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        gridGap: '30px',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
        '&.friendFightInit': {
          width: '95%',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
        },
        '&.friendFightReceive': {
          width: '95%',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
        },
        '&.lobbyConfirmGame': {
          width: '95%',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
        },
        '&.lobbyInitiatorAwaiting': {
            maxWidth: '180px',
        },
    },

    button: {
        width: '100%',
        '& .steel-wrapper, & .black-wrapper, & .button': {
            width: '100%',
        },
    },

    '@keyframes uploadAvatarWrapper': {
        '0%': {
            opacity: 0,
            transform: ` translate(0%, 0%) translateY(${currentHeight}px)`,
            borderRadius: '10px',
        },
        '100%': {
            opacity: 1,
            borderRadius: '35px',
            transform: `translate(0%, 0%) translateY(${0}px)`,
        },
    },
});
