import axios from "axios";

import endpoints from "src/core/endpoints";

import {
    IGameChatTab,
    IStickerPackList,
    ISendMessageInGame,
    ISendMessageResponse,
} from "./requestResponses";

enum EGameChatTab {
    chat = "chat",
    history = "history",
    fastGame = "fastGame",
    watchers = "watchers",
}

export const setGameChatTab = async (params: {
    mode: keyof typeof EGameChatTab;
}) => {
    return await axios.post(endpoints.gameChatSettings, params);
};

export const getChatStickerPacks = async () => {
    return await axios.get<IStickerPackList[]>(endpoints.gameChatStickers);
};

export const sendMessageInGame = async (params: ISendMessageInGame) => {
    return axios.post<ISendMessageResponse>(endpoints.gameMessage, params);
};
