import cn from 'classnames';

import loaderGif from './assets/king-loading.gif';
import { useStyles } from './styles';

export interface ILoaderProps {
    size?: number;
    className?: string;
}

export const Loader = ({ size, className }: ILoaderProps) => {
    const styles = useStyles();
    return (
        <img
            src={loaderGif}
            className={cn(styles.image, className)}
            style={{ width: size, height: size }}
        />
    );
};
