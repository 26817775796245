import { breakpoints } from "./../../shared/helpers/ThemeHelper/constants";
import { makeStyles } from "@mui/styles";

import theme from "src/theme";
import { MIN_L_TABLET, MIN_XL_TABLET } from "src/pages/game-new/constants";

import bg from "./images/clockBackgroundFixed.svg";

const cashBoxWrapperSkew = 10;

const figureBorderColor = "#1B1D2B";

export const useStyles = makeStyles({
    playerInfoRedesign: {
        width: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 110px 1fr",
        position: "relative",
        padding: 0,
        zIndex: 0,
        [theme.breakpoints.down(MIN_XL_TABLET)]: {
            height: "100%",
        },
        // "@media (min-width: 1024px)": {
        //     gridTemplateColumns: "auto 1fr",
        //     gridGap: 16,
        //     position: "relative",
        //     background: "none",
        //     "&.active": {
        //         "&::after": {
        //             content: '""',
        //             position: "absolute",
        //             top: 0,
        //             left: "-15%",
        //             width: "100%",
        //             height: "100%",
        //             zIndex: -1,
        //             transition: "1s background ease-in",
        //             background:
        //                 "radial-gradient(50% 50% at 50% 50%, rgba(0, 238, 138, 0.5) 0%, rgba(0, 238, 138, 0.00) 100%)",
        //             filter: "blur(12px)",
        //             transform: "scale(1.22)",
        //         },
        //     },
        // },
        //======
        "@media (min-width: 1024px)": {
            // gridTemplateColumns: "30% 1fr 7.5%",
            gridTemplateColumns: "30% 1fr auto",
            gridGap: 16,
            position: "relative",
            background: "none",
            alignItems: "center",
            "&.active": {
                "&::after": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: "-15%",
                    width: "100%",
                    height: "100%",
                    zIndex: -1,
                    transition: "1s background ease-in",
                    background:
                        "radial-gradient(50% 50% at 50% 50%, rgba(0, 238, 138, 0.5) 0%, rgba(0, 238, 138, 0.00) 100%)",
                    filter: "blur(12px)",
                    transform: "scale(1.22)",
                },
            },
        },
        "&.inLobbyPreview": {
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr 100px 1fr",
            position: "relative",
            padding: 0,
            zIndex: 0,
        },
        // "&.isMultiTable": {
        //     "@media (max-height: 860px)": {
        //         maxHeight: 65,
        //     },
        // },
    },

    // avatarAndButtonWrapperRedesign: {
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     "@media (min-width: 1024px)": {
    //         marginLeft: 10,
    //     },
    //     "@media (min-width: 1800px)": {
    //         marginLeft: 15,
    //     },
    //     "&.inLobbyPreview": {
    //         "@media (min-width: 1024px)": {
    //             marginLeft: 0,
    //         },
    //     },
    // },

    avatarAndButtonWrapperRedesign: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        "@media (min-width: 1024px)": {
            marginLeft: 0,
        },
        "@media (min-width: 1800px)": {
            marginLeft: 0,
        },
        "&.inLobbyPreview": {
            "@media (min-width: 1024px)": {
                marginLeft: 0,
            },
        },
    },

    avatarLandAndButtonWrapper: {
        display: "none",
        "@media (min-width: 1024px)": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "-20px 0px",
            borderRadius: "50%",
        },
        "&.inLobbyPreview": {
            "@media (min-width: 1024px)": {
                display: "none",
            },
        },
    },

    // userAvatarSpan: {
    //     "&.isMobileTabletResign": {
    //         "@media (orientation: portrait)": {
    //             height: "100%",
    //             aspectRatio: "1 / 1",
    //         },
    //     },
    //     "&.isDiceButton": {
    //         position: "relative",
    //         transform: "scale(1.2)",
    //     },
    // },
    userAvatarSpan: {
        aspectRatio: "1 / 1",
        height: "100%",
        "&.isMobileTabletResign": {
            "@media (orientation: portrait)": {
                height: "100%",
            },
        },
        "&.isDiceButton": {
            position: "relative",
            transform: "scale(1.2)",
        },
    },

    // userAvatar: {
    //     width: 90,
    //     height: 90,
    //     padding: 1,
    //     borderRadius: "50%",
    //     "&.isMultiTable": {
    //         width: 52,
    //         height: 52,
    //         [theme.breakpoints.down(1700)]: {
    //             width: 48,
    //             height: 48,
    //         },
    //         "@media screen and (max-height: 900px)": {
    //             width: 45,
    //             height: 45,
    //         },
    //     },
    //     "&.isTwoGameTable": {
    //         width: 75,
    //         height: 75,
    //     },
    //     "& .nickname-box": {
    //         fontSize: 21,
    //     },
    //     "&.isMobileTabletResign": {
    //         "@media (orientation: portrait)": {
    //             width: "100%",
    //             height: "inherit",
    //             padding: 0,
    //             margin: 0,
    //         },
    //     },
    //     [theme.breakpoints.up(1800)]: {
    //         width: 112,
    //         height: 112,
    //     },
    // },

    userAvatar: {
        width: "100%",
        height: "100%",
        padding: 1,
        borderRadius: "50%",
        "&.isMultiTable": {
            width: 52,
            height: 52,
            [theme.breakpoints.down(1700)]: {
                width: 48,
                height: 48,
            },
            "@media screen and (max-height: 900px)": {
                width: 45,
                height: 45,
            },
        },
        "&.isTwoGameTable": {
            width: 75,
            height: 75,
        },
        "& .nickname-box": {
            fontSize: 21,
        },
        "&.isMobileTabletResign": {
            "@media (orientation: portrait)": {
                width: "100%",
                height: "inherit",
                padding: 0,
                margin: 0,
            },
        },
    },

    piecesWrapperRedesign: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        "&.isMultiTable": {
            overflow: "hidden",
        },
        "@media (min-width: 1024px)": {
            display: "none",
        },
        "&.inLobbyPreview": {
            "@media (min-width: 1024px)": {
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
            },
        },
    },
    killedPiecesRedesign: {
        "&.isMultiTable": {
            "@media screen and (max-height: 900px)": {
                marginBottom: -2,
            },
        },
    },
    miniPiecesRedesign: {
        display: "flex",
        alignItems: "flex-start",
        [theme.breakpoints.down(MIN_XL_TABLET)]: {
            width: "100%",
            justifyContent: "space-evenly",
            maxWidth: 240,
            paddingLeft: 12,
            gridGap: 3,
        },
        "&.inLobbyPreview": {
            [theme.breakpoints.down(MIN_XL_TABLET)]: {
                width: "100%",
                justifyContent: "space-evenly",
                maxWidth: 240,
                paddingLeft: 12,
                gridGap: 3,
            },
        },
    },

    chessMenMarginMobile: {
        margin: 0,
        // marginRight: -13,

        // "&.isTwoGameTable": {
        //     marginRight: -10,
        // },
        // "@media screen and (max-width: 768px)": {
        //     marginRight: -10,
        // },
        // "@media screen and (max-width: 520px)": {
        //     marginRight: -12,
        // },
        // "@media screen and (max-width: 450px)": {
        //     marginRight: -10,
        // },
    },
    piecesGroupWrapperMobile: {
        display: "flex",
    },
    piecesGroupWrapperMarginMobile: {
        marginRight: 5,
        "&.isMultiTable": {
            marginRight: 0,
            [theme.breakpoints.down(1450)]: {
                marginRight: 3,
            },
        },
        "&.isTwoGameTable": {
            marginRight: 4,
        },
        "@media screen and (max-width: 768px)": {
            marginRight: 3,
        },
        "@media screen and (max-width: 600px)": {
            marginRight: 0,
        },
        "@media screen and (max-width: 450px)": {
            marginRight: 0,
        },
    },

    leftPlayerWrapperRedesign: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        gridGap: "2px",
    },

    clockWrapperRedesign: {
        width: "100%",
        height: "100%",
        display: "grid",
        placeItems: "center",
        // fontSize: 38,
        //====

        zIndex: 1,
        overflow: "hidden",
        maxWidth: "100%",
        [theme.breakpoints.between(MIN_L_TABLET, "lg")]: {
            "@media (orientation: portrait)": {
                paddingRight: 0,
                fontSize: 30,
                maxWidth: "calc(100% - 5px)",
                width: "100%",
            },
        },
        "@media screen and (max-width: 767px)": {
            paddingRight: 0,
            fontSize: 30,
            width: "100%",
        },
        "@media screen and (max-width: 500px)": {
            paddingRight: 0,
            fontSize: 18,
        },
        "&.isOpponent": {
            [theme.breakpoints.between("xs", "md")]: {
                "@media (orientation: portrait)": {
                    maxWidth: "100%",
                    width: "100%",
                    // placeItems: "flex-start",
                    placeItems: "center",
                },
            },
            [theme.breakpoints.up("md")]: {
                "@media (orientation: portrait)": {
                    maxWidth: "calc(100% - 7px)",
                    width: "100%",
                    // placeItems: "flex-start",
                    placeItems: "center",
                },
            },
        },
    },

    nikAndclockWrapperBoxRedesign: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "100%",
        overflow: "hidden",
        justifyContent: "flex-start",
        [theme.breakpoints.between("md", "lg")]: {
            "@media (orientation: portrait)": {
                // width: currentWidth < MIN_CHANGE_SCREEN_WIDTH ? "auto" : "100%",
                // maxWidth: currentWidth < MIN_CHANGE_SCREEN_WIDTH ? 130 : 150,
                width: "100%",
            },
        },
        "&.isMultiTable": {
            alignItems: "center",
        },
        "&.isTwoGameTable": {
            alignItems: "center",
        },
    },

    redesignClockWrapperRedesign: {
        width: "100%",
        "@media (orientation: landscape)": {
            position: "relative",
            "@media screen and (min-width: 1024px)": {
                // height: "40px",
                //====
                height: "60%",
            },
            "@media screen and (min-width: 1250px)": {
                // height: "50px",
                height: "60%",
            },
        },
        "&.inGame": {},
        "&.inProgressPreview": {
            height: "30px",
        },
        "&.inProgressTournamentView": {
            height: "auto",
        },
        "&.inHistoryView": {
            height: "auto",
        },
        "@media (orientation: portrait)": {},
        "&.isMultiTable": {
            height: "auto",
        },
        "&.inLobbyPreview": {
            height: "35px",
        },
    },

    redesignClockBoxRedesign: {},

    nicknameBgFigure: {
        position: "absolute",
        top: 0,
        width: "50%",
        height: "100%",
        zIndex: 0,
        background: "#141826",
        borderTop: `1px solid ${figureBorderColor}`,
        borderBottom: `1px solid ${figureBorderColor}`,
    },
    nicknameBgFigureLeft: {
        left: 0,
        borderLeft: `1px solid ${figureBorderColor}`,
        borderRadius: "10px 0 0 10px",
        transform: "skew(-30deg, 0deg) translateX(15px)",
    },
    nicknameBgFigureRight: {
        right: 0,
        borderRight: `1px solid ${figureBorderColor}`,
        borderRadius: "0 10px 10px 0",
        transform: "skew(30deg, 0deg) translateX(-15px)",
    },

    pawnIconWrapperMobile: {
        zIndex: 1,
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        // "&.isTwoGameTable svg": {
        //     width: 22,
        //     height: 22,
        // },
        "& svg": {
            width: "100%",
            aspectRatio: "1 / 1",
        },
    },

    backgroundWrapper: {
        display: "flex",
        justifyContent: "center",
    },
    contentWrapper: {
        paddingLeft: 30,
        paddingRight: 50,
        // position: 'absolute',
        // top: 0, left: 0,
        // width: '100%',
        // backgroundSize: '100% 100px',
        fontSize: 25,
        minHeight: 80,
        "@media screen and (max-width: 1366px)": {
            transform: "scale(0.8)",
        },
    },

    clockWrapperDisabled: {
        backgroundImage: `url(${bg})`,
        filter: "contrast(0.8)",
        backgroundRepeat: "no-repeat",
        position: "absolute",
        width: "104px",
        height: "35px",
        top: 4,
    },
    clockContainer: {
        paddingTop: 13,
        fontSize: 38,
        marginLeft: "auto",
        paddingRight: 0,
    },

    cashBoxWrapper: {
        textAlign: "center",
        padding: "3px 30px",
        zIndex: 5,
        position: "absolute",
        top: "100%",
        transform: "translateY(-70%)",
        display: "none",
        justifyContent: "center",
        alignItems: "center",
        left: "7%",
        width: "fit-content",
        "@media screen and (max-width: 768px)": {
            display: "flex",
        },
        "@media screen and (max-width: 520px)": {
            padding: "3px 25px",
            right: 8,
        },
    },
    cashBoxFigure: {
        position: "absolute",
        top: 0,
        width: "50%",
        height: "100%",
        zIndex: 0,
        background: "#141826",
        borderTop: `1px solid ${figureBorderColor}`,
        borderBottom: `1px solid ${figureBorderColor}`,
    },
    cashBoxFigureLeft: {
        left: 0,
        borderRadius: "4px 0 0 4px",
        transform: `skew(-${cashBoxWrapperSkew}deg, 0deg) translateX(15px)`,
        borderLeft: `1px solid ${figureBorderColor}`,
        opacity: "0.8",
    },
    cashBoxFigureRight: {
        right: 0,
        borderRadius: "0 4px 4px 0",
        transform: `skew(${cashBoxWrapperSkew}deg, 0deg) translateX(-15px)`,
        borderRight: `1px solid ${figureBorderColor}`,
        opacity: "0.8",
    },
    cashBoxAmount: {
        fontWeight: 500,
        fontSize: 16,
        zIndex: 1,
        background: "linear-gradient(180deg, #F8BF44 50%, #A16529 100%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        display: "flex",
        alignItems: "center",
        "@media screen and (max-width: 460px)": {
            fontSize: 14,
            display: "inline-block",
            lineHeight: "0",
            "-webkit-box-decoration-break": "clone",
        },
    },
    cashBoxAmountCoins: {
        fontWeight: 500,
        fontSize: 16,
        zIndex: 1,
        background: "linear-gradient(180.73deg, #e9eefc 9.68%, #c2c2c2 104.5%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        display: "flex",
        alignItems: "center",
        "@media screen and (max-width: 460px)": {
            fontSize: 14,
            display: "inline-block",
            lineHeight: "0",
            "-webkit-box-decoration-break": "clone",
        },
    },

    cashBoxAmountBank: {
        fontWeight: 500,
        fontSize: 16,
        zIndex: 1,
        background: "linear-gradient(180deg, #F8BF44 50%, #A16529 100%)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        display: "flex",
        alignItems: "center",
        "@media screen and (max-width: 460px)": {
            fontSize: 14,
            display: "inline-block",
            lineHeight: "0",
        },
    },

    coinIcon: {
        width: 17,
        height: 17,
        objectFit: "contain",
        marginRight: 5,
        "-webkit-box-decoration-break": "clone",
        // '@media screen and (max-width: 460px)': {
        //     width: 14,
        //     height: 14,
        // },
    },
});
