import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import { useLocale } from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";

import { usePlayer } from "../../../../sounds/use-player";
import { RematchStage } from "../../../../store/gameStore/dialogs";
import { useChessBoardProps } from "../../hooks/useChessBoardProps";

import WaitForOpponentDialog from "./components/waitForOpponentToDecide";
import OpponentRejectedRematchAlert from "./components/OpponentRejectedRematch";
import AcceptOrRejectDialog from "./components/acceptOrReject";

interface IRematchAlert {
    isTabletMobile?: boolean | undefined;
}

const RematchAlert = ({ isTabletMobile }: IRematchAlert) => {
    const { isMultiTable, onLobbyClose } = useChessBoardProps();
    const { gameStore } = useStores();
    const { isOpen, stage } = gameStore.dialogs.rematchDialog;

    const viewMode = useMemo(() => {
        return gameStore.viewGameMode;
    }, [gameStore.viewGameMode]);

    // if (viewMode) {
    //     return (
    //         <GameFinishedDialog
    //             cause
    //             ratio={designRatio}
    //             onCancel={() => {
    //                 history.push("/lobby");
    //             }}
    //         />
    //     );
    // }

    if (!isOpen) return null;

    if (stage === RematchStage.waitingForOpponentToDecide) {
        return (
            <WaitForOpponentDialog
                isXlTablet={isTabletMobile}
                isMultiTable={isMultiTable}
            />
        );
    } else if (stage === RematchStage.acceptOrRejectRematch) {
        return (
            <AcceptOrRejectDialog
                isXlTablet={isTabletMobile}
                isMultiTable={isMultiTable}
            />
        );
    } else if (stage === RematchStage.rejected) {
        return (
            <OpponentRejectedRematchAlert
                isXlTablet={isTabletMobile}
                isMultiTable={isMultiTable}
                onLobbyClose={onLobbyClose}
            />
        );
    }

    return null;

    /*if (isWon) {
        return (
            <WinDialog
                cause={resultData.cause}
                amount={(gameStore.gameState.bank * (1 - 2 * setting.rake)).toFixed(2)}
                message={winCauses[resultData.cause]}
                onApprove={() => {
                    gameStore.dialogs.requestRematch();
                }}
                onCancel={() => {
                    history.push('/lobby');
                }}
            />
        );
    }*/
};

export default observer(RematchAlert);
