import React, { useRef, useEffect, useState } from "react";
import cn from "classnames";

import DiceButton from "src/components/controls/dice-button";
import useWindowSize from "src/hooks/useWindowSize";
import {
    MIN_L_TABLET,
    MIN_CHANGE_SCREEN_WIDTH,
} from "src/pages/game-new/constants";

import { useStyles } from "./styles";

const DiceButtonWithSpinner = ({
    enabled,
    onClick,
    showSpinner,
    isMultiTable = false,
}) => {
    const styles = useStyles();
    const changeWindowSize = useWindowSize();
    const diceButtonWithSpinnerRef = useRef<HTMLDivElement>(null);
    const [isDiceButtonRef, setDiceButtonRef] = useState<boolean | number>(
        false
    );
    const windowWidth = window.innerWidth;

    useEffect(() => {
        if (
            windowWidth > MIN_L_TABLET &&
            windowWidth < MIN_CHANGE_SCREEN_WIDTH
        ) {
            return setDiceButtonRef(100);
        }
        if (windowWidth >= 600 && windowWidth < MIN_L_TABLET) {
            return setDiceButtonRef(100);
        }
    }, []);

    useEffect(() => {
        const diceRef = diceButtonWithSpinnerRef?.current;
        const diceRefWidth = diceButtonWithSpinnerRef?.current?.clientWidth;
        const diceRefHeight = diceButtonWithSpinnerRef.current?.clientHeight;
        const MIN_DICE_SIZE = 58;
        const TINY_DEVICE_SIZE = 65;
        if (diceRef && diceRefWidth && diceRefHeight) {
            if (diceRefHeight < 50 && window.innerHeight < 610) {
                return setDiceButtonRef(TINY_DEVICE_SIZE);
            }
            if (windowWidth >= MIN_L_TABLET && windowWidth < 1024) {
                return setDiceButtonRef(80);
            }
            if (windowWidth >= 600 && windowWidth < MIN_L_TABLET) {
                return setDiceButtonRef(100);
            }
            if (changeWindowSize.width && changeWindowSize.width < 768) {
                return setDiceButtonRef(65);
            }
            if (
                windowWidth >= MIN_L_TABLET &&
                windowWidth < MIN_CHANGE_SCREEN_WIDTH
            ) {
                return setDiceButtonRef(100);
            }
            if (diceRefWidth > MIN_DICE_SIZE && diceRefHeight > MIN_DICE_SIZE) {
                return diceRefHeight - diceRefWidth >= 0
                    ? setDiceButtonRef(diceRefWidth)
                    : setDiceButtonRef(diceRefHeight);
            } else {
                return setDiceButtonRef(MIN_DICE_SIZE);
            }
        }
    }, [changeWindowSize]);

    return (
        <div
            className={cn(styles.diceButtonWithSpinner, {
                // isRotate: !enabled,
            })}
            ref={diceButtonWithSpinnerRef}
        >
            <DiceButton
                enabled={enabled}
                onClick={onClick}
                isMultiTable={isMultiTable}
            />
        </div>
    );
};

export default DiceButtonWithSpinner;
