import React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import cn from "classnames";

import { useLocale } from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";
import UserAvatar from "src/components/UserAvatar";
import FadeInOutVertical from "src/components/Animated";
import { ButtonType, GreenButton } from "src/components/buttons/greenButton";
import paths from "src/consts/paths";
import useChangeTheme from "src/hooks/useChangeTheme";
import { getButtonColor } from "src/helpers";

import { GameType, UserBalanceType } from "../../../../../../store/models";
import { endpointsRoot } from "../../../../../../core/endpoints";
import useStyles from "../../../common/rematchGeneralDialog/style";

const OpponentRejectedRematchAlert = ({
    isXlTablet,
    isMultiTable = false,
    onLobbyClose,
}) => {
    const history = useHistory();
    const { gameStore, gamesStore } = useStores();
    const styles = useStyles();
    const {
        game: {
            rematch: {
                opponentRejected: { reject },
            },
            dialogs: {
                rematch: {
                    opponentRejected: { title },
                },
            },
        },
    } = useLocale();

    const opponentColor = gameStore.gameState.getOpponentColor();
    const appearanceTheme = useChangeTheme();

    if (!opponentColor) return null;
    const player = gameStore.gameState.getPlayerByColor(opponentColor);
    if (!player) return null;

    return (
        <FadeInOutVertical stylesProp={{ alignItems: "flex-end" }}>
            <div
                className={cn(
                    styles.rematchActionContainer,
                    appearanceTheme,
                    "opponentRejectedRematchAlert",
                    {
                        isMultiTable,
                    }
                )}
            >
                <div
                    className={cn(styles.flexContainer, {
                        isXlTablet: isXlTablet,
                        isMultiTable,
                    })}
                >
                    <div className={styles.avatarBlockWrapper}>
                        <UserAvatar
                            avatarUrl={
                                (player.avatar &&
                                    `${endpointsRoot}/avatars/${player.avatar}`) ||
                                ""
                            }
                            nickname={""}
                            className={cn(styles.avatarBlock, { isMultiTable })}
                            isSystemAvatar={player.isSystemAvatar}
                        />
                    </div>
                    <div>
                        <p className={cn(styles.title, { isMultiTable })}>
                            <span className={styles.playerName}>
                                {player.name}
                            </span>
                            <span>{title("Opponent refused")}</span>
                        </p>
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <GreenButton
                        type={getButtonColor(appearanceTheme, ButtonType)}
                        text={reject("To Lobby")}
                        isWidthFixed
                        fontSize={isMultiTable ? 13 : 16}
                        onClick={() => {
                            if (isMultiTable) {
                                onLobbyClose?.();
                                return;
                            }
                            gameStore.dialogs.rejectRematch();
                            gameStore.currentGame?.balanceType ===
                            UserBalanceType.play
                                ? history.push(paths.diceChessLobby)
                                : history.push(paths.lobbyFreePlay);
                        }}
                    />
                </div>
            </div>
        </FadeInOutVertical>
    );
};

export default observer(OpponentRejectedRematchAlert);
