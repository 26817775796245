import React from 'react';
import { observer } from 'mobx-react';
import { useLocale } from 'src/providers/LocaleProvider';

import useStores from '../../../../../hooks/useStores';
import ViewModeModal, { textType } from '../ViewModeModal';
import { ColorVariant } from 'src/store/models';

const OfferedX2Modal = () => {
    const { gameStore } = useStores();
    const {
        game: {
            dialogs: {
                x2: { userIsOfferingDoubling },
            },
        },
    } = useLocale();

    const player = gameStore.gameState.getPlayerByColor(
        gameStore.initData?.doubling.aggressor!
    );

    return (
        <ViewModeModal
            title={userIsOfferingDoubling.compile(
                {
                    playerWhoOffers: player?.name ?? 'Anonymous',
                },
                '{{playerWhoOffers}} offered his opponent a doubling'
            )}
            titleType={textType.gold}
            messsageType={textType.gray}
        />
    );
};

export default observer(OfferedX2Modal);
