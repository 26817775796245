import { makeStyles } from '@mui/styles';
import { media } from 'src/utils/media';
import theme from 'src/theme';
import { MAX_XL_TABLET } from 'src/pages/game-new/constants';

export const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        gridArea: 'C',
    },
    balanceWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: '1vw',
        borderRadius: '1vw',
        [theme.breakpoints.up('lg')]: {
            padding: '0',
            '&.gold': {
                // background: 'transparent',
                // backgroundImage:
                //     'linear-gradient(19.92deg, #0E0F0F 13.3%, #0E0F0F 86.7%), linear-gradient(#F8BF44C2, #A16529)',
                // backgroundClip: 'padding-box, border-box',
                // backgroundOrigin: 'border-box',
                // border: '1px solid transparent',
                // '&:hover': {
                //     cursor: 'pointer',
                //     transition: 'all 0.7s',
                //     boxShadow: '0px 0px 20px 0px rgba(255, 187, 124, 0.5)',
                //     borderRadius: '12px',
                // },
            },
            '&.silver': {
                // background: 'transparent',
                // backgroundImage:
                //     'linear-gradient(19.92deg, #0E0F0F 13.3%, #0E0F0F 86.7%), linear-gradient(#e9eefc6e, #c2c2c291)',
                // backgroundClip: 'padding-box, border-box',
                // backgroundOrigin: 'border-box',
                // border: '1px solid transparent',
                // '&:hover': {
                //     cursor: 'pointer',
                //     transition: 'all 0.7s',
                //     boxShadow: '0px 0px 20px 0px silver',
                //     borderRadius: '12px',
                // },
            },
        },
        [theme.breakpoints.down('lg')]: {
            //background: '#1A171B',
            //border: '0.1vw solid #2F3348',
            //borderRadius: '2vw',
            //padding: '0.1vw 1vw 0 0.5vw',
            //gridGap: '1vw',
            '&.noBackground': {
                background: 'none',
                border: 'none',
                borderRadius: '1vw',
            },
        },
        [theme.breakpoints.down('md')]: {
            padding: '1vw',
        },
    },
    balancesContainer: {
        position: 'relative',
    },
    balanceWrapperSkeleton: {
        opacity: 0,
        visibility: 'hidden',
    },
    mainBalanceWrapper: {
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    amountWrapper: {
        position: 'relative',
        marginLeft: 5,
        display: 'flex',
        alignItems: 'center',
    },
    amount: {
        textShadow: '0px 1.13216px 3.39647px rgba(0, 0, 0, 0.25)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        background: 'linear-gradient(180deg, #F8BF44 50%, #A16529 100%)',
        display: 'inline !important',
        [theme.breakpoints.up('lg')]: {
            fontSize: media(18, 30, 1022),
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: '1.1vw',
        },
        [theme.breakpoints.between('md', 'lg')]: {
        },
        [theme.breakpoints.down('md')]: {
            width: 'auto!important',
        },
        [theme.breakpoints.down('sm')]: {
            //lineHeight: '1vw',
            fontSize: 18,
            marginLeft: 2,
        },
    },
    amountCoins: {
        textShadow: '0px 1.13216px 3.39647px rgba(0, 0, 0, 0.25)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        background: 'linear-gradient(180deg, #e9eefc 50%, #c2c2c2 100%)',
        marginLeft: 5,
        display: 'inline !important',
        [theme.breakpoints.up('lg')]: {
            fontSize: media(20, 32, 1024),
        },
        [theme.breakpoints.up('xl')]: {
            fontSize: 24,
        },
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: 18,
        },
        [theme.breakpoints.down('md')]: {
            width: 'auto!important',
        },
        [theme.breakpoints.down('sm')]: {
            lineHeight: '1vw',
            fontSize: 18,
            marginLeft: 2,
        },
    },
    amountBase: {
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    amountSkeleton: {
        opacity: 0,
    },
    coinIcon: {
        width: '1.3vw',
        height: '1.3vw',
        objectFit: 'contain',
        [theme.breakpoints.down('md')]: {
            width: '4vw',
            height: '4vw',
        },
        [theme.breakpoints.down('sm')]: {
            width: '1vw',
            height: '1vw',
        },
        '@media (max-width: 500px)': {
            width: '4vw',
            height: '4vw',
        },
    },
});
