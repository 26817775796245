import { makeStyles } from '@mui/styles';
import theme from 'src/theme';

interface IAvatars {
    height: number;
}

export const useStyles = makeStyles({
    stepAreaWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            justifyContent: 'center',
            gridGap: 20,
        },
    },

    gridAvatarContainer: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        height: 'calc(100vh - 300px)',
        // maxHeight: 'calc(90vh - 450px)',
        alignContent: 'start',
        border: '2px solid rgba(138, 104, 233, 0.4)',
        boxShadow: '0px 0px 30px 5px rgba(138, 104, 233, 0.30) inset',
        borderRadius: 10,
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        'scrollbar-width': 'none',
        [theme.breakpoints.up('xs')]: {
            height: 'calc(100vh - 280px)',
            '&.isCustom': {
                height: 'calc(100vh - 280px)',
            },
        },
        [theme.breakpoints.up('md')]: {
            height: 'calc(100vh - 300px)',
            '&.isCustom': {
                height: 'calc(100vh - 300px)',
                animationName: '$gridAvatarContainer',
                animationDelay: '0.01s',
                animationIterationCount: 1,
                animationFillMode: 'forwards',
                animationDuration: '1000ms',
            },
        },
    },

    gridBlockWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            '&.nonGrid': {
                display: 'none',
            },
        },
    },

    currentStepWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    gridAvatarItem: {
        minHeight: '80px',
        maxHeight: '300px',
        minWidth: '80px',
        maxWidth: '300px',
        margin: '0 auto',
        borderRadius: '50%',
        position: 'relative',
    },

    gridAvatarImg: {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        borderRadius: '50%',
        opacity: 0.8,
        animationName: '$isAppear',
        animationDelay: '0s',
        animationIterationCount: 1,
        animationFillMode: 'forwards',
        animationDuration: '400ms',
        boxShadow: '0px 0px 20px 14px rgba(78, 93, 180, 0.08)',
        '&.isSelected': {
            animationName: '$isStepHide',
            animationDelay: '0s',
            animationIterationCount: 1,
            animationFillMode: 'forwards',
            animationDuration: '800ms',
            boxShadow: '0px 0px 20px 14px rgba(78, 93, 180, 0.08)',
        },
        '&.isLoaded': {
            filter: 'blur(4px)',
            borderRadius: '50%',
        },
    },

    gridAvatarFastImg: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        filter: 'blur(5px)',
        zIndex: 2,
    },

    '@keyframes isAppear': {
        '0%': {
            opacity: 0.2,
        },
        '100%': {
            opacity: 1,
        },
    },

    '@keyframes isStepHide': {
        '0%': {
            opacity: 0.8,
            boxShadow: '0px 0px 18px 15px rgba(78, 93, 180, 0.1)',
        },
        '60%': {
            boxShadow: '0px 0px 28px 20px rgba(78, 93, 180, 0.9)',
        },
        '100%': {
            opacity: 1,
            boxShadow: '0px 0px 22px 15px rgba(78, 93, 180, 0.7)',
        },
    },

    buttonBox: {
        justifySelf: 'center',
        marginTop: 24,
        display: 'flex',
        gridGap: 10,
        flexDirection: 'column',
    },
    wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    wrapperFormik: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('xs')]: {
            minHeight: 300,
            '&.isCustom': {},
        },
    },

    wrapperFormikBox: {
        [theme.breakpoints.up('xs')]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
        },
    },

    form: {
        gridRowGap: 8,
    },

    buttonBoxLoaded: {
        display: 'flex',
        flexDirection: 'column',
        justifySelf: 'center',
        margin: '0 auto',
        marginTop: 25,
        width: 'auto',
        alignItems: 'center',
    },

    buttonBoxInfoGetOwn: {
        marginTop: 18,
        marginBottom: 7,
        opacity: 0.8,
        transition: 'all 0.5s',
        [theme.breakpoints.up('xs')]: {
            opacity: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        // [theme.breakpoints.up(380)]: {
        //     display: 'flex',
        //     width: '100%',
        //     flexDirection: 'row',
        //     justifyContent: 'center',
        //     gridGap: 5,
        // },
        [theme.breakpoints.up('lg')]: {
            opacity: 0.7,
            '&:hover': {
                transition: 'all 0.5s',
                opacity: 1,
            },
        },
    },
});
