import { makeStyles } from "@mui/styles";

import { appColors, backgroundColors } from "src/consts/app-config";
import freeCoin from "src/assets/icons/freeCoin.png";
import coin from "src/assets/icons/coin.svg";
import theme from "src/theme";
import {
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
} from "src/pages/game-new/constants";

interface ICoin {
    vertical: number | boolean;
    horizontal: number | boolean;
    animationLatency: number;
    transitionLatency: number;
}

const height = window.innerHeight;

const getTransform = (
    vertical: number | boolean,
    horizontal: number | boolean
) => {
    if (typeof vertical === "number" && typeof horizontal === "number") {
        return `translate(${horizontal}px, ${vertical}px)`;
    }
};

const coinAnimateSettings = {
    opacity: 1,
    zIndex: 310,
    transition: "transform 1s ease-in",
    animationName: "testBoxAnimate",
    animationIterationCount: 1,
    animationFillMode: "forwards",
    animationDuration: "100ms",
};

const useStyles = makeStyles({
    "@global": {
        "@keyframes testBoxAnimate": {
            "0%": {
                opacity: 1,
                zIndex: 10,
            },
            "100%": {
                opacity: 0,
                zIndex: -1,
            },
        },
        "@keyframes riseMainIcon": {
            "0%": {
                transform: "translate(-50%, -50%) scale(1)",
            },
            "30%": {
                transform: "translate(-50%, -50%) scale(1.5)",
            },
            "80%": {
                transform: "translate(-50%, -50%) scale(1.5)",
            },
            "100%": {
                transform: "translate(-50%, -50%) scale(1)",
            },
        },
    },

    coinBoxMain: {
        position: "relative",
        marginRight: "10px",
        width: "42px",
        height: "42px",
        [theme.breakpoints.down(500)]: {
            width: "15px",
            height: "15px",
        },
        [theme.breakpoints.up("xs")]: {
            "@media screen  and (orientation: portrait) ": {
                width: "25px",
                height: "25px",
            },
        },
        [theme.breakpoints.up(600)]: {
            "@media screen  and (orientation: portrait) ": {
                width: "33px",
                height: "33px",
            },
        },
        [theme.breakpoints.up("lg")]: {
            "@media screen  and (orientation: landscape) ": {
                width: "33px",
                height: "33px",
                marginRight: 7,
                "&.isMultiTable": {
                    marginRight: 10,
                },
            },
        },
        [theme.breakpoints.up(1800)]: {
            "@media screen  and (orientation: landscape) ": {
                width: "45px",
                height: "45px",
                marginRight: 10,
                "&.isMultiTable": {
                    marginRight: 10,
                },
            },
        },
        "&.deepBlack": {
            marginRight: "0px",
        },
    },

    coinBox: ({
        vertical,
        horizontal,
        animationLatency,
        transitionLatency,
    }: ICoin) => ({
        display: "flex",
        borderRadius: "50%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        zIndex: 311,
        "&.stack": {
            opacity: 0,
        },
        ...(vertical &&
            horizontal &&
            animationLatency && {
                "&.mainIcon": {
                    animationName: "riseMainIcon",
                    animationIterationCount: 1,
                    animationFillMode: "forwards",
                    animationDuration: "1400ms",
                },
                "&.stack_0": {
                    transitionDelay: `${transitionLatency * 0}s`,
                    animationDelay: `${animationLatency * 5}s`,
                    transform: getTransform(vertical, horizontal),
                    ...coinAnimateSettings,
                },
                "&.stack_1": {
                    transitionDelay: `${transitionLatency * 1}s`,
                    animationDelay: `${animationLatency * 6}s`,
                    transform: getTransform(vertical, horizontal),
                    ...coinAnimateSettings,
                },
                "&.stack_2": {
                    transitionDelay: `${transitionLatency * 2}s`,
                    animationDelay: `${animationLatency * 7}s`,
                    transform: getTransform(vertical, horizontal),
                    ...coinAnimateSettings,
                },
                "&.stack_3": {
                    transitionDelay: `${transitionLatency * 3}s`,
                    animationDelay: `${animationLatency * 8}s`,
                    transform: getTransform(vertical, horizontal),
                    ...coinAnimateSettings,
                },
                "&.stack_4": {
                    transitionDelay: `${transitionLatency * 4}s`,
                    animationDelay: `${animationLatency * 9}s`,
                    transform: getTransform(vertical, horizontal),
                    ...coinAnimateSettings,
                },
            }),
    }),

    coinImage: {
        width: "100%",
        height: "100%",
        zIndex: 310,
        position: "relative",
    },

    amount: () => ({
        color: "#4D4D4D",
        background: "linear-gradient(180.73deg, #F8BF44 9.68%, #E08632 104.5%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        textShadow: "0px 2.76486px 8.29459px rgba(0, 0, 0, 0.25)",
        fontSize: 48,
        fontWeight: 900,
        marginRight: 10,
        zIndex: 5,
        [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            fontSize: 40,
        },
        [theme.breakpoints.up(1800)]: {
            fontSize: 54,
        },
    }),
});

export default useStyles;
