import { PlayerInfoExistPosition, IGame, ITournaments } from "src/store/models";

export type WatchingType = {
    id: string;
    nickname: string;
    avatar?: string;
};

export enum WatchersStatusType {
    show = "show",
    hide = "hide",
}

export interface IWatchingList {
    setOpen: (properties: boolean | WatchersStatusType) => void;
    isOpen: boolean | string;
    watchingArray: WatchingType[];
    openStatus: boolean | WatchersStatusType;
    elementPlace?: keyof typeof PlayerInfoExistPosition;
    dryStyle?: boolean;
    height?: number;
    hideToggler?: boolean;
    isMobileGamePage?: boolean;
    togglerClassName?: string;
    isPreview?: boolean | undefined;
    solidMultiGames?: IGame[] | any;
    isMobileView?: boolean | undefined;
}
