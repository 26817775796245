import React, { useMemo, useState } from "react";
import cn from "classnames";
import { observer } from "mobx-react";
import {Link, useHistory} from "react-router-dom";

import { useLocale } from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";
import GCLogo from "src/assets/images/gc-logo.png";
import LogoIcon from "src/components/controls/app-bar/images/logo.png";
import businessIcon from "src/pages/lobbyNew/assets/briefcase.svg";
import VisaIcon from 'src/assets/icons/social/visaIcon.svg';
import MastercardIcon from 'src/assets/icons/social/masterCardIcon.svg';
import CoinIcon from 'src/assets/icons/social/sbpIcon.svg';
import SberIcon from 'src/assets/icons/social/sberIcon.svg';
import BitcoinIcon from 'src/assets/icons/social/bircoinIcon.svg';
import TetherIcon from 'src/assets/icons/social/tetherIcon.svg';
import EthereumIcon from 'src/assets/icons/social/etherIcon.svg';
import BinanceIcon from 'src/assets/icons/social/binanceIcon.svg';
import EnvelopeIcon from 'src/assets/icons/social/EnvelopeIcon.svg';
import WhatsappIcon from 'src/assets/icons/social/WhatsappIcon.svg';
import TelegramIcon from 'src/assets/icons/social/TelegramIcon.svg';
import paths, { GSLink } from "src/consts/paths";
import SocialPaths from 'src/pages/socialPathContainer/socialPathsForFooter';
import { useMediaQuery } from "@mui/material";


import { ModalTypes } from "../../../pages/landing/utils/context";
import { ESocial } from "../../SideMenu";

import { useStyles } from "./styles";


interface IFooter extends React.HTMLAttributes<HTMLDivElement> {
    isMainPage?: boolean;
}

export const Footer = observer(({ isMainPage }: IFooter) => {
    const devType = process.env.REACT_APP_NODE_ENV;
    const GSLinkEnv = process.env.REACT_APP_GS_ENV;
    const GSLinkEnvMocked = "beturanga.com";

    const showGSLink = () => {
        console.log("service:", devType, GSLinkEnv);
        const urlHref = new URL(window.location.href);
        if (
            urlHref.toString().includes(GSLinkEnvMocked) &&
            urlHref.hostname.toString().includes(GSLinkEnvMocked)
        ) {
            return true;
        }
        return false;
    };

    const showLink = useMemo(
        () => showGSLink(),
        [devType, GSLinkEnv, GSLinkEnvMocked]
    );

    const classes = useStyles();
    const { generalStore, generalLobbyStore } = useStores();
    const {
        footer: {
            aboutUs,
            privacyPolicy,
            contactUs,
            termAndConditions,
            responsibleGaming,
            gamesRules,
            amlPolicy,
        },
        aboutUs: {
            newContent: { contentMain },
        },
    } = useLocale();
    const navigate = useHistory();


    const [modalState, setModalState] = useState({
        register: false,
        login: false,
        businessMsg: false,
        notifications: false,
    });
    const openModal = (modal: ModalTypes) => {
        setModalState((prev) => ({ ...prev, [modal]: true }));
    };

    const aboutUsText = [
        `Copyright (C) 2024 beturanga.com is owned and operated by R1 Entertainment N.V. as a License Holder (Curacao license No. 365/ Jaz).Rebux Ltd. provides processing service on the website as a Billing Agent and with a registered office located at Kalymnou 1, “Q MERITO” 4th Floor, Agios Nikolaos, 6037 Larnaca, Cyprus,(reg. number HE 432622). All rights reserved and protected by law.`,
    ];

    const aboutUsTextHalyk = [
        `Copyright (C) 2024 halyk.games is owned and operated by R1 Entertainment N.V. as a License Holder (Curacao license No. 365/ Jaz).Rebux Ltd. provides processing service on the website as a Billing Agent and with a registered office located at Kalymnou 1, “Q MERITO” 4th Floor, Agios Nikolaos, 6037 Larnaca, Cyprus,(reg. number HE 432622). All rights reserved and protected by law.`,
    ];

    const isMobile = useMediaQuery("(max-width: 1200px)");


    return (
        <div
            className={cn(classes.footer, {
                isMainPage: isMainPage,
            })}
        >
            {!generalStore.isHalyk && <div className={classes.logoContainer}>
                <img src={LogoIcon} className={classes.logoIcon} />
                <img src={GCLogo} className={classes.gcLogo} />
            </div>}
            <div
                className={cn(classes.aboutUsBox, {
                    isMainPage: isMainPage,
                })}
            >
                <p
                    className={cn(classes.aboutUsText, {
                        isMainPage: isMainPage,
                        showLink: showLink,
                    })}
                >
                    {/*{showLink && (
                        <a
                            className={cn()}
                            href={GSLink}
                            target="_blank"
                        >
                            <img
                                className={cn()}
                                src={GCLogo}
                                alt="GSLogo"
                            />
                        </a>
                    )}*/ }


                    {contentMain(!generalStore.isHalyk ? aboutUsText : aboutUsTextHalyk)}
                </p>
            </div>
            <div
                className={cn(classes.footerNavlinksWrapper, {
                    isMainPage: isMainPage,
                })}
            >
                <Link
                    to={paths.policy}
                    onClick={() => {
                        generalStore.closeSideMenu();
                    }}
                    className={cn(classes.link, { isMainPage: isMainPage })}
                >
                    {" "}
                    {amlPolicy("AML Policy")}{" "}
                </Link>
                <Link
                    to={paths.termAndConditions}
                    onClick={() => {
                        generalStore.closeSideMenu();
                    }}
                    className={cn(classes.link, { isMainPage: isMainPage })}
                >
                    {" "}
                    {termAndConditions("Terms and conditions")}{" "}
                </Link>
                <Link
                    to={paths.responsibleGaming}
                    onClick={() => {
                        generalStore.closeSideMenu();
                    }}
                    className={cn(classes.link, { isMainPage: isMainPage })}
                >
                    {" "}
                    {responsibleGaming("Responsive gaming")}{" "}
                </Link>
                <Link
                    to={paths.privacyPolicy}
                    onClick={() => {
                        generalStore.closeSideMenu();
                    }}
                    className={cn(classes.link, { isMainPage: isMainPage })}
                >
                    {" "}
                    {privacyPolicy("Privacy policy")}{" "}
                </Link>
                <div className={classes.businessContainer}>
                    {!generalStore.isHalyk && <div
                        className={classes.business}
                        onClick={() => {
                            generalLobbyStore.setOpenBusinessModal(
                                true
                            );
                            openModal("businessMsg");
                        }}
                    >
                        <img
                            src={businessIcon}
                            className={classes.businessIcon}
                        />
                        <span>Business</span>
                    </div>}
                    {!generalStore.isHalyk && <div className={classes.social}>
                        <span>Contact us</span>
                        <div className={classes.socialIcons}>
                            <img src={EnvelopeIcon} alt="Envelope" className={classes.socialIcon} />
                            <img src={WhatsappIcon} alt="Whatsapp" className={classes.socialIcon} />
                            <img src={TelegramIcon} alt="Telegram" className={classes.socialIcon} />
                        </div>
                    </div>}
                    {generalStore.isHalyk && <div className={classes.social} onClick={() => {
                        navigate.push(paths.faq);

                    }
                    }>
                        <span>&nbsp;&nbsp;Contact us</span>
                    </div>}
                </div>
                <div className={classes.iconContainer}>
                    <img src={VisaIcon} alt="Visa" className={classes.payIcon} />
                    <img src={MastercardIcon} alt="Mastercard" className={classes.payIcon} />
                    <img src={CoinIcon} alt="Coin" className={classes.payIcon} />
                    <img src={SberIcon} alt="Sber" className={classes.payIcon} />
                    <img src={BitcoinIcon} alt="Bitcoin" className={classes.payIcon} />
                    <img src={TetherIcon} alt="Tether" className={classes.payIcon} />
                    <img src={EthereumIcon} alt="Ethereum" className={classes.payIcon} />
                    <img src={BinanceIcon} alt="Binance" className={`${classes.payIcon} ${classes.smallIcon}`} />
                </div>
               
            </div>
            
        </div>


    );
});
