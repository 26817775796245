import React from "react";
import PulseLoader from "react-spinners/PulseLoader";
import BeatLoader from "react-spinners/BeatLoader";
import cn from "classnames";

import { useStyles } from "./styles";
import calcButtonWidth from "../../../utils/calcButtonWidth";

type ButtonTagType = "button" | "reset" | "submit" | undefined;

export enum ButtonType {
    primary = "linear-gradient(19.92deg, #0D5C52 13.3%, #50BAAE 86.7%)",
    purple = "linear-gradient(19.92deg, #220D5C 13.3%, #6C50BA 86.7%)",
    negative = "linear-gradient(19.92deg, #341010 13.3%, #831D1D 86.7%)",
    secondary = "linear-gradient(19.92deg, #220D5C 13.3%, #6C50BA 86.7%)",
    info = "linear-gradient(19.92deg, #0D235C 13.3%, #506EBA 86.7%)",
    yellow = "linear-gradient(180deg, #F8BF44 0%, #A16529 100%)",
    blue = "radial-gradient(47.56% 8.33% at 50% 8.33%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(49.97% 10% at 50% 90%, #FFFFFF 0%, rgba(255, 255, 255, 0) 69.87%), linear-gradient(19.92deg, #0D235C 13.3%, #506EBA 86.7%)",
    //======
    darkBlue_primary = "rgba(0, 186, 108, 1)",
    deepBlack_secondary = "rgba(0, 186, 108, 1)",
    deepBlack_yellow = "rgba(0, 186, 108, 1)",
    deepBlack_negative = "rgba(0, 186, 108, 1)",
}

export enum ButtonTrafficLight {
    primary,
    secondary,
    yellow,
    negative,
    continueBtn,
}

const defaultProps = {
    ratio: 1,
    color: "#edf4ff",
    disabled: false,
    loading: false,
    icon: undefined,
    iconPosition: "start",
    onClick: () => {},
    text: "",
    buttonType: "button" as ButtonTagType,
    fontSize: 16,
};
interface Props {
    onClick?: () => void;
    text: string;
    disabled?: boolean;
    color?: string;
    icon?: any;
    iconPosition?: "start" | "end";
    loading?: boolean;
    ratio?: number;
    buttonType?: ButtonTagType;
    bgColor?: string;
    className?: string;
    fontSize?: number;
    isWidthFixed?: boolean;
    loaderSize?: number;
    width?: number | string | undefined;
    widthWrapper?: number | string | undefined;
    padding?: number | string | undefined;
    height?: number | undefined;
}

export const SteelButton = (props: Props) => {
    let t = {
        ...defaultProps,
        ...props,
    };
    const classes = useStyles(t);

    const {
        onClick,
        disabled,
        color,
        text,
        loading = false,
        fontSize,
        isWidthFixed,
        icon,
        iconPosition,
        loaderSize,
        width,
        height,
        padding,
        widthWrapper,
    } = t;

    const Icon = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {iconPosition === "start" && (
                    <div
                        style={{
                            paddingRight: 8,
                            paddingTop: 4,
                            transform: `scale(${props.ratio})`,
                        }}
                    >
                        {icon}
                    </div>
                )}
                <div>
                    {loading ? (
                        <PulseLoader size={14} color="white" loading />
                    ) : (
                        text
                    )}
                </div>
                {iconPosition === "end" && (
                    <div
                        style={{
                            paddingLeft: 8,
                            transform: `scale(${props.ratio})`,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {icon}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div
            className={cn(classes.buttonWrapper, t.className)}
            onClick={(e) => {
                e.stopPropagation();
                if (!disabled && !loading) onClick();
            }}
        >
            <div
                style={{
                    width: widthWrapper ? widthWrapper : undefined,
                }}
                className={cn(classes.buttonSteelWrapper, "steel-wrapper")}
            >
                <div
                    style={{
                        width: widthWrapper ? widthWrapper : undefined,
                    }}
                    className={cn(classes.buttonBlackWrapper, "black-wrapper")}
                >
                    <button
                        className={cn(classes.customButton, "button")}
                        style={{
                            background: t.bgColor,
                            width: width ? width : undefined,
                            minWidth: isWidthFixed
                                ? `${calcButtonWidth(fontSize)}px`
                                : undefined,
                            padding: padding,
                            height: height,
                        }}
                        type={t.buttonType}
                    >
                        <div
                            style={{
                                color,
                                fontSize,
                            }}
                            className={cn(classes.buttonText, "text")}
                        >
                            {icon ? (
                                <Icon />
                            ) : loading ? (
                                <BeatLoader
                                    size={loaderSize || 16}
                                    color="white"
                                    loading
                                />
                            ) : (
                                <div
                                    style={{
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    {text}
                                </div>
                            )}
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};
