/*
 const getForm = async () => {
    try {
      const response = await axios.post(endpoints.getPaymentForm, { amount, paymentGateway })
      const { data } = response
      const { action, method, parameters } = data.paymentForm
      const { amount: payAmount, fee, total } = data

      setPaymentForm({ action, method, parameters, amount: payAmount, fee, total })
      setShowCreateGameDialog(true)
    } catch (error) {
      console.log(error)
    }
  }
 */

import axios, { AxiosResponse } from 'axios';

import { IPaymentMethods } from 'src/store/models';

import endpoints from '../../../core/endpoints';

import {
    ICreateInterkassaPayoutRequest,
    ICreateInterkassaPayoutResponse,
    IGetFormRequest,
    IGetFormResponse,
    IGetPayoutFormResponse,
    IGetTransactionsListResponse,
    IPurchaseCoinsRequest,
    IPurchaseCoinsResponse,
} from './requestResponses';

export const purchaseCoins = async (params: IPurchaseCoinsRequest) => {
    return await axios.post<IPurchaseCoinsResponse>(
        `${endpoints.purchaseCoins}`,
        params
    );
};

export const getPaymentForm = async (params: IGetFormRequest) => {
    return await axios.post<IGetFormResponse>(
        `${endpoints.getPaymentForm}`,
        params
    );
};

export const getPayoutForm = async (params: IGetFormRequest) => {
    return await axios.post<IGetPayoutFormResponse>(
        `${endpoints.getPayoutForm}`,
        params
    );
};

export const getPaymentsList = async () => {
    return await axios.get<IGetTransactionsListResponse>(
        `${endpoints.paymentsList}`
    );
};

export const createInterkassaPayout = async (
    params: ICreateInterkassaPayoutRequest
) => {
    return await axios.post<
        ICreateInterkassaPayoutRequest,
        AxiosResponse<ICreateInterkassaPayoutResponse>
    >(`${endpoints.interkassaCreatePayout}`, params);
};

export const createCryptoPayout = async (
    params: ICreateInterkassaPayoutRequest
) => {
    return await axios.post<
        ICreateInterkassaPayoutRequest,
        AxiosResponse<ICreateInterkassaPayoutResponse>
    >(`${endpoints.cryptoCreatePayout}`, params);
};

export const getPaymentMethods = () => {
    return axios.get<IPaymentMethods>(`${endpoints.getPaymentMethods}`);
};

export const cancelPayout = async (id: string) => {
    return axios.post(endpoints.cancelWithdraw, { id });
};
