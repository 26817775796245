import { makeStyles } from '@mui/styles';

import { appColors, backgroundColors } from 'src/consts/app-config';
import theme from 'src/theme';

export const useStyles = makeStyles({
    container: {
        width: '100%',
        padding: '10px 0',
    },
    tabContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 1.5vw', 
    },
    tabs: {
        minHeight: 'auto!important',
        flexGrow: 1,
        '& .MuiTabs-flexContainer': {
            justifyContent: 'space-between',
        },
        '& .MuiTab-root': {
            borderRadius: '0',
            borderBottom: '2px solid transparent',
            flex: 1,
        },
        '& .Mui-selected': {
            color: `${appColors.white} !important`,
            background: 'transparent',
            borderBottom: '2px solid #00BA6C',
        },
    },
    tabScrollButton: {
        width: 'auto!important',
    },
    tabItem: {
        color: '#FFFFFF!important',
        textTransform: 'none',
        fontSize: '16px!important',
        fontWeight: '500!important',
        fontFamily: 'Montserrat, sans-serif!important',
        minHeight: 'auto!important',
        padding: '10px 15px!important',
        margin: '0!important',
        minWidth: "auto",
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
    },
    providerSelect: {
        marginLeft: 'auto',
    },
    providerSelectTab: {
        padding: '0 !important',
        minWidth: 'auto !important',
        '& .MuiTab-wrapper': {
            display: 'block',
        },
    },
    activeTab: {
        borderBottom: '2px solid #00BA6C!important',
    },
    checkboxItem: {
        color: '#A8B1CE!important',
        fontSize: '16px!important',
        // @ts-ignore
        fontWeight: '500!important',
        fontFamily: 'inherit',
    },
    searchTextField: {
        maxWidth: 500,
        margin: '5px auto 0px',
        [theme.breakpoints.down('md')]: {
            margin: '5px auto 0px',
            height: 40,
            fontSize: 14,
        },
        '&.isMultiTable': {
            margin: '5px auto 0px',
            height: 40,
            fontSize: 14,
        },
    },

});
