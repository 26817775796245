import React, { useState, useEffect, useRef, memo } from "react";
import Picker from "emoji-picker-react";
import { Box } from "@mui/material";

import { useLocale } from "src/providers/LocaleProvider";
import { GameMessageType } from "src/service/api/gameChat/requestResponses";

import { IGameChatHistoryTextArea } from "../types/IChat";

import { useStyles } from "./styles";
import { Smile } from "./smile.icon";
import { Send } from "./send.icon";
import "./styles.css";

export const GameChatHistoryTextArea = memo(
    ({
        gameID,
        text,
        onChange,
        onEnter,
        shouldHide,
        onStickerToggler,
    }: IGameChatHistoryTextArea) => {
        const inputRef = useRef<any>(null);
        const inputValue = inputRef?.current?.value;
        const {
            game: {
                chat: { placeMessage },
            },
        } = useLocale();
        const inputHelpers = {
            placeholder: "Написать сообщение",
            targetKey: "Enter",
        };

        const [showPicker, setShowPicker] = useState(false);
        const classes = useStyles({ showPicker });

        const handleClear = () => {
            inputRef.current.value = "";
        };

        //Dont Delete! Emoji Logical
        // const [inputStr, setInputStr] = useState('');
        // const onEmojiClick = (event, emojiObject) => {
        //     setInputStr((prevInput) => prevInput + emojiObject.emoji);
        // };
        // useEffect(() => {
        //     shouldHide && showPicker && setShowPicker(false);
        // }, [shouldHide]);

        return (
            <div className={classes.messageBackgroundBox}>
                <input
                    ref={inputRef}
                    className={classes.input}
                    // placeholder={placeMessage(inputHelpers.placeholder)}
                    onKeyDown={(e) => {
                        // setShowPicker(false);
                        if (e.key === inputHelpers.targetKey) {
                            onEnter({
                                gameId: gameID,
                                message: inputRef?.current?.value,
                                type: GameMessageType.one,
                            });
                            handleClear();
                            // setInputStr('');
                        }
                    }}
                />
                <span
                    className={classes.messageStickericon}
                    onClick={onStickerToggler}
                    //Its Logical for open Emoji Chat
                    // onClick={() => setShowPicker((val) => !val)}
                >
                    <Smile width={20} height={20} />
                </span>
                <button
                    className={classes.buttonSendMessageFromChat}
                    onClick={() => {
                        onEnter({
                            gameId: gameID,
                            message: inputRef?.current?.value,
                            type: GameMessageType.one,
                        });
                        handleClear();
                        // setInputStr('');
                        // setShowPicker(false);
                    }}
                >
                    <Send />
                </button>
                {/* <Box className={classes.pickerWrapper}>
                {showPicker && (
                    <Picker
                        pickerStyle={{
                            width: '100%',
                            background: '#111427',
                            boxShadow: 'none',
                            border: 'none',
                        }}
                        onEmojiClick={onEmojiClick}
                    />
                )}
            </Box> */}
            </div>
        );
    }
);
