import React, { memo, useCallback } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import TabPanel from "@mui/lab/TabPanel";

import useStores from "src/hooks/useStores";
import { useLocale } from "src/providers/LocaleProvider";
import { EGameChatTab, IStickerRequest } from "src/store/models";
import { ICommunicationChatMessage } from "src/store/gameStore/types";
import useChangeTheme from "src/hooks/useChangeTheme";
import {
    isGameDialogMessage,
    isGameDialogMessageNew,
} from "src/store/gameStore/types";

import BlockMessages from "../blockMessages";
import { useStyles } from "../styles";
import { GameChatHistoryMessage } from "../message";
import { IChatOpponentAndMeMessages } from "../types/IChat";

const GameChatPanel = observer(
    ({
        currentTab,
        showHideOpponent,
        chat,
        isChatBlocked,
    }: IChatOpponentAndMeMessages) => {
        const appearanceTheme = useChangeTheme();
        const { extractCompiled } = useLocale();

        const getTextParser = {
            isLabel: "label",
            isNumber: "number",
            isObject: "object",
            isString: "string",
            isEmptyMessage: "",
        };

        const { isLabel, isObject, isEmptyMessage } = getTextParser;

        const {
            game: {
                chat: { blockedMessages },
            },
        } = useLocale();
        const { gameStore, authStore, tournamentsStore } = useStores();

        const shouldHide = tournamentsStore.isHideChat;
        const chessboardSize = gameStore?.chessboardSize;
        const stickers = authStore.stickers;

        const classes = useStyles({
            shouldHide,
            showHideOpponent,
            appearanceTheme,
            chessboardSize,
            stickers,
        });

        const getTextMessage = (message) => {
            if (typeof message === isLabel && message.id && message.pack) {
                return;
            }
            return typeof message === isObject
                ? getTextParser.isLabel in message
                    ? (extractCompiled(
                          message.label,
                          message.props || {},
                          message.defaultValue || isEmptyMessage
                      ) as JSX.Element)
                    : (JSON.stringify(message) as string)
                : (message as JSX.Element);
        };

        const getStickerMessage = (message: IStickerRequest) => {
            if (
                stickers &&
                message &&
                typeof message === isObject &&
                message.id &&
                message.pack
            ) {
                const currentStickerPack = stickers.find(
                    (pack) => pack.stickerPackName === message.pack
                );
                const currentStickerPath =
                    currentStickerPack &&
                    currentStickerPack?.stickersDetails.find(
                        (sticker) => sticker.id === message.id
                    )?.filename;

                return currentStickerPath;
            }
        };

        const calcPosition = useCallback(
            (item: ICommunicationChatMessage) => {
                return authStore.currentUser?._id === item.owner._id
                    ? "ltr"
                    : "rtl";
            },
            [chat]
        );

        return (
            <TabPanel
                value={EGameChatTab.chat}
                style={{
                    height: "100%",
                    padding: 0,
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                    borderRadius: "12px",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                    borderBottomStyle: "none",
                    width: "auto",
                }}
            >
                <div className={cn(classes.messagesBox, appearanceTheme)}>
                    <>
                        {!isChatBlocked ? (
                            <div
                                className={cn(
                                    classes.messagesBoxContainer,
                                    EGameChatTab.chat,
                                    appearanceTheme
                                )}
                            >
                                {chat &&
                                    [...chat].reverse().map((item, key) => {
                                        let el: JSX.Element | null = null;
                                        if (
                                            isGameDialogMessageNew(item) &&
                                            item.type === 1
                                        ) {
                                            el = (
                                                <GameChatHistoryMessage
                                                    key={`${item?.time}_${key}`}
                                                    time={item.time}
                                                    position={calcPosition(
                                                        item
                                                    )}
                                                    stickers={stickers}
                                                >
                                                    {getTextMessage(
                                                        item.message
                                                    )}
                                                </GameChatHistoryMessage>
                                            );
                                        }
                                        if (
                                            isGameDialogMessageNew(item) &&
                                            item.type === 3
                                            //We have to check type of stickerpack and id of sticker
                                            // item?.message?.id &&
                                            // item?.message?.pack
                                        ) {
                                            el = (
                                                <GameChatHistoryMessage
                                                    key={`${item?.time}_${key}`}
                                                    time={item.time}
                                                    position={item.position}
                                                    stickers={stickers}
                                                    // getStickerMessage={getStickerMessage(
                                                    //     item?.message
                                                    // )}
                                                />
                                            );
                                        }
                                        return <div key={key}>{el}</div>;
                                    })}
                            </div>
                        ) : (
                            <BlockMessages
                                message={blockedMessages(
                                    "Oppoent blocked Chat"
                                )}
                            />
                        )}
                    </>
                </div>
            </TabPanel>
        );
    }
);

export default memo(GameChatPanel);
