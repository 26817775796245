import { makeStyles } from "@mui/styles";

import { appColors } from "src/consts/app-config";
import theme from "src/theme";

export const useStyles = makeStyles({
    wrapper: {
        display: "flex",
        alignItems: "center",
    },
    icon: {
        color: "#F3B145",
        fontSize: 22,
        [theme.breakpoints.down("md")]: {
            marginLeft: 3,
            fontSize: 18,
        },
    },
    text: {
        marginLeft: 5,
        fontSize: 16,
        fontWeight: 700,
        color: appColors.white,
        [theme.breakpoints.down("md")]: {
            marginLeft: 3,
            fontSize: 14,
        },
    },
});
