import { height } from '@mui/system';
import { makeStyles } from '@mui/styles';

import { media } from 'src/utils/media';
import theme from 'src/theme';

export const useStyles = makeStyles({
    menuLayer: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: 'auto',
        [theme.breakpoints.down('xl')]: {
            width: 'auto',
        },
        '@media screen and (max-width: 1300px)': {
            width: 'auto',
        },
        // '@media screen and (max-width: 1150px)': {
        //     width: '200px',
        // },
        '@media screen and (max-width: 1150px) and (orientation: portrait)': {
            width: 'auto',
            marginTop: 5,
        },
        '@media screen (max-width: 400px)': {
            width: '110px',
            marginTop: 0,
            justifyContent: 'center'
        },
        // [theme.breakpoints.down('lg')]: {
        //     width: '240px',
        // },
        [theme.breakpoints.down('lg')]: {
            marginTop: 0,
            justifyContent: 'center',
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
            justifyContent: 'flex-start',
        },
        [theme.breakpoints.down(520)]: {
            //display: 'none',
        },
        '&.isPoker': {
            width: '100%',
            [theme.breakpoints.down('md')]: {
                marginTop: '0',
                height: 30,
            },
            [theme.breakpoints.down(520)]: {
                display: 'flex',
                marginTop: '0',
                height: 30,
            },
        },
    },

    gameDetectWrapperBox: {
        height: 40,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up(1250)]: {
            '&:hover .navDropdown': {
                visibility: 'visible',
                opacity: 1,
                transform: 'translateY(0)',
            },
        },
    },

    logoWrapper: {
        position: 'relative',
        '&.isPoker': {
            width: '100%',
            height: '100%',
            '@media screen and (max-width: 768px)': {
                width: '100%',
                height: '100%',
            },
        },
        '@media screen and (min-width: 1600px)': {
          width: 210,
          height: 'auto',
      },

    },

    logo: {
        // width: media(195, 320, 1024),
        // height: media(28, 50, 1024),
        height: media(20, 50, 1024),
        objectFit: 'contain',
        cursor: 'pointer',
        '@media screen and (max-width: 1500px)': {
            height: 40,
        },
        '@media screen and (max-width: 1024px)': {
           height: 32,
        },
        '@media screen and (max-width: 768px)': {
            height: '6vw',
        },
        '&.isHalyk': {
            height: media(65, 65, 1024),
        },
        //'&.isPoker': {
        //    height: 65,
        //    width: '100%',
        //    '@media screen and (max-width: 768px)': {
        //        width: '100%',
        //        height: '100%',
        //    },
        //},
    },

    logoFlake: {
        width: '40px',
        position: 'absolute',
        right: '0%',
        top: '25%',
        transform: 'translate(45%, -50%)',
        objectFit: 'cover',
        zIndex: -1,

        animationDelay: '0s',
        animationIterationCount: 'infinite',
        animationFillMode: 'forwards',
        animationDuration: '5000ms',
        [theme.breakpoints.down('xl')]: {
            width: '38px',
        },
        '@media screen and (max-width: 1300px)': {
            width: '33px',
        },
        '@media screen and (max-width: 1150px)': {
            width: '25px',
        },
        [theme.breakpoints.down('lg')]: {
            width: '20px',
        },
        '&.newYearFlake': {
            animationName: '$newYearFlake',
        },
        '&.newYearChina': {
            transform: 'translate(60%, -50%)',
            animationName: '$newYearChina',
            borderRadius: '50%',
        },
    },

    '@keyframes newYearFlake': {
        '0%': {
            right: '0%',
            top: '25%',
            transform: 'translate(45%, -50%) scale(1) rotate(0deg)',
        },
        '75%': {
            right: '0%',
            top: '25%',
            transform: 'translate(45%, -50%) scale(1.2) rotate(180deg)',
        },
        '100%': {
            right: '0%',
            top: '25%',
            transform: 'translate(45%, -50%) scale(1) rotate(360deg)',
        },
    },

    '@keyframes newYearChina': {
        '0%': {
            right: '0%',
            top: '20%',
            transform: 'translate(60%, -50%) scale(1) rotate(0deg)',
        },
        '25%': {
            right: '0%',
            top: '20%',
            transform: 'translate(60%, -50%) scale(1) rotate(20deg)',
            boxShadow: '0px 0px 42px 0px rgba(255, 101, 101, 0.5) inset',
        },
        '50%': {
            right: '0%',
            top: '25%',
            transform: 'translate(60%, -50%) scale(1.15) rotate(0deg)',
            boxShadow: 'rgba(255, 101, 101, 0.7) inset',
            filter: 'drop-shadow(0px 0px 15px rgba(255, 101, 101, 1))',
        },
        '75%': {
            right: '0%',
            top: '20%',
            transform: 'translate(60%, -50%) scale(1.2) rotate(-20deg)',
            boxShadow: '0px 0px 42px 0px rgba(255, 101, 101, 0.5) inset',
        },
        '100%': {
            right: '0%',
            top: '20%',
            transform: 'translate(60%, -50%) scale(1) rotate(0deg)',
        },
    },
});
