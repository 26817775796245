import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { animated } from 'react-spring';

import { useStyles } from './styles';
import TransitionWithUnMount from 'src/components/TransitionWithUnMount';

export enum textType {
    gold = 'gold',
    white = 'white',
    gray = 'gray',
    green = 'green',
}

type ViewModeModal = {
    title: string;
    message?: string;
    titleType: textType;
    messsageType?: textType;
    delay?: number;
};

const ViewModeModal = ({
    title,
    message,
    titleType,
    messsageType,
    delay,
}: ViewModeModal) => {
    const styles = useStyles();
    const [active, setActive] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setActive(false);
        }, 3000);
    }, []);
    return (
        <TransitionWithUnMount
            show={active}
            from={{ opacity: 0, transform: 'translate(0%, 100%)' }}
            enter={{ opacity: 1, transform: 'translate(0%, 0%)' }}
            leave={{ opacity: 0, transform: 'translate(0%, -100%)' }}
            delay={delay}
        >
            {(styleProps) => (
                <animated.div className={styles.wrapper} style={styleProps}>
                    <span className={cn(styles.text, titleType)}>{title}</span>
                    {message && (
                        <p
                            className={cn(
                                styles.text,
                                messsageType,
                                styles.message
                            )}
                        >
                            {message}
                        </p>
                    )}
                </animated.div>
            )}
        </TransitionWithUnMount>
    );
};

export default ViewModeModal;
