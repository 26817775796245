import React, {
    ReactNode,
    useEffect,
    useMemo,
    useState,
    useCallback,
    memo,
    useRef,
} from "react";
import { Box } from "@mui/material";
import cn from "classnames";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";

import calcNameLength from "src/utils/calcNameLength";
import useChangeTheme from "src/hooks/useChangeTheme";
import lastMoveIsUnAvailable from "src/utils/lastMoveIsUnAvailable";
import FenParser from "src/store/gameStore/utils/parser";
import FenHelper from "src/store/gameStore/utils/helper";
import useStores from "src/hooks/useStores";
import useDoublingStageStatus from "src/pages/game-new/hooks/useDoublingStageStatus";
import shortColor from "src/utils/shortColor";
import { ArrayHelper } from "src/shared/helpers/ArrayHelper";
import getOppositeColor from "src/utils/oppositeColor";
import NickPlayerComponent from "./components/NickComponent";
import { MIN_XL_TABLET, MAX_XL_TABLET } from "src/pages/game-new2/constants";
import { miniPiecesColors, miniPiecesStroke } from "src/consts/app-config";

import UserAvatar from "../UserAvatar";
import {
    ColorVariant,
    IPlayer,
    UserBalanceType,
    PlayerInfoExistPosition,
} from "../../store/models";
import { endpointsRoot } from "../../core/endpoints";
import X2AndDiceRedesign from "src/pages/game-new2/components/x2ButtonAndRollDice";

import { RedesignClock } from "./components/RedesignClock";
import { useStyles } from "./styles";
import miniPieces from "./images/mini-pieces";
import BeatPiecesCounter from "./components/BeatPicesCounter";

interface IKilledPiece {
    name: string;
    component: any;
    killed: boolean;
}
const allPieces = {
    N: 2,
    B: 2,
    R: 2,
    Q: 1,
    P: 8,
};

interface IPlayerInfo {
    name: string;

    avatar: string;
    noTimerSound?: boolean;
    active: boolean;
    color: ColorVariant;
    elementPlace: keyof typeof PlayerInfoExistPosition;
    initTime: number;
    piecesBeatCounter?: number;
    isSystemAvatar?: boolean | undefined;
    activeClock?: {
        color: ColorVariant;
    };
    styles?: any;
    killedPieces: {};
    reverse?: boolean;
    className?: string;
    userCashBox?: string | number | any;
    id?: string;
    winner?: string;
    resign?: string;
    showX2Bet?: boolean;
    balanceType?: UserBalanceType;
    isTournamentGame?: boolean | undefined;
    isXlTablet?: boolean | undefined;
    isMultiTable?: boolean;
    isTwoGameTable?: boolean;
    opponent?: IPlayer;
    isOpponent?: boolean;
}

const PlayerInfoRedesign = observer((props: IPlayerInfo) => {
    const classes = useStyles();

    const {
        name,
        avatar,
        noTimerSound = false,
        isSystemAvatar,
        isTournamentGame,
        isMultiTable,
        killedPieces,
        isOpponent,
        opponent,
        elementPlace,
        piecesBeatCounter,
    } = props;

    const { active, activeClock, initTime, showX2Bet, isTwoGameTable } = props;
    const { color, reverse, className, userCashBox, winner, resign } = props;
    const { gameStore, achievementsStatStore } = useStores();
    const clockRef = useRef<HTMLDivElement>(null);
    const appearanceTheme = useChangeTheme();

    const location = useLocation();
    const currentWidth = window.innerWidth;
    const currentHeight = window.innerHeight;
    const isMobileTabletResign =
        currentWidth < MAX_XL_TABLET ||
        elementPlace === PlayerInfoExistPosition.inLobbyPreview
            ? true
            : false;

    const { showAcceptOrRejectDialog } = useDoublingStageStatus();

    const toRoll = gameStore?.gameState?.toRoll?.();
    const myColor = gameStore?.gameState?.getMyColor?.();
    const isLastMoveAreUnAvailable = useMemo(() => {
        return lastMoveIsUnAvailable(
            !gameStore?.gameState?.legalPieces?.length,
            gameStore?.gameState?.history ?? [],
            gameStore?.gameState?.getMyColor?.() ?? ColorVariant.white
        );
    }, [
        gameStore?.gameState?.legalPieces?.length,
        gameStore?.gameState?.history,
    ]);
    const moveFinished = !gameStore?.gameState?.legalPieces?.length;
    const history = gameStore?.gameState?.history;
    const leftColor =
        gameStore?.gameState?.getMyColor?.() === ColorVariant.white
            ? ColorVariant.black
            : ColorVariant.white;

    const parse = new FenParser(FenHelper);
    const lastRolledPlayer = parse.getWhoLastRollsColor(
        gameStore?.gameState?.history
    );
    const [pawns, setPawns] = useState<IKilledPiece[]>([]);
    const [pieces, setPieces] = useState<IKilledPiece[]>([]);
    const [killedPiecesLocal, setKilledPieces] = useState<any>();

    const moves = useMemo(() => {
        return parse.diceMessagesFromHistory(
            history as any,
            leftColor,
            [],
            moveFinished
        );
    }, [history?.length, leftColor, moveFinished]);

    useEffect(() => {
        // Logical For Show Hide VersusBox Preview
        if (moves?.[0] || gameStore?.currentGame?.history?.[0]) {
            return gameStore?.initGameBegin?.(true);
        }
        return gameStore?.initGameBegin?.(false);
    }, [moves, gameStore?.currentGame?.history]);

    useEffect(() => {
        //Turned off  for desktop
        if (!isMobileTabletResign) return;
        if (!killedPiecesLocal && killedPieces) {
            setKilledPieces(killedPieces);
        }
        const collectedPawns: IKilledPiece[] = [];
        const collectedPieces: IKilledPiece[] = [];

        Object.keys(allPieces).forEach((key) => {
            const total =
                allPieces[key] > killedPieces[key]
                    ? allPieces[key]
                    : killedPieces[key];
            const c = shortColor(getOppositeColor(color));
            const piece = c + key;
            let killed = killedPieces[key];

            for (let i = 0; i < total; i++) {
                if (key !== "P") {
                    if (i <= allPieces[key] - 1) {
                        collectedPieces.push({
                            name: piece + i,
                            component: miniPieces[piece],
                            killed: killed > 0,
                        });
                        killed--;
                    } else {
                        collectedPawns.push({
                            name: `${c}P${collectedPawns.length}`,
                            component: miniPieces[`${c}P`],
                            killed: true,
                        });
                    }
                } else {
                    collectedPawns.push({
                        name: piece + i,
                        component: miniPieces[piece],
                        killed: killed > 0,
                    });
                    killed--;
                }
            }
        });

        setPieces(collectedPieces);
        setPawns(collectedPawns);
    }, [killedPieces, location.pathname]);

    //Turned off temprorary
    const shotKilledPieces = useMemo(() => {
        //Turned off  for desktop
        if (!isMobileTabletResign) return null;
        const piecesByName = pieces.reduce((acc, elem) => {
            if (acc[elem.name.slice(0, 2)]) {
                acc[elem.name.slice(0, 2)].push(elem);
            } else {
                acc[elem.name.slice(0, 2)] = [elem];
            }
            return acc;
        }, {});
        return Object.values(piecesByName).map((piece: IKilledPiece[], idx) => {
            return (
                <div
                    key={idx}
                    className={cn(classes.piecesGroupWrapperMobile, {
                        [classes.piecesGroupWrapperMarginMobile +
                        " pieces-group-wrapper-margin"]: idx > 0,
                        isMultiTable,
                        isTwoGameTable,
                    })}
                >
                    {ArrayHelper.reverseMap<IKilledPiece, ReactNode>(
                        piece,
                        (p, i) => {
                            return (
                                <div
                                    key={i}
                                    className={cn(
                                        classes.pawnIconWrapperMobile,
                                        {
                                            [classes.chessMenMarginMobile +
                                            "chessmen-margin"]: i > 0,
                                            isTwoGameTable,
                                        }
                                    )}
                                >
                                    {p.component(
                                        p.killed
                                            ? [
                                                  miniPiecesColors[
                                                      appearanceTheme
                                                  ],
                                                  miniPiecesStroke[
                                                      appearanceTheme
                                                  ],
                                              ]
                                            : [
                                                  "none",
                                                  miniPiecesStroke[
                                                      appearanceTheme
                                                  ],
                                              ]
                                    )}
                                </div>
                            );
                        }
                    )}
                </div>
            );
        });
    }, [pieces]);

    const shotKilledPawns = useMemo(() => {
        //Turned off  for desktop
        if (!isMobileTabletResign) return null;
        return ArrayHelper.reverseMap<IKilledPiece, ReactNode>(
            pawns,
            (p, i) => {
                return (
                    <div
                        key={i}
                        className={cn(classes.pawnIconWrapperMobile, {
                            [classes.chessMenMarginMobile]: i > 0,
                            isMultiTable,
                            isTwoGameTable,
                        })}
                    >
                        {p.component(
                            p.killed
                                ? [
                                      miniPiecesColors[appearanceTheme],
                                      miniPiecesStroke[appearanceTheme],
                                  ]
                                : ["none", miniPiecesStroke[appearanceTheme]]
                        )}
                    </div>
                );
            }
        );
    }, [pawns]);

    const lastMyMoveIsUnAvailable =
        lastRolledPlayer === myColor && isLastMoveAreUnAvailable;

    const currentOpponent = opponent?.id
        ? achievementsStatStore.getMyCurrentOpponent(opponent?.id)
        : null;

    const UserAvatarMemo = useMemo(
        () => (
            <>
                <UserAvatar
                    winner={winner}
                    resign={resign}
                    color={color}
                    nickname={name}
                    avatarUrl={
                        (avatar && `${endpointsRoot}/avatars/${avatar}`) || ""
                    }
                    className={cn(classes.userAvatar, {
                        isMultiTable,
                        isTwoGameTable,
                        isMobileTabletResign: isMobileTabletResign,
                    })}
                    isSystemAvatar={isSystemAvatar}
                    isChessBoard
                    opponent={opponent}
                />
            </>
        ),
        [winner, currentOpponent]
    );

    if (!isMobileTabletResign) {
        return (
            <Box
                component="div"
                className={cn(
                    classes.playerInfoRedesign,
                    className,
                    elementPlace,
                    appearanceTheme,
                    {
                        isMultiTable,
                        isTwoGameTable,
                        active,
                    }
                )}
            >
                <div className={classes.avatarAndButtonWrapperRedesign}>
                    <span
                        className={cn(
                            classes.userAvatarSpan,
                            elementPlace,
                            appearanceTheme
                        )}
                        id={`${color}-${gameStore?.currentGame?.id}`}
                        onClick={() => {
                            if (opponent) {
                                achievementsStatStore.getPlayerSettingsModal(
                                    opponent
                                );
                            }
                        }}
                    >
                        {UserAvatarMemo}
                    </span>
                </div>
                <Box
                    className={cn(
                        classes.leftPlayerWrapperRedesign,
                        appearanceTheme,
                        {}
                    )}
                >
                    <div
                        className={cn(
                            classes.clockWrapperRedesign,
                            "clock-wrapper",
                            appearanceTheme,
                            {
                                isOpponent: opponent,
                            }
                        )}
                    >
                        <Box
                            component="div"
                            className={cn(
                                classes.nikAndclockWrapperBoxRedesign,
                                elementPlace,
                                appearanceTheme,
                                {
                                    isMultiTable,
                                    isTwoGameTable,
                                }
                            )}
                        >
                            <Box
                                component="div"
                                className={cn(
                                    classes.redesignClockWrapperRedesign,
                                    appearanceTheme,
                                    elementPlace,
                                    {
                                        isMultiTable,
                                    }
                                )}
                            >
                                <div
                                    className={cn(
                                        classes.redesignClockBoxRedesign,
                                        appearanceTheme,
                                        {
                                            isMultiTable,
                                        }
                                    )}
                                >
                                    <RedesignClock
                                        warningLimit={30}
                                        active={active}
                                        initTime={initTime}
                                        noTimerSound={noTimerSound}
                                        activeClock={activeClock}
                                        color={color}
                                        isMultiTable={isMultiTable}
                                        isTwoGameTable={isTwoGameTable}
                                    />
                                </div>
                            </Box>
                            <NickPlayerComponent
                                name={name}
                                appearanceTheme={appearanceTheme}
                                isMultiTable={isMultiTable}
                                isTwoGameTable={isTwoGameTable}
                                opponent={opponent}
                            />
                        </Box>
                    </div>
                </Box>
                <BeatPiecesCounter piecesBeatCounter={piecesBeatCounter} />
            </Box>
        );
    }

    return (
        <Box
            component="div"
            className={cn(
                classes.playerInfoRedesign,
                className,
                elementPlace,
                appearanceTheme,
                {
                    isMultiTable,
                    isTwoGameTable,
                }
            )}
        >
            <div
                className={cn(
                    classes.piecesWrapperRedesign,
                    "pieces-wrapper",
                    appearanceTheme,
                    elementPlace,
                    {
                        isMultiTable,
                    }
                )}
            >
                <div
                    className={cn(
                        classes.miniPiecesRedesign,
                        elementPlace,
                        appearanceTheme
                    )}
                >
                    {shotKilledPawns}
                </div>
                <div
                    className={cn(
                        classes.miniPiecesRedesign,
                        classes.killedPiecesRedesign,
                        elementPlace,
                        appearanceTheme,
                        {
                            isMultiTable,
                        }
                    )}
                >
                    {shotKilledPieces}
                </div>
            </div>

            <div
                className={cn(
                    classes.avatarAndButtonWrapperRedesign,
                    elementPlace,
                    appearanceTheme
                )}
            >
                {toRoll &&
                !lastMyMoveIsUnAvailable &&
                !showX2Bet &&
                !showAcceptOrRejectDialog &&
                (isTournamentGame || userCashBox) ? (
                    <span
                        id={`${color}-${gameStore?.currentGame?.id}`}
                        className={cn(
                            classes.userAvatarSpan,
                            "isMobileTabletResign",
                            "isDiceButton",
                            elementPlace,
                            appearanceTheme
                        )}
                    >
                        <X2AndDiceRedesign
                            isPlayerInfo={true}
                            isTournamentGame={isTournamentGame}
                            diceButtonForTabletandMobile
                        />
                    </span>
                ) : (
                    <span
                        id={`${color}-${gameStore?.currentGame?.id}`}
                        className={cn(
                            classes.userAvatarSpan,
                            "isMobileTabletResign",
                            elementPlace,
                            appearanceTheme
                        )}
                        onClick={() => {
                            if (opponent) {
                                achievementsStatStore.getPlayerSettingsModal(
                                    opponent
                                );
                            }
                        }}
                    >
                        {UserAvatarMemo}
                    </span>
                )}
            </div>

            <Box
                className={cn(
                    classes.leftPlayerWrapperRedesign,
                    appearanceTheme,
                    elementPlace,
                    {}
                )}
            >
                <div
                    className={cn(
                        classes.clockWrapperRedesign,
                        "clock-wrapper",
                        elementPlace,
                        {
                            isOpponent: opponent,
                        }
                    )}
                >
                    <Box
                        component="div"
                        className={cn(
                            classes.nikAndclockWrapperBoxRedesign,
                            appearanceTheme,
                            elementPlace,
                            {
                                isMultiTable,
                                isTwoGameTable,
                            }
                        )}
                    >
                        <Box
                            component="div"
                            className={cn(
                                classes.redesignClockWrapperRedesign,
                                appearanceTheme,
                                elementPlace,
                                {
                                    isMultiTable,
                                }
                            )}
                        >
                            <div
                                className={cn(
                                    classes.redesignClockBoxRedesign,
                                    appearanceTheme,
                                    elementPlace,
                                    {
                                        isMultiTable,
                                    }
                                )}
                            >
                                <RedesignClock
                                    warningLimit={30}
                                    active={active}
                                    initTime={initTime}
                                    noTimerSound={noTimerSound}
                                    activeClock={activeClock}
                                    color={color}
                                    isMultiTable={isMultiTable}
                                    isTwoGameTable={isTwoGameTable}
                                />
                            </div>
                        </Box>
                        <NickPlayerComponent
                            name={name}
                            appearanceTheme={appearanceTheme}
                            isMultiTable={isMultiTable}
                            isTwoGameTable={isTwoGameTable}
                            opponent={opponent}
                        />
                    </Box>
                </div>
                {elementPlace === PlayerInfoExistPosition.inGame && (
                    <X2AndDiceRedesign
                        isTournamentGame={isTournamentGame}
                        isOpponent={!!opponent?.id}
                        x2ForTabletandMobile
                    />
                )}
            </Box>
        </Box>
    );
});

export default PlayerInfoRedesign;
