import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import cn from "classnames";
import { useLocation } from "react-router-dom";

import useCalcTableLabel from "src/hooks/useCalcTableLabel";
import { useLocale } from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";
import { ColorVariant, IPlayer } from "src/store/models";

import { useStyles } from "./styles";

interface IGameScore {
    stage?: number;
    title?: string;
    winner?: string;
}

const GameScore = ({ stage, title, winner }: IGameScore) => {
    const {
        tournaments: {
            titles: {
                detailsTab: { upTo },
            },
        },
        game: { pot, match },
    } = useLocale();
    const { gameStore } = useStores();

    const viewMode = gameStore?.isViewMode();
    const location = useLocation();

    const myColor = viewMode
        ? ColorVariant.white
        : gameStore?.gameState.getMyColor() ?? ColorVariant.white;

    const firstPlayer = viewMode
        ? gameStore?.gameState.getPlayerByColor(ColorVariant.white)
        : gameStore?.gameState.getPlayerByColor(myColor);

    const oponentColor = viewMode
        ? ColorVariant.black
        : gameStore?.gameState.getOpponentColor() ?? ColorVariant.black;
    const secondPlayer = viewMode
        ? gameStore?.gameState.getPlayerByColor(ColorVariant.black)
        : gameStore?.gameState.getPlayerByColor(oponentColor);

    const rounds =
        gameStore?.currentGame?.tournament?.gameSeries ??
        gameStore.currentGame?.settings?.rounds;
    const amount = gameStore?.gameState?.bank;

    const [firstPlayerState, setFirstScore] = useState<IPlayer | any>(null);
    const [secondPlayerState, setSecondScore] = useState<IPlayer | any>(null);
    const [clicker, setClick] = useState<boolean | string>(false);
    const delayForAnumationStart = 1000;

    const width = window.innerWidth;
    const styles = useStyles({
        clicker,
        myColor,
        oponentColor,
    });
    const stageLabel = useCalcTableLabel(stage);

    useEffect(() => {
        setClick(false);
        if (!winner || !firstPlayerState || !secondPlayerState) return;
        if (winner === myColor) {
            setClick("me");
            setTimeout(() => {
                setFirstScore({
                    ...firstPlayerState,
                    score: firstPlayerState.score + 1,
                });
            }, delayForAnumationStart);
            return;
        }
        if (winner === oponentColor) {
            setClick("oponent");
            setTimeout(() => {
                setSecondScore({
                    ...secondPlayerState,
                    score: secondPlayerState.score + 1,
                });
            }, delayForAnumationStart);
            return;
        }
    }, [winner]);

    useEffect(() => {
        if (firstPlayer && !firstPlayerState) {
            setFirstScore(firstPlayer);
        }
        if (secondPlayer && !secondPlayerState) {
            setSecondScore(secondPlayer);
        }
    }, [firstPlayer, secondPlayer]);

    useEffect(() => {
        if (location.pathname) {
            if (firstPlayer) {
                setFirstScore(firstPlayer);
            }
            if (secondPlayer) {
                setSecondScore(secondPlayer);
            }
        }
    }, [location.pathname]);

    return (
        <>
            <Box
                component="div"
                className={cn(styles.scoreAndTournamentInfoWrapper)}
            >
                {/* TODO: replace !title with isMultiRoundGame props */}
                {!title && (
                    <Box
                        component="div"
                        className={cn(styles.matchGameScoreTitleBox)}
                    >
                        <p
                            className={cn(
                                styles.gameScoreTitles,
                                "tournamentDetails"
                            )}
                        >
                            {match("Match")}
                            <span
                                className={cn(
                                    styles.matchGameScoreTitleBoxShine
                                )}
                            />
                        </p>
                    </Box>
                )}
                {width < 1251 ? (
                    <Box
                        component="div"
                        className={cn(styles.matchGameScoreTitleBox)}
                    >
                        <p
                            className={cn(
                                styles.gameScoreTitles,
                                "tournamentDetails"
                            )}
                        >
                            {title
                                ? `${title}, ${stageLabel}`
                                : `${pot("POT")}: $${amount}`}
                            <span
                                className={cn(
                                    styles.matchGameScoreTitleBoxShine
                                )}
                            />
                        </p>
                    </Box>
                ) : (
                    <Box
                        component="div"
                        className={cn(styles.matchGameScoreTitleBox)}
                    >
                        <p
                            className={cn(
                                styles.gameScoreTitles,
                                "tournamentDetails",
                                "tournamentDetailsTitle"
                            )}
                        >
                            {title ?? `${pot("POT")}: $${amount}`}
                            <span
                                className={cn(
                                    styles.matchGameScoreTitleBoxShine
                                )}
                            />
                        </p>
                        <p
                            className={cn(
                                styles.gameScoreTitles,
                                "tournamentDetails",
                                "tournamentDetailsRound"
                            )}
                        >
                            {stageLabel}
                        </p>
                    </Box>
                )}

                <Box component="div" className={styles.matchRoundBox}>
                    {firstPlayerState && secondPlayerState && (
                        <>
                            <span
                                className={cn(styles.gameScoreTitlesFirst, {
                                    me: clicker === "me",
                                })}
                            >
                                <p
                                    className={cn(
                                        styles.gameScoreTitlesName,
                                        "score"
                                    )}
                                >
                                    {firstPlayerState?.score}
                                </p>
                            </span>
                            <span
                                className={cn(styles.gameScoreTitles, "colon")}
                            >
                                :
                            </span>
                            <span
                                className={cn(styles.gameScoreTitlesSecond, {
                                    oponent: clicker === "oponent",
                                })}
                            >
                                <p
                                    className={cn(
                                        styles.gameScoreTitlesName,
                                        "score"
                                    )}
                                >
                                    {secondPlayerState?.score}
                                </p>
                            </span>
                            {rounds && rounds > 1 && (
                                <span
                                    className={cn(
                                        styles.gameScoreTitles,
                                        "upTo"
                                    )}
                                >
                                    {`(${upTo(
                                        "Up to"
                                    ).toLowerCase()} ${rounds})`}
                                </span>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default observer(GameScore);
