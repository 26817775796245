import React, { useState } from 'react';
import { Box } from '@mui/material';
import cn from 'classnames';

import endpoints from 'src/core/endpoints';
import avatarPlaceholder from 'src/assets/images/defaultPlaceholder.png';
import LazyImages from 'src/components/lazyImages';

import { useStyles } from './styles';

interface IItemAvatar {
    item: {
        id: string;
        path: string;
        pathCompressed?: string;
    };
    getAvatarId: (id: string | null) => void;
    choosenAvatarId: string | boolean;
}

const ItemAvatar = ({ item, getAvatarId, choosenAvatarId }: IItemAvatar) => {
    const localStyles = useStyles();
    const [isLoaded, setLoaded] = useState<boolean>(false);

    const handleSelect = () => {
        getAvatarId(choosenAvatarId === item.id ? null : item.id);
    };

    return (
        <Box className={cn(localStyles.gridAvatarItem)} id={item.id}>
            {/* <img
                loading='lazy'
                id={item.id}
                onClick={(e) => handleSelect(e)}
                className={cn(localStyles.gridAvatarImg, {
                    isSelected: isSelected,
                    // isLoaded: !isLoaded,
                })}
                src={`${endpoints.avatarPath}${item.path}`}
                alt={`${endpoints.avatarPath}${item.path}`}
            /> */}
            <LazyImages
                className={cn(localStyles.gridAvatarImg, {
                    isSelected: choosenAvatarId === item.id,
                    isLoaded: !isLoaded,
                })}
                placeholderImg={
                    item?.pathCompressed
                        ? `${endpoints.avatarPath}${item.pathCompressed}`
                        : avatarPlaceholder
                }
                src={`${endpoints.avatarPath}${item.path}`}
                errorImg={avatarPlaceholder}
                id={item.id}
                onClick={handleSelect}
                setLoaded={(status: boolean) => setLoaded(status)}
            />
        </Box>
    );
};

export default ItemAvatar;

{
    /* <Box
className={cn(localStyles.gridAvatarItem)}
id={item.id}
>
<LazyLoadImage
    beforeLoad={() => setLoaded(false)}
    afterLoad={() => setLoaded(true)}
    className={cn(localStyles.gridAvatarImg, {
      isSelected: isSelected,
      isLoaded: !isLoaded
    })}
    id={item.id}
    src={`${endpoints.avatarPath}/${item.path}`}
    alt={item.id}
    onClick={(e) => handleSelect(e)}
/>
{!isLoaded &&
(<img
className={cn(localStyles.gridAvatarFastImg)}
src={avatarPlaceholder} />)}
</Box> */
}
