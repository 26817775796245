import React, { memo } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import TabPanel from "@mui/lab/TabPanel";

import useStores from "src/hooks/useStores";
import { ChatExistPosition } from "src/store/models";
import { useLocale } from "src/providers/LocaleProvider";
import {
    isGameMoveMessage,
    isGameNotification,
} from "src/store/gameStore/types";
import { EGameChatTab, IStickerRequest } from "src/store/models";
import useChangeTheme from "src/hooks/useChangeTheme";

import BlockMessages from "../blockMessages";
import { useStyles } from "../styles";
import { GameChatHistoryMessage } from "../message";
import { IChatHistoryMessages } from "../types/IChat";
import { GameChatHistoryDiceMessage } from "../dice-message";
import DesktopWatchers from "src/pages/game-new2/components/desktopWatchers";

const WatchersChatPanel = observer(
    ({ currentTab, showHideOpponent, moveMessages }: IChatHistoryMessages) => {
        const appearanceTheme = useChangeTheme();
        const { extractCompiled } = useLocale();

        const { gameStore, authStore, tournamentsStore } = useStores();
        const shouldHide = tournamentsStore.isHideChat;
        const chessboardSize = gameStore?.chessboardSize;
        const stickers = authStore.stickers;

        const classes = useStyles({
            shouldHide,
            showHideOpponent,
            appearanceTheme,
            chessboardSize,
            stickers,
        });

        return (
            <TabPanel
                value={EGameChatTab.watchers}
                key={EGameChatTab.watchers}
                className={cn(classes.watchingTabContainer, appearanceTheme)}
                style={{
                    height: "100%",
                    padding: 0,
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                    borderRadius: "12px",
                    margin: "0px 10px",
                    width: "auto",
                }}
            >
                <DesktopWatchers componentPosition={ChatExistPosition.inGame} />
            </TabPanel>
        );
    }
);

export default memo(WatchersChatPanel);
