import { makeStyles } from "@mui/styles";

import theme from "src/theme";
import { appColors, backgroundColors } from "src/consts/app-config";

import { MIN_L_TABLET } from "../../constants";

export const useStyles = makeStyles({
    wrapperReturnFromGame: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 30,
        "@media screen and (min-width: 768px) and (max-height: 1024px) and (max-height: 750px)":
            {
                margin: "unset",
            },
        "&.isMobileGamePage": {
            margin: 0,
            padding: 0,
            height: "inherit",
            width: "inherit",
        },
        "&.deepBlack": {
            height: "100%",
            minHeight: "100%",
            padding: 0,
            margin: 0,
            gridGap: "16px",
            "&.isMobileGamePage": {
                height: "100%",
                width: "100%",
            },
        },
    },

    actionButtonWrapper: {
        height: "inherit",
        aspectRatio: "1 / 1",
        borderRadius: "50%",
        background: "rgba(245, 245, 245, 0.1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "8px",
    },

    actionIconsContainer: {
        display: "flex",
        justifyContent: "flex-start",
        height: "100%",
        width: "100%",
        "&.isMobileGamePage": {},
        "&.isMultitableMobile": {
            justifyContent: "space-between",
        },
    },

    actionIconsBox: {
        display: "flex",
        justifyContent: "flex-start",
        "&.deepBlack": {
            gridGap: "4px",
            height: "100%",
            width: "100%",
            padding: 0,
            margin: 0,
        },
        "&.isMobileGamePage": {},
        "&.isMultitableMobile": {},
    },

    badgeNotifications: {
        "&.MuiBadge-root > span": {
            margin: 0,
            padding: 0,
            outline: "0.5px solid #ffffff47",
            background:
                "linear-gradient(19.92deg, rgb(34, 13, 85) 13.3%, rgb(108, 80, 200) 86.7%)",
            [theme.breakpoints.up("xs")]: {
                transform: "translate(50%, -15%)",
                maxWidth: 16,
                minWidth: 16,
                maxHeight: 16,
                minHeight: 16,
            },
        },
        "&.mobileToggler": {
            "&.MuiBadge-root > span": {
                [theme.breakpoints.up("xs")]: {
                    transform: "translate(-100%, -50%)",
                    maxWidth: 12,
                    minWidth: 12,
                    maxHeight: 12,
                    minHeight: 12,
                },
            },
        },
        "&.badgeEye": {
            "&.MuiBadge-root > span": {
                [theme.breakpoints.up("xs")]: {
                    transform: "translate(50%, -25%)",
                    maxWidth: 10,
                    minWidth: 10,
                    maxHeight: 10,
                    minHeight: 10,
                },
            },
        },
        "&.open": {
            transform: "translate(85%, -47%) rotateY(0deg)",
            transition: "transform 300ms ease-out",
        },
        "&.hide": {
            transform: "translate(85%, -47%) rotateY(180deg)",
            transition: "transform 300ms ease-out",
        },
        "&.emptyNote": {
            "&.MuiBadge-root > span": {
                display: "none",
            },
        },
        "&.moreThan99": {
            "&.MuiBadge-root > span": {
                fontSize: "7px",
            },
        },
    },

    watchingViewMobWrapper: {
        display: "flex",
        justifyContent: "flex-start",
        gridGap: "4px",
    },

    actionIcon: {
        height: "100%",
        aspectRatio: "1 / 1",
    },

    bankInfo: {
        background: "#1A171B",
        border: "1px solid #2F3348",
        borderRadius: "19px",
        display: "flex",
        alignItems: "center",
        color: appColors.white,
        fontWeight: 700,
        fontSize: 16,
        padding: "5px 16px",
        marginLeft: 20,
        "@media screen and (max-width: 480px)": {
            marginLeft: 0,
        },
    },

    bankInfoAnimated: {
        background: "#1A171B",
        border: "1px solid #2F3348",
        borderRadius: "19px",
        display: "flex",
        alignItems: "center",
        color: appColors.white,
        fontWeight: 700,
        fontSize: 16,
        padding: "5px 16px",
        marginLeft: 20,
        "@media screen and (max-width: 480px)": {
            marginLeft: 0,
        },
        [theme.breakpoints.down("sm")]: {
            animationName: "$bankInfoAnimated",
            animationDelay: "1s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "1500ms",
        },
    },

    coinIcon: {
        width: 20,
        height: 20,
        objectFit: "contain",
    },

    coinIconAnimated: {
        width: 20,
        height: 20,
        objectFit: "contain",
        [theme.breakpoints.down("sm")]: {
            animationName: "$bankInfoAnimated",
            animationDelay: "0.8s",
            animationIterationCount: 1,
            animationFillMode: "forwards",
            animationDuration: "300ms",
        },
    },

    "@keyframes bankInfoAnimated": {
        "0%": {
            opacity: 1,
        },
        "100%": {
            opacity: 0,
            display: "none",
        },
    },
});
