import React from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from '@mui/material';
import cn from 'classnames';

import LocaleText from 'src/components/locale-text/locale.text';
import coinIcon from 'src/assets/icons/coin.svg';
import freeCoin from 'src/assets/icons/freeCoin.png';
import bonusCoin from 'src/assets/icons/bonusCoin.svg';
import theme from 'src/theme';

import { UserBalanceType } from '../../store/models';

import { useStyles } from './styles';
import { roundBalance } from './utils';
import { ToggleHidden } from './components/ToggleHidden';

interface Props {
    amount: number;
    hidden: boolean;
    onChange?: (v: boolean, b: string) => void;
    type: UserBalanceType;
    backGroundColor: string;
    onClick: () => void;
    showEye?: boolean;
    className?: string;
}

const Balance = (props: Props) => {
    const classes = useStyles();

    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const coinIcons = {
        [UserBalanceType.coins]: freeCoin,
        [UserBalanceType.play]: coinIcon,
        [UserBalanceType.referralBonus]: bonusCoin,
    };

    const textClasses = {
        [UserBalanceType.play]: classes.amount,
        [UserBalanceType.coins]: classes.amountCoins,
        [UserBalanceType.referralBonus]: classes.amount,
    };

    return (
        <div className={cn(classes.wrapper, props.className)}>
            {props.showEye && !isMobile && (
                <ToggleHidden
                    onChange={props.onChange}
                    hidden={props.hidden}
                    backGroundColor={props.backGroundColor}
                />
            )}
            <div className={classes.balancesContainer}>
                <div
                    className={cn(
                        classes.balanceWrapper,
                        classes.balanceWrapperSkeleton,
                        {
                            gold: props.type === UserBalanceType.play,
                            silver: props.type === UserBalanceType.coins,
                            noBackground:
                                props.type === UserBalanceType.referralBonus,
                        }
                    )}
                >
                    <img
                        src={coinIcons[props.type]}
                        className={classes.coinIcon}
                    />
                    <div className={classes.amountWrapper}>
                        <span
                            className={cn(
                                textClasses[props.type],
                                classes.amountSkeleton
                            )}
                        >
                            {roundBalance(props.amount)}
                        </span>
                    </div>
                    {props.showEye && isMobile && (
                        <ToggleHidden
                            onChange={props.onChange}
                            hidden={props.hidden}
                            backGroundColor={props.backGroundColor}
                        />
                    )}
                </div>

                <div
                    className={cn(
                        classes.balanceWrapper,
                        classes.mainBalanceWrapper,
                        {
                            gold: props.type === UserBalanceType.play,
                            silver: props.type === UserBalanceType.coins,
                            noBackground:
                                props.type === UserBalanceType.referralBonus,
                        }
                    )}
                >
                    <img
                        src={coinIcons[props.type]}
                        className={classes.coinIcon}
                        onClick={props.onClick}
                    />
                    <div
                        className={classes.amountWrapper}
                        onClick={props.onClick}
                    >
                        <span className={cn(textClasses[props.type])}>
                            {props.hidden ? (
                                <LocaleText
                                    label='common.balance.hiddenBalance'
                                    defaultValue='*****'
                                />
                            ) : !isNaN(props.amount) ? (
                                roundBalance(props.amount)
                            ) : (
                                0
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(Balance);
