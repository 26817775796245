import { makeStyles } from "@mui/styles";

import theme from "src/theme";
import { windowsSizeParams } from "src/consts/window-size-params";
import { appColors } from "src/consts/app-config";

import {
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_L_TABLET,
    MIN_CHANGE_SCREEN_WIDTH,
    MAX_XL_TABLET,
} from "src/pages/game-new2/constants";

export const useStyles = makeStyles({
    "@global": {
        "@keyframes gameContainer": {
            "0%": {
                opacity: 0,
                transform: "scale(4)",
            },
            "100%": {
                opacity: 1,
                transform: "scale(1)",
            },
        },
    },

    mobileTabletLayoutContainer: {
        display: "flex",
        position: "relative",
        minHeight: "100%",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        [theme.breakpoints.down("lg")]: {
            maxWidth: "85%",
        },
        [theme.breakpoints.down("md")]: {
            maxWidth: "85%",
        },
        [theme.breakpoints.down(600)]: {
            maxWidth: "90%",
        },
        [theme.breakpoints.down("sm")]: {
            maxWidth: "100%",
        },
    },

    mobileTabletLeftBox: {
        alignSelf: "stretch",
        flexGrow: 1,
        flexShrink: 0,
        flexBasis: "calc((100% - 590px) / 2)",
        display: "flex",
        alignItems: "center",
        padding: "0px 20px 0px 0px",
        [theme.breakpoints.up(MIN_CHANGE_SCREEN_WIDTH)]: {
            display: "flex",
            alignItems: "center",
            padding: "0px 20px 30px 0px",
        },
        [theme.breakpoints.up(1800)]: {
            display: "flex",
            alignItems: "center",
            padding: "0px 20px 30px 0px",
            "@media screen and (min-height: 950px)": {
                flexBasis: "33%",
            },
        },
        "@media screen and (min-width: 1401px) and (max-width: 1650px) and (max-height: 850px)":
            {
                flexBasis: "calc((100% - 500px) / 2)",
            },
        "@media screen and (max-width: 1400px)": {
            flexBasis: "calc((100% - 500px) / 2)",
        },
        "&.mobileType": {
            [theme.breakpoints.between("xs", "lg")]: {
                display: "flex",
                alignItems: "flex-start",
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                maxWidth: "600px",
                height: "100dvh",
                opacity: "0.98",
                zIndex: 311,
                padding: 0,
                transform: "translate(-102%, 0%)",
                [theme.breakpoints.between("xs", "sm")]: {
                    height: `calc(100dvh - ${windowsSizeParams.mobileAppBarHeight}px)`,
                },
                [theme.breakpoints.between("sm", "md")]: {
                    height: `calc(100dvh - ${windowsSizeParams.mobileAppBarHeight}px)`,
                },
                [theme.breakpoints.between("md", "lg")]: {
                    height: `calc(100dvh - ${windowsSizeParams.largeTabletAppBarHeight}px)`,
                },
                "&.open": {
                    transform: "translate(0%, 0%)",
                    transition: "transform ease-in 500ms",
                    backdropFilter: "blur(10px)",
                },
                "&.hide": {
                    transform: "translate(-102%, 0%)",
                    transition: "transform ease-out 500ms",
                },
            },
        },
    },
    mobileTabletCenterBox: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: 0,
        [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
            width: "fit-content",
            margin: "0px 10px ",
            "@media (orientation: portrait)": {
                width: "100%",
            },
        },
        "@media screen and (min-width: 1024px) and (max-height: 550px)": {
            margin: "0",
        },
        [theme.breakpoints.down("sm")]: {
            minHeight: "100%",
            height: "auto",
            display: "block",
        },

        // width: "100%",
        // display: "flex",
        // flexDirection: "column",
        // flexGrow: 0,
        // [theme.breakpoints.between(MAX_L_TABLET, MAX_CHANGE_SCREEN_WIDTH)]: {
        //     width: "fit-content",
        //     margin: "0px 10px ",
        //     "@media (orientation: portrait)": {
        //         width: "100%",
        //     },
        // },
        // "@media screen and (min-width: 1024px) and (max-height: 550px)": {
        //     margin: "0",
        // },
        // [theme.breakpoints.down("sm")]: {
        //     minHeight: "100%",
        //     height: "auto",
        //     display: "block",
        // },
    },
});
