import { makeStyles } from "@mui/styles";

import theme from "src/theme";

import {
    MIN_L_TABLET,
    MAX_CHANGE_SCREEN_WIDTH,
    MAX_XL_TABLET,
    MAX_L_TABLET,
} from "src/pages/game-new2/constants";
import { appColors } from "src/consts/app-config";

export const useStyles = makeStyles({
    rightPartNew: {
        position: "relative",
        paddingLeft: 0,
        wordBreak: "break-all",
        width: "100%",
        display: "grid",
        height: "inherit",
        // gridTemplateRows: "90px auto 90px",
        //====
        gridTemplateRows: "13.5% auto 13.5%",
        overflow: "hidden",
        padding: "10px 5px",
        [theme.breakpoints.up(1800)]: {
            // gridTemplateRows: "112px auto 112px",
            padding: "15px 0px",
            "&.isMultiTable": {
                height: "100%",
                padding: 0,
                "@media screen and (max-height: 860px)": {
                    gridTemplateRows: "60px 1fr 60px",
                },
            },
        },
        "&.isMultiTable": {
            height: "100%",
            padding: 0,
            "@media screen and (max-height: 860px)": {
                gridTemplateRows: "60px 1fr 60px",
            },
        },

        // "&.isGameOver": {
        //     display: "flex",
        //     flexDirection: "column",
        //     justifyContent: "space-between",
        //     alignItems: "center",
        // },
    },

    actionAndDiceCenterBox: {
        display: "grid",
        // gridTemplateRows: "35% 1fr 50%",
        position: "relative",
        overflow: "hidden",
        gridTemplateRows: "35% 20% 45%",
        width: "100%",
        height: "100%",
        // paddingBottom: "10px",
        "&.isSoloGameEnd": {
            gridTemplateRows: "35% auto auto",
            justifyContent: "stretch",
        },
        "&.isMultiTable": {
            paddingBottom: "0px",
            gridTemplateRows: "30% 1fr 50%",
        },
    },

    showPopupContainerNew: {
        position: "relative",
        bottom: 0,
        width: "100%",
        height: "100%",
        padding: "0px",
        display: "flex",
        alignItems: "center",
        overflow: "scroll",
        "-ms-overflow-style": "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
            width: "0!important",
        },
    },

    alertInteractiveData: {
        position: "absolute",
        top: "0%",
        zIndex: 311,
        maxWidth: "100%",
        width: "fit-content",
        height: "fit-content",
        // borderRadius: "24px",
        //padding: '16px',
        borderRadius: "calc(1vmin + 1%)",
        padding: "calc(1vmin + 1%)",
        backdropFilter: "blur(10px)",
        background:
            "linear-gradient(180.21deg, rgba(245, 245, 245, 0) 0.18%, rgba(245, 245, 245, 0.5) 98.24%)",
    },

    alertInteractiveParagraph: {
        fontSize: "40px",
        color: "rgba(0, 186, 108, 1)",
        textAlign: "center",
        fontWeight: 700,
    },

    bankWrapperNew: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 0,
        "&.isMultiRoundGame": {},
    },
    dicesWrapperNew: {
        display: "flex",
        justifyContent: "center",
        position: "relative",
        width: "100%",
        alignItems: "center",
    },

    winOrLoseAndRematchDialogWrapperNew: {
        maxWidth: "100%",
        minWidth: "80%",
        [theme.breakpoints.between(MAX_L_TABLET, "lg")]: {
            transform: "scale(0.9)",
        },
        [theme.breakpoints.up(1800)]: {
            // transform: "scale(1.2)",
            maring: 0,
            padding: 0,
            // marginBottom: 40,
        },
        [theme.breakpoints.up(2400)]: {
            // transform: "scale(1.25)",
            margin: 0,
        },
        "&.isMultiTable": {
            [theme.breakpoints.up(1800)]: {
                transform: "scale(1)",
                marginBottom: 0,
                padding: "10px",
                minWidth: "auto",
            },
        },
    },
});
