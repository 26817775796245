import React, { useEffect, useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { makeStyles } from "@mui/styles";
import cx from "classnames";
import cn from "classnames";
import _get from "lodash/get";
import Countdown, { zeroPad } from "react-countdown";
import { observer } from "mobx-react";

import { useLocale } from "src/providers/LocaleProvider";
import deleteIcon from "src/assets/icons/delete.svg";
import useChangeTheme from "src/hooks/useChangeTheme";
import { appColors, backgroundColors } from "src/consts/app-config";
import { ButtonType, GreenButton } from "src/components/buttons/greenButton";
import useStores from "src/hooks/useStores";
import { errorService, infoService } from "src/service/services";

import errorIcon from "../assets/icons/error.svg";
import { useModalStyles } from "../styles";
import TextInput from "../TextInput";

type ChangeNicknameModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

const useStyles = makeStyles({
    buttonBox: {
        justifySelf: "flex-start",
        marginTop: 8,
    },
    form: {
        gridRowGap: 8,
    },
    currentNickname: {
        fontSize: 16,
        fontWeight: 400,
        color: appColors.txt900,
        marginBottom: 16,
        "& a": {
            cursor: "pointer",
            position: "relative",
            "&:before": {
                content: '""',
                width: "100%",
                height: 0.5,
                background: appColors.txt900,
                position: "absolute",
                left: 0,
                bottom: 0,
            },
        },
    },
    errorBox: {
        fontSize: 14,
        fontWeight: 400,
        color: "#eb5757",
        display: "flex",
        alignItems: "center",
    },
    errorIcon: {
        width: 16,
        height: 16,
        objectFit: "contain",
        marginRight: 4,
    },
});

type FormValuesType = {
    nickname: "";
};

const initialValues: FormValuesType = {
    nickname: "",
};

const ChangeNicknameModal = ({ isOpen, onClose }: ChangeNicknameModalProps) => {
    const { authStore } = useStores();
    const monthTime = 2592000000;
    // TODO: maybe we should rename this state? to canChangeNickname or something like this
    const [nicknameKeeper, changeKeeper] = useState<boolean>(false);
    const {
        profile: {
            nickname: {
                changeNickname,
                yourNickname,
                form: { placeNewNickname, errors },
                saveButton,
                successFullyChanged,
                aviliableIn,
            },
        },
    } = useLocale();
    const locales = useLocale();
    const localStyles = useStyles();
    const styles = useModalStyles();
    const appearanceTheme = useChangeTheme();
    const lastNicknameChange = authStore?.currentUser?.nicknameChangedAt;

    const validationSchema = yup.object({
        nickname: yup
            .string()
            .required(errors.required("Nickname is required"))
            /*.matches(
                /^[^а-яА-Я]+$/,
                errors.unsupportedChars('Please use only English letters!')
            )*/
            .matches(
                /^[a-zA-Z_0-9]+$/,
                errors.notCorrectFormat(
                    'Please use only English letters and "_'
                )
            )
            .max(10, errors.tooLong("Nickname is too long"))
            .min(5, errors.tooShort("Nickname is too short")),
    });

    useEffect(() => {
        if (authStore?.currentUser) {
            if (!lastNicknameChange) return changeKeeper(true);
            if (
                lastNicknameChange &&
                Date.now() - Date.parse(lastNicknameChange) >= monthTime
            )
                return changeKeeper(true);
        }
        return changeKeeper(false);
    }, [authStore.currentUser]);

    const handleSubmit = async (values: FormValuesType, actions: any) => {
        try {
            await authStore.changeNickname(values.nickname);
            infoService.sendInfo(
                successFullyChanged(
                    "Your nickname has been successfully changed"
                )
            );
            return onClose();
        } catch (e) {
            const error = e?.response?.data?.errors?.nickname;
            if (error) {
                errorService.sendError(
                    _get(
                        locales,
                        error.locale?.label
                    )(error.locale.defaultValue || "")
                );
            }
        } finally {
            actions.setSubmitting(false);
        }
    };

    const timerBox = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            changeKeeper(true);
            return null;
        } else {
            return (
                <span className={cn(styles.tournamentModalTimer)}>
                    {aviliableIn("Available in")}: {days}:{zeroPad(hours)}:
                    {zeroPad(minutes)}:{zeroPad(seconds)}
                </span>
            );
        }
    };

    return (
        <Modal open={isOpen} onClose={onClose} className={styles.modalWrapper}>
            <Box
                className={cx(
                    styles.modal,
                    appearanceTheme,
                    "changeNicknameModal"
                )}
            >
                <Box className={styles.modalTopBox}>
                    <Typography className={styles.title}>
                        {changeNickname("Change nickname")}
                    </Typography>
                    <img
                        onClick={onClose}
                        src={deleteIcon}
                        className={styles.closeIcon}
                    />
                </Box>
                <Typography className={localStyles.currentNickname}>
                    {yourNickname("Your nickname")}:{" "}
                    <a>{authStore.currentUser?.nickname}</a>
                </Typography>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(formikProps) => (
                        <form
                            className={cx(styles.form, localStyles.form)}
                            onSubmit={formikProps.handleSubmit}
                        >
                            {!(!nicknameKeeper && lastNicknameChange) && (
                                <>
                                    <TextInput
                                        name="nickname"
                                        label={placeNewNickname("New Nickname")}
                                    />
                                    {formikProps.errors.nickname && (
                                        <div className={localStyles.errorBox}>
                                            <img
                                                src={errorIcon}
                                                className={
                                                    localStyles.errorIcon
                                                }
                                            />{" "}
                                            {formikProps.errors.nickname}
                                        </div>
                                    )}
                                </>
                            )}
                            <div className={localStyles.buttonBox}>
                                {!nicknameKeeper && lastNicknameChange ? (
                                    <Box>
                                        <Countdown
                                            date={
                                                Date.parse(lastNicknameChange) +
                                                monthTime
                                            }
                                            renderer={timerBox}
                                        />
                                    </Box>
                                ) : (
                                    <GreenButton
                                        buttonType="submit"
                                        text={saveButton("Save")}
                                        type={ButtonType.secondary}
                                        loading={formikProps.isSubmitting}
                                        fontSize={20}
                                        isWidthFixed
                                    />
                                )}
                            </div>
                        </form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
};

export default observer(ChangeNicknameModal);
