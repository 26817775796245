import { observer } from 'mobx-react';
import React, { ReactNode, useEffect, useState } from 'react';

import useStores from 'src/hooks/useStores';
import OfferedX2Modal from './OfferedX2Modal';
import OfferedRematchModal from './OfferedRematchModal';
import WinModal from './WinModal';
import X2AcceptedModal from './X2AcceptedModal';
import { DoublingStage, RematchStage } from 'src/store/gameStore/dialogs';
import RejectedRematchModal from './RejectedRematchModal';
import { GameModeType } from 'src/store/models';

const ViewModeModals = () => {
    const { gameStore, lobbyStore } = useStores();
    const [modals, setModals] = useState<
        { isActive: boolean; component: ReactNode; key: string }[]
    >([]);

    const isRegularGame = gameStore.currentGame?.type === GameModeType.REGULAR
    const dialogs = gameStore.dialogs;

    const isShowWinOrLostDialog = gameStore.isShowWinOrLostDialog;

    const showX2Modal =
        dialogs.doublingDialog.isOpen &&
        dialogs.doublingDialog.stage === DoublingStage.acceptOrRejectDoubling &&
        !dialogs.giveUpDialog.isOpen &&
        !dialogs.rematchDialog.isOpen &&
        !isShowWinOrLostDialog;

    const showX2AcceptedModal =
        dialogs.doublingDialog.isOpen &&
        dialogs.doublingDialog.stage ===
            DoublingStage.opponentAcceptedDoubling &&
        !dialogs.giveUpDialog.isOpen &&
        !dialogs.rematchDialog.isOpen &&
        !isShowWinOrLostDialog;

    const showRematchModal =
        dialogs.rematchDialog.isOpen &&
        dialogs.rematchDialog.stage ===
            RematchStage.waitingForOpponentToDecide &&
        !isShowWinOrLostDialog;

    const showRejectedRematchModal =
        dialogs.rematchDialog.isOpen &&
        dialogs.rematchDialog.stage === RematchStage.rejected &&
        !isShowWinOrLostDialog;

    const offeredX2Modal =       {
      isActive: showX2Modal,
      component: <OfferedX2Modal key='showX2Modal' />,
      key: 'showX2Modal',
    }

    const offeredRematchModal =     {
      isActive: showRematchModal,
      component: <OfferedRematchModal key='showRematchModal' />,
      key: 'showRematchModal',
    }

    const  rejectedRematchModal =  {
      isActive: showRejectedRematchModal,
      component: <RejectedRematchModal key='showRejectedRematchModal' />,
      key: 'showRejectedRematchModal',
    }

    const winModal =      {
      isActive: isShowWinOrLostDialog,
      component: <WinModal key='showWinOrLostDialog' />,
      key: 'isShowWinOrLostDialog',
    }

    const x2AcceptedModal =      {
      isActive: showX2AcceptedModal,
      component: <X2AcceptedModal key='X2AcceptedModal' />,
      key: 'X2AcceptedModal',
    };


    const dataForRegular = [offeredX2Modal, offeredRematchModal, rejectedRematchModal, winModal, x2AcceptedModal];
    const dataForTournament = [winModal]

    useEffect(() => {
        const activeModal = isRegularGame ? dataForRegular.find((el) => el.isActive) : dataForTournament.find((el) => el.isActive)
        if (!activeModal) return;
        const alreadyInModals = modals.find((el) => el.key === activeModal.key);
        if (alreadyInModals) return;

        setModals((prevModals) => [...prevModals, activeModal]);

        const deleteActiveModal = () => {
            if (isShowWinOrLostDialog) return;
            setModals((prevModals) => [
                ...prevModals.filter((el) => el.key !== activeModal.key),
            ]);
        };
        const timeout = setTimeout(deleteActiveModal, 10000);

        return () => {
            deleteActiveModal();
            clearTimeout(timeout);
        };
    }, [showX2Modal, showRematchModal, isShowWinOrLostDialog]);

    return <>{modals.map((elem) => elem.component).slice(0, 2)}</>;
};

export default observer(ViewModeModals);
