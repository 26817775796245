import { Box, useMediaQuery } from "@mui/material";
import cn from "classnames";

import { observer } from "mobx-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import _get from "lodash/get";

import PlayerInfoRedesign from "src/components/PlayerInfoRedesign";
import WatchingList from "src/components/WatchingPlayers/components/WatchingList";
import {
    WatchersStatusType,
    WatchingType,
} from "src/components/WatchingPlayers/components/types/watchingTypes";
import MobilePot from "src/components/controls/bank/coinAnimation/MobilePotContainer/mobilePot";
import CoinAnimationContainer from "src/components/controls/bank/coinAnimation/coinAnimationContainer";
import GameScore from "src/components/gameScore";
import GameScoreRedesign from "src/components/gameScoreRedesign";
import { windowsSizeParams } from "src/consts/window-size-params";
import useChangeTheme from "src/hooks/useChangeTheme";
import useCheckFullScreen from "src/hooks/useCheckFullScreen";
import useOrientation from "src/hooks/useOrientation";
import useStores from "src/hooks/useStores";
import useWindowSize from "src/hooks/useWindowSize";

import ActionAndReturn from "src/pages/game-new2/components/actionAndReturn";
import useDoublingStageStatus from "src/pages/game-new/hooks/useDoublingStageStatus";
import { usePlayer } from "src/sounds/use-player";
import {
    ChessboardType,
    ColorVariant,
    GameModeType,
    GameStatus,
    UserBalanceType,
    PlayerInfoExistPosition,
    ScreenType,
} from "src/store/models";
import shortColor from "src/utils/shortColor";

import X2Dialogs from "src/pages/game-new2/alerts/X2Dialogs";
import DrawDialog from "src/pages/game-new2/alerts/drawDialog";
import RematchDialog from "src/pages/game-new2/alerts/rematchAlerts";
import SelectFiguresAlertWrapper from "src/pages/game-new2/alerts/select-figure-alert";
import Chessboard from "src/pages/game-new2/layouts/components/center/chessboard-custom";
import WinOrLostAlert from "src/pages/game-new2/alerts/winOrLostAlerts/";

import {
    MobileGiveUpAlert,
    MobilePlayerThinking,
    MobileRematchDialogs,
    MobileWinOrLostAlerts,
    MobileX2Alert,
    TournamentsMatchResult,
} from "src/pages/game-new2/components/MobileAlerts";

import { MobileDrawDialogs } from "src/pages/game-new2/components/MobileAlerts/DrawDialogs";

import DicesWrapper from "src/pages/game-new2/components/dicesWrapper";
import RakeBackModal from "src/pages/game-new2/components/modals/RakeBackModal";

import StartMatch from "src/pages/game-new2/components/startMatch";

import {
    MAX_CHANGE_SCREEN_WIDTH,
    MIN_L_TABLET,
    MIN_XL_TABLET,
} from "src/pages/game-new2/constants";

import { useChessBoardProps } from "src/pages/game-new2/hooks/useChessBoardProps";

import { useStyles } from "src/pages/game-new2/layouts/layoutTypes/gameChessboardWrapper/styles";

const MobileGameChessboardWrapper = (props) => {
    const {
        gameStore,
        authStore,
        lobbyStore,
        tournamentsStore,
        achievementsStatStore,
    } = useStores();

    const {
        chessBoardWidth,
        chessBoardRef,
        isMultiTable,
        numberOfGames,
        isTwoGameTable,
    } = useChessBoardProps();
    const location = useLocation();
    const windowSize = useWindowSize();
    const screenStatus = useCheckFullScreen();
    const { showOpponentAcceptedDoublingDialog } = useDoublingStageStatus();
    const orientation = useOrientation();
    const isVisible = authStore.isVisibleWebApp;
    const soundPlayer = usePlayer();
    const changeScreenStyles = useMediaQuery(
        `(max-width: ${MAX_CHANGE_SCREEN_WIDTH}px)`
    );
    const L_Tablet = useMediaQuery(`(max-width: ${MIN_L_TABLET}px)`);
    const XL_Tablet = useMediaQuery(`(max-width: ${MIN_XL_TABLET}px)`);
    const isGamePage = useRouteMatch("/game/:id");
    const refBoard = useRef<HTMLInputElement>(null);
    const diceWrapperRef = useRef<HTMLDivElement>(null);

    const viewMode = gameStore.viewGameMode;
    const yourColor = useMemo(() => {
        return viewMode
            ? ColorVariant.black
            : gameStore.gameState.getOpponentColor();
    }, [viewMode, location.pathname, gameStore.gameId]);

    const myColor = useMemo(() => {
        return viewMode ? ColorVariant.white : gameStore.gameState.getMyColor();
    }, [viewMode, location.pathname, gameStore.gameId]);

    const gameContainerWidth =
        props.mobileTabletLayoutContainer?.current?.offsetWidth;
    const gameContainerHeight =
        props.mobileTabletLayoutContainer?.current?.offsetHeight;

    const user = authStore.currentUser;
    const appearanceTheme = useChangeTheme();

    const watchers = gameStore.guests;
    const resign = gameStore?.gameState?.gameResultData?.cause;
    const gameStage = gameStore?.currentGame?.tournament?.stage;

    const doubleThinking = gameStore.dialogs.oponentThinkingDialog.isOpen;
    const isWon = gameStore.gameState.isWon;
    const isShowWinOrLostDialog = gameStore.isShowWinOrLostDialog;
    const winner = gameStore?.gameState?.gameResultData?.winner;

    //
    const userCoinBalance = authStore.userCoinBalance;
    const userFreeCoinBalance = authStore.userFreeCoinBalance;
    const balanceType = gameStore.gameBalanceType;
    const dialogsAreHide = gameStore.dialogsAreHide;
    const showWatchersBlock = gameStore.getShowWatchersBlock;
    const gameSeries = gameStore.currentGame?.tournament?.gameSeries;
    const firstPlayer = gameStore.currentGame?.players?.[0]?.score;
    const secondPlayer = gameStore.currentGame?.players?.[1]?.score;
    const gameType = gameStore.currentGame?.type;
    const isMultiRoundGame =
        gameStore.currentGame?.settings?.rounds! > 1 ||
        gameType === GameModeType.TOURNAMENT;
    const isMatchGame = gameStore.currentGame?.settings?.rounds! > 1;
    const isTournamentGame = gameType === GameModeType.TOURNAMENT;
    const userRakeBack = authStore?.currentUser?.balance?.rakeBack;

    const currentChessBoardHeight = refBoard?.current?.clientHeight;

    const activeClock = gameStore.gameState.activeClock;
    //Тут And Now Try To create depend wich contain some comditions^ if viewMode changed, get some funtions

    const opponent = useMemo(() => {
        return viewMode
            ? gameStore.gameState.getPlayerByColor(ColorVariant.black)
            : gameStore.gameState.getPlayerByColor(yourColor);
    }, [viewMode, location.pathname, yourColor, gameStore.gameId]);

    const meOrWhitePlayer = useMemo(() => {
        return viewMode
            ? gameStore.gameState.getPlayerByColor(ColorVariant.white)
            : gameStore.gameState.getPlayerByColor(myColor);
    }, [viewMode, location.pathname, myColor, gameStore.gameId]);

    const currentPlayer = gameStore.gameState.getCurrentPlayer();
    const fen = gameStore.gameState.fen;

    const yourTime = useMemo(() => {
        return viewMode
            ? gameStore.gameState.playerTimeLeft(yourColor)
            : gameStore.gameState.opponentTimeLeft;
    }, [viewMode, yourColor, gameStore.gameId, activeClock]);

    const myTime = useMemo(() => {
        return viewMode
            ? gameStore.gameState.playerTimeLeft(myColor)
            : gameStore.gameState.myTimeLeft;
    }, [viewMode, myColor, gameStore.gameId, activeClock]);

    const isParticipateInTheGame =
        isGamePage && gameStore.gameState.getMyColor() !== ColorVariant.none;

    const [elemHeight, setHeight] = useState<number | undefined>();

    const [randomValue, setRandomValue] = useState<number>(1);
    const [isOpenWatchers, setOpenWatchers] = useState<
        boolean | WatchersStatusType
    >(false);
    const [chessBoardHeight, setChessBoardHeight] = useState<
        number | undefined
    >(undefined);
    const [uniqWatchers, setUniqWatchers] = useState<WatchingType[]>(watchers);
    const classes = useStyles({ elemHeight });

    const getAllMyParticipatingGames = lobbyStore.getParticipatingGames();

    const solidMultiGames = [
        ...getAllMyParticipatingGames,
        ...tournamentsStore.myGamesList,
    ].filter((game) => {
        return (
            game &&
            game.status === GameStatus.IN_PROGRESS &&
            !location.pathname.includes(game.id)
        );
    });

    const isActivePlayer = (color, clock) =>
        shortColor(color) === _get(clock, "color", "unknown");

    const killedPieces = useMemo(() => {
        return gameStore.gameState.killedPieces;
    }, [gameStore.gameState?.plainFen]);

    const calcFinalMatch = (resetFuntion?: () => void) => {
        if (gameStore.currentGame?.type === GameModeType.REGULAR) return false;
        if (gameSeries) {
            if (gameSeries - firstPlayer! === 0) {
                resetFuntion && resetFuntion();
                return true;
            }
            if (gameSeries - secondPlayer! === 0) {
                resetFuntion && resetFuntion();
                return true;
            }
            return false;
        }
        return false;
    };

    useEffect(() => {
        if (doubleThinking || showOpponentAcceptedDoublingDialog) {
            let generateRandomNumber = (Math.random() * 10) | 0;
            if (generateRandomNumber && randomValue === generateRandomNumber) {
                setRandomValue(9 - generateRandomNumber);
            } else {
                setRandomValue(generateRandomNumber);
            }
        }
    }, [doubleThinking, showOpponentAcceptedDoublingDialog]);

    useEffect(() => {
        if (Array.isArray(watchers) && watchers.length > 0) {
            const uniqWatchingObject = {
                //@ts-ignore
                ...[...new Set(watchers.map((guest) => guest.id))],
            };
            const uniqWatchingArray = watchers.filter(
                (guest, index) => guest.id === uniqWatchingObject[index]
            );
            setUniqWatchers(uniqWatchingArray);
        }
        if (Array.isArray(watchers) && watchers.length === 0) {
            setUniqWatchers([]);
        }
    }, [watchers.length]);

    useEffect(() => {
        if (isOpenWatchers === WatchersStatusType.hide) {
            setTimeout(() => {
                setOpenWatchers(false);
            }, 500);
        }
    }, [isOpenWatchers]);

    const getCurrentCashbox = useMemo(() => {
        if (balanceType === UserBalanceType.play) {
            return userCoinBalance;
        } else {
            return userFreeCoinBalance;
        }
    }, [balanceType]);

    const getCenterSize = <
        T extends number | undefined,
        U extends number | undefined
    >(
        gameContainerWidth: T,
        gameContainerHeight: U
    ): string => {
        if (gameContainerWidth && gameContainerHeight) {
            let calcSize = Math.min(gameContainerWidth, gameContainerHeight);
            if (gameContainerHeight - gameContainerWidth < 290) {
                return `${gameContainerHeight - 290}px`;
            }

            return `${calcSize}px`;
        }
        return "calc((100dvh - 80px) / 3)";
    };

    const getTopBottomSize = <
        T extends number | undefined,
        U extends number | undefined
    >(
        gameContainerWidth: T,
        gameContainerHeight: U
    ): string | undefined => {
        if (gameContainerWidth && gameContainerHeight) {
            if (gameContainerHeight / 3 >= gameContainerWidth) {
                return `${gameContainerWidth}px`;
            }
        }
        return;
    };

    const calcDiceSize = <
        T extends number | undefined,
        U extends number | undefined
    >(
        diceWrapperWidthParams: T,
        diceWrapperHeightParams: U
    ): number => {
        if (diceWrapperWidthParams && diceWrapperHeightParams) {
            return (
                Math.min(diceWrapperWidthParams / 3, diceWrapperHeightParams) *
                0.7
            );
        }
        return 50;
    };

    const centerSize = useMemo(
        () => getCenterSize(gameContainerWidth, gameContainerHeight),
        [gameContainerWidth, gameContainerHeight]
    );

    const topBottomSize = useMemo(
        () => getTopBottomSize(gameContainerWidth, gameContainerHeight),
        [gameContainerWidth, gameContainerHeight]
    );

    useEffect(() => {
        let actualSize = calcDiceSize(
            gameContainerWidth,
            diceWrapperRef?.current?.offsetHeight
        );
        gameStore.setPiecesSize(actualSize, ScreenType.mobile);
    }, [
        gameContainerWidth,
        gameContainerHeight,
        diceWrapperRef?.current?.offsetWidth,
        diceWrapperRef?.current?.offsetHeight,
    ]);

    if (window.innerWidth <= MIN_XL_TABLET) {
        return (
            <>
                {!isShowWinOrLostDialog && dialogsAreHide && (
                    <WatchingList
                        setOpen={(properties) => setOpenWatchers(properties)}
                        openStatus={WatchersStatusType.hide}
                        isOpen={isOpenWatchers}
                        watchingArray={uniqWatchers}
                        isMobileGamePage
                        elementPlace={PlayerInfoExistPosition.inProgressPreview}
                    />
                )}
                <Box
                    component="div"
                    // className={cn({
                    //     [classes.mainMobileGameContainerNew]: !resign,
                    // })}
                    className={cn(classes.mainMobileGameContainerNew)}
                    style={{ gridTemplateRows: `1fr ${centerSize} 1fr` }}
                >
                    <div
                        className={classes.mobileTabletTopBox}
                        style={{ height: topBottomSize }}
                    >
                        <div
                            className={cn(
                                classes.playerInfoWrapperNew,
                                classes.opponentInfoWrapperNew
                            )}
                        >
                            <Box
                                component="div"
                                className={cn(
                                    classes.presentAndNavigationBox,
                                    "top",
                                    {
                                        isTournamentGame: isTournamentGame,
                                        isMatchGame: isMatchGame,
                                    }
                                )}
                            >
                                {isMultiRoundGame && (
                                    <GameScoreRedesign
                                        winner={winner}
                                        stage={
                                            gameStore?.currentGame?.tournament
                                                ?.stage
                                        }
                                        title={
                                            gameStore?.currentGame?.tournament
                                                ?.title
                                        }
                                    />
                                )}
                                {XL_Tablet && isParticipateInTheGame && (
                                    <>
                                        <div className={classes.bankWrapperNew}>
                                            {gameStore.currentGame?.settings
                                                ?.rounds! === 1 &&
                                                gameType ===
                                                    GameModeType.REGULAR && (
                                                    <MobilePot
                                                        isGamePage={
                                                            !!isGamePage
                                                        }
                                                        chessboardType={
                                                            ChessboardType.mobileGame
                                                        }
                                                    >
                                                        <CoinAnimationContainer
                                                            balanceType={
                                                                balanceType
                                                            }
                                                            isMultiTable={
                                                                isMultiTable
                                                            }
                                                            chessboardType={
                                                                ChessboardType.mobileGame
                                                            }
                                                        />
                                                    </MobilePot>
                                                )}
                                        </div>
                                    </>
                                )}
                                {XL_Tablet &&
                                    isParticipateInTheGame &&
                                    gameStore.isShowWinOrLostDialog &&
                                    userRakeBack &&
                                    gameType === GameModeType.REGULAR && (
                                        <RakeBackModal />
                                    )}
                            </Box>

                            <Box
                                component="div"
                                className={cn(
                                    classes.playerInfoInnerWrapperNew,
                                    { viewMode, isMultiTable }
                                )}
                            >
                                {XL_Tablet && !viewMode && (
                                    <div
                                        className={cn(
                                            classes.dicesAndAlertHolderNew,
                                            {
                                                inProgress: isMultiRoundGame,
                                            }
                                        )}
                                    >
                                        <Box
                                            component="div"
                                            className={
                                                classes.dicesAlertsBoxNew
                                            }
                                        >
                                            {gameStore.dialogs.doublingDialog
                                                .isOpen &&
                                                !gameStore.dialogs.giveUpDialog
                                                    .isOpen &&
                                                !isShowWinOrLostDialog &&
                                                gameType ===
                                                    GameModeType.REGULAR && (
                                                    <MobileX2Alert
                                                        randomValue={
                                                            randomValue
                                                        }
                                                    />
                                                )}
                                            {gameStore.dialogs
                                                .oponentThinkingDialog.isOpen &&
                                                !gameStore.dialogs.giveUpDialog
                                                    .isOpen &&
                                                !isShowWinOrLostDialog &&
                                                !gameStore.gameState.hasSpecialActivity() &&
                                                gameType ===
                                                    GameModeType.REGULAR &&
                                                opponent && (
                                                    <MobilePlayerThinking
                                                        player={opponent}
                                                        randomValue={
                                                            randomValue
                                                        }
                                                    />
                                                )}
                                            {gameStore.dialogs.giveUpDialog
                                                .isOpen &&
                                                !isShowWinOrLostDialog &&
                                                gameType ===
                                                    GameModeType.REGULAR && (
                                                    <MobileGiveUpAlert />
                                                )}
                                            {gameStore.dialogs.rematchDialog
                                                .isOpen &&
                                                !isShowWinOrLostDialog &&
                                                gameType ===
                                                    GameModeType.REGULAR && (
                                                    <MobileRematchDialogs />
                                                )}
                                            {isShowWinOrLostDialog &&
                                                gameType ===
                                                    GameModeType.REGULAR && (
                                                    <MobileWinOrLostAlerts />
                                                )}
                                            {gameStore.dialogs.drawDialog
                                                .isOpen &&
                                                !isShowWinOrLostDialog &&
                                                gameType ===
                                                    GameModeType.REGULAR && (
                                                    <MobileDrawDialogs />
                                                )}
                                            {gameStore.dialogs.drawDialog
                                                .isOpen &&
                                                !isShowWinOrLostDialog &&
                                                gameType ===
                                                    GameModeType.REGULAR && (
                                                    <MobileDrawDialogs />
                                                )}
                                        </Box>
                                        <div
                                            ref={diceWrapperRef}
                                            className={cn(
                                                classes.dicesWrapperNew,
                                                {
                                                    isTournamentGame:
                                                        isMultiRoundGame,
                                                    isShowWinOrLostDialog:
                                                        isShowWinOrLostDialog &&
                                                        isMultiRoundGame,
                                                }
                                            )}
                                        >
                                            {!isShowWinOrLostDialog &&
                                                !gameStore.dialogs.rematchDialog
                                                    .isOpen &&
                                                gameStore.gameIsStart &&
                                                !calcFinalMatch() && (
                                                    <DicesWrapper
                                                        rndPrefix={
                                                            props.rndPrefix
                                                        }
                                                        diceClickRnd={
                                                            props.diceClickRnd
                                                        }
                                                        standOffColor={
                                                            props.standOffColor
                                                        }
                                                        myColor={myColor}
                                                        isMultiTable={
                                                            isMultiTable
                                                        }
                                                    />
                                                )}

                                            {!isShowWinOrLostDialog &&
                                                !viewMode &&
                                                !gameStore.dialogs.rematchDialog
                                                    .isOpen &&
                                                !calcFinalMatch() && (
                                                    <StartMatch
                                                        rolled={
                                                            gameStore.gameIsStart
                                                        }
                                                        elemHeight={
                                                            elemHeight &&
                                                            elemHeight - 10
                                                        }
                                                        isMultitable={
                                                            isMultiTable
                                                        }
                                                    />
                                                )}
                                            {gameType ===
                                                GameModeType.TOURNAMENT &&
                                                typeof gameStage === "number" &&
                                                gameStage > 1 &&
                                                calcFinalMatch() && (
                                                    <TournamentsMatchResult
                                                        gameStage={gameStage}
                                                        winner={winner}
                                                        myColor={myColor}
                                                    />
                                                )}
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={cn(classes.playerInfoBoxNew, {
                                        [classes.viewModePlayerInfoBoxNew]:
                                            viewMode,
                                        isMultiTable,
                                    })}
                                    style={{
                                        width: centerSize,
                                        minWidth: 320,
                                    }}
                                >
                                    {opponent && (
                                        <PlayerInfoRedesign
                                            resign={resign}
                                            winner={winner}
                                            noTimerSound
                                            avatar={opponent.avatar}
                                            name={opponent.name || "Unknown"}
                                            initTime={yourTime}
                                            activeClock={activeClock}
                                            active={isActivePlayer(
                                                yourColor,
                                                activeClock
                                            )}
                                            killedPieces={killedPieces[myColor]}
                                            color={yourColor}
                                            isSystemAvatar={
                                                opponent.isSystemAvatar
                                            }
                                            isMultiTable={isMultiTable}
                                            isTwoGameTable={isTwoGameTable}
                                            isOpponent={true}
                                            opponent={opponent}
                                            elementPlace={
                                                PlayerInfoExistPosition.inGame
                                            }
                                        />
                                    )}
                                </div>
                            </Box>
                        </div>
                    </div>
                    <div className={classes.mobileTabletCenterBox}>
                        <Chessboard
                            marginTop={0}
                            marginBottom={0}
                            border
                            fen={fen.split(" ")[0]}
                            myColor={myColor}
                            viewMode={viewMode}
                            isMultiTable={false}
                        />
                    </div>
                    <div
                        className={classes.mobileTabletBottomBox}
                        style={{ height: topBottomSize }}
                    >
                        <div className={cn(classes.playerInfoWrapperNew)}>
                            <Box
                                component="div"
                                className={cn(
                                    classes.playerInfoInnerWrapperNew,
                                    { viewMode, isMultiTable }
                                )}
                            >
                                <div
                                    className={cn(
                                        classes.playerInfoBoxNew,
                                        classes.myInfoWrapper
                                    )}
                                    style={{
                                        width: centerSize,
                                        minWidth: 320,
                                    }}
                                >
                                    {meOrWhitePlayer && (
                                        <PlayerInfoRedesign
                                            resign={resign}
                                            winner={winner}
                                            avatar={meOrWhitePlayer.avatar}
                                            name={
                                                meOrWhitePlayer.name ||
                                                "Unknown"
                                            }
                                            initTime={myTime}
                                            activeClock={activeClock}
                                            active={isActivePlayer(
                                                myColor,
                                                activeClock
                                            )}
                                            killedPieces={
                                                killedPieces[yourColor]
                                            }
                                            color={myColor}
                                            reverse
                                            userCashBox={getCurrentCashbox}
                                            isSystemAvatar={
                                                user?.isSystemAvatar
                                            }
                                            isTournamentGame={
                                                gameType ===
                                                GameModeType.TOURNAMENT
                                            }
                                            isMultiTable={isMultiTable}
                                            isTwoGameTable={isTwoGameTable}
                                            elementPlace={
                                                PlayerInfoExistPosition.inGame
                                            }
                                        />
                                    )}
                                </div>
                                {XL_Tablet && !viewMode && (
                                    <div
                                        className={cn(classes.alertHolder, {
                                            showWatchersBlock,
                                        })}
                                    >
                                        {gameStore.dialogs.selectFiguresDialog
                                            .isOpen &&
                                            !isShowWinOrLostDialog &&
                                            !gameStore.dialogs.rematchDialog
                                                .isOpen && (
                                                <SelectFiguresAlertWrapper
                                                    isTabletMobile
                                                    setNoanimation={() => {}}
                                                />
                                            )}

                                        {gameStore.dialogs.doublingDialog
                                            .isOpen &&
                                            !gameStore.dialogs.giveUpDialog
                                                .isOpen &&
                                            !isShowWinOrLostDialog && (
                                                <X2Dialogs
                                                    showOpponentAcceptedDoubling={
                                                        false
                                                    }
                                                    isTabletMobile
                                                />
                                            )}
                                        {gameStore.dialogs.drawDialog.isOpen &&
                                            !isShowWinOrLostDialog && (
                                                <DrawDialog isTabletMobile />
                                            )}
                                        {isShowWinOrLostDialog && (
                                            <div
                                                className={cn(
                                                    classes.alertHolderAnimate
                                                )}
                                            >
                                                <WinOrLostAlert
                                                    opponent={opponent}
                                                    isTabletMobile
                                                />
                                            </div>
                                        )}
                                        {gameStore.dialogs.rematchDialog
                                            .isOpen &&
                                            !isShowWinOrLostDialog && (
                                                <RematchDialog isTabletMobile />
                                            )}
                                    </div>
                                )}
                            </Box>
                            <Box
                                component="div"
                                className={cn(
                                    classes.presentAndNavigationBox,
                                    "bottom"
                                )}
                            >
                                {!isShowWinOrLostDialog && dialogsAreHide && (
                                    <ActionAndReturn
                                        setOpen={(properties) =>
                                            setOpenWatchers(properties)
                                        }
                                        openStatus={WatchersStatusType.show}
                                        isOpen={isOpenWatchers}
                                        watchingArray={uniqWatchers}
                                        solidMultiGames={solidMultiGames}
                                        isMobileGamePage={true}
                                        elementPlace={
                                            PlayerInfoExistPosition.inGame
                                        }
                                    />
                                )}
                            </Box>
                        </div>
                    </div>
                </Box>
            </>
        );
    }
};

export default observer(MobileGameChessboardWrapper);
