import { makeStyles } from "@mui/styles";

import theme from "src/theme";
import pattern from "src/assets/pattern.png";
import { appColors } from "src/consts/app-config";

export const useStyles = makeStyles({
    dialog: {
        [theme.breakpoints.down(500)]: {
            "& .MuiDialog-scrollPaper": {
                alignItems: "flex-end",
            },
        },
    },
    dialogPaperInviteFriend: {
        maxWidth: 680,
        width: "100%",
        border: "1px solid #0B0C12",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "25px 10px",
        margin: 0,
        overflowY: "unset",
        backgroundColor: "#17191F !important",
        borderRadius: "50px",
        gridGap: "20px",
        [theme.breakpoints.down("md")]: {
            maxWidth: "95%",
        },
        [theme.breakpoints.down(350)]: {
            padding: 18,
        },
    },
    pattern: {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        opacity: 0.5,
        mixBlendMode: "soft-light",
        zIndex: 0,
        backgroundImage: `url(${pattern})`,
    },

    newGameText: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "130%",
        color: appColors.white,
    },
    gameFiltersHolder: {
        width: "80%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridColumnGap: "12px",
        marginTop: "12px",
        "& .bet-filter-popup": {
            maxHeight: 420,
        },
        "@media screen and (min-width: 768px) and (max-height: 785px)": {
            "& .bet-filter-popup": {
                maxHeight: 390,
            },
        },
        "@media screen and (max-width: 768px) and (max-height: 785px)": {
            "& .bet-filter-popup": {
                maxHeight: 370,
            },
        },
        "@media screen and (max-width: 768px) and (max-height: 665px)": {
            "& .bet-filter-popup": {
                maxHeight: 335,
            },
        },
        "@media screen and (max-width: 768px) and (max-height: 600px)": {
            "& .bet-filter-popup": {
                maxHeight: 310,
            },
        },
        "@media screen and (max-width: 500px) and (min-height: 785px)": {
            "& .bet-filter-popup": {
                maxHeight: "unset",
            },
        },
        [theme.breakpoints.down(500)]: {
            width: "90%",
        },
        [theme.breakpoints.down(375)]: {
            width: "100%",
        },
    },
});
