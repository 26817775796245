import { Subject } from "rxjs";

const errorSubject = new Subject();
const infoSubject = new Subject();
const warningSubject = new Subject();
const userRejectedSubject = new Subject();
const notificationSubject = new Subject();

export const errorService = {
    sendError: (error) => errorSubject.next(error),
    getError: () => errorSubject.asObservable(),
};

export const infoService = {
    sendInfo: (info) => infoSubject.next(info),
    getInfo: () => infoSubject.asObservable(),
};

export const warningService = {
    sendWarning: (warning) => warningSubject.next(warning),
    getWarning: () => warningSubject.asObservable(),
};

export const notificationService = {
    sendNotification: (text: string, icon: string) =>
        notificationSubject.next({ text, icon }),
    getNotification: () => notificationSubject.asObservable(),
};
