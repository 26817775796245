import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    wrapper: {
        background: 'rgba(26, 23, 27, 0.9)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        zIndex: 2,
        maxWidth: 280,
        width: '100%',
        borderRadius: 16,
        padding: '16px 10px',
        justifySelf: 'center',
        '@media screen and (min-width: 1251px) and (max-height: 740px)': {
            maxWidth: 250,
        },
    },

    text: {
        fontWeight: 700,
        fontSize: 16,
        textAlign: 'center',
        '&.gray': {
            color: '#798097',
        },
        '&.gold': {
            background: 'linear-gradient(180deg, #F8BF44 0%, #A16529 100%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            backgroundClip: 'text',
            'text-fill-color': 'transparent',
        },
        '&.green': {
            background:
                'linear-gradient(19.92deg, #158F80 13.3%, #50BAAE 86.7%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
            backgroundClip: 'text',
            'text-fill-color': 'transparent',
        },
    },
    message: {
        margin: '5px 0 0 0',
    },
});
