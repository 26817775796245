import { useState, useEffect } from "react";
import { io, Socket } from "socket.io-client";

import { Nullable } from "src/shared/types";

export const useSocket = (params?: { token: string; id?: string }) => {
    const [host, setHost] = useState<Nullable<string>>();
    const [socket, setSocket] = useState<Socket | null>(null);

    useEffect(() => {
        let gameId: string | undefined = undefined;

        if (location.href.includes("/game/")) {
            const gameForSearch = location.href.split("/").slice(-1)[0];
            gameId = gameForSearch;
        }

        if (!host) return;
        const t = io(host, {
            // forceNew: true,
            //withCredentials: true,
            // query: {
            //     Authorization: `Bearer ${params?.token}`,
            // },
            query: {
                userKey: params?.token,
                place: 2,
                gameId,
            },
        });
        setSocket(t);
        return () => {
            if (socket) {
                socket.removeAllListeners();
                socket.disconnect();
                setSocket(null);
            }
        };
    }, [host]);

    const initSocket = (host: Nullable<string>) => {
        setHost(host);
    };
    const disconnectSocket = () => {
        setHost(null);
        if (socket) {
            socket.removeAllListeners();
            socket.disconnect();
            setSocket(null);
        }
    };
    return [initSocket, socket, disconnectSocket] as [
        (host: Nullable<string>) => void,
        Socket,
        () => void
    ];
};
