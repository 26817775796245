import { recoverPassword } from "./../service/api/auth/index";
export const endpointsRoot = process.env.REACT_APP_API_ENDPOINT;

const endpoints = {
    getUser: `${endpointsRoot}/api/3/user`,
    createGameNew: `${endpointsRoot}/api/3/invite/create-free`,
    sendInvite: `${endpointsRoot}/api/3/invite/send-invite`,
    applyNormalInvite: `${endpointsRoot}/api/3/invite/apply-normal-invite`,
    declineNormalInvite: `${endpointsRoot}/api/3/invite/decline-normal-invite`,
    applyFreeInvite: `${endpointsRoot}/api/3/invite/apply-free-invite`,
    login: `${endpointsRoot}/api/3/auth/sign-in`,
    auth: `${endpointsRoot}/api/3/auth/sign-up`,
    recoveryPasswordNew: `${endpointsRoot}/api/3/auth/recover-password`,
    resetPasswordNew: `${endpointsRoot}/api/3/auth/reset-password`,

    initGame: `${endpointsRoot}/game`,
    gameMove: `${endpointsRoot}/api/3/game/move`,
    gameRoll: `${endpointsRoot}/api/3/game/roll`,
    gameBet: `${endpointsRoot}/api/3/game/bet`,
    gameApplyBet: `${endpointsRoot}/api/3/game/apply-bet`,
    gameMessage: `${endpointsRoot}/api/3/game/message`,
    addFriend: `${endpointsRoot}/api/3/user-friend/add`,
    removeFriend: `${endpointsRoot}/api/3/user-friend/remove`,

    loginByToken: `${endpointsRoot}/api/v2/loginByToken`,
    logout: `${endpointsRoot}/api/v2/logout`,
    locale: `${endpointsRoot}/api/v2/locale`,
    lobby: `${endpointsRoot}/api/v2/lobby`,
    diceLobby: `${endpointsRoot}/api/v2/diceChessLobby`,
    games: `${endpointsRoot}/api/v2/games`,
    createGame: `${endpointsRoot}/api/v2/game`,
    getActiveGames: `${endpointsRoot}/api/v2/activeGames`,
    game: `${endpointsRoot}/api/v2/game`,
    deleteGame: `${endpointsRoot}/api/v2/game`,
    account: `${endpointsRoot}/api/v2/account`,
    editAccount: `${endpointsRoot}/api/v2/account/edit`,
    friends: `${endpointsRoot}/api/v2/friends`,
    deleteFriend: `${endpointsRoot}/api/v2/friends/delete`,
    acceptFriendRequest: `${endpointsRoot}/api/v2/friends/accept-request`,
    rejectFriendRequest: `${endpointsRoot}/api/v2/friends/reject-request`,
    cancelFriendRequest: `${endpointsRoot}/api/v2/friends/cancel-request`,
    users: `${endpointsRoot}/api/v2/users`,
    searchUsers: `${endpointsRoot}/api/v2/users`,
    markLocaleAsUsed: `${endpointsRoot}/api/v2/locale/markAsUsed`,
    gamesHistory: `${endpointsRoot}/api/v2/account/my-games`,
    register: `${endpointsRoot}/api/v2/registration`,
    changePassword: `${endpointsRoot}/api/v2/change-password`,
    changeNickname: `${endpointsRoot}/api/v2/change-nickname`,
    changeUserStatus: `${endpointsRoot}/api/v2/change-status`,
    takeRakeBack: `${endpointsRoot}/api/v2/take-rakeback`,
    checkEmail: `${endpointsRoot}/api/v2/check-email`,
    recoveryPassword: `${endpointsRoot}/api/v2/recovery-password`,
    resetPassword: `${endpointsRoot}/api/v2/reset-password`,
    lobbySocket: `${endpointsRoot}/lobby`,
    localeSocket: `${endpointsRoot}/locale`,
    mainSocket: `${endpointsRoot}/mainNamespace`,
    uploadAvatar: `${endpointsRoot}/api/v2/avatar`,
    uploadStaticAvatar: `${endpointsRoot}/api/v2/updateAvatarById`,
    updateProfile: `${endpointsRoot}/api/v2/profile`,
    updateBalanceHidden: `${endpointsRoot}/api/v2/profile/balanceHidden`,
    getPaymentForm: `${endpointsRoot}/api/v2/payments/getPaymentForm`,
    getPayoutForm: `${endpointsRoot}/api/v2/payments/getPayoutForm`,
    getAvatarList: `${endpointsRoot}/api/v2/libs/getAvatarsList`,
    interkassaCreatePayout: `${endpointsRoot}/api/v2/payments/interkassa/payout`,
    cryptoCreatePayout: `${endpointsRoot}/api/v2/payments/crypto/payout`,
    paymentsList: `${endpointsRoot}/api/v2/payments/getPaymentsList`,
    createWithdraw: `${endpointsRoot}/api/v2/payments/withdraw`,
    avatarPath: `${endpointsRoot}/avatars/`,
    usersList: `${endpointsRoot}/api/v2/libs/getUsersSuggestions`,
    tournamentSocket: `${endpointsRoot}/tournaments`,
    createTournament: `${endpointsRoot}/api/v2/create-tournament`,
    getTournaments: `${endpointsRoot}/api/v2/tournaments`,
    getCurrentTournament: `${endpointsRoot}/api/v2/tournament/`,
    joinTournaments: `${endpointsRoot}/api/v2/join-tournament`,
    cancelJoinTournaments: `${endpointsRoot}/api/v2/tournaments/cancel-join`,
    removeAllTournaments: `${endpointsRoot}/api/v2/tournaments`,
    getTopPlayers: `${endpointsRoot}/api/v2/tournaments/topPlayers`,
    lastVersion: `${endpointsRoot}/api/v2/versions`,
    changeBackGroundColor: `${endpointsRoot}/api/v2/profile/changeBackgroundAndDiceColor`,
    getSupportMessages: `${endpointsRoot}/api/v2/support/getMessages`,
    supportMessage: `${endpointsRoot}/api/v2/support/message`,
    supportFilesMessage: `${endpointsRoot}/api/v2/support/files-message`,
    supportMedia: `${endpointsRoot}/support-attachments`,
    alg1xBetGetUrl: `${endpointsRoot}/api/v2/externalGames/1xbet/getUrl`,
    algAltenteGetUrl: `${endpointsRoot}/api/3/external-game/open-game`,
    algLiveDealerGames: `${endpointsRoot}/api/3/external-game/live-dealers`,
    algAltenteGames: `${endpointsRoot}/api/3/external-game/slots`,
    toggleExternalGameFavorite: `${endpointsRoot}/api/v2/externalGames/favorites`,
    getJackpokerUrl: `${endpointsRoot}/api/3/external-game/open-poker`,
    getPaymentMethods: `${endpointsRoot}/api/v2/payment-methods`,
    purchaseCoins: `${endpointsRoot}/api/v2/payments/purchaseCoins`,
    cancelWithdraw: `${endpointsRoot}/api/v2/payments/cancelWithdraw`,
    getReferralStatistic: `${endpointsRoot}/api/v2/referral-program/getMyStatistics`,
    getReferralUsers: `${endpointsRoot}/api/v2/referral-program/getMyUsers`,
    getReferralWithdraw: `${endpointsRoot}/api/v2/referral-program/withdrawRevenue`,
    getJackpot: `${endpointsRoot}/api/v2/jackpot`,
    businessMessage: `${endpointsRoot}/api/v2/support/sendBusinessOpportunity`,
    userVerificationInfo: `${endpointsRoot}/api/v2/account/update-verification-info`,
    userVerificationPhoto: `${endpointsRoot}/api/v2/account/update-verification-info-photo-url`,
    getMyNotifications: `${endpointsRoot}/api/v2/my-notifications`,
    markNotificationAsViewed: `${endpointsRoot}/api/v2/notifications/viewed`,
    banners: `${endpointsRoot}/api/v2/banners`,
    media: `${endpointsRoot}/media`,
    getBonuses: `${endpointsRoot}/api/v2/rank-bonus`,
    impersonateUser: `${endpointsRoot}/api/v2/impersonate-user`,
    variables: `${endpointsRoot}/api/v2/variables`,
    gameChatSettings: `${endpointsRoot}/api/v2/account/update-right-block-mode`,
    gameChatStickers: `${endpointsRoot}/api/v2/sticker-packs`,
    externalGameProviders: `${endpointsRoot}/api/3/external-game/providers`,
    opponentSettings: `${endpointsRoot}/api/v2/user-to-user-settings`,
    myOpponentsSettings: `${endpointsRoot}/api/v2/my-user-to-user-settings`,
    meOpponentsSettings: `${endpointsRoot}/api/v2/user-to-user-settings-to-me`,
    logger: `${endpointsRoot}/api/v2/libs/logger`,

    P2PInvoice: `${endpointsRoot}/api/v2/payments/p2p/invoice`,
    cassyInvoice: `${endpointsRoot}/api/v2/payments/cassy/invoice`,
    setP2PInvoiceAsPaid: `${endpointsRoot}/api/v2/payments/p2p/invoice/user-paid`,
    setP2PInvoiceAsCantPay: `${endpointsRoot}/api/v2/payments/p2p/invoice/user-cant-pay`,
    p2pWithdrawal: `${endpointsRoot}/api/v2/payments/p2p/withdrawal`,
    cassyWithdrawal: `${endpointsRoot}/api/v2/payments/cassy/withdrawal`,

    betting: `${endpointsRoot}/api/3/external-game/open-betting`,
};

export default endpoints;
