import { makeStyles } from "@mui/styles";
import { animations } from "./animations";

interface IUseStylesParams {
    cubeWidth: number;
    cubeHeight: number;
    cubeDepth: number;
    innerOffset: number;
}

export const useStyles = makeStyles({
    diceContainer: {
        perspective: 600,
        margin: "0 auto",
        height: "100%",
    },
    figure: { width: 120, height: 120 },

    space3d: (props: IUseStylesParams) => ({
        textAlign: "center",
        margin: "0 auto",
        display: "flex",
        height: "100%",
        aspectRatio: "1 / 1",
    }),

    _3dbox: () => ({
        display: "inline-block",
        textAlign: "center",
        position: "relative",
        height: "100%",
        containerType: "size",
        aspectRatio: "1 / 1",
        transformStyle: "preserve-3d",
    }),

    _3dface: (props) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        position: "absolute",
        lineHeight: props.cubeWidth,
        borderRadius: 16,
        "& > svg": {
            height: "100%",
            width: "100%",
        },
    }),
    _3dface__front: (props) => ({
        height: "100cqh",
        aspectRatio: "1 / 1",
        transform: `translate3d(0, 0, 50cqh)`,
    }),

    _3dface__bottom: (props) => ({
        height: "100cqh",
        aspectRatio: "1 / 1",
        transform: `rotateX(-90deg) translate3d(0, 0, 50cqh)`,
    }),

    inner: (props) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        overflow: "hidden",
        willСhange: "transform",
        backfaceVisibility: "hidden",
        borderRadius: 5,
        "&.golden": {
            boxShadow: "inset 0 0 140px #9F6013",
            background:
                "linear-gradient(44.74deg, #8E4A06 17.08%, #B1701F 41.85%, #F8DD79 53.68%, #9F6013 80.15%)",
        },
        "&.classic": {
            borderRadius: 6,
            boxShadow: "inset 0 0 140px #d8d9dd",
            backgroundColor: "#d8d9dd",
        },
    }),

    _3dface__front__inner: (props) => ({
        height: "100cqh",
        aspectRatio: "1 / 1",
        transform: `translate3d(0, 0, calc(50cqh - ${props.innerOffset}px))`,
    }),
    _3dface__top: (props) => ({
        height: "100cqh",
        aspectRatio: "1 / 1",
        transform: `rotateX(90deg) translate3d(0, 0, calc(50cqh - ${props.innerOffset}px))`,
    }),

    _3dface__top__inner: (props) => ({
        height: "100cqh",
        aspectRatio: "1 / 1",
        transform: `rotateX(90deg) translate3d(0, 0, calc(50cqh - ${props.innerOffset}px))`,
    }),
    _3dface__bottom__inner: (props) => ({
        height: "100cqh",
        aspectRatio: "1 / 1",
        transform: `rotateX(-90deg) translate3d(0, 0, calc(50cqh - ${props.innerOffset}px))`,
    }),

    _3dface__left: (props) => ({
        // width: props.cubeDepth,
        // height: props.cubeHeight,
        // transform: `rotateY(-90deg) translate3d(0, 0, ${
        //     props.cubeWidth / 2
        // }px)`,
        // marginLeft: -props.cubeDepth / 2,
        height: "100cqh",
        aspectRatio: "1 / 1",
        left: "50%",
        marginLeft: "-50cqh",
        transform: `rotateY(-90deg) translate3d(0, 0, 50cqh)`,
    }),
    _3dface__right: (props) => ({
        height: "100cqh",
        aspectRatio: "1 / 1",
        left: "50%",
        marginLeft: "-50cqh",
        transform: `rotateY(90deg) translate3d(0, 0, 50cqh)`,
    }),

    _3dface__back: (props) => ({
        height: "100cqh",
        aspectRatio: "1 / 1",
        transform: `rotateY(180deg) translate3d(0, 0, 50cqh)`,
    }),

    _3dface_left_inner: (props) => ({
        height: "100cqh",
        aspectRatio: "1 / 1",
        left: "50%",
        marginLeft: "-50cqh",
        transform: `rotateY(-90deg) translate3d(0, 0, calc(50cqh - ${props.innerOffset}px))`,
    }),

    _3dface__right__inner: (props) => ({
        height: "100cqh",
        aspectRatio: "1 / 1",
        left: "50%",
        marginLeft: "-50cqh",
        transform: `rotateY(90deg) translate3d(0, 0, calc(50cqh - ${props.innerOffset}px))`,
    }),

    _3dface__back__inner: (props) => ({
        height: "100cqh",
        aspectRatio: "1 / 1",
        transform: `rotateY(180deg) translate3d(0, 0, calc(50cqh - ${props.innerOffset}px))`,
    }),

    space3dAnimation: {
        perspective: "none",
        animationName: "$space3dAnimation",
    },
    "@keyframes space3dAnimation": {
        ...animations[2],
    },
});
