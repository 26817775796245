import React, { useEffect, useState } from 'react';
import { usePageVisibility } from 'react-page-visibility';

const useInitRematchTimer = (
    serverTime: string | number | undefined,
    timer: number
) => {
    const isVisible = usePageVisibility();
    const [actualTimer, setActualTimer] = useState<number>(10);

    useEffect(() => {
        if (!serverTime || !timer) return;
        let serverTimeNumber =
            typeof serverTime === 'number'
                ? serverTime
                : Date.parse(serverTime);
        let currentDate = +new Date();
        const validTime = timer - (currentDate - serverTimeNumber);
        isVisible && validTime < 1
            ? setActualTimer(0)
            : setActualTimer(Math.floor(validTime / 1000));
    }, [isVisible]);

    if (serverTime && timer) {
        return actualTimer;
    }
    return 10;
};

export default useInitRematchTimer;
