import React from "react";
import { Box } from "@mui/material";
import cn from "classnames";

import { useLocale } from "src/providers/LocaleProvider";
import useStores from "src/hooks/useStores";
import { UserBalanceType } from "src/store/models";
import useChangeTheme from "src/hooks/useChangeTheme";

import useStyles from "./styles";
interface IMobilePot {
    isGamePage: boolean;
    chessboardType: string;
    children: any;
}

const MobilePot = ({ children }: IMobilePot) => {
    const {
        game: { pot },
    } = useLocale();
    const { gameStore } = useStores();
    const appearanceTheme = useChangeTheme();

    const styles = useStyles({});
    const resultData = gameStore?.initData?.over;
    const balanceType = gameStore?.currentGame?.balanceType;
    // if(!resultData) return null;

    return (
        <>
            <Box
                className={cn(styles.potToTop, {
                    runToMid: resultData,
                })}
            >
                <span className={cn(styles.bankTitle, appearanceTheme)}>
                    {pot("Pot")}
                </span>
                {children}
                <span
                    className={
                        balanceType === UserBalanceType.play
                            ? cn(styles.bankAmount, appearanceTheme)
                            : cn(styles.bankFreeAmount, appearanceTheme)
                    }
                >
                    {gameStore?.isReady ? gameStore?.gameState.bank : "0"}
                </span>
            </Box>
        </>
    );
};

export default MobilePot;
