import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useLocale } from 'src/providers/LocaleProvider';

import { ButtonType } from '../../../../components/buttons/greenButton';
import useStores from '../../../../hooks/useStores';
import { usePlayer } from '../../../../sounds/use-player';
import GeneralGameDialogNew from '../common/generalDialogNew';

const X2Dialog = ({ isXlTablet, isMultiTable = false }) => {
    const soundPlayer = usePlayer();

    const {
        game: {
            dialogs: {
                x2: { title, approve, cancel, message },
            },
        },
    } = useLocale();

    const { gameStore } = useStores();
    const aggressor = gameStore.initData?.doubling.aggressor;
    if (!aggressor) return null;
    const player = gameStore.gameState.getPlayerByColor(aggressor);
    if (!player) return null;

    const onApprove = () => {
        gameStore.dialogs.sendDouble();
        // gameStore.dialogs.closeDoublingDialog();
        soundPlayer('bidConfirmSoundPlay');
    };

    const onReject = () => {
        gameStore.dialogs.confirmGiveUp();
        soundPlayer('lostSoundPlay');
    };

    return (
        <GeneralGameDialogNew
            avatar={player.avatar}
            noButtons={false}
            onApprove={onApprove}
            onCancel={onReject}
            title={title("Let's double?")}
            message={message.compile(
                { amount: gameStore.gameState.userBet * 2 },
                `Rise up to {{amount}} or resign`
            )}
            approveButtonType={ButtonType.primary}
            approveText={approve('Double2')}
            cancelText={cancel('Resign')}
            isSystemAvatar={player.isSystemAvatar}
            isXlTablet={isXlTablet}
            isMultiTable={isMultiTable}
        />
    );
};

export default observer(X2Dialog);
