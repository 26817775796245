import { breakpoints } from "./../../../../../shared/helpers/ThemeHelper/constants";
import { makeStyles } from "@mui/styles";
import { buttonSizes } from "../styles";
import { MAX_CHANGE_SCREEN_WIDTH } from "../../../constants";
import theme from "src/theme";
import chipMain from "src/assets/icons/gameChips/chipButton.svg";
import quaterChip from "src/assets/icons/gameChips/quarterChip.svg";
import halfChip from "src/assets/icons/gameChips/halfChip.svg";
import threeFourthsChip from "src/assets/icons/gameChips/threeFourthsChip.svg";
import maxChip from "src/assets/icons/gameChips/maxChip.svg";

export const useStyles = makeStyles(() => ({
    chipButtonMain: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        borderRadius: "50%",
        width: "100%",
        height: "100%",
        backgroundImage: `url(${chipMain})`,
        backgroundSize: "cover",
        zIndex: 1,
        "&.open": {},
    },
    chipButton: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        width: "75%",
        height: "75%",
        color: "white",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%) scale(0)",
        zIndex: -1,
        transition: "all 0.25s",
        backgroundSize: "cover",

        "&.maxChip": {
            transitionDelay: "0ms",
            zIndex: 1,
            transform: "translate(-80%, -190%) scale(1)",
            transition: "all 0.25s",
        },
        "&.threeFourthsChip": {
            transitionDelay: "150ms",
            zIndex: 1,
            transform: "translate(-170%, -110%) scale(1)",
            transition: "all 0.25s",
        },
        "&.halfChip": {
            transitionDelay: "300ms",
            zIndex: 1,
            transform: "translate(-170%, 10%) scale(1)",
            transition: "all 0.25s",
        },
        "&.quarterChip": {
            transitionDelay: "450ms",
            zIndex: 1,
            transform: "translate(-80%, 90%) scale(1)",
            transition: "all 0.25s",
        },
    },
}));
