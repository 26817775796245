import { makeStyles } from "@mui/styles";
import theme from "src/theme";

export const useStyles = makeStyles({
    wrapper: {
        position: "absolute",
        color: "rgba(255, 255, 255, 0.5)",
        "@media screen and (max-width: 768px)": {
            color: "#000",
        },
    },
    verticalWrapper: ({ borderWidth }: { borderWidth: number }) => ({
        // top: -12,
        // bottom: -12,
        // right: -12,
        // left: -borderWidth,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        "@media screen and (max-width: 768px)": {
            left: 0,
            top: 0,
            bottom: 0,
        },
        "&.isMultiTable": {
            left: 0,
            top: -10,
            bottom: -10,
            right: 0,
            color: "#000",
        },
    }),

    horizontalWrapper: ({ borderWidth }: { borderWidth: number }) => ({
        bottom: 0,
        // right: -12,
        // left: -borderWidth,
        right: 0,
        left: 0,
        width: "100%",
        height: "12.5%",

        "&.isMultiTable": {
            left: 5,
            bottom: 7,
            right: -23,
            color: "#000",
        },
    }),
});
