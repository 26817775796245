export enum EFilter {
    all = "all",
    popular = "popular",
    new = "new",
    favorites = "favorites",
    providers = "providers",
}

export enum EExternalProviderType {
    algNet = "algNet",
    evenBet = "evenBet",
}

export interface ISlotGame {
    id: string;
    name: string;
    imageUrl: string;
    isNew: boolean;
    isPopular: boolean;
    isFavorite: boolean;
    providerId: string;
}

export interface IGetSlotsResponse {
    id: string;
    name: string;
    image: string;
    isNew: boolean;
    isPopular: boolean;
    isFavorite: boolean;
    providerId: string;
}

export interface ILiveDealerGame {
    id: string;
    name: string;
    isOpened: boolean;
    generalMin: number;
    generalMax: number;
    lastWins: number[];
    imageUrl: string;
    isNew: boolean;
    isPopular: boolean;
    isFavorite: boolean;
    providerId: string;
}

export interface IGetLiveDealerGameResponse {
    id: string;
    name: string;
    isOpened: boolean;
    general_min: number;
    general_max: number;
    lastWins: number[];
    image: string;
    isNew: boolean;
    isPopular: boolean;
    isFavorite: boolean;
    providerId: string;
}

export interface IGameProvider {
    id: string;
    name: string;
}

export interface IGameProviderResponse {
    _id: string;
    name: string;
}

export interface IGetGameProviderResponse {
    providers: IGameProviderResponse[];
}

export enum ExternalGameType {
    slots = "slots",
    liveDealer = "liveDealer",
}
